import { useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TInnerCircle } from '../BaseInnerCircle'
import { TEditInnerCircle } from './types'

enum QueryKeys{
  InnerCircleEdit = 'InnerCircleEdit',
  InnerCircleSingle = 'InnerCircleSingle'
}

async function getSingleInnerCircle(innerCircleId: string): Promise<TInnerCircle> {
  try {
    const response = await apiClient(`app/inner-circle/${innerCircleId}`)
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useGetSingleInnerCircleQuery = () => {
  const { innerCircleId } = useParams()
  const keys = [innerCircleId, QueryKeys.InnerCircleSingle]
  return useQuery<TInnerCircle, Error>(
    keys,
    () => getSingleInnerCircle(innerCircleId!),
    {
      enabled: !!innerCircleId,
      cacheTime: 0
    }
  )
}

async function editInnerCircle(data: TEditInnerCircle, innerCircleId: string): Promise<void> {
  try {
    await apiClient.put(`app/inner-circle/${innerCircleId}`, data)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useEditInnerCircleMutation = () => {
  const { innerCircleId } = useParams()
  const key = [QueryKeys.InnerCircleEdit]
  const queryClient = useQueryClient()
  return useMutation<void, Error, TEditInnerCircle>(
    (data) => editInnerCircle(data, innerCircleId!),
    {
      mutationKey: key,
      onSuccess: () => queryClient.invalidateQueries(['InnerCircleList'])
    }
  )
}
