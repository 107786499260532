import { NavLink, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './UserNavigationMenu.module.scss'
import { navigationBaseKey } from '../../constants/translate'
import NavigationDropDownMenu from '../NavigationDropDownMenu/NavigationDropDownMenu'
import { NotificationMenu } from '../../../../features/notificationMenu'
import CloseFillIcon from '../../../../shared/components/icons/CloseFill'
import HamburgerIcon from '../../../../shared/components/icons/HamburgerIcon'
import {
  useAnalyticsNavigationOptions,
  usePlanningNavigationOptions,
  useTradingNavigationOptions
} from '../../../../features/navigationBoard'
import { useMainLayoutContext } from '../../state/MainLayoutState'
import { useTablet } from '../../../../shared/hooks/useDevice'
import { useAuthContext } from '../../../../features/auth/authBase'
import DropDownMenu from '../DropDownMenu/DropDownMenu'
import { TMenuOptions } from '../../types'

export function useUserMenuOptions() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const userMenuOptions: TMenuOptions[] = [
    {
      label: t(`${navigationBaseKey}.user.myAccount`),
      handleSelect: () => navigate('/my-account')
    },
    {
      label: t(`${navigationBaseKey}.user.myOrders`),
      handleSelect: () => navigate('/my-orders')
    },
    {
      label: t(`${navigationBaseKey}.user.mySells`),
      handleSelect: () => navigate('/my-sells')
    },
    {
      label: t(`${navigationBaseKey}.user.innerCircle`),
      handleSelect: () => navigate('/inner-circle')
    },
    {
      label: t(`${navigationBaseKey}.settings`),
      handleSelect: () => navigate('/settings')
    },
    {
      label: t(`${navigationBaseKey}.user.recommend`),
      handleSelect: () => window.open('https://www.freshtrade.ch/invite', '_blank')?.focus()
    },
    {
      label: t(`${navigationBaseKey}.user.logout`),
      handleSelect: () => navigate('/logout')
    }
  ]

  return userMenuOptions
}

const UserNavigationMenu = () => {
  const { isSidebar, setIsSidebar } = useMainLayoutContext()
  const isTablet = useTablet()
  const { authData } = useAuthContext()
  const handleToggle = () => setIsSidebar(!isSidebar)
  const tradingNavigationOptions = useTradingNavigationOptions()
  const analyticsNavigationOptions = useAnalyticsNavigationOptions()
  const planningNavigationOptions = usePlanningNavigationOptions()
  const { t } = useTranslation()
  const userDropDownOptions = useUserMenuOptions()
  return (
    <>
      {
        authData && (
          !isTablet
            ? (
              <>
                <div className={styles.container}>
                  <NavLink end className={styles.navigation} to="/dashboard">
                    {t(`${navigationBaseKey}.dashboard`)}
                  </NavLink>
                  <NavigationDropDownMenu
                    options={planningNavigationOptions}
                    mainTo="/planning"
                    mainLabel={t('navigationBoard.planning.header')}
                  />
                  <NavigationDropDownMenu
                    options={tradingNavigationOptions}
                    mainTo="/trading"
                    mainLabel={t('navigationBoard.trading.header')}
                  />
                  <NavigationDropDownMenu
                    options={analyticsNavigationOptions}
                    mainTo="/analytics"
                    mainLabel={t('navigationBoard.analytics.header')}
                  />
                </div>
                <div className={classNames(styles.container, styles.gap)}>
                  <NotificationMenu/>
                  <DropDownMenu options={userDropDownOptions}/>
                </div>
              </>
            )
            : (
              <div className={classNames(styles.container, styles.gap)}>
                {!isSidebar && <NotificationMenu/>}
                <button type="button" onClick={handleToggle} className={styles.icon}>
                  {isSidebar
                    ? <CloseFillIcon width={32} height={32}/>
                    : <HamburgerIcon/>}
                </button>
              </div>
            )
        )
      }
    </>
  )
}

export default UserNavigationMenu
