import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import { FC, useMemo } from 'react'
import CustomSelectControl
  from '../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { TProductLabelItem } from '../../types'
import { prepareProductSelectValue } from '../../utils/productLabelUtils'
import styles from './ProductLabelSelect.module.scss'

function useProductLabelOptions(
  optionsData?: TProductLabelItem[]
) {
  const { t } = useTranslation()

  return useMemo(() => {
    if (!optionsData || !optionsData.length) {
      return []
    }

    return optionsData.map((item) => ({
      label: `${item.product.name} (${item.label.name})`,
      value: prepareProductSelectValue(item)
    }))
  }, [optionsData])
}

type TProps = {
  control: Control<any>
  optionsData?: TProductLabelItem[]
  multiSelect?: boolean
}

const ProductLabelSelect: FC<TProps> = ({
  control,
  optionsData,
  multiSelect
}) => {
  const { t } = useTranslation()
  const options = useProductLabelOptions(optionsData)
  return (
    <CustomSelectControl
      control={control}
      multiple={multiSelect}
      options={options}
      name={multiSelect ? 'products' : 'product'}
      label={t('dashboard.product')}
      selectAllLabel={multiSelect
        ? t('dashboard.weeklyAnalytics.allProducts') as string
        : ''}
      selectAll={!!multiSelect}
      disabled={!optionsData?.length}
      placeholder={
        multiSelect && optionsData?.length
          ? t('dashboard.weeklyAnalytics.selectProduct')
          : <div className={styles.noProduct}>{t('dashboard.weeklyAnalytics.noProducts')}</div>
      }
    />
  )
}

export default ProductLabelSelect
