import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import {
  TEditMutationParams,
  TFilter,
  TRegistrationRequestsStatusParams,
  TUser,
  TUserDetails,
  TUserStatusParams
} from './types'
import { useNotificationContext } from '../../notifications'

enum QueryKeys{
  Users = 'Users',
  UserDetails = 'UserDetails',
  RegistrationRequestsUserDetails = 'RegistrationRequestsUserDetails',
  CheckEmail = 'CheckEmail'
}

async function users(page: number, filter: TFilter): Promise<TUser> {
  try {
    const response = await apiClient(`/admin/users?status=${filter}`, { params: { page } })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export function useUsersQuery(page: number, filter: TFilter) {
  const keys = [QueryKeys.Users, filter, page]
  const enabled = ['ACTIVE', 'DEACTIVATED'].includes(filter)

  return useQuery<TUser, Error>(
    keys,
    () => users(page, filter),
    {
      enabled,
      keepPreviousData: true
    }
  )
}

async function registrationRequests(page: number, filter: TFilter) {
  try {
    const response = await apiClient(`admin/users/registration-requests?status=${filter}`, { params: { page } })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export function useRegistrationRequestsQuery(page: number, filter: TFilter) {
  const keys = [QueryKeys.Users, filter, page]
  const enabled = ['PENDING_APPROVAL', 'REJECTED'].includes(filter)

  return useQuery<TUser, Error>(
    keys,
    () => registrationRequests(page, filter),
    {
      enabled,
      keepPreviousData: true
    }
  )
}
async function userDetails(id?: string): Promise<TUserDetails> {
  try {
    const response = await apiClient(`admin/users/${id}`)
    return response.data
  } catch (error: any) {
    throw new Error(error)
  }
}

export function useUserDetailsQuery(id?: string) {
  const keys = [QueryKeys.UserDetails, id]

  return useQuery<TUserDetails, Error>(
    [keys],
    () => userDetails(id),
    {
      enabled: !!id, cacheTime: 0, staleTime: 0
    }
  )
}

async function userStatus(params: TUserStatusParams): Promise<void> {
  try {
    const response = await apiClient.put(`admin/users/${params.userId}/status?status=${params.status}`)
    return response.data
  } catch (error: any) {
    throw new Error(error)
  }
}

export function useUserStatusMutation() {
  const queryClient = useQueryClient()
  return useMutation<void, Error, TUserStatusParams>(
    (params) => userStatus(params),
    {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.Users])
    }
  )
}

async function checkEmail(email: string): Promise<boolean> {
  try {
    const response = await apiClient(`auth/email/validate?email=${email}`)
    return response.data
  } catch (error: any) {
    throw new Error(error)
  }
}

export function useCheckEmailMutation() {
  const keys = [QueryKeys.CheckEmail]
  return useMutation<boolean, Error, string>(

    (email) => checkEmail(email),
    {
      mutationKey: keys
    }
  )
}

async function editUser({ userId, ...rest }: TEditMutationParams): Promise<boolean> {
  try {
    const response = await apiClient.put(`/admin/users/${userId}`, rest)
    return response.data
  } catch (error: any) {
    throw new Error(error)
  }
}

export function useEditUserMutation(userId?: string) {
  const queryClient = useQueryClient()

  return useMutation<boolean, Error, TEditMutationParams>(
    (params) => editUser(params), {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.Users])
        await queryClient.invalidateQueries([QueryKeys.UserDetails, userId])
      }
    }
  )
}

async function registrationRequestsStatus(
  params: TRegistrationRequestsStatusParams
): Promise<boolean> {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await apiClient.put(`/admin/users/registration-requests/${params.userId}/status?status=${params.status}`)
    return response.data
  } catch (error: any) {
    throw error
  }
}
export function useRegistrationRequestsStatusMutation() {
  const queryClient = useQueryClient()
  const { showErrorNotification } = useNotificationContext()
  return useMutation<boolean, Error, TRegistrationRequestsStatusParams >(
    (params) => registrationRequestsStatus(params),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([QueryKeys.RegistrationRequestsUserDetails])
        await queryClient.invalidateQueries([QueryKeys.Users])
      },
      onError: (error) => {
        showErrorNotification(error?.message)
      }
    }
  )
}

async function registrationRequestUserDetails(id?: string): Promise<TUserDetails> {
  try {
    const response = await apiClient(`/admin/users/registration-requests/${id}`)
    return response.data
  } catch (error: any) {
    throw new Error(error)
  }
}

export function useRegistrationRequestUserDetails(id?: string) {
  const keys = [QueryKeys.RegistrationRequestsUserDetails, id]

  return useQuery<TUserDetails, Error>(
    [keys],
    () => registrationRequestUserDetails(id),
    {
      enabled: !!id
    }
  )
}
