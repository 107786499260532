import { FC, memo } from 'react'
import PrimaryButton from '../buttons/PrimaryButton/PrimaryButton'
import styles from './NoData.module.scss'

type TProps = {
  title: string
  buttonText: string
  handleClick: () => void
}
const NoData: FC<TProps> = ({ title, handleClick, buttonText }) => (
  <div className={styles.wrapper}>
    <p className={styles.title}>{title}</p>
    <div className={styles.buttonWrapper}>
      <PrimaryButton
        onClick={handleClick}
        text={buttonText}/>
    </div>
  </div>
)

export default memo(NoData)
