import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import styles from './NavigationBoard.module.scss'
import { Navigation } from '../../../../analytics/dashboard'

type TProps = {
  title: string
  heading: string
  description: string
  navigations: {
    to: string
    text: string
  }[]
}
const NavigationBoard: FC<TProps> = ({ title, heading, description, navigations }) => {
  const { t } = useTranslation()
  return (
    <PrimaryLayout maxHeight size="large" header={<div className={styles.heading}>{heading}</div>}>
      <div className={styles.content}>
        <div className={styles.dashboardInfo}>
          <div className={styles.title}>{title}</div>
          <div className={styles.description}>{description}</div>
        </div>
        <div className={styles.navigationWrap}>
          <div className={styles.navigationText}>{t('navigationBoard.startNowWith')}</div>
          {
            navigations.map(({ to, text }) => (
              <Navigation key={to} to={to} text={text}/>
            ))
          }
        </div>
      </div>
    </PrimaryLayout>
  )
}

export default NavigationBoard
