import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { NavigationBoard } from '../../../BaseNavigatioBoard'

export function usePlanningNavigationOptions() {
  const { t } = useTranslation()
  return useMemo(() => [
    {
      to: '/growing-plan',
      text: t('mainLayout.navigation.growingPlan')
    },
    {
      to: '/quantity-reports',
      text: t('mainLayout.navigation.quantityReports')
    },
    {
      to: '/crop-stock',
      text: t('mainLayout.navigation.crop/stock')
    }
  ], [t])
}

const Planning = () => {
  const { t } = useTranslation()
  const navigationOptions = usePlanningNavigationOptions()

  return (
    <NavigationBoard
      navigations={navigationOptions}
      heading={t('navigationBoard.planning.header')}
      description={t('navigationBoard.planning.description')}
      title={t('navigationBoard.planning.title')}
    />
  )
}

export default Planning
