import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { useMemo } from 'react'
import CustomSelectChip
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectChip/CustomSelectChip'
import { useCompaniesListQuery } from '../../../../../growingPlan/BaseGrowingPlanWorkShop/queries'
import { useInnerCircleListFinderQuery } from '../../../queries'

function useOptions() {
  const { t } = useTranslation()
  const { data: companiesListData } = useCompaniesListQuery()
  const { data: innerCircleListData } = useInnerCircleListFinderQuery()
  return useMemo(() => {
    if (!companiesListData || !innerCircleListData) {
      return []
    }
    const companiesList = companiesListData.map(({ id, name }) => ({
      value: id,
      label: name
    }))
    const innerCircleList = innerCircleListData.map(({ id, title }) => ({
      value: id,
      label: title
    }))
    return [
      {
        groupName: t('sell.workShop.selects.innerCircle.innerCircles'),
        options: innerCircleList
      },
      {
        groupName: t('sell.workShop.selects.innerCircle.companies'),
        options: companiesList
      }]
  },
  [companiesListData, innerCircleListData])
}

const InnerCircleChipSelect = () => {
  const { control } = useFormContext()
  const options = useOptions()
  const { t } = useTranslation()
  return (
    <CustomSelectChip
      name="innerCircle"
      control={control}
      placeholder={t('sell.workShop.selects.innerCircle.placeholder')}
      handleError={(error) => (error as any)?.innerCircle?.root}
      groupedOptions={options}/>
  )
}

export default InnerCircleChipSelect
