import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { FC, useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import PrimaryLayout from '../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import styles from './Settings.module.scss'
import PrimaryButton from '../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { TDeliveryDays, TSettingsForm, TSettingsSeller } from '../../types'
import {
  useDeliveryDaysQuery,
  useDeliveryDaysUpdateMutation, useSettingsSellersMutation,
  useSettingsSellersQuery
} from '../../queries'
import Spinner from '../../../../shared/components/Spinner/Spinner'
import DaysSettings from '../DaysSettings/DaysSettings'
import CustomerSettings from '../CustomerSettings/CustomerSettings'
import {
  prepareDaysOfWeekMutation,
  prepareSettingsSellersForm,
  prepareSettingsSellersMutation
} from '../../utils/settingsUtils'

type TProps = {
  deliveryDaysData: TDeliveryDays
  settingsSellersData: TSettingsSeller[]
}

function useFormInit({ deliveryDaysData, settingsSellersData }: TProps) {
  return useForm<TSettingsForm>({
    defaultValues: { ...deliveryDaysData, ...prepareSettingsSellersForm(settingsSellersData) }
  })
}

function useOnSubmit() {
  const { mutate: deliveryDaysMutationAsync } = useDeliveryDaysUpdateMutation()
  const { mutate: settingsSellerMutationAsync } = useSettingsSellersMutation()
  return useCallback(async (formData: TSettingsForm) => {
    await deliveryDaysMutationAsync(prepareDaysOfWeekMutation(formData))
    await settingsSellerMutationAsync(prepareSettingsSellersMutation(formData))
  }, [])
}

const Settings: FC<TProps> = ({ deliveryDaysData, settingsSellersData }) => {
  const { key } = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { control, watch, getValues } = useFormInit({
    deliveryDaysData,
    settingsSellersData
  })
  const weekdays = watch()
  const isUpdateAble = useMemo(() => Object.values(weekdays).some((day) => day), [weekdays])

  const submit = useOnSubmit()

  const handleNavigate = () => {
    const isNavigationHistory = key === 'default'
    if (isNavigationHistory) {
      navigate('/')
    } else {
      navigate(-1)
    }
  }

  return (
    <div>
      <PrimaryLayout header={<div className={styles.title}>{t('deliverydays.title')}</div>} size="large" >
        <div className={styles.wrapper}>
          <DaysSettings control={control}/>
          <CustomerSettings control={control}/>
        </div>

        <div className={styles.buttonGroup}>
          <PrimaryButton onClick={() => submit(getValues())} disabled={!isUpdateAble} text={t('common.update')}/>
          <SecondaryButton onClick={handleNavigate} text={t('common.cancel')}/>
        </div>
      </PrimaryLayout>
    </div>
  )
}

export default () => {
  const { data: deliveryDaysData,
    isLoading: isDeliveryDaysLoading } = useDeliveryDaysQuery()
  const { data: settingsSellersData,
    isLoading: isSettingsSellersLoading } = useSettingsSellersQuery()
  if (isDeliveryDaysLoading
    || isSettingsSellersLoading
    || !settingsSellersData
    || !deliveryDaysData) {
    return (
      <div className={styles.spinnerWrap}>
        <Spinner/>
      </div>
    )
  }
  return (
    <Settings
      deliveryDaysData={deliveryDaysData}
      settingsSellersData={settingsSellersData}
    />
  )
}
