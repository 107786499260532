import { useTranslation } from 'react-i18next'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import Body from '../Body/Body'
import { Header } from '../../../baseAnalytics'
import { PlanningForecastProvider } from '../../state/PlanningForecastState'
import Footer from '../Footer/Footer'

const PlanningForecast = () => {
  const { t } = useTranslation()
  return (
    <div>
      <PrimaryLayout size="large" header={<Header title={t('planningForecast.label')}/>}>
        <Body/>
        <Footer/>
      </PrimaryLayout>
    </div>
  )
}

export default () => (
  <PlanningForecastProvider>
    <PlanningForecast/>
  </PlanningForecastProvider>
)
