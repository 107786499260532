export const integerFormat = (value: string) => value && value.replace(/\D/g, '')
export const floatFormat = (value: string, round: number = 2) => {
  if (value !== '') {
    const valueToInt = Number(value)
    const isFloat = value.at(-1) === '.'
    const numberAfterPoint = value.split('.')?.[1]
    const isZeroAfterPoint = numberAfterPoint?.[0] === '0'

    if (numberAfterPoint?.length > round) {
      return Number(value.substring(0, value.length - 1))
    }

    if (valueToInt && !isFloat && !isZeroAfterPoint) {
      return value
    }

    return !isFloat && !isZeroAfterPoint
      ? Number(value.substring(0, value.length - 1))
      : value
  }
  return value
}

export const addZeros = (value: string | number, minimumFractionDigits: number = 2) => {
  const formattedValue = typeof value === 'string' ? Number(value) : value
  return formattedValue?.toLocaleString('en', {
    useGrouping: false,
    minimumFractionDigits
  })
}
