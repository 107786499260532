import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import styles from './QuantityDetails.module.scss'
import { TOrdersCalendarDataItem } from '../../../types'
import DetailsEditModal from '../../DetailsEditModal/DetailsEditModal'

type TProps = {
  item: TOrdersCalendarDataItem
}

const QuantityDetails: FC<TProps> = ({ item }) => {
  const { t } = useTranslation()
  const [modalData, setModalData] = useState<TOrdersCalendarDataItem>()
  const handleDetails = () => setModalData(item)

  return (
    <>
      <DetailsEditModal
        details={modalData}
        onClose={() => setModalData(undefined)}
      />
      <SecondaryButton
        text={t('quantity.calendar.details')}
        className={styles.buttonText}
        onClick={handleDetails}
      />
    </>
  )
}

export default QuantityDetails
