import { FC, SyntheticEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './AccordionSummary.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import DeleteInnerCircle from '../DeleteInnerCircle/DeleteInnerCircle'

type TProps = {
  id: string
  title: string
  description?: string
  icon: JSX.Element
}
const AccordionSummary: FC<TProps> = ({
  icon,
  id,
  description,
  title
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleEdit = (event: SyntheticEvent) => {
    event.stopPropagation()
    navigate(`/inner-circle/edit/${id}`)
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.iconWrap}>
          {icon}
        </div>
        <h1 className={styles.title}>{title}</h1>
        {
          description && (
            <>
              <div className={styles.circle}/>
              <p className={styles.description}>{description}</p>
            </>
          )
        }
      </div>
      <div className={styles.buttonGroup}>
        <PrimaryButton
          onClick={handleEdit}
          text={t('common.edit')}/>
        <DeleteInnerCircle id={id} title={title}/>
      </div>
    </div>
  )
}

export default AccordionSummary
