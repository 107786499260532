import { TForm } from '../../BaseInnerCircle'
import { TEditInnerCircle } from '../types'

type TParams = {
  initialCompanyIds: string[]
} & TForm
export const prepareEditInnerCircle = ({
  title,
  description,
  companyIds,
  initialCompanyIds }: TParams): TEditInnerCircle => {
  const companyToRemoveIds = initialCompanyIds.filter((id) => !companyIds.includes(id))

  return {
    title,
    description,
    companyToRemoveIds,
    companyIds
  }
}
