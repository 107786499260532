import { useTranslation } from 'react-i18next'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import { AuthLayout } from '../../../../../layouts/primaryLayout'
import { TAccountEditForm, TAccountEditMutationParams } from '../../types'
import { useAccountDetailsQuery } from '../../../accountBase'
import { setForm } from '../../utils/setForm'
import styles from './AccountEdit.module.scss'
import { useEditAccountMutation } from '../../queries'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { prepareAttachment, useUploadAttachment } from '../../../../attechment'
import CustomCheckbox from '../../../../../shared/components/formControls/CustomCheckbox/CustomCheckbox'
import FirstName from '../../../../../shared/components/formFields/FirstName'
import CompanyName from '../../../../../shared/components/formFields/CompanyName'
import LastName from '../../../../../shared/components/formFields/LastName'
import CompanyUid from '../../../../../shared/components/formFields/CompanyUid'
import Address from '../../../../../shared/components/formFields/Address'
import ImageFileUpload from '../../../../../shared/components/formControls/FileUpload/ImageFileUpload/ImageFileUpload'
import Zip from '../../../../../shared/components/formFields/Zip'
import City from '../../../../../shared/components/formFields/City'
import Phone from '../../../../../shared/components/formFields/Phone'
import Password from '../../../../../shared/components/formFields/Password'
import MatchingPassword from '../../../../../shared/components/formFields/MatchingPassword'
import Email from '../../../../../shared/components/formFields/Email'
import { prepareDataAccountEditMutation } from '../../utils/accountEditMutation'
import Iban from '../../../../../shared/components/formFields/Iban'
import Invoice from '../../../../../shared/components/formFields/Invoice'
import { TAttachment } from '../../../../attechment/types'

function useFormInit() {
  const { data } = useAccountDetailsQuery()
  return useForm<TAccountEditForm>({
    defaultValues: useMemo(() => {
      if (!data) {
        return undefined
      }
      return setForm(data)
    }, [data])
  })
}

function useResetForm({ reset, formState }: UseFormReturn<TAccountEditForm>) {
  const { data } = useAccountDetailsQuery()
  useEffect(() => {
    if (!data || Object.values(formState.defaultValues || {}).length) {
      return
    }
    reset(setForm(data))
  }, [data])
}

function useOnSubmit(
  uploadAttachmentMutateAsync: UseMutateAsyncFunction<string, Error, TAttachment>,
  accountEditMutateAsync: UseMutateAsyncFunction<void, Error, TAccountEditMutationParams>
) {
  const { data: accountDetailsData } = useAccountDetailsQuery()

  return useCallback(async (data: TAccountEditForm) => {
    const { file } = data

    if (!accountDetailsData) {
      return
    }

    const preparedDataAccountEdit = prepareDataAccountEditMutation(data, accountDetailsData)

    if (file) {
      const logoId = await uploadAttachmentMutateAsync(prepareAttachment(file))
      await accountEditMutateAsync({
        ...preparedDataAccountEdit,
        company: { ...preparedDataAccountEdit.company, logoId }
      })
      return
    }
    await accountEditMutateAsync(preparedDataAccountEdit)
  }, [accountDetailsData, accountEditMutateAsync, uploadAttachmentMutateAsync])
}

export function useClearPasswordsError({ watch, trigger }: UseFormReturn<TAccountEditForm>) {
  const password = watch('password')
  const matchingPassword = watch('matchingPassword')
  useEffect(() => {
    if (!password && !matchingPassword) {
      trigger('password')
      trigger('matchingPassword')
    }
  }, [password, matchingPassword])
}

const AccountEdit = () => {
  const {
    mutateAsync: uploadAttachmentMutateAsync,
    isLoading: uploadAttachmentIsLoading
  } = useUploadAttachment()
  const {
    mutateAsync: accountEditMutateAsync,
    isLoading: isLoadingAccountEdit
  } = useEditAccountMutation()
  const navigate = useNavigate()
  const onsubmit = useOnSubmit(uploadAttachmentMutateAsync, accountEditMutateAsync)
  const { t } = useTranslation()
  const useFormReturn = useFormInit()
  useResetForm(useFormReturn)
  useClearPasswordsError(useFormReturn)

  const { handleSubmit, watch, control, setValue } = useFormReturn

  const password = watch('password')
  const matchingPassword = watch('matchingPassword')

  const isValidationRules = !!password || !!matchingPassword
  const removeImageForm = () => {
    setValue('companyLogoId', undefined)
    setValue('companyLogoUrl', undefined)
  }
  return (
    <AuthLayout size="medium" header={t('account.edit.label')}>
      <FormProvider {...useFormReturn}>
        <form className={styles.form} onSubmit={handleSubmit(onsubmit)}>
          <FirstName/>
          <CompanyName/>
          <LastName/>
          <CompanyUid/>
          <Address/>
          <Iban/>
          <Invoice/>
          <div className={styles.inputGroup}>
            <Zip/>
            <City/>
          </div>
          <Phone/>
          <ImageFileUpload
            imageWatch={watch('companyLogoUrl')}
            label={t('signUp.fileUploadLabel')}
            removeImageForm={removeImageForm} />
          <Email isDisabled/>
          <div className={styles.passwordContainer}>
            <h2 className={styles.title}>{t('common.password')}</h2>
            <div className={styles.passwordInputGroup}>
              <Password
                label={t('account.edit.newPassword')}
                isRequired={isValidationRules}
                isPasswordValidation={isValidationRules}/>
              <MatchingPassword
                label={t('account.edit.confirmNewPassword')}
                isRequired={isValidationRules}
                isConfirmPasswordValidation={isValidationRules}/>
            </div>
          </div>
          <div className={styles.checkBoxContainer}>
            <CustomCheckbox control={control} name="subscribeToNewsLetters"/>
            <span className={styles.label}>{t('signUp.subscribe')}</span>
          </div>
          <div className={styles.buttonGroup}>
            <PrimaryButton
              disabled={isLoadingAccountEdit || uploadAttachmentIsLoading}
              type="submit"
              text={t('account.edit.update')}
            />
            <SecondaryButton
              onClick={() => navigate('/my-account')}
              text={t('common.cancel')}/>
          </div>
        </form>
      </FormProvider>
    </AuthLayout>
  )
}

export default AccountEdit
