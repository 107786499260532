import { useTranslation } from 'react-i18next'
import { FormProvider, useForm } from 'react-hook-form'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import styles from './OfferFooter.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import { useGetProduct } from '../../queries'
import { TOfferLocalData, TForm } from '../../types'
import { OFFER_LOCAL_STORAGE_KEY } from '../../../../../shared/constants/localeStorage'
import Bid from '../Bid/Bid'
import { useAuthContext } from '../../../../auth/authBase'

function useFormInit() {
  return useForm<TForm>({
    defaultValues: {
      amountTu: '',
      priceChf: ''
    }
  })
}

function useOnSubmit() {
  const { data: productData } = useGetProduct()
  const [, setOffer] = useLocalStorage<TOfferLocalData>(OFFER_LOCAL_STORAGE_KEY)
  const navigate = useNavigate()
  return useCallback(
    (data: TForm) => {
      const { negotiation, pricePerCu } = productData!

      setOffer({
        amountTu: Number(data.amountTu),
        priceCHF: negotiation ? Number(data?.priceChf) : pricePerCu!
      })
      navigate('bid-offer')
    },
    []
  )
}

const OfferFooter = () => {
  const { data } = useGetProduct()
  const { t } = useTranslation()
  const useFormReturn = useFormInit()
  const { authData } = useAuthContext()
  const onSubmit = useOnSubmit()
  const {
    discount,
    minDiscountAmountTu,
    negotiation
  } = data!

  return (
    <FormProvider {...useFormReturn}>
      <div className={styles.wrapper}>
        {discount && minDiscountAmountTu && authData && (
          <p className={styles.description}>
            {t('buy.offer.footer.description', { discount, minDiscountAmountTu })}
          </p>
        )}
        <Bid/>
        {
          authData && (
            <div className={styles.buttonWrap}>
              <PrimaryButton
                onClick={useFormReturn.handleSubmit(onSubmit)}
                text={t(`buy.offer.footer.${negotiation ? 'placeMyBid' : 'buyNow'}`)}/>
            </div>
          )
        }

      </div>
    </FormProvider>
  )
}

export default OfferFooter
