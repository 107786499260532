import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import UserDetailsModal from '../UserDetailsModal/UserDetailsModal'
import styles from './UserDetails.module.scss'

type TProps = {
  id: string
  isModalAction?: boolean
  isRegistrationRequest?: boolean
}
const UserDetails: FC<TProps> = ({ id, isModalAction, isRegistrationRequest }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <>
      <SecondaryButton className={styles.button} onClick={() => setOpen(true)}>{t('admin.users.table.details')}</SecondaryButton>
      <UserDetailsModal
        isRegistrationRequest={isRegistrationRequest}
        isModalAction={isModalAction}
        id={id}
        isOpen={open}
        onClose={() => setOpen(false)}/>
    </>
  )
}

export default UserDetails
