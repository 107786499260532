import Accordion from '../Accordion/Accordion'
import styles from './WeekView.module.scss'
import { usePanningForecastQuery } from '../../queries'
import { usePlanningForecastContext } from '../../state/PlanningForecastState'
import { dotDateFormat, startOfWeekCustom } from '../../../../../shared/utils/date'
import { prepareProductLabel } from '../../../weeklyReportsAnalytics'
import Spinner from '../../../../../shared/components/Spinner/Spinner'

const WeekView = () => {
  const { formReturn, productLabel } = usePlanningForecastContext()
  const { date, products } = formReturn.watch()
  const { data, isFetching, isLoading } = usePanningForecastQuery(
    dotDateFormat(startOfWeekCustom(date)),
    prepareProductLabel(products, productLabel)
  )

  if (isFetching && isLoading) {
    return (
      <div className={styles.spinnerWrap}>
        <Spinner/>
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      {
        data?.map((props) => (
          <Accordion
            key={`${props.product.id}_${props.label}_${props.forecasts}`}
            {...props}/>
        ))
      }

    </div>
  )
}

export default WeekView
