import { UseFormReturn } from 'react-hook-form'
import { useMemo } from 'react'
import isEqual from 'lodash/isEqual'
import { useIsEditMode, useIsOrderDraft } from './editModeHooks'
import { TWeeklyOrderDataItem } from '../../WeeklyReport/types'

export function useControlsDisableState(
  initialFormData: any,
  { watch }: UseFormReturn<any>,
  quantityIndex: number,
  order?: TWeeklyOrderDataItem,
  onlyDay?: string
) {
  const formData = watch()
  const hasFormInitialValue = useMemo(() =>
    isEqual(initialFormData.orderItems[quantityIndex], formData.orderItems[quantityIndex]),
  [formData, initialFormData])
  const isEditMode = useIsEditMode()
  const isDraft = useIsOrderDraft(order)

  const editableForCurrentUser = onlyDay
    ? order?.dailyOrders
      ?.find((daily) => daily.date === onlyDay)?.editableForCurrentUser ?? true
    : order?.editableForCurrentUser ?? true

  return {
    isSubmitDisabled: isEditMode && !isDraft
        && (!editableForCurrentUser || hasFormInitialValue),
    isConfirmDisabled:
      isEditMode
      && (!editableForCurrentUser || !hasFormInitialValue),
    isSaveForLaterDisabled: order && (!editableForCurrentUser || hasFormInitialValue)
  }
}
