import { Control, RegisterOptions, useController } from 'react-hook-form'
import { FC } from 'react'
import { FormControl, FormControlLabel, Radio, RadioGroup, FormLabel as MuiFormLabel } from '@mui/material'
import get from 'lodash/get'
import { TOption } from '../CustomSelect/CustomSelectBase/CustomSelectBase'
import styles from './CustomRadioGroup.module.scss'
import RadioIcon from '../../icons/RadioIcon'
import RadioCheckedIcon from '../../icons/RadioCheckIcon'

export type TOptionRadio = {
  disabled?: boolean
} & TOption

type TProps = {
  control: Control<any>
  name: string
  rules?: RegisterOptions
  label?: string
  options: TOptionRadio[]
  handleChange?: (value: string) => void
}
const CustomRadioGroup: FC<TProps> = ({
  control,
  name,
  rules,
  label,
  options,
  handleChange
}) => {
  const {
    field,
    formState: { errors }
  } = useController({
    control,
    name,
    rules
  })
  const { onChange, ...rest } = field
  return (
    <FormControl className={styles.formControlRoot}>
      {label && <MuiFormLabel className={styles.label}>{label}</MuiFormLabel>}
      <RadioGroup
        {...rest}
        onChange={(event, value) => {
          onChange(value)
          handleChange?.(value)
        }}
        className={styles.radioGroup}>
        {
          options.map(({ value, label, disabled }) => (
            <FormControlLabel
              className={styles.formControlLabel}
              key={value}
              value={value}
              label={label}
              disabled={disabled}
              control={(
                <Radio
                  checkedIcon={<RadioCheckedIcon/>}
                  icon={<RadioIcon/>}
                  className={styles.radio}
                />
              )}
            />
          ))
        }
      </RadioGroup>
      {!!get(errors, name)
        && (
          <p className={styles.errorText}>
            {get((errors as any), name)?.message}
          </p>
        )}
    </FormControl>
  )
}

export default CustomRadioGroup
