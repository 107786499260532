import { useTranslation } from 'react-i18next'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import styles from './ProductLabelLayout.module.scss'
import { AddProductLabel, ProductLabelList } from '../../index'
import { ProductLabelProvider, useProductLabelContext } from '../../state/ProductLabelState'
import { TProductLabelProps } from '../../types'

const ProductLabelLayout = () => {
  const { t } = useTranslation()
  const { type } = useProductLabelContext()

  return (
    <div>
      <PrimaryLayout
        size="medium"
        header={(
          <div className={styles.headerWrap}>
            <h1 className={styles.title}>{t(`admin.${type}s.title`)}</h1>
            <AddProductLabel/>
          </div>
        )}>
        <ProductLabelList/>
      </PrimaryLayout>
    </div>
  )
}

export default (props: TProductLabelProps) => (
  <ProductLabelProvider {...props}>
    <ProductLabelLayout/>
  </ProductLabelProvider>
)
