import classNames from 'classnames'
import { FC, memo } from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styles from './ActiveAccordionSummary.module.scss'
import ProductSelect from '../selects/ProductSelect/ProductSelect'
import PlanRemove from '../PlanRemove/PlanRemove'
import UnitSelect from '../selects/UnitSelect/UnitSelect'
import { useMobile } from '../../../../../shared/hooks/useDevice'

type TProps = {
  isDeleteButton: boolean
  icon: JSX.Element
  control: Control<any>
  planIndex: number
  removePlan: () => void
  total: number
  isPlanOrderExist: boolean
}
const ActiveAccordionSummary: FC<TProps> = ({
  icon,
  isDeleteButton,
  control,
  planIndex,
  removePlan,
  isPlanOrderExist,
  total
}) => {
  const { t } = useTranslation()
  const isMobile = useMobile()
  return (
    <div className={styles.planGroup}>
      <div className={styles.productGroup}>
        <div className={classNames(styles.iconArrow)}>
          {icon}
        </div>
        <ProductSelect disabled={isPlanOrderExist} control={control} planIndex={planIndex}/>
        {
          isMobile && isDeleteButton
          && <PlanRemove removePlan={removePlan}/>
        }
      </div>
      <div className={styles.inputGroup}>
        <UnitSelect disabled={isPlanOrderExist} control={control} planIndex={planIndex}/>
        <div className={styles.amountWrapper}>
          <p className={styles.amountLabel}>
            {t('growingPlan.workShop.amountYear')}
          </p>
          <p className={styles.amountDescription}>{total}</p>
        </div>
      </div>
      {
        (!isMobile && isDeleteButton)
        && <PlanRemove removePlan={removePlan}/>
      }
    </div>
  )
}

export default memo(ActiveAccordionSummary)
