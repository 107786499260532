import { useMutation } from '@tanstack/react-query'
import { TOfferParams } from '../BaseOffer'
import { apiClient } from '../../Interceptor'

async function createOffer(
  { data, publish }: {data: TOfferParams, publish?: boolean}
): Promise<void> {
  try {
    await apiClient.post(`app/offer?publish=${publish}`, data)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useCreateOfferMutation = () => useMutation<
  void, Error, { data: TOfferParams, publish: boolean }
>(
  (params) => createOffer(params)
)
