import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import ProductLabelLayout from '../../../baseAdmin/components/ProductLabelLayout/ProductLabelLayout'
import {
  useCreateProductMutation,
  useProductsQuery,
  useProductStatusMutation,
  useUpdateProductMutation
} from '../../queries'
import { prepareProductLabelData } from '../../../baseAdmin/utils/baseAdminUtils'

const Products = () => {
  const [page, setPage] = useState(1)
  const { data, isLoading } = useProductsQuery(page - 1)
  const { mutateAsync: addProduct } = useCreateProductMutation()
  const { mutateAsync: editProduct } = useUpdateProductMutation()
  const { mutateAsync: productStatus } = useProductStatusMutation()

  return (
    <ProductLabelLayout
      type="product"
      data={data}
      setPage={setPage}
      isLoading={isLoading}
      handleStatus={(data) => productStatus(data)}
      handleEdit={async (data) => {
        const { id, ...rest } = data
        await editProduct({ ...prepareProductLabelData(rest), id })
      }}
      handleAdd={async (data) => addProduct(prepareProductLabelData(data))}/>
  )
}

export default Products
