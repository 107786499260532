import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TProductLabelTranslation, TSingleProductLabel } from '../baseAdmin'
import { TEditProductLabel, TProductLabelList, TProductLabelStatus } from '../baseAdmin/types'
import { useNotificationContext } from '../../notifications'

enum QueryKeys{
  Products = 'Products',
  Product = 'Product'
}

async function getProduct(id?: string): Promise<TSingleProductLabel> {
  try {
    const response = await apiClient(`app/admin/products/${id}`)
    return response.data
  } catch (error: any) {
    throw new Error(error)
  }
}

export function useGetProductQuery(id?: string) {
  const keys = [QueryKeys.Product, id]
  return useQuery<TSingleProductLabel, Error>(keys, () => getProduct(id), { enabled: !!id })
}

async function getProducts(page: number): Promise<TProductLabelList> {
  try {
    const response = await apiClient('app/admin/products', { params: { page } })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export function useProductsQuery(page: number) {
  return useQuery<TProductLabelList, Error>([QueryKeys.Products, page], () => getProducts(page), {
    cacheTime: 0,
    keepPreviousData: true
  })
}

async function updateProduct({ id, ...rest }: TEditProductLabel): Promise<void> {
  // eslint-disable-next-line no-useless-catch
  try {
    await apiClient.put(`app/admin/products/${id}`, {
      localizations: rest })
  } catch (e: any) {
    throw e
  }
}

export function useUpdateProductMutation() {
  const queryClient = useQueryClient()
  return useMutation<void, Error, TEditProductLabel>(
    (params) => updateProduct(params),
    {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.Products])
    }
  )
}

async function createProduct(product: TProductLabelTranslation): Promise<void> {
  // eslint-disable-next-line no-useless-catch
  try {
    await apiClient.post('app/admin/products', {
      localizations: product })
  } catch (error: any) {
    throw error
  }
}

export function useCreateProductMutation() {
  const queryClient = useQueryClient()
  return useMutation<void, Error, TProductLabelTranslation>(
    (params: TProductLabelTranslation) => createProduct(params),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QueryKeys.Products])
      }
    }
  )
}

async function productStatus(params: TProductLabelStatus): Promise<void> {
  try {
    await apiClient.put(`/app/admin/products/${params.id}/status?status=${params.status}`)
  } catch (error: any) {
    throw error
  }
}

export function useProductStatusMutation() {
  const queryClient = useQueryClient()
  const { showErrorNotification } = useNotificationContext()
  return useMutation<void, Error, TProductLabelStatus>((params) => productStatus(params), {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.Products])
    },
    onError: (error) => {
      showErrorNotification(error?.message)
    }
  })
}
