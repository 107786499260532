import { Control } from 'react-hook-form'
import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CustomSelectChip
  from '../../../../../shared/components/formControls/CustomSelect/CustomSelectChip/CustomSelectChip'
import styles from './CompaniesChipSelect.module.scss'
import { TForm } from '../../types'
import { useCompaniesListQuery } from '../../../../growingPlan/BaseGrowingPlanWorkShop/queries'

function useOptions() {
  const { data } = useCompaniesListQuery()

  return useMemo(
    () => (data?.length
      ? data.map(({ id, name }) => ({
        value: id,
        label: name
      }))
      : []),
    [data]
  )
}
type TProps = {
  control: Control<TForm>
}
const CompaniesChipSelect: FC<TProps> = ({ control }) => {
  const { t } = useTranslation()
  const options = useOptions()
  return (
    <div className={styles.wrapper}>
      <CustomSelectChip
        label={`${t('innerCircle.create.companies')} *`}
        placeholder={t('innerCircle.create.selectCompanies')}
        name="companyIds"
        control={control}
        handleError={(error) => (error as any)?.companyIds?.root}
        options={options}/>
    </div>
  )
}

export default CompaniesChipSelect
