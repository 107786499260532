import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { NavLink } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import { UseMutateFunction } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import styles from './Login.module.scss'
import { TLoginForm } from '../../types'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import { useLoginMutation } from '../../queries'
import { TAuthData } from '../../../authBase'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import Email from '../../../../../shared/components/formFields/Email'
import Password from '../../../../../shared/components/formFields/Password'

function useFormInit() {
  return useForm<TLoginForm>({
    defaultValues: {
      email: '',
      password: ''
    }
  })
}
function useOnSubmit(mutate: UseMutateFunction<TAuthData, Error, TLoginForm>) {
  return useCallback((data: TLoginForm) => {
    mutate(data)
  }, [mutate])
}

function useErrorHandling(
  { setError }: UseFormReturn<TLoginForm>,
  isLoginError: boolean,
  loginError: any
) {
  useEffect(() => {
    if (isLoginError) {
      setError('email', { message: loginError?.message })
      setError('password', { message: '' })
    }
  }, [loginError?.message, isLoginError, setError])
}

const Login = () => {
  const { t } = useTranslation()
  const { mutate, isError, error, isLoading } = useLoginMutation()
  const useFormReturn = useFormInit()
  const onSubmit = useOnSubmit(mutate)
  useErrorHandling(useFormReturn, isError, error)
  const { handleSubmit } = useFormReturn

  return (
    <PrimaryLayout header={t('common.login')}>
      <FormProvider {...useFormReturn}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Email isEmailValidation={false}/>
          <div className={styles.container}>
            <Password isPasswordValidation={false}/>
            <NavLink className={styles.navigateForgotPassword} to="/forgot-password">
              {t('login.forgotPassword')}
            </NavLink>
          </div>
          <PrimaryButton disabled={isLoading} type="submit" text={t('common.login')}/>
          <p className={styles.text}>
            {t('login.noAccount')}
            <NavLink to={`/sign-up${window.location.search}`} className={styles.navigateSignUp}>
              {t('common.signUp')}
            </NavLink>
          </p>
        </form>
      </FormProvider>
    </PrimaryLayout>
  )
}

export default Login
