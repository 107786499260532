import { useNavigate } from 'react-router-dom'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './ReviewWeeklyReport.module.scss'
import { TOrdersCalendarDataItem } from '../../../types'
import { useQuantityReportsContext } from '../../../state/QuantityReportsState'
import SecondaryButton from '../../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'

type TProps = {
  item: TOrdersCalendarDataItem
}
const ReviewWeeklyReport: FC<TProps> = ({ item: { startDate, endDate } }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { formMethods: { getValues } } = useQuantityReportsContext()
  const handleNavigate = () => {
    const { buyer, seller, growingPlan } = getValues()
    navigate({
      pathname: `weekly/${growingPlan}`,
      search: `&buyerId=${buyer}&sellerId=${seller}&dateFrom=${startDate}&dateTo=${endDate}`
    })
  }

  return (
    <SecondaryButton
      text={t('quantity.calendar.reviewWeeklyReport')}
      onClick={handleNavigate}
      className={styles.buttonText}
    />
  )
}

export default ReviewWeeklyReport
