import { useSearchParams } from 'react-router-dom'
import { useIsEditMode } from './editModeHooks'
import { dateFromDotDateFormat } from '../../../../shared/utils/date'

export function useDate(firstWeekDate?: string) {
  const [searchParams] = useSearchParams()
  const searchParamDate = searchParams.get('date') || undefined

  return useIsEditMode()
    ? firstWeekDate ? dateFromDotDateFormat(firstWeekDate) : undefined
    : searchParamDate ? dateFromDotDateFormat(searchParamDate) : undefined
}
