import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './PlanningForecast.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import CustomDatePicker from '../../../../../shared/components/formControls/CustomDatePicker/CustomDatePicker'
import { useDashboardContext } from '../../state/DashboardState'
import { ProductInfo, ProductLabelSelect, useGetProductLabelQuery } from '../../../baseAnalytics'
import Spinner from '../../../../../shared/components/Spinner/Spinner'
import { dotDateFormat } from '../../../../../shared/utils/date'

const PlanningForecast = () => {
  const { t } = useTranslation()
  const {
    formReturn: { control, watch, getValues },
    stockForecast: { data, isLoading, isFetching }
  } = useDashboardContext()
  const navigate = useNavigate()
  const { data: productSelectData } = useGetProductLabelQuery(dotDateFormat(watch('date')))

  const handleNavigate = () => {
    const { date } = getValues()
    navigate(`/dashboard/planning-forecast?date=${dotDateFormat(date)}&dashboard=true`)
  }

  if (isLoading && isFetching) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }

  const isPositiveForecast = data && data.forecast > 0 ? true : undefined
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t('dashboard.planningForecast')}</h1>
      <div className={styles.selectsGroup}>
        <ProductLabelSelect control={control} optionsData={productSelectData} key={watch('product')}/>
        <CustomDatePicker name="date" control={control} label={t('dashboard.date')}/>
      </div>
      {
        data && (
          <>
            <div className={styles.productOverview}>
              <ProductInfo label={t('dashboard.stock')} value={`${data.stockAmount} ${t(`units.${data.unit.toLowerCase()}`)}`}/>
              {data.ordersBuyAmount ? (
                <ProductInfo
                  label={t('dashboard.weeklyReportsBuy')}
                  value={`+ ${data.ordersBuyAmount} ${t(`units.${data.unit.toLowerCase()}`)}`}/>
              ) : null}
              {
                data.ordersSellAmount ? (
                  <ProductInfo
                    label={t('dashboard.weeklyReportsSell')}
                    value={`- ${data.ordersSellAmount} ${t(`units.${data.unit.toLowerCase()}`)}`}/>
                ) : null
              }
              <div className={styles.line}/>
              <ProductInfo
                label={<p className={styles.label}>{t('dashboard.forecast')}</p>}
                value={(
                  <p className={styles[isPositiveForecast ? 'positive' : 'negative']}>
                    {isPositiveForecast ? '+' : null}
                    {`${data.forecast} ${t(`units.${data.unit.toLowerCase()}`)}`}
                  </p>
                )}
              />
            </div>
            <div className={styles.buttonWrap}>
              <PrimaryButton onClick={handleNavigate} text={t('dashboard.analyticsOverview')}/>
            </div>
          </>
        )
      }
    </div>
  )
}

export default PlanningForecast
