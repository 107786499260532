import { useSearchParams } from 'react-router-dom'
import { useReportIdParam } from './useReportIdParam'
import { TWeeklyOrderDataItem } from '../../WeeklyReport/types'

export function useIsEditMode() {
  return !!useReportIdParam()
}

export function useIsDraft() {
  const [searchParams] = useSearchParams()
  return !!searchParams.get('draft')
}

export const useIsOrderDraft = (order?: TWeeklyOrderDataItem) => order?.status === 'DRAFT'

export const useIsEditableForUser = (order?: TWeeklyOrderDataItem, onlyDay?: string) => {
  if (!order) return true

  return (onlyDay
    ? !!order?.dailyOrders?.find((daily) => daily.date === onlyDay)?.editableForCurrentUser
    : !!order?.editableForCurrentUser)
}
