import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FC, memo } from 'react'
import styles from './CropStockHeader.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'

type TProps = {
  isCreateButton: boolean
}
const CropStockHeader: FC<TProps> = ({ isCreateButton }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={styles.wrapper}>
      <div>{t('cropStock.label')}</div>
      {isCreateButton && (
        <div className={styles.buttonWrapper}>
          <PrimaryButton
            onClick={() => navigate('/crop-stock/create')}
            text={t('cropStock.addNewProduct')}/>
        </div>
      )}
    </div>
  )
}

export default memo(CropStockHeader)
