import { Control, Controller } from 'react-hook-form'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { FC } from 'react'
import fr from 'date-fns/locale/fr'
import en from 'date-fns/locale/en-US'
import de from 'date-fns/locale/de'
import it from 'date-fns/locale/it'
import 'dayjs/locale/ar-sa'
import { DefaultTFuncReturn } from 'i18next'
import { InputAdornment, TextField } from '@mui/material'
import styles from './CustomDatePicker.module.scss'
import DateIcon from '../../icons/DateIcon'
import { useValidationRules } from '../../../hooks/useValidationRules'
import { DOT_DATE_FORMAT } from '../../../constants/date'
import { TLanguage } from '../../../../features/account/accountBase'
import { useLanguageSwitcherContext } from '../../../../features/languageSwitcher'

type TProps = {
  control: Control<any>,
  name: string
  label?: DefaultTFuncReturn
  isRequired?: boolean
}
const locales: {[key in TLanguage]: Locale} = {
  en, fr, de, it
}

const CustomDatePicker: FC<TProps> = ({ control, name, label, isRequired }) => {
  const { requiredRule, dateValidation } = useValidationRules()
  const { language } = useLanguageSwitcherContext()

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locales[language]}>
      <Controller
        control={control}
        rules={{
          required: isRequired ? requiredRule() : undefined,
          validate: dateValidation
        }}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div>
            <p className={styles.label}>{label}</p>
            <DatePicker
              inputFormat={DOT_DATE_FORMAT}
              onChange={onChange}
              value={value || ''}
              views={['year', 'month', 'day']}
              components={{
                OpenPickerIcon: DateIcon
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end"><DateIcon /></InputAdornment>
              }}
              closeOnSelect
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: DOT_DATE_FORMAT
                  }}
                  className={styles.textField}
                  error={!!error?.message}
                  helperText={error?.message}
                />
              )}
            />
          </div>
        )}
      />
    </LocalizationProvider>
  )
}

export default CustomDatePicker
