import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './CropStockList.module.scss'
import Spinner from '../../../../../shared/components/Spinner/Spinner'
import { useCropStockListQuery } from '../../queries'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import NoData from '../../../../../shared/components/NoData/NoData'
import CropStockOverview from '../CropStockOverview/CropStockOverview'
import CropStockHeader from '../CropStockHeader/CropStockHeader'
import CropStockUpdateModal from '../CropStockUpdateModal/CropStockUpdateModal'
import { CropStockModalStateProvider } from '../../state/CropStockModalState'
import CropStockEditModal from '../CropStockEditModal/CropStockEditModal'
import CropStockDeleteModal from '../CropStockDeleteModal/CropStockDeleteModal'

const CropStockList = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data, isLoading } = useCropStockListQuery()

  if (isLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }

  return (
    <div className={!data?.length && styles.wrapper}>
      <CropStockDeleteModal/>
      <CropStockEditModal/>
      <CropStockUpdateModal/>
      <PrimaryLayout
        header={<CropStockHeader isCreateButton={!!data?.length}/>}
        maxHeight
        size="large">
        {
          data?.length
            ? <CropStockOverview/>
            : (
              <NoData
                handleClick={() => navigate('/crop-stock/create')}
                title={t('cropStock.noCropStock')}
                buttonText={t('cropStock.addNewProduct')}
              />
            )
        }
      </PrimaryLayout>
    </div>
  )
}
export default () => (
  <CropStockModalStateProvider>
    <CropStockList/>
  </CropStockModalStateProvider>
)
