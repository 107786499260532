import constate from 'constate'
import { useCallback, useMemo } from 'react'
import { useFieldArray, UseFormReturn } from 'react-hook-form'
import { TForm, TProductLabel } from '../types'
import { generateProductPlan } from '../utils/generateProductPlan'
import { useGrowingPlanAccordionContext } from './GrowingPlanAccordionState'
import { generateTableDefaultData } from '../utils/generateTableDefaultData'
import { useLabelListQuery } from '../queries'

const totalTableCell: TProductLabel = {
  name: 'TOTAL',
  id: 'TOTAL',
  locale: 'en',
  status: 'ACTIVE'
}
function setAccordionValue(index: number, activeAccordion?: number) {
  if (activeAccordion === undefined) {
    return undefined
  }
  if (index > activeAccordion) {
    return activeAccordion
  }
  if (index < activeAccordion) {
    return activeAccordion - 1
  }
  if (index === activeAccordion) {
    return undefined
  }
  return undefined
}

type TProps = {
  formReturn: UseFormReturn<any>
  handleSubmit: (data: TForm) => void
  isGrowingPlanOrderExist?: boolean
}

const GrowingPlanWorkShopFormState = ({
  formReturn,
  handleSubmit,
  isGrowingPlanOrderExist
}: TProps) => {
  const fieldArrayReturn = useFieldArray({ control: formReturn.control, name: 'productPlans' })
  // eslint-disable-next-line prefer-const
  let { setActiveAccordion, activeAccordion } = useGrowingPlanAccordionContext()
  const { data: labelsData } = useLabelListQuery()

  const tableDefaultValues = useMemo(() => {
    if (!labelsData) {
      return []
    }

    return generateTableDefaultData([...labelsData, totalTableCell])
  }, [labelsData])

  const defaultProductPlan = useMemo(() => {
    if (!labelsData) {
      return []
    }
    return generateProductPlan(labelsData)
  }, [labelsData])

  return {
    appendPlan: useCallback(
      () => {
        fieldArrayReturn.append(defaultProductPlan)
        setActiveAccordion(fieldArrayReturn.fields.length)
      },
      [fieldArrayReturn]
    ),
    removePlan: useCallback(
      (index: number) => {
        fieldArrayReturn.remove(index)
        setActiveAccordion(setAccordionValue(index, activeAccordion))
      },
      [activeAccordion, fieldArrayReturn, setActiveAccordion]
    ),
    fieldArrayReturn,
    formReturn,
    handleSubmit,
    isGrowingPlanOrderExist: !!isGrowingPlanOrderExist,
    tableDefaultValues,
    labelsData
  }
}

export const [
  GrowingPlanWorkShopFormProvider,
  useGrowingPlanWorkShopFormContext
] = constate(GrowingPlanWorkShopFormState)
