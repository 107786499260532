import { useTranslation } from 'react-i18next'
import { memo } from 'react'
import classNames from 'classnames'
import styles from './GrowingPlanWorkShopHeader.module.scss'
import CustomInput from '../../../../../shared/components/formControls/CustomInput/CustomInput'
import CustomTextArea from '../../../../../shared/components/formControls/CustomTextArea/CustomTextArea'
import CustomerSelect from '../selects/CustomerSelect/CustomerSelect'
import YearSelect from '../selects/YearSelect/YearSelect'
import { useGrowingPlanWorkShopFormContext } from '../../state/GrowingPlanWorkShopFormState'
import { useValidationRules } from '../../../../../shared/hooks/useValidationRules'
import CustomTimePicker from '../../../../../shared/components/formControls/CustomTimePicker/CustomTimePicker'

const GrowingPlanWorkShopHeader = () => {
  const { t } = useTranslation()
  const {
    formReturn: { control,
      formState: { errors }
    },
    isGrowingPlanOrderExist } = useGrowingPlanWorkShopFormContext()
  const { requiredRule } = useValidationRules()

  return (
    <div className={styles.wrapper}>
      <div className={styles.main}>
        <div className={styles.group}>
          <CustomInput
            name="title"
            control={control}
            label={`${t('growingPlan.workShop.title')} *`}
            rules={{
              required: requiredRule()
            }}
          />
          <CustomerSelect disabled={isGrowingPlanOrderExist} control={control}/>
        </div>
        <div className={styles.textAreaWrapper}>
          <CustomTextArea
            label={t('growingPlan.workShop.description')}
            control={control}
            name="description"/>
        </div>
      </div>
      <YearSelect disabled={isGrowingPlanOrderExist} control={control}/>
      <div className={styles.orderWrapper}>
        <p className={styles.label}>{`${t('growingPlan.workShop.dailyOrder')} *`}</p>
        <div className={classNames(
          styles.timeContainer,
          { [styles.alignStart]: errors?.dailyOrderTime }
        )}>
          <p className={styles.label}>{t('growingPlan.workShop.timeLabel')}</p>
          <div className={styles.timeInput}>
            <CustomTimePicker control={control} name="dailyOrderTime"/>
          </div>
          {errors?.dailyOrderTime
            && <p className={styles.errorText}>{(errors as any)?.dailyOrderTime.message}</p>}
        </div>
      </div>
    </div>
  )
}

export default memo(GrowingPlanWorkShopHeader)
