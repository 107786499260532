import { FC, memo } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import styles from './WeeklyDailyHeader.module.scss'

type TProps = {
    title: string
    description: string
    goBack: () => void
}
const WeeklyDailyHeader: FC<TProps> = ({ title, description, goBack }) => (
  <div className={styles.wrapper}>
    <div className={styles.titleWrapper}>
      <button
        onClick={goBack}
        type="button"
        className={styles.iconWrap}>
        <ArrowBackIcon/>
      </button>
      <div>{title}</div>
    </div>

    <div className={styles.description}>{description}</div>
  </div>
)

export default memo(WeeklyDailyHeader)
