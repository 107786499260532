import { FC } from 'react'
import { ReactComponent as Icon } from './svg/Hamburger.svg'
import { IconWrapper } from './iconsBase/IconWrapper/IconWrapper'

const HamburgerIcon: FC = () => (
  <IconWrapper>
    <Icon/>
  </IconWrapper>
)

export default HamburgerIcon
