import { useTranslation } from 'react-i18next'
import { FC, useCallback, useMemo } from 'react'
import DangerousButton from '../../../../../shared/components/buttons/DangerousButton/DangerousButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import styles from './CropStockDeleteModal.module.scss'
import { useCropStockFiltered, useDeleteCropStockMutation } from '../../queries'
import { useCropStockModalContext } from '../../state/CropStockModalState'
import { TCropStockMutationParams } from '../../types'
import Spinner from '../../../../../shared/components/Spinner/Spinner'
import Modal from '../../../../../shared/components/Modal/Modal'

function useHandleDelete() {
  const { modalState, handleClose } = useCropStockModalContext()
  const { mutateAsync } = useDeleteCropStockMutation()

  return useCallback(() => {
    if (!modalState?.productStockId || !modalState?.transactionId) {
      return
    }
    mutateAsync({
      transactionId: modalState.transactionId,
      productStockId: modalState.productStockId
    }).then(handleClose)
  }, [modalState?.productStockId, modalState?.transactionId])
}

function useProductText() {
  const { modalState } = useCropStockModalContext()
  const { t } = useTranslation()
  const { data } = useCropStockFiltered(modalState as TCropStockMutationParams)

  return useMemo(
    () => (data && {
      description: t('cropStock.delete.description', {
        productName: data.product.name,
        returnObjects: true
      }) as string,
      title: t('cropStock.delete.title', {
        date: data.date,
        returnObjects: true
      }) as string,
      productTitle: data.title
    }),
    [data]
  )
}

const CropStockDeleteModal: FC = () => {
  const { t } = useTranslation()
  const { modalState, handleClose } = useCropStockModalContext()
  const productText = useProductText()
  const handleDelete = useHandleDelete()

  return (
    <Modal
      isOpen={modalState?.type === 'DELETE'}
      onClose={handleClose}>
      {
        productText ? (
          <div>
            <h1 className={styles.title}>{productText.title}</h1>
            <p className={styles.description}>
              {productText.description}
              <span className={styles.bold}>{` ${productText.productTitle} ?`}</span>
            </p>
            <div className={styles.line}/>
            <div className={styles.buttonGroup}>
              <DangerousButton onClick={handleDelete} text={t('common.delete')}/>
              <SecondaryButton onClick={handleClose} text={t('common.cancel')}/>
            </div>
          </div>
        ) : (
          <div className={styles.spinnerWrapper}>
            <Spinner/>
          </div>
        )
      }
    </Modal>
  )
}

export default CropStockDeleteModal
