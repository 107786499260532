import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import logo from '../../assets/Logo.png'
import adminLogo from '../../assets/adminLogo.png'
import { useAuthContext } from '../../../../features/auth/authBase'
import InnerLayout from '../Inner/InnerLayout'

import { useMainLayoutContext } from '../../state/MainLayoutState'
import styles from './Header.module.scss'

import UserNavigationMenu from '../UserNavigationMenu/UserNavigationMenu'
import AdminNavigationMenu from '../AdminNavigationMenu/AdminNavigationMenu'

const Header = () => {
  const { isNonAuth } = useMainLayoutContext()
  const { authData } = useAuthContext()

  const { t } = useTranslation()
  const { pathname } = useLocation()

  const isAdmin = authData?.user?.role === 'ADMIN'
  return (
    <div className={styles.wrapper}>
      <InnerLayout borderBottomLine contentClass={styles.innerContent}>
        <div className={classNames(styles.content, {
          [styles.adminContent]: isAdmin
        })}>
          <img
            className={classNames(styles.logo, {
              [styles.adminLogo]: isAdmin
            })}
            src={isAdmin ? adminLogo : logo}
            alt="logo" />

          {isAdmin ? <AdminNavigationMenu/> : <UserNavigationMenu/>}

          {isNonAuth && !authData && (
            <NavLink className={styles.loginNavigation} to={`/login?redirect=${pathname}`}>
              {t('login.loginInNow')}
            </NavLink>
          )}
        </div>

      </InnerLayout>
    </div>
  )
}

export default Header
