import { useTranslation } from 'react-i18next'
import Accordion from '../Accordion/Accordion'
import { useWeeklyReportsAnalyticsContext } from '../../state/WeeklyReportsAnalyticsState'
import styles from './Footer.module.scss'
import Spinner from '../../../../../shared/components/Spinner/Spinner'

const Footer = () => {
  const { t } = useTranslation()
  const { analyticsWeekly: { data, isLoading, isFetching } } = useWeeklyReportsAnalyticsContext()

  if (isFetching && isLoading) {
    return (
      <div className={styles.spinnerWrap}>
        <Spinner/>
      </div>
    )
  }

  if (data && !data?.length) {
    return <></>
  }

  return (
    <div className={styles.wrapper}>
      {
        data && data.length ? data.map((weeklyAnalytics) => (
          <Accordion
            defaultExpanded
            key={weeklyAnalytics.product.id}
            analyticsWeeklyData={weeklyAnalytics}/>
        ))
          : <p className={styles.noDataText}>{t('dashboard.weeklyAnalytics.table.noData')}</p>
      }
    </div>

  )
}

export default Footer
