import { useTranslation } from 'react-i18next'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import Calendar from '../Calendar/Calendar'
import styles from './QuantityReports.module.scss'
import { QuantityReportsProvider } from '../../state/QuantityReportsState'
import QuantityReportHeader from '../QuantityReportHeader/QuantityReportHeader'

const QuantityReports = () => {
  const { t } = useTranslation()

  return (
    <div>
      <PrimaryLayout
        header={<div className={styles.label}>{t('quantity.quantityReports')}</div>}
        noPadding
        size="large"
      >
        <div>
          <QuantityReportHeader/>
          <Calendar/>
        </div>
      </PrimaryLayout>
    </div>
  )
}

export default () => (
  <QuantityReportsProvider>
    <QuantityReports/>
  </QuantityReportsProvider>
)
