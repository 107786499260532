import React, { FC, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import styles from './DeactivateProductLabel.module.scss'
import { DeactivateModal } from '../../index'

type TProps = {
  name: string
  handleDeactivate: () => Promise<void>
}
const DeactivateProductLabel: FC<TProps> = ({ name, handleDeactivate }) => {
  const { t } = useTranslation()
  const [isModal, setIsModal] = useState(false)
  const onClose = useCallback(() => {
    setIsModal(false)
  }, [])

  return (
    <>
      <SecondaryButton className={styles.button} onClick={() => setIsModal(true)}>
        {t('admin.users.table.deactivate')}
      </SecondaryButton>
      <DeactivateModal
        handleDeactivate={() => handleDeactivate().finally(onClose)}
        onClose={onClose}
        name={name}
        isOpen={isModal}/>
    </>
  )
}

export default DeactivateProductLabel
