import { useTranslation } from 'react-i18next'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useCallback, useEffect, useMemo } from 'react'
import { format } from 'date-fns'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import styles from './CropStockEditModal.module.scss'
import { ChangeCropStockFormField } from '../../../BaseCropStock'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { useCropStockModalContext } from '../../state/CropStockModalState'
import { useCropStockFiltered, useEditCropStockMutation } from '../../queries'
import { TCropStockFilteredResponse, TCropStockMutationParams, TEditCropStockMutationParams, TForm } from '../../types'
import { DOT_DATE_FORMAT } from '../../../../../shared/constants/date'
import { prepareFormData } from '../../utils/prepareFormData'
import Spinner from '../../../../../shared/components/Spinner/Spinner'
import Modal from '../../../../../shared/components/Modal/Modal'

export function useFormInit(formData?: TCropStockFilteredResponse) {
  return useForm<TForm>({
    defaultValues: useMemo(() => {
      if (!formData) {
        return undefined
      }
      return prepareFormData(formData)
    }, [formData])
  })
}

export function useFormReset(
  useFormReturn: UseFormReturn<TForm>,
  isLoading: boolean,
  formData?: TCropStockFilteredResponse

) {
  useEffect(() => {
    if (!formData) {
      return undefined
    }
    return useFormReturn.reset(prepareFormData(formData))
  }, [formData, isLoading])
}

function useOnSubmit(
  { handleSubmit }: UseFormReturn<TForm>,
  mutateAsync: UseMutateAsyncFunction<void, Error, TEditCropStockMutationParams>
) {
  const { modalState, handleClose } = useCropStockModalContext()

  return useCallback((data: TForm) => {
    if (!modalState?.productStockId || !modalState?.transactionId) {
      return
    }
    mutateAsync({
      ...data,
      date: format(new Date(data?.date!), DOT_DATE_FORMAT),
      productStockId: modalState.productStockId,
      transactionId: modalState.transactionId!
    }).then(handleClose)
  }, [modalState?.productStockId, modalState?.transactionId])
}

const CropStockEditModal = () => {
  const { mutateAsync, isLoading: isEditCropStockLoading } = useEditCropStockMutation()
  const { t } = useTranslation()
  const { modalState, handleClose } = useCropStockModalContext()
  const { data, isLoading } = useCropStockFiltered(modalState as TCropStockMutationParams)
  const useFormReturn = useFormInit(data)
  useFormReset(useFormReturn, isLoading, data)

  const onSubmit = useOnSubmit(useFormReturn, mutateAsync)
  const { handleSubmit } = useFormReturn

  const isModalContent = !isLoading && data

  return (
    <Modal
      large
      isOpen={modalState?.type === 'EDIT'}
      onClose={handleClose}
      fullWidth
      modalClass={styles.modal}
    >
      { isModalContent ? (
        <>
          <h1 className={styles.title}>
            {`
          ${t('cropStock.editCropStock')} - ${data?.product.name} 
          (${data ? data.label.name : ''})
          `}
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ChangeCropStockFormField control={useFormReturn.control}/>
            <div className={styles.line}/>
            <div className={styles.buttonGroup}>
              <PrimaryButton disabled={isEditCropStockLoading} type="submit" text={t('common.save')}/>
              <SecondaryButton onClick={handleClose} text={t('common.cancel')}/>
            </div>
          </form>
        </>
      ) : (
        <div className={styles.spinnerWrapper}>
          <Spinner/>
        </div>
      )}
    </Modal>
  )
}

export default CropStockEditModal
