import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import { FC } from 'react'
import styles from './DaysSettings.module.scss'
import Header from '../Header/Header'
import { useDeliveryDaysQuery } from '../../queries'
import LabelCheckbox from '../LabelCheckbox/LabelCheckbox'

type TProps = {
  control: Control<any>
}
const DaysSettings: FC<TProps> = ({ control }) => {
  const { t } = useTranslation()
  const { data } = useDeliveryDaysQuery()

  if (!data) {
    return <></>
  }

  return (
    <div className={styles.wrapper}>
      <Header
        title={t('deliveryDaysSettings.title')}
        descriptionBold={t('deliveryDaysSettings.descriptionBold')}
        description={t('deliveryDaysSettings.description')}
      />
      <div className={styles.checkBoxWrap}>
        {
          Object.keys(data).map((day) => (
            <LabelCheckbox
              control={control}
              key={day}
              name={day}
              label={t(`quantity.${day.toLowerCase()}`)}/>
          ))
        }
      </div>
    </div>
  )
}

export default DaysSettings
