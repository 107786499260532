import { useForm, UseFormReturn } from 'react-hook-form'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SellWorkShop, TForm } from '../../../BaseOffer'
import { useEditOfferMutation, useGetOfferByIdQuery } from '../../queries'
import { prepareOfferForm } from '../../utils/prepareOfferForm'
import styles from './EditOffer.module.scss'
import Spinner from '../../../../../shared/components/Spinner/Spinner'

function useFormInit() {
  const { data } = useGetOfferByIdQuery()

  return useForm<TForm>({
    defaultValues: useMemo(() => {
      if (!data) {
        return undefined
      }
      return prepareOfferForm(data)
    }, [data])
  })
}

function useFormReset({ reset, formState }: UseFormReturn<TForm>) {
  const { data } = useGetOfferByIdQuery()
  useEffect(() => {
    if (!data || Object.values(formState.defaultValues || {}).length) {
      return
    }
    reset(prepareOfferForm(data))
  }, [data])
}

const EditOffer = () => {
  const { data, isLoading } = useGetOfferByIdQuery()
  const useFormReturn = useFormInit()
  useFormReset(useFormReturn)
  const { mutateAsync } = useEditOfferMutation()
  const { t } = useTranslation()
  if (isLoading || !data) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }
  return (
    <SellWorkShop
      label={t('sell.workShop.editOffer')}
      key={Object.values(useFormReturn.formState.defaultValues || {}).length}
      onSubmit={mutateAsync}
      useFormReturn={useFormReturn}/>
  )
}

export default EditOffer
