import { FC, memo } from 'react'
import CustomInput from '../../../../../../shared/components/formControls/CustomInput/CustomInput'
import { useGrowingPlanWorkShopFormContext } from '../../../state/GrowingPlanWorkShopFormState'
import styles from './InputCell.module.scss'
import { floatFormat } from '../../../../../../shared/utils/formUtils'

type TProps = {
  baseName: string
}
const InputCell: FC<TProps> = ({ baseName }) => {
  const name = `${baseName}.value`
  const { formReturn: { control, setValue, watch } } = useGrowingPlanWorkShopFormContext()
  const handleBlur = (value: string) => { setValue(name, value === '' ? 0 : Number(value)) }
  const disabled = watch(`${baseName}.ordersExist`)

  return (
    <div className={styles.wrapper}>
      <CustomInput
        disabled={disabled}
        onBlur={({ target: { value } }) => handleBlur(value)}
        name={name}
        handleChange={floatFormat}
        control={control}
      />
    </div>
  )
}

export default memo(InputCell)
