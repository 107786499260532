import { useForm } from 'react-hook-form'
import { formDefaultValues } from '../constansts/formDefaultValues'
import { TProductLabelTranslation } from '../types'

export function useFormInit(defaultValues?: TProductLabelTranslation, shouldUnregister?: boolean) {
  return useForm({
    defaultValues: defaultValues || formDefaultValues,
    shouldUnregister
  })
}
