import { useMemo } from 'react'
import { Column } from 'react-table'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import format from 'date-fns/format'
import styles from './OrderListTable.module.scss'
import { Table as CustomTable } from '../../../../shared/components/table'
import { dateFromDotDateTimeFormat } from '../../../../shared/utils/date'
import { DOT_DATE_TIME_FORMAT } from '../../../../shared/constants/date'
import { TStatus } from '../../types'
import { useOrderListQuery } from '../../queries'
import { addZeros } from '../../../../shared/utils/formUtils'

const statusMap: {[key in TStatus]: string} = {
  CONFIRMED: 'confirmed',
  DECLINED: 'declined',
  NEGOTIATING: 'negotiating'
}
const OrderListTable = () => {
  const { data: listOffers } = useOrderListQuery()
  const { t } = useTranslation()

  const columns = useMemo<Column[]>(() =>
    [
      {
        Header: <div className={classNames(styles.tableHeader, styles.product)}>{t('buy.myOrders.list.product')}</div>,
        accessor: 'product.name',
        Cell: ({ value }) => <span className={styles.text}>{value}</span>
      },
      {
        Header: <div className={classNames(styles.tableHeader, styles.label)}>{t('buy.myOrders.list.label')}</div>,
        accessor: 'label',
        Cell: ({ value }) => <span className={styles.text}>{value.name}</span>

      },
      {
        Header: <div className={classNames(styles.tableHeader, styles.amountTu)}>{t('buy.myOrders.list.amountInTu')}</div>,
        accessor: 'amountTu',
        Cell: ({ value }) => <span className={styles.text}>{value}</span>

      },
      {
        Header: <div className={classNames(styles.tableHeader, styles.totalPrice)}>{t('buy.myOrders.list.totalPrice')}</div>,
        accessor: 'totalInclVat',
        Cell: ({ value }) => <span className={styles.text}>{`${t('common.chf')} ${addZeros(value)}`}</span>
      },
      {
        Header: <div className={classNames(styles.tableHeader, styles.orderDate)}>{t('buy.myOrders.list.orderDate')}</div>,
        accessor: 'dateTime',
        Cell: ({ value }) => (
          <span className={styles.text}>
            {format(dateFromDotDateTimeFormat(value), DOT_DATE_TIME_FORMAT)}
          </span>
        ) },
      {
        Header: <div className={classNames(styles.statusHeader, styles.tableHeader, styles.noMarginRight)}>{t('buy.myOrders.list.status')}</div>,
        accessor: 'status',
        Cell: ({ value }) => (
          <span
            className={classNames(styles.text, styles.status, styles[statusMap[value as TStatus]])}>
            {t(`buy.myOrders.status.${statusMap[value as TStatus]}`)}
          </span>
        )
      }

    ], [listOffers])
  return (
    <div className={styles.tableWrapper}>
      <CustomTable
        data={listOffers || []}
        contentClass={styles.table}
        columns={columns}
      />
    </div>
  )
}

export default OrderListTable
