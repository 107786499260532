import { Control } from 'react-hook-form'
import { FC } from 'react'
import CustomInput from '../../../../../../shared/components/formControls/CustomInput/CustomInput'
import { useValidationRules } from '../../../../../../shared/hooks/useValidationRules'
import { useBaseQuantityFormContext } from '../../../state/BaseQuantityFormState'
import { floatFormat } from '../../../../../../shared/utils/formUtils'

type TProps = {
  disabled: boolean
  quantityIndex: number
  control: Control<any>
  name: string,
  label: string
  rules?: any
}
const AmountInput: FC<TProps> = ({
  quantityIndex, control,
  name, label, disabled,
  rules }) => {
  const { formReturn: { formState: { errors } } } = useBaseQuantityFormContext()
  const inputErrorName = (errors as any)?.orderItems?.[quantityIndex]?.[name]

  return (
    <CustomInput
      disabled={disabled}
      rules={rules}
      onClick={(event) => event.stopPropagation()}
      label={label}
      name={`orderItems.[${quantityIndex}].${name}`}
      control={control}
      handleChange={(value) => floatFormat(value, 3)}
      error={!!inputErrorName}
      helperText={inputErrorName?.message ? inputErrorName?.message : ''}
    />
  )
}

export default AmountInput
