import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useMemo } from 'react'
import styles from './ViewToggle.module.scss'
import { useQuantityReportsContext } from '../../state/QuantityReportsState'
import { useGrowingPlanYear } from '../QuantityReportHeader/QuantityReportHeader'

function useIsWeekViewDisabled() {
  const growingPlanYear = useGrowingPlanYear()
  const { formMethods: { watch } } = useQuantityReportsContext()
  const month = watch('month')
  const year = growingPlanYear || watch('year')

  return useMemo(() => {
    const date = new Date()
    return date.getMonth() !== Number(month) || date.getFullYear() !== Number(year)
  }, [month, year])
}

const ViewToggle = () => {
  const { view, handleView } = useQuantityReportsContext()
  const isWeekViewDisabled = useIsWeekViewDisabled()
  const { t } = useTranslation()
  return (
    <div className={styles.wrapper}>
      <div className={styles.viewContent}>
        <p className={styles.viewText}>{t('planningForecast.view')}</p>
        <div className={styles.buttonGroup}>
          <button
            disabled={isWeekViewDisabled}
            onClick={() => handleView('WEEK')}
            className={classNames(styles.toggleButton, {
              [styles.active]: view === 'WEEK'
            })}
            type="button">
            {t('quantity.week')}
          </button>
          <span className={styles.slash}>/</span>
          <button
            onClick={() => handleView('MONTH')}
            className={classNames(styles.toggleButton, { [styles.active]: view === 'MONTH' })}
            type="button">
            {t('quantity.month')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ViewToggle
