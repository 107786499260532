import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import styles from './Delivery.module.scss'
import CustomRadioGroup from '../../../../../shared/components/formControls/CustomRadioGroup/CustomRadioGroup'
import { useGetProduct } from '../../../Offer'
import { preparePickUpDateTime } from '../../utils/preparePickUpDateTime'

function useOptions() {
  const { data } = useGetProduct()
  const { pickUpDateTime } = data!
  const { t } = useTranslation()

  return useMemo(() => [
    {
      value: 'PICK_UP',
      label: t('buy.bidOffer.delivery.options.PICK_UP', { pickUpDateTime: preparePickUpDateTime(pickUpDateTime) })
    },
    {
      value: 'DELIVERY',
      label: t('buy.bidOffer.delivery.options.DELIVERY'),
      disabled: true
    }
  ], [])
}

const Delivery = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()
  const options = useOptions()

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.label}>
        {t('buy.bidOffer.delivery.label')}
      </h1>
      <CustomRadioGroup
        control={control}
        name="deliveryOption"
        options={options}
      />
    </div>
  )
}

export default Delivery
