import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import { FC } from 'react'
import styles from './CustomerSettings.module.scss'
import Header from '../Header/Header'
import { useSettingsSellersQuery } from '../../queries'
import LabelCheckbox from '../LabelCheckbox/LabelCheckbox'

type TProps = {
  control: Control<any>
}
const CustomerSettings: FC<TProps> = ({ control }) => {
  const { t } = useTranslation()
  const { data } = useSettingsSellersQuery()

  if (!data) {
    return <></>
  }

  return (
    <div className={styles.wrapper}>
      <Header
        title={t('deliveryCustomerSettings.title')}
        descriptionBold={t('deliveryCustomerSettings.descriptionBold')}
        description={t('deliveryCustomerSettings.description')}
      />
      {
        data.length
          ? (
            <div className={styles.checkBoxWrap}>
              {
                data.map(({ id, seller: { name } }) => (
                  <LabelCheckbox
                    control={control}
                    key={id}
                    name={id}
                    label={name}/>
                ))
              }
            </div>
          )
          : (
            <p className={styles.noDataText}>{t('deliveryCustomerSettings.noData')}</p>
          )
      }

    </div>
  )
}

export default CustomerSettings
