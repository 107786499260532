import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import styles from './Body.module.scss'
import { useGetWeekLabel } from '../../../../../shared/components/formControls/CustomWeekPicker/CustomWeekPicker'
import { ProductLabelSelect } from '../../../baseAnalytics'
import { usePlanningForecastContext } from '../../state/PlanningForecastState'
import CustomDatePicker from '../../../../../shared/components/formControls/CustomDatePicker/CustomDatePicker'
import { dotDateFormat } from '../../../../../shared/utils/date'
import ViewToggle from '../ViewToggle/ViewToggle'
import FoodWasteSelect from '../FoodWasteSelect/FoodWasteSelect'

const Body = () => {
  const { t } = useTranslation()
  const {
    formReturn: { control, watch },
    productLabel: { data }, view
  } = usePlanningForecastContext()

  const weekLabel = useGetWeekLabel({ control, name: 'date' })
  const date = view === 'WEEK' ? weekLabel : dotDateFormat(watch('date'))
  return (
    <div className={styles.wrapper}>
      <div className={styles.selectsWrapper}>
        <CustomDatePicker name="date" control={control} label={t('dashboard.date')}/>
        <ProductLabelSelect control={control} optionsData={data} multiSelect/>
        <FoodWasteSelect />
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.overview}>
          <h2 className={styles.label}>{t('planningForecast.overview')}</h2>
          <p className={styles.date}>{date}</p>
        </div>
        <ViewToggle/>
      </div>
    </div>
  )
}

export default Body
