import styles from './OfferBody.module.scss'
import { useGetProduct } from '../../queries'
import defaultImage from '../../../assets/emptyProductImage.png'
import { getLogo } from '../../../utils/getLogo'
import Address from '../Address/Address'
import OfferInfo from '../OfferInfo/OfferInfo'
import { useLabelListQuery } from '../../../../growingPlan/BaseGrowingPlanWorkShop/queries'

const OfferBody = () => {
  const { data } = useGetProduct()
  const { imageUrl, product, label, additionalInfo } = data!
  useLabelListQuery()
  return (
    <div className={styles.wrapper}>

      <div className={styles.imageWrapper}>
        <img
          className={styles.productImage}
          src={imageUrl || defaultImage}
          alt={product.name}/>
        {getLogo(label.id)
          && (
            <img
              className={styles.logo}
              src={getLogo(label.id)}
              alt={product.name}/>
          )}
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>{product.name}</h1>
        <p className={styles.description}>{additionalInfo}</p>
        <div className={styles.infoWrapper}>
          <OfferInfo/>
          <Address/>
        </div>
      </div>
    </div>
  )
}
export default OfferBody
