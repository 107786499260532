import { useQuery } from '@tanstack/react-query'
import { apiClient } from '../Interceptor'
import { TOrder } from './types'

enum QueryKeys{
  OrderList = 'OrderList'
}

async function orderList(): Promise<TOrder[]> {
  try {
    const response = await apiClient('/app/orders/list')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useOrderListQuery = () => {
  const key = [QueryKeys.OrderList]
  return useQuery<TOrder[], Error>(key, orderList)
}
