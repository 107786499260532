import { useNavigate } from 'react-router-dom'
import { getMonth } from 'date-fns'
import { NO_GROWING_PLAN_OPTION } from '../constants/quantityReports'

export function useNavigateToCalendar(
  growingPlanId?: string | null,
  date?: Date,
  sellerId?: string,
  buyerId?: string
) {
  const navigate = useNavigate()

  return () => navigate(`/quantity-reports?growingPlanId=${growingPlanId || NO_GROWING_PLAN_OPTION}&sellerId=${sellerId}&buyerId=${buyerId}${date ? `&month=${getMonth(date) + 1}` : ''}`)
}
