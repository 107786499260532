import { FC, memo, useMemo } from 'react'
import { Column, Row } from 'react-table'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import styles from './GrowingPlanWorkShopTable.module.scss'
import { Table as CustomTable } from '../../../../../shared/components/table'
import InputCell from '../tableCells/InputCell/InputCell'
import TotalCell from '../tableCells/TotalCell/TotalCell'
import { TTableRow } from '../../types'
import { generateTableDefaultData } from '../../utils/generateTableDefaultData'
import { useGrowingPlanWorkShopFormContext } from '../../state/GrowingPlanWorkShopFormState'

function checkIsTableLastRow({ index }: Row, lastRow: number) {
  return index === lastRow
}

type TProps = {
  planIndex: number
}

const GrowingPlanWorkShopTable: FC<TProps> = ({ planIndex }) => {
  const { tableDefaultValues, labelsData } = useGrowingPlanWorkShopFormContext()
  const lastRow = tableDefaultValues.length - 1
  const { t } = useTranslation()
  const productPlanValues = `productPlans[${planIndex}]`
  const columns = useMemo<Column[]>(() => [
    {
      Header: '',
      accessor: 'label',
      Cell: ({ value, row }) => (
        <span className={
          classNames(
            styles.tableHeader,
            { [styles.textBold]: checkIsTableLastRow(row, lastRow) }
          )
        }>
          {value !== 'TOTAL' ? labelsData?.find(({ id }) => id === value)?.name : t('common.total')}
        </span>
      )
    },
    {
      Header: () => <div className={styles.tableHeader}>{t('months.jan')}</div>,
      accessor: 'jan',
      Cell: ({ row }) => (
        <>
          {checkIsTableLastRow(row, lastRow)
            ? <TotalCell name={productPlanValues} month="jan"/>
            : <InputCell baseName={`${productPlanValues}.values[${row.index}].jan`}/>}
        </>
      )
    },
    {
      Header: <div className={styles.tableHeader}>{t('months.feb')}</div>,
      accessor: 'feb',
      Cell: ({ row }) => (
        <>
          {checkIsTableLastRow(row, lastRow)
            ? <TotalCell name={productPlanValues} month="feb"/>
            : <InputCell baseName={`${productPlanValues}.values[${row.index}].feb`}/>}
        </>
      )
    },
    {
      Header: <div className={styles.tableHeader}>{t('months.mar')}</div>,
      accessor: 'mar',
      Cell: ({ row }) => (
        <>
          {checkIsTableLastRow(row, lastRow)
            ? <TotalCell name={productPlanValues} month="mar"/>
            : <InputCell baseName={`${productPlanValues}.values[${row.index}].mar`}/>}
        </>
      )
    },
    {
      Header: <div className={styles.tableHeader}>{t('months.apr')}</div>,
      accessor: 'apr',
      Cell: ({ row }) => (
        <>
          {checkIsTableLastRow(row, lastRow)
            ? <TotalCell name={productPlanValues} month="apr"/>
            : <InputCell baseName={`${productPlanValues}.values[${row.index}].apr`}/>}
        </>
      )
    },
    {
      Header: <div className={styles.tableHeader}>{t('months.may')}</div>,
      accessor: 'may',
      Cell: ({ row }) => (
        <>
          {checkIsTableLastRow(row, lastRow)
            ? <TotalCell name={productPlanValues} month="may"/>
            : <InputCell baseName={`${productPlanValues}.values[${row.index}].may`}/>}
        </>
      )
    },
    {
      Header: <div className={styles.tableHeader}>{t('months.jun')}</div>,
      accessor: 'jun',
      Cell: ({ row }) => (
        <>
          {checkIsTableLastRow(row, lastRow)
            ? <TotalCell name={productPlanValues} month="jun"/>
            : <InputCell baseName={`${productPlanValues}.values[${row.index}].jun`}/>}
        </>
      )
    },
    {
      Header: <div className={styles.tableHeader}>{t('months.jul')}</div>,
      accessor: 'jul',
      Cell: ({ row }) => (
        <>
          {checkIsTableLastRow(row, lastRow)
            ? <TotalCell name={productPlanValues} month="jul"/>
            : <InputCell baseName={`${productPlanValues}.values[${row.index}].jul`}/>}
        </>
      )
    },
    {
      Header: <div className={styles.tableHeader}>{t('months.aug')}</div>,
      accessor: 'aug',
      Cell: ({ row }) => (
        <>
          {checkIsTableLastRow(row, lastRow)
            ? <TotalCell name={productPlanValues} month="aug"/>
            : <InputCell baseName={`${productPlanValues}.values[${row.index}].aug`}/>}
        </>
      )
    },
    {
      Header: <div className={styles.tableHeader}>{t('months.sep')}</div>,
      accessor: 'sep',
      Cell: ({ row }) => (
        <>
          {checkIsTableLastRow(row, lastRow)
            ? <TotalCell name={productPlanValues} month="sep"/>
            : <InputCell baseName={`${productPlanValues}.values[${row.index}].sep`}/>}
        </>
      )
    },
    {
      Header: <div className={styles.tableHeader}>{t('months.oct')}</div>,
      accessor: 'oct',
      Cell: ({ row }) => (
        <>
          {checkIsTableLastRow(row, lastRow)
            ? <TotalCell name={productPlanValues} month="oct"/>
            : <InputCell baseName={`${productPlanValues}.values[${row.index}].oct`}/>}
        </>
      )
    },
    {
      Header: <div className={styles.tableHeader}>{t('months.nov')}</div>,
      accessor: 'nov',
      Cell: ({ row }) => (
        <>
          {checkIsTableLastRow(row, lastRow)
            ? <TotalCell name={productPlanValues} month="nov"/>
            : <InputCell baseName={`${productPlanValues}.values[${row.index}].nov`}/>}
        </>
      )
    },
    {
      Header: <div className={styles.tableHeader}>{t('months.dec')}</div>,
      accessor: 'dec',
      Cell: ({ row }) => (
        <>
          {checkIsTableLastRow(row, lastRow)
            ? <TotalCell name={productPlanValues} month="dec"/>
            : <InputCell baseName={`${productPlanValues}.values[${row.index}].dec`}/>}
        </>
      )
    },
    {
      Header: () => (
        <span className={
          classNames(
            styles.textBold,
            styles.tableHeaderLast
          )
        }>
          {t('common.total')}
        </span>
      ),
      accessor: 'total',
      Cell: ({ row }) => <TotalCell name={productPlanValues} rowIndex={row.index} />
    }
  ], [lastRow, productPlanValues, t, labelsData])

  return (
    <div className={styles.tableWrapper}>
      <CustomTable
        contentClass={styles.content}
        columns={columns}
        data={tableDefaultValues}/>
    </div>
  )
}

export default memo(GrowingPlanWorkShopTable)
