import constate from 'constate'
import { ReactNode, useCallback } from 'react'
import { toast } from 'react-toastify'
import SuccessNotification from '../components/SuccessNotification/SuccessNotification'
import ErrorNotification from '../components/ErrorNotification/ErrorNotification'

type TNotificationVariant = 'error' | 'success'

const ToastVariant: {[key in TNotificationVariant]: (message: string) => ReactNode} = {
  success: (message) => <SuccessNotification message={message}/>,
  error: (message) => <ErrorNotification message={message}/>
}
function useShowToast(variant: TNotificationVariant) {
  const toastComponent = ToastVariant[variant]
  return useCallback((content: string) => toast[variant](
    toastComponent(content),
    {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      rtl: false,
      pauseOnFocusLoss: false,
      draggable: false,
      pauseOnHover: false,
      className: variant
    }
  ), [toastComponent, variant])
}

const NotificationState = () => ({
  showErrorNotification: useShowToast('error'),
  showSuccessNotification: useShowToast('success')
})

export const [NotificationProvider, useNotificationContext] = constate(NotificationState)
