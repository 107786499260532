import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './GrowingPlanList.module.scss'
import GrowingPlanHeader from '../GrowingPlanHeader/GrowingPlanHeader'
import GrowingPlanOverview from '../GrowingPlanOverview/GrowingPlanOverview'
import NoData from '../../../../../shared/components/NoData/NoData'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import DeleteModal from '../DeleteModal/DeleteModal'
import { GrowingPlanDetailsProvider } from '../../state/GrowingPlanListState'
import { useGrowingPlanListQuery } from '../../queries'
import Spinner from '../../../../../shared/components/Spinner/Spinner'

const GrowingPlanList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data, isLoading } = useGrowingPlanListQuery()
  const isPlans = useMemo(
    () => !!(data?.activePlans.length || data?.inactivePlans.length),
    [data]
  )

  if (isLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <DeleteModal/>
      <PrimaryLayout
        header={<GrowingPlanHeader isCreateButton={isPlans}/>}
        maxHeight
        size="large">
        {
          isPlans
            ? <GrowingPlanOverview/>
            : (
              <NoData
                handleClick={() => navigate('/growing-plan/create')}
                title={t('growingPlan.noGrowingPlan.title')}
                buttonText={t('growingPlan.createGrowingPlan')}
              />
            )
        }
      </PrimaryLayout>
    </div>
  )
}

export default () => (
  <GrowingPlanDetailsProvider>
    <GrowingPlanList/>
  </GrowingPlanDetailsProvider>
)
