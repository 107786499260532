import { FC, useMemo } from 'react'
import { Column, Row } from 'react-table'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import BaseTable from '../../../../../shared/components/table/components/Table/Table'
import styles from './Table.module.scss'
import { TGpItem, TValue } from '../../types'
import { prepareTableValues } from '../../utils/prepareWeeklyAnalyticsTable'

function usePrepareTableData(tableData: TGpItem[], totalAmountPerWeek: number) {
  const { t } = useTranslation()
  const values = prepareTableValues(tableData) as TValue[]

  const tableWeekTotalValues = Array(7)
    .fill({}).map((value, index) => (index === 3 ? {
      amount: totalAmountPerWeek,
      dayOfWeek: 'THURSDAY'
    } : value))

  const tableDayTotal: Partial<TGpItem> = {
    growingPlanTitle: t('dashboard.weeklyAnalytics.table.totalPerDay') as string,
    growingPlanId: 'stubDayGrowingPlanId',
    values,
    customerName: '',
    customerId: 'stubDayCustomerId',
    sellerName: ''
  }
  const tableWeekTotal: Partial<TGpItem> = {
    growingPlanTitle: t('dashboard.weeklyAnalytics.table.totalPerWeek') as string,
    growingPlanId: 'stubWeekGrowingPlanId',
    values: tableWeekTotalValues,
    customerName: '',
    customerId: 'stubWeekCustomerId',
    sellerName: ''
  }
  return [...tableData, tableDayTotal, tableWeekTotal]
}

function getWeekDayAmount(row: Row, index: number) {
  const rowOriginal = row.original as TGpItem
  const rowVal = rowOriginal.values[index].amount
  const isShowPlus = !rowOriginal.currentUserSeller && rowVal > 0
  return `${isShowPlus ? '+' : ''} ${rowVal ?? ''}`
}

type TProps = {
  tableData: TGpItem[]
  totalAmountPerWeek: number
}
const Table: FC<TProps> = ({ tableData, totalAmountPerWeek }) => {
  const { t } = useTranslation()
  const preparedTableData = usePrepareTableData(tableData, totalAmountPerWeek)

  const columns = useMemo<Column[]>(() => [
    {
      Header: (
        <div className={classNames(styles.tableHeader, styles.paddingLeft)}>
          {t('dashboard.weeklyAnalytics.table.growingPlan')}
        </div>
      ),
      accessor: 'growingPlanTitle',
      Cell: ({ row }) => {
        const rowOriginal = row.original as TGpItem
        const description = rowOriginal.currentUserSeller
          ? rowOriginal.customerName
          : rowOriginal.sellerName
        return (
          <div className={styles.paddingLeft}>
            {`${rowOriginal.growingPlanTitle} ${description ? `(${description})` : ''}`}
          </div>
        )
      }
    },
    {
      Header: <div className={styles.tableHeader}>{t('dashboard.weeklyAnalytics.table.unit')}</div>,
      accessor: 'unit',
      Cell: ({ value }) => <div>{value ?? ''}</div>
    },
    {
      Header: <div className={styles.tableHeader}>{t('dashboard.weeklyAnalytics.table.monday')}</div>,
      accessor: 'values[0].dayOfWeek',
      Cell: ({ row }) => <div>{getWeekDayAmount(row, 0)}</div>
    },
    {
      Header: <div className={styles.tableHeader}>{t('dashboard.weeklyAnalytics.table.tuesday')}</div>,
      accessor: 'values[1].dayOfWeek',
      Cell: ({ row }) => <div>{getWeekDayAmount(row, 1)}</div>
    },
    {
      Header: <div className={styles.tableHeader}>{t('dashboard.weeklyAnalytics.table.wednesday')}</div>,
      accessor: 'values[2].dayOfWeek',
      Cell: ({ row }) => <div>{getWeekDayAmount(row, 2)}</div>
    },
    {
      Header: <div className={styles.tableHeader}>{t('dashboard.weeklyAnalytics.table.thursday')}</div>,
      accessor: 'values[3].dayOfWeek',
      Cell: ({ row }) => <div>{getWeekDayAmount(row, 3)}</div>
    },
    {
      Header: <div className={styles.tableHeader}>{t('dashboard.weeklyAnalytics.table.friday')}</div>,
      accessor: 'values[4].dayOfWeek',
      Cell: ({ row }) => <div>{getWeekDayAmount(row, 4)}</div>
    },
    {
      Header: <div className={styles.tableHeader}>{t('dashboard.weeklyAnalytics.table.saturday')}</div>,
      accessor: 'values[5].dayOfWeek',
      Cell: ({ row }) => <div>{getWeekDayAmount(row, 5)}</div>
    },
    {
      Header: <div className={styles.tableHeader}>{t('dashboard.weeklyAnalytics.table.sunday')}</div>,
      accessor: 'values[6].dayOfWeek',
      Cell: ({ row }) => <div>{getWeekDayAmount(row, 6)}</div>
    }
  ], [])

  return (
    <div className={styles.wrapper}>
      <BaseTable
        columns={columns}
        data={preparedTableData}
        contentClass={styles.table}
      />
    </div>

  )
}

export default Table
