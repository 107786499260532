import { useTranslation } from 'react-i18next'
import { MenuItem } from '@mui/material'
import React, { FC, MouseEvent, useCallback, useMemo, useState } from 'react'
import ThreeDotsIcon from '../../../../../../shared/components/icons/ThreeDotsIcon'
import UserDetails from '../../UserDetails/UserDetails'
import styles from './AllUsersActions.module.scss'
import Menu from '../../../../../../shared/components/menu/Menu'
import { DeactivateModal } from '../../../../baseAdmin'
import EditUser from '../../EditUser/EditUser'
import { useUserStatusMutation } from '../../../queries'
import { useUsersContext } from '../../../state/UsersState'

function useUserName(id: string) {
  const { users } = useUsersContext()

  return useMemo(() => {
    if (!users.data?.content?.length) {
      return undefined
    }
    const foundUser = users.data.content.find(({ id: userId }) => userId === id)
    if (!foundUser) {
      return undefined
    }
    const { firstName, lastName } = foundUser
    return `${firstName} ${lastName}`
  }, [id])
}

type TProps = {
  id: string
}
const AllUsersActions: FC<TProps> = ({ id }) => {
  const { t } = useTranslation()
  const [deactivateModal, setDeactivateModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { mutateAsync } = useUserStatusMutation()

  const handleCloseDeactivateModal = useCallback(() => {
    setDeactivateModal(false)
  }, [])

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDeactivate = () => {
    mutateAsync({ userId: id, status: 'DEACTIVATED' }).then(() => handleCloseDeactivateModal())
  }

  return (
    <>
      <UserDetails id={id}/>
      <EditUser id={id}/>
      <div>
        <button
          onClick={handleClick}
          type="button"
          className={styles.iconWrap}>
          <ThreeDotsIcon width={22} height={22}/>
        </button>
        <Menu
          size="small"
          anchorEl={anchorEl}
          isOpen={open}
          onClose={handleClose}
        >
          <MenuItem
            className={styles.menuItem}
            onClick={() => {
              setDeactivateModal(true)
              handleClose()
            }}>
            {t('admin.users.table.deactivate')}
          </MenuItem>
        </Menu>
      </div>
      <DeactivateModal
        name={useUserName(id)}
        handleDeactivate={handleDeactivate}
        onClose={handleCloseDeactivateModal}
        isOpen={deactivateModal}/>
    </>
  )
}

export default AllUsersActions
