import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { DefaultTFuncReturn } from 'i18next'
import CustomInput from '../formControls/CustomInput/CustomInput'
import { useValidationRules } from '../../hooks/useValidationRules'

type TProps = {
  isRequired?: boolean
  isConfirmPasswordValidation?: boolean
  label: DefaultTFuncReturn | null
}
const MatchingPassword: FC<TProps> = ({
  isRequired = true,
  isConfirmPasswordValidation = true,
  label
}) => {
  const { requiredRule, confirmPasswordValidation } = useValidationRules()
  const { control, watch } = useFormContext()

  return (
    <CustomInput
      label={`${label} ${isRequired ? '*' : ''}`}
      name="matchingPassword"
      control={control}
      type="password"
      rules={{
        required: isRequired ? requiredRule() : undefined,
        validate:
          isConfirmPasswordValidation
            ? (value: string) => confirmPasswordValidation(value, watch('password'))
            : undefined
      }}
    />
  )
}

export default MatchingPassword
