import React, { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './AmountCard.module.scss'
import { TNameId, TProductLabel } from '../../../BaseGrowingPlanWorkShop'
import GrowingPlanDeal from '../GrowingPlanDeal/GrowingPlanDeal'

type TProps = {
  product: TNameId
  label: TProductLabel
  growingPlan: number
  unit: string
  buy: number
  sell: number
}
const AmountCard: FC<TProps> = ({ product, sell, unit, growingPlan, label, buy }) => {
  const { t } = useTranslation()
  const unit18n = t(`units.${unit.toLowerCase()}`)
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate('/dashboard/weeklyReports', { state: { product, label } })
  }
  return (
    <button type="button" onClick={handleNavigate} className={styles.wrapper}>
      <p className={styles.product}>{product.name}</p>
      <p className={styles.label}>{label.name}</p>
      <GrowingPlanDeal label={t('growingPlan.growingPlan')} unit={unit18n} value={growingPlan}/>
      {sell ? <GrowingPlanDeal label={t('growingPlan.sell')} unit={unit18n} value={sell}/> : null}
      {buy ? <GrowingPlanDeal label={t('growingPlan.buy')} unit={unit18n} value={buy}/> : null}
    </button>
  )
}

export default memo(AmountCard)
