import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { memo, useMemo } from 'react'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { TLogisticUnit } from '../../../../../quantityReports/BaseQuantityReports/types'

function useOptions() {
  const { t } = useTranslation()
  const optionValue: TLogisticUnit[] = ['PALETTE', 'GROSSKISTEN', 'BALOXEN']
  return useMemo(() => optionValue.map((value) => ({
    value,
    label: t(`logisticOptions.${value}`)
  })), [])
}

const LogisticSelect = () => {
  const { t } = useTranslation()
  const options = useOptions()
  const { control } = useFormContext()
  return (
    <CustomSelectControl
      control={control}
      options={options}
      isRequired
      label={`${t('sell.workShop.selects.logisticUnit.label')} *`}
      placeholder={t('sell.workShop.selects.logisticUnit.placeholder') as string}
      name="lu"
      handleError={(error) => (error as any)?.lu}
    />
  )
}

export default memo(LogisticSelect)
