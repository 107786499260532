import { useTranslation } from 'react-i18next'
import { ReactNode, useMemo } from 'react'
import { Column } from 'react-table'
import classNames from 'classnames'
import BaseTable from '../../../../../shared/components/table/components/Table/Table'
import styles from './Table.module.scss'
import { useUsersContext } from '../../state/UsersState'
import { TContent, TFilter } from '../../types'
import AllUsersActions from '../actions/AllUsersActions/AllUsersActions'
import NewRegistrationsActions from '../actions/NewRegistrationsActions/NewRegistrationActions'
import DeactivatedActions from '../actions/DeactivatedActions/DeactivatedActions'
import RejectedActions from '../actions/RejectedActions/RejectedActions'

const actionsMap: {[key in TFilter]: (id: string) => ReactNode} = {
  ACTIVE: (id) => <AllUsersActions id={id}/>,
  PENDING_APPROVAL: (id) => <NewRegistrationsActions id={id}/>,
  DEACTIVATED: (id) => <DeactivatedActions id={id}/>,
  REJECTED: (id) => <RejectedActions id={id}/>
}

const styleMap: {[key in TFilter]: string} = {
  ACTIVE: 'allUsersActions',
  PENDING_APPROVAL: 'newRegistrationActions',
  DEACTIVATED: 'deactivatedActions',
  REJECTED: 'rejectedActions'
}

const Table = () => {
  const { users: { data, isLoading }, filter, handleChangePage } = useUsersContext()

  const { t } = useTranslation()

  const columns = useMemo<Column[]>(() => [
    {
      Header: <div className={styles.tableHeader}>{t('admin.users.table.companyName')}</div>,
      accessor: 'company.name',
      Cell: ({ value }) => <div>{value}</div>
    },
    {
      Header: <div className={styles.tableHeader}>{t('admin.users.table.name')}</div>,
      accessor: 'firstName',
      Cell: ({ value, row }) => {
        const { lastName } = row.original as TContent
        return <div>{`${value} ${lastName}`}</div>
      }
    },
    {
      Header: <div className={styles.tableHeader}>{t('admin.users.table.email')}</div>,
      accessor: 'email',
      Cell: ({ value }) => <div>{value}</div>
    },
    {
      Header: <div className={styles.tableHeader}>{t('admin.users.table.phone')}</div>,
      accessor: 'phone',
      Cell: ({ value }) => <div>{value}</div>
    },
    {
      Header: <div className={styles.tableHeader}>{t('admin.users.table.registeredOn')}</div>,
      accessor: 'registrationDate',
      Cell: ({ value }) => <div>{value}</div>
    },
    {
      Header: <div className={classNames(styles.tableHeader, styles.actions)}>{t('common.actions')}</div>,
      accessor: 'id',
      Cell: ({ value }) => (
        <div className={styles.actionsWrap}>
          {actionsMap[filter ?? 'ALL_USERS'](value)}
        </div>
      )
    }
  ], [filter])

  const hiddenColumns = filter !== 'ACTIVE' ? ['registrationDate'] : undefined

  const noTableData = !isLoading && !data?.content.length
  return (
    <div className={classNames(styles.tableWrapper, styles[styleMap[filter]], {
      [styles.noTable]: noTableData
    })}>
      {
        noTableData
          ? <p className={styles.noData}>{t(`admin.users.${filter.toLowerCase()}.noData`)}</p>
          : (
            <BaseTable
              key={filter}
              contentClass={styles.table}
              data={data?.content ?? []}
              columns={columns}
              showLoader={isLoading}
              hiddenColumns={hiddenColumns}
              paginationConfig={data && {
                isEnabled: data?.totalPages > 1,
                pageSize: 10,
                manual: true,
                totalRows: data.totalElements,
                pageCount: data.totalPages,
                onPageChange: (page) => handleChangePage(page)
              }}
            />
          )
      }

    </div>

  )
}

export default Table
