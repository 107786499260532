import { useTranslation } from 'react-i18next'
import { Control, useWatch } from 'react-hook-form'
import { FC, memo, useMemo } from 'react'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { useProductListQuery } from '../../../queries'
import styles from './ProductSelect.module.scss'
import { TForm, TProductPlanForm } from '../../../types'
import { TOption } from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectBase/CustomSelectBase'

function useOptions() {
  const { data } = useProductListQuery()

  return useMemo(
    () => (data?.length
      ? data.map(({ id, name }) => ({
        value: id,
        label: name
      }))
      : []),
    [data]
  )
}

function useFilterOptions(control: Control<any>, options: TOption[], planIndex: number) {
  const productsPlan = useWatch({ control, name: 'productPlans' }) as TProductPlanForm[]
  const selfProductId = useWatch({ control, name: `productPlans[${planIndex}].productId` })
  const productsIds = productsPlan
    .map(({ productId }) => productId)
    .filter(Boolean)
    .filter((productId) => productId !== selfProductId)

  return useMemo(
    () =>
      (productsIds.length
        ? options.filter(({ value }) => !productsIds.includes(value as string))
        : options),
    [options, productsIds]
  )
}

type TProps = {
  planIndex: number
  control: Control<TForm>
  disabled: boolean
}

const ProductSelect: FC<TProps> = ({ planIndex, control, disabled }) => {
  const { t } = useTranslation()
  const options = useOptions()
  const filteredOptions = useFilterOptions(control, options, planIndex)

  return (
    <div className={styles.wrapper}>
      <CustomSelectControl
        disabled={disabled}
        control={control}
        options={filteredOptions}
        isRequired
        name={`productPlans[${planIndex}].productId`}
        label={`${t('growingPlan.workShop.product')} *`}
        placeholder={t('growingPlan.workShop.selectProduct') as string}
        handleError={(error) => (error as any)?.productPlans?.[planIndex]?.productId}
      />
    </div>
  )
}

export default memo(ProductSelect)
