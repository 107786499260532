import { FC, memo, ReactNode } from 'react'
import styles from './ProductInfo.module.scss'

type TProps = {
  label: string | ReactNode
  value: string | ReactNode
}
const ProductInfo: FC<TProps> = ({ label, value }) => (
  <div className={styles.wrapper}>
    <div className={styles.label}>{label}</div>
    <div className={styles.value}>{value}</div>
  </div>
)

export default memo(ProductInfo)
