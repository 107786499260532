import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../Interceptor'
import { TDeclineParams, TMySell } from './types'

enum QueryKeys{
  MySells = 'MySells'
}

async function approve(orderId: string) {
  try {
    await apiClient.put(`/app/market/order/${orderId}/approve`)
  } catch (e: any) {
    throw new Error(e)
  }
}

export function useApproveMutation() {
  const queryClient = useQueryClient()
  return useMutation<void, Error, string>(
    (orderId) => approve(orderId),
    {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.MySells])
    }
  )
}

async function decline(params: TDeclineParams) {
  const { orderId, ...rest } = params
  try {
    await apiClient.put(`/app/market/order/${orderId}/decline`, rest)
  } catch (e: any) {
    throw new Error(e)
  }
}

export function useDeclineMutation() {
  const queryClient = useQueryClient()
  return useMutation<void, Error, TDeclineParams>(
    (params) => decline(params),
    {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.MySells])
    }
  )
}

async function mySells(): Promise<TMySell[]> {
  try {
    const response = await apiClient('app/sells/list')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export function useMySellsQuery() {
  const key = [QueryKeys.MySells]
  return useQuery<TMySell[], Error>(key, mySells)
}
