import { Control, RegisterOptions, useController } from 'react-hook-form'
import { Checkbox } from '@mui/material'
import { FC } from 'react'
import classNames from 'classnames'
import styles from './CustomCheckbox.module.scss'

type TProps = {
  name: string
  rules?: RegisterOptions
  control: Control<any>
  checkBoxStyle?: string
}
const CustomCheckbox: FC<TProps> = ({ rules, name, control, checkBoxStyle }) => {
  const { field } = useController({ name, control, rules })

  return (
    <Checkbox
      className={classNames(styles.customCheckBox, checkBoxStyle)}
      {...field}
      checked={field.value} />
  )
}

export default CustomCheckbox
