import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import styles from '../DeactivatedActions/DeactivatedActions.module.scss'
import UserDetails from '../../UserDetails/UserDetails'
import PrimaryButton from '../../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import { useRegistrationRequestsStatusMutation } from '../../../queries'

type TProps = {
  id: string
}

const RejectedActions: FC<TProps> = ({ id }) => {
  const { t } = useTranslation()
  const { mutate, isLoading } = useRegistrationRequestsStatusMutation()

  const handleApprove = () => {
    mutate({ userId: id, status: 'APPROVED' })
  }

  return (
    <>
      <UserDetails isRegistrationRequest id={id}/>
      <PrimaryButton disabled={isLoading} className={styles.button} onClick={handleApprove}>{t('admin.users.table.approve')}</PrimaryButton>
    </>
  )
}

export default RejectedActions
