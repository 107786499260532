import { TAccountEditForm } from '../types'
import { TAccountDetailsResponse } from '../../accountBase'

export function prepareDataAccountEditMutation(
  formData: TAccountEditForm,
  accountDetailsData: TAccountDetailsResponse,
  withEmail?: boolean
) {
  const { firstName, companyName, companyLogoId, email,
    companyUid, lastName, city, zip,
    matchingPassword, password, subscribeToNewsLetters,
    phone, address, iban, invoicePaymentTerm
  } = formData
  return {
    firstName,
    lastName,
    phone,
    email: withEmail ? email : undefined,
    company: {
      id: accountDetailsData.company.id,
      name: companyName,
      uid: companyUid,
      logoId: companyLogoId,
      iban: iban || undefined,
      invoicePaymentTerm: invoicePaymentTerm ? +invoicePaymentTerm : undefined
    },
    subscribeToNewsLetters,
    address: {
      city,
      address,
      zip,
      id: accountDetailsData?.address.id
    },
    userId: accountDetailsData.id,
    changePassword: (matchingPassword && password) ? {
      matchingPassword,
      password
    } : undefined
  }
}
