import { useTranslation } from 'react-i18next'
import { FC, useState } from 'react'
import { TOfferDeleteParams } from '../../types'
import DeleteModal from '../DeleteModal/DeleteModal'
import DeleteBinIcon from '../../../../../shared/components/icons/DeleteBinIcon'
import styles from './DeleteOffer.module.scss'

const DeleteOffer: FC<TOfferDeleteParams> = ({ title, id }) => {
  const { t } = useTranslation()
  const [offerDeleteParams, setOfferDeleteParams] = useState<TOfferDeleteParams>()
  const handleDelete = () => {
    setOfferDeleteParams({ title, id })
  }

  return (
    <>
      <DeleteModal
        offerDeleteParams={offerDeleteParams}
        handleClose={() => setOfferDeleteParams(undefined)} />
      <button type="button" className={styles.iconWrap} onClick={handleDelete}>
        <DeleteBinIcon width={20} height={20}/>
      </button>
    </>
  )
}

export default DeleteOffer
