import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { memo, useMemo } from 'react'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { TTradeInUnit } from '../../../../../quantityReports/BaseQuantityReports/types'

const optionsValue: TTradeInUnit[] = [
  'IFCO_6408', 'IFCO_6410', 'IFCO_6413',
  'IFCO_6416', 'IFCO_6418', 'IFCO_6420',
  'IFCO_6424', 'MIGROS_AN', 'MIGROS_BN',
  'MIGROS_CN', 'MIGROS_UN', 'G1', 'G2', 'G3'
]

function useOptions() {
  const { t } = useTranslation()
  return useMemo(() => optionsValue.map((value) => ({
    value,
    label: t(`tradeInOptions.${value}`)
  })), [])
}

const TradeUnitSelect = () => {
  const { t } = useTranslation()
  const options = useOptions()
  const { control } = useFormContext()
  return (
    <CustomSelectControl
      control={control}
      options={options}
      isRequired
      label={`${t('sell.workShop.selects.tradeUnit.label')} *`}
      placeholder={t('sell.workShop.selects.tradeUnit.placeholder') as string}
      name="tu"
      handleError={(error) => (error as any)?.tu}
    />
  )
}

export default memo(TradeUnitSelect)
