import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Iban from '../../../../shared/components/formFields/Iban'
import Invoice from '../../../../shared/components/formFields/Invoice'
import styles from './Payment.module.scss'
import PrimaryButton from '../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { useAccountDetailsQuery } from '../../../account/accountBase'
import { usePaymentInfoMutation } from '../../queries'
import Modal from '../../../../shared/components/Modal/Modal'

type TPaymentForm = {
  iban: string
  invoicePaymentTerm: string
}
function useFormInit() {
  const { data: accountDetails } = useAccountDetailsQuery()

  return useForm<TPaymentForm>({
    defaultValues: useMemo(() => ({
      iban: accountDetails?.company.iban || '',
      invoicePaymentTerm: accountDetails?.company.invoicePaymentTerm?.toString() || ''
    }), [accountDetails])
  })
}

function useFormReset({ reset }: UseFormReturn<TPaymentForm>) {
  const { data: accountDetails } = useAccountDetailsQuery()
  useEffect(() => {
    if (!accountDetails) {
      return
    }
    reset({
      iban: accountDetails.company.iban,
      invoicePaymentTerm: accountDetails.company.invoicePaymentTerm?.toString()
    })
  }, [accountDetails])
}

function useOnSubmit(callBack?: () => void) {
  const { mutateAsync } = usePaymentInfoMutation()
  const { data: accountDetails } = useAccountDetailsQuery()

  return useCallback(
    ({ iban, invoicePaymentTerm }: TPaymentForm) => {
      if (!accountDetails) {
        return
      }
      mutateAsync({
        iban,
        invoicePaymentTerm: Number(invoicePaymentTerm),
        companyId: accountDetails.company.id
      }).then(() => {
        callBack?.()
      })
    },
    [accountDetails]
  )
}

type TProps = {
  label: string
  isOpen: boolean
  onClose: () => void
  callBack?: () => void
}

const Payment: FC<TProps> = ({ isOpen, onClose, callBack, label }) => {
  const { t } = useTranslation()
  const useFormReturn = useFormInit()
  useFormReset(useFormReturn)
  const onSubmit = useOnSubmit(callBack)

  return (
    <Modal modalClass={styles.modal} isOpen={isOpen} onClose={onClose}>
      <FormProvider {...useFormReturn} >
        <form className={styles.form} onSubmit={useFormReturn.handleSubmit(onSubmit)}>
          <h1 className={styles.title}>{t('quantity.modals.payment.title')}</h1>
          <p className={styles.label}>{label}</p>
          <div className={styles.inputGroup}>
            <Iban isRequired/>
            <Invoice isRequired/>
          </div>
          <div className={styles.line}/>
          <div className={styles.buttonGroup}>
            <PrimaryButton type="submit" text={t('common.save')}/>
            <SecondaryButton onClick={onClose} text={t('common.cancel')}/>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default Payment
