import React, { FC, memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './ProductCard.module.scss'
import { TOfferList } from '../../types'
import defaultImage from '../../../assets/emptyProductImage.png'
import { getLogo } from '../../../utils/getLogo'
import { addZeros } from '../../../../../shared/utils/formUtils'

const ProductCard: FC<TOfferList> = ({
  imageUrl,
  product,
  id,
  availableAmount,
  tu,
  pricePerCu,
  label,
  cu
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <button
      type="button"
      onClick={() => navigate(`/buy/${id}`)}
      className={styles.wrapper}
    >
      <div className={styles.imageWrapper}>
        <img className={styles.productImage} src={imageUrl || defaultImage} alt={product.name}/>
        {getLogo(label.id)
          && <img className={styles.logo} src={getLogo(label.id)} alt={product.name}/>}
      </div>
      <div className={styles.infoWrapper}>
        <p className={styles.title}>{product.name}</p>
        <p className={styles.amount}>{`${availableAmount} ${t(`tradeInOptions.${tu}`)}`}</p>
        {
          !!pricePerCu && (
            <div className={styles.totalPrice}>
              <p className={styles.price}>{`${t('common.chf')} ${addZeros(pricePerCu)}`}</p>
              <p className={styles.dimension}>
                /
                {t(`customerUnitOptions.${cu}`)}
              </p>
            </div>
          )
        }
      </div>
    </button>
  )
}

export default memo(ProductCard)
