import { useTranslation } from 'react-i18next'
import styles from './Body.module.scss'
import { useWeeklyReportsAnalyticsContext } from '../../state/WeeklyReportsAnalyticsState'
import CustomWeekPicker, {
  useGetWeekLabel
} from '../../../../../shared/components/formControls/CustomWeekPicker/CustomWeekPicker'
import { ProductLabelSelect } from '../../../baseAnalytics'
import CompanySelect from '../CompanySelect/CompanySelect'

const Body = () => {
  const {
    formReturn: { control },
    sellProductLabel: { data }
  } = useWeeklyReportsAnalyticsContext()
  const weekLabel = useGetWeekLabel({ control, name: 'week' })
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <CustomWeekPicker control={control} name="week" label={t('dashboard.week')}/>
      <ProductLabelSelect
        control={control}
        optionsData={data}
        multiSelect
      />
      <CompanySelect/>
      <div className={styles.contentWrapper}>
        <div className={styles.overview}>
          <h2 className={styles.label}>{t('dashboard.weeklyAnalytics.overview')}</h2>
          <p className={styles.date}>{weekLabel}</p>
        </div>
      </div>
    </div>
  )
}

export default Body
