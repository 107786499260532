import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import styles from './EditUser.module.scss'
import EditIcon from '../../../../../shared/components/icons/EditIcon'
import Modal from '../../../../../shared/components/Modal/Modal'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import FirstName from '../../../../../shared/components/formFields/FirstName'
import CompanyName from '../../../../../shared/components/formFields/CompanyName'
import LastName from '../../../../../shared/components/formFields/LastName'
import CompanyUid from '../../../../../shared/components/formFields/CompanyUid'
import Address from '../../../../../shared/components/formFields/Address'
import Iban from '../../../../../shared/components/formFields/Iban'
import Invoice from '../../../../../shared/components/formFields/Invoice'
import Zip from '../../../../../shared/components/formFields/Zip'
import City from '../../../../../shared/components/formFields/City'
import Phone from '../../../../../shared/components/formFields/Phone'
import ImageFileUpload from '../../../../../shared/components/formControls/FileUpload/ImageFileUpload/ImageFileUpload'
import Email from '../../../../../shared/components/formFields/Email'
import Password from '../../../../../shared/components/formFields/Password'
import MatchingPassword from '../../../../../shared/components/formFields/MatchingPassword'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { setForm, useClearPasswordsError, TAccountEditForm } from '../../../../account/accountEdit'
import { useCheckEmailMutation, useEditUserMutation, useUserDetailsQuery } from '../../queries'
import { prepareAttachment, useUploadAttachment } from '../../../../attechment'
import { prepareDataAccountEditMutation } from '../../../../account/accountEdit/utils/accountEditMutation'
import { TAttachment } from '../../../../attechment/types'
import { TEditMutationParams, TUserDetails } from '../../types'

function useFormInit(data?: TUserDetails) {
  return useForm<TAccountEditForm>({
    defaultValues: useMemo(() => {
      if (!data) {
        return {}
      }
      return setForm(data)
    }, [data])
  })
}

function useResetForm(
  { reset, formState }: UseFormReturn<any>,
  openModal: boolean,
  data?: TUserDetails
) {
  useEffect(() => {
    if (data) {
      reset(setForm(data as any))
    }
  }, [data])
}

function useOnSubmit(
  { setError, reset }: UseFormReturn<any>,
  uploadAttachmentMutateAsync: UseMutateAsyncFunction<string, Error, TAttachment>,
  editUserMutateAsync: UseMutateAsyncFunction<boolean, Error, TEditMutationParams>,
  callback: () => void,
  userAccountData?: TUserDetails
) {
  const { t } = useTranslation()
  const { mutateAsync } = useCheckEmailMutation()
  return useCallback(async (data: TAccountEditForm) => {
    if (!userAccountData) {
      return
    }

    const isEmailSame = data.email.toLowerCase().trim()
      === userAccountData.email.toLowerCase().trim()

    const { file } = data
    const response = isEmailSame ? false : await mutateAsync(data.email)

    if (response) {
      setError('email', { message: t('email.error.existInDB') as string })
      return
    }
    const preparedDataAccountEdit = prepareDataAccountEditMutation(
      data,
      userAccountData,
      true
    )

    if (file) {
      const logoId = await uploadAttachmentMutateAsync(prepareAttachment(file))
      await editUserMutateAsync({
        ...preparedDataAccountEdit,
        company: { ...preparedDataAccountEdit.company, logoId }
      })
      callback()
      return
    }
    await editUserMutateAsync(preparedDataAccountEdit).then(() => callback())
  }, [userAccountData])
}

type TProps = {
  id: string
}

const EditUser: FC<TProps> = ({ id }) => {
  const { t } = useTranslation()
  const [openModal, setOpenModal] = useState(false)
  const userId = openModal ? id : undefined
  const {
    mutateAsync: uploadAttachmentMutateAsync,
    isLoading: uploadAttachmentIsLoading
  } = useUploadAttachment()

  const {
    mutateAsync: editUserMutateAsync,
    isLoading: isLoadingEditUser
  } = useEditUserMutation(userId)

  const { data: userAccountData } = useUserDetailsQuery(userId)

  const useFormReturn = useFormInit(userAccountData)
  useResetForm({ ...useFormReturn }, openModal, userAccountData)
  useClearPasswordsError(useFormReturn)

  const handleClose = useCallback(() => {
    useFormReturn.reset({})
    setOpenModal(false)
  }, [])

  const onsubmit = useOnSubmit(
    { ...useFormReturn },
    uploadAttachmentMutateAsync,
    editUserMutateAsync,
    handleClose,
    userAccountData
  )

  const { handleSubmit, watch, control, setValue } = useFormReturn

  const password = watch('password')
  const matchingPassword = watch('matchingPassword')

  const isValidationRules = !!password || !!matchingPassword
  const removeImageForm = () => {
    setValue('companyLogoId', undefined)
    setValue('companyLogoUrl', undefined)
  }

  return (
    <>
      <button
        onClick={() => setOpenModal(true)}
        type="button"
        className={styles.iconWrap}>
        <EditIcon width={22} height={22}/>
      </button>
      <Modal isOpen={openModal} onClose={handleClose}>
        <h1 className={styles.title}>{t('admin.users.editUser')}</h1>
        <FormProvider {...useFormReturn}>
          <form className={styles.form} onSubmit={handleSubmit(onsubmit)}>
            <FirstName/>
            <CompanyName/>
            <LastName/>
            <CompanyUid label={`${t('admin.uidMwst')} *`}/>
            <Address/>
            <Iban/>
            <Invoice/>
            <div className={styles.inputGroup}>
              <Zip/>
              <City/>
            </div>
            <Phone/>
            <ImageFileUpload
              imageWatch={watch('companyLogoUrl')}
              label={t('signUp.fileUploadLabel')}
              removeImageForm={removeImageForm} />
            <Email isEmailValidation />
            <div className={styles.passwordContainer}>
              <h2 className={styles.passwordTitle}>{t('common.password')}</h2>
              <div className={styles.passwordInputGroup}>
                <Password
                  label={t('account.edit.newPassword')}
                  isRequired={isValidationRules}
                  isPasswordValidation={isValidationRules}/>
                <MatchingPassword
                  label={t('account.edit.confirmNewPassword')}
                  isRequired={isValidationRules}
                  isConfirmPasswordValidation={isValidationRules}/>
              </div>
            </div>

            <div className={styles.buttonGroup}>
              <PrimaryButton
                disabled={isLoadingEditUser || uploadAttachmentIsLoading}
                type="submit"
                text={t('common.save')}
              />
              <SecondaryButton
                onClick={handleClose}
                text={t('common.cancel')}/>
            </div>
          </form>
        </FormProvider>
      </Modal>
    </>
  )
}

export default EditUser
