import { FC, useEffect } from 'react'
import { useTableContext } from '../../state/TableState'
import Pagination from '../../../Pagination/Pagination'

const TablePagination: FC = () => {
  const {
    tableInstance: {
      pageCount,
      gotoPage,
      state: { pageIndex, pageSize }
    },
    isPaginationEnabled,
    totalPaginationRows
  } = useTableContext()

  useEffect(() => gotoPage(0), [pageCount, totalPaginationRows, pageSize])

  if (!isPaginationEnabled) {
    return null
  }

  return (
    <Pagination
      page={pageIndex + 1}
      count={pageCount}
      onChange={(_, page: number) => gotoPage(page - 1)}
      color="primary"
      variant="outlined"
      shape="rounded"
      hidePrevButton
      hideNextButton
    />
  )
}
export default TablePagination
