import { FC, useMemo } from 'react'
import { Column, Row } from 'react-table'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Table as CustomTable } from '../../../../../shared/components/table/index'
import styles from './GrowingPlanTable.module.scss'

function rowStyle({ index }: Row, lastRow: number) {
  const isLastRow = index === lastRow
  return isLastRow && styles.textBold
}

type TProps = {
  productsPlan: any
}

const GrowingPlanTable: FC<TProps> = ({ productsPlan }) => {
  const lastRow = productsPlan.length - 1
  const { t } = useTranslation()

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: '',
        accessor: 'label',
        Cell: ({ value, row }) => (
          <span
            className={rowStyle(row, lastRow)}>
            {value !== 'TOTAL' ? value?.name : t('common.total')}
          </span>
        )
      },
      {
        Header: <div className={styles.tableHeader}>{t('months.jan')}</div>,
        accessor: 'jan',
        Cell: ({ value, row }) => <span className={rowStyle(row, lastRow)}>{value.value}</span>
      },
      {
        Header: <div className={styles.tableHeader}>{t('months.feb')}</div>,
        accessor: 'feb',
        Cell: ({ value, row }) => <span className={rowStyle(row, lastRow)}>{value.value}</span>
      },
      {
        Header: <div className={styles.tableHeader}>{t('months.mar')}</div>,
        accessor: 'mar',
        Cell: ({ value, row }) => <span className={rowStyle(row, lastRow)}>{value.value}</span>
      },
      {
        Header: <div className={styles.tableHeader}>{t('months.apr')}</div>,
        accessor: 'apr',
        Cell: ({ value, row }) => <span className={rowStyle(row, lastRow)}>{value.value}</span>
      },
      {
        Header: <div className={styles.tableHeader}>{t('months.may')}</div>,
        accessor: 'may',
        Cell: ({ value, row }) => <span className={rowStyle(row, lastRow)}>{value.value}</span>
      },
      {
        Header: <div className={styles.tableHeader}>{t('months.jun')}</div>,
        accessor: 'jun',
        Cell: ({ value, row }) => <span className={rowStyle(row, lastRow)}>{value.value}</span>
      },
      {
        Header: <div className={styles.tableHeader}>{t('months.jul')}</div>,
        accessor: 'jul',
        Cell: ({ value, row }) => <span className={rowStyle(row, lastRow)}>{value.value}</span>
      },
      {
        Header: <div className={styles.tableHeader}>{t('months.aug')}</div>,
        accessor: 'aug',
        Cell: ({ value, row }) => <span className={rowStyle(row, lastRow)}>{value.value}</span>
      },
      {
        Header: <div className={styles.tableHeader}>{t('months.sep')}</div>,
        accessor: 'sep',
        Cell: ({ value, row }) => <span className={rowStyle(row, lastRow)}>{value.value}</span>
      },
      {
        Header: <div className={styles.tableHeader}>{t('months.oct')}</div>,
        accessor: 'oct',
        Cell: ({ value, row }) => <span className={rowStyle(row, lastRow)}>{value.value}</span>
      },
      {
        Header: <div className={styles.tableHeader}>{t('months.nov')}</div>,
        accessor: 'nov',
        Cell: ({ value, row }) => <span className={rowStyle(row, lastRow)}>{value.value}</span>
      },
      {
        Header: <div className={styles.tableHeader}>{t('months.dec')}</div>,
        accessor: 'dec',
        Cell: ({ value, row }) => <span className={rowStyle(row, lastRow)}>{value.value}</span>
      },
      {
        Header: () => <div className={classNames(styles.tableHeader, styles.textBold)}>{t('common.total')}</div>,
        accessor: 'total',
        Cell: ({ value, row }) => <span className={styles.textBold}>{value}</span>
      }

    ],
    [productsPlan]
  )
  return (
    <div className={styles.tableWrapper}>
      <CustomTable
        columns={columns}
        data={productsPlan}/>
    </div>
  )
}

export default GrowingPlanTable
