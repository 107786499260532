import { useTranslation } from 'react-i18next'
import ProductCard from '../ProductCard/ProductCard'
import styles from './ProductList.module.scss'

import { useOffersContext } from '../../state/OffersState'

const ProductList = () => {
  const { t } = useTranslation()
  const {
    productListMutation: { data: productListData }
  } = useOffersContext()

  return (
    <>
      <h1 className={styles.title}>{t('buy.offers.currentOffers')}</h1>
      {productListData?.length
        ? (
          <div className={styles.productsWrap}>
            {productListData.map((offer) => <ProductCard {...offer} key={offer.id}/>)}
          </div>
        )
        : (
          <p className={styles.text}>
            {t('buy.offers.notFound')}
          </p>
        )}

    </>
  )
}

export default ProductList
