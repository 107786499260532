import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import BaseAccordion from '../../../../../shared/components/Accordion/Accordion'
import AccordionSummary from '../../../weeklyReportsAnalytics/components/AccordionSummary/AccordionSummary'
import AccordionDetail from '../AccordionDetail/AccordionDetail'
import { TPlanningForecast } from '../../types'

type TProps = TPlanningForecast
const Accordion: FC<TProps> = ({ product, label, unit, forecasts }) => {
  const { t } = useTranslation()
  const productLabel = { product, label }

  return (
    <BaseAccordion
      summary={(icon) => (
        <AccordionSummary
          icon={icon}
          label={label.name}
          productName={product.name}/>
      )}
      detail={(
        <AccordionDetail
          forecasts={forecasts}
          productLabel={productLabel}
          unit={t(`units.${unit.toLowerCase()}`)}/>
      )}/>
  )
}

export default Accordion
