import { useMemo } from 'react'
import { Column } from 'react-table'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'
import classNames from 'classnames'
import styles from './OrderTable.module.scss'
import { Table as CustomTable } from '../../../../../shared/components/table'
import { useGetProduct } from '../../../Offer'
import emptyProductImage from '../../../assets/emptyProductImage.png'
import { TOfferLocalData } from '../../../Offer/types'
import { OFFER_LOCAL_STORAGE_KEY } from '../../../../../shared/constants/localeStorage'
import { useCalculateTotal } from '../../hooks/useCalculateTotal'
import { addZeros } from '../../../../../shared/utils/formUtils'

export const usePrepareTableData = () => {
  const { data } = useGetProduct()
  const [offerLocalData] = useLocalStorage<TOfferLocalData>(OFFER_LOCAL_STORAGE_KEY)
  const total = useCalculateTotal()

  const { amountTu, priceCHF } = offerLocalData!
  const {
    imageUrl,
    label,
    pricePerCu,
    product,
    additionalInfo,
    negotiation
  } = data!
  const price = Number(negotiation ? priceCHF : pricePerCu)

  return useMemo(() => [
    {
      productInfo: {
        imageUrl: imageUrl || emptyProductImage,
        productName: product.name,
        additionalInfo
      },
      label,
      pricePerCu: price,
      amountTu,
      total
    }
  ], [data, offerLocalData])
}

const OrderTable = () => {
  const { t } = useTranslation()
  const tableData = usePrepareTableData()

  const columns = useMemo<Column[]>(() => [
    {
      Header: () => <div className={styles.tableHeader}>{t('buy.bidOffer.order.table.product')}</div>,
      accessor: 'productInfo',
      Cell: ({ value }) => (
        <div className={styles.productInfoWrapper}>
          <img alt="test" className={styles.image} src={value.imageUrl}/>
          <div className={styles.content}>
            <p className={styles.cellText}>{value.productName}</p>
            <p className={styles.cellText}>
              {value.additionalInfo}
            </p>
          </div>
        </div>
      )
    },
    {
      Header: () => <div className={styles.tableHeader}>{t('buy.bidOffer.order.table.label')}</div>,
      accessor: 'label',
      Cell: ({ value }) => (
        <span className={styles.cellText}>{value.name}</span>
      )
    },
    {
      Header: () => <div className={styles.tableHeader}>{t('buy.bidOffer.order.table.amountTU')}</div>,
      accessor: 'amountTu',
      Cell: ({ value }) => (
        <span className={styles.cellText}>{value}</span>
      )
    },
    {
      Header: () => <div className={styles.tableHeader}>{t('buy.bidOffer.order.table.priceCU')}</div>,
      accessor: 'pricePerCu',
      Cell: ({ value }) => (
        <span className={styles.cellText}>{addZeros(value)}</span>
      )
    },
    {
      Header: () => (
        <div
          className={classNames(styles.tableHeader, styles.alignRight)}>
          {t('buy.bidOffer.order.table.totalPrice')}
        </div>
      ),
      accessor: 'total',
      Cell: ({ value }) => (
        <div className={classNames(styles.cellText, styles.alignRight)}>{`${t('common.chf')} ${addZeros(value)}`}</div>
      )
    }
  ], [])

  return (
    <div className={styles.tableWrapper}>
      <CustomTable contentClass={styles.table} columns={columns} data={tableData}/>
    </div>

  )
}

export default OrderTable
