import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import CustomRadioGroup, {
  TOptionRadio
} from '../../../../../../shared/components/formControls/CustomRadioGroup/CustomRadioGroup'
import { useValidationRules } from '../../../../../../shared/hooks/useValidationRules'

function useOptions(): TOptionRadio[] {
  const { t } = useTranslation()
  return useMemo(() => [
    {
      value: 'YES',
      label: t('sell.workShop.radios.anonymousOffer.options.yes')
    },
    {
      value: 'NO',
      label: t('sell.workShop.radios.anonymousOffer.options.no')
    }
  ], [])
}

const AnonymousOfferRadio = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()
  const options = useOptions()
  const { requiredRule } = useValidationRules()
  return (
    <CustomRadioGroup
      label={`${t('sell.workShop.radios.anonymousOffer.label')} *`}
      control={control}
      name="anonymous"
      options={options}
      rules={{ required: requiredRule() }}
    />
  )
}
export default AnonymousOfferRadio
