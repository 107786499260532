import { FC } from 'react'
import styles from './AccordionDetails.module.scss'
import { TCompany } from '../../../BaseInnerCircle'

type TProps = {
  companies: TCompany[]
}
const AccordionDetails: FC<TProps> = ({ companies }) => (
  <ul className={styles.list}>
    {companies.map(({ id, contact, name }) => (
      <li className={styles.listItem} key={id}>
        <p className={styles.label}>{name}</p>
        {
          contact && (
            <>
              <div className={styles.circle}/>
              <p className={styles.description}>{contact}</p>
            </>
          )
        }
      </li>
    ))}
  </ul>
)

export default AccordionDetails
