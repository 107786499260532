import constate from 'constate'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useEffect, useMemo } from 'react'
import { TDashboardForm, TStockForecastParams } from '../types'
import { parseProductSelectValue, prepareProductSelectValue, useGetProductLabelQuery } from '../../baseAnalytics'
import { dotDateFormat } from '../../../../shared/utils/date'
import {
  useStockForecastQuery,
  useWeeklyOverviewQuery
} from '../queries'

function useFormInit() {
  return useForm<TDashboardForm>({
    defaultValues: {
      date: new Date(),
      week: new Date()
    }
  })
}

function useResetForm({ watch, setValue }: UseFormReturn<TDashboardForm>) {
  const date = watch('date')
  const { data } = useGetProductLabelQuery(dotDateFormat(date))
  useEffect(() => setValue('product', !data || !data.length
    ? undefined
    : prepareProductSelectValue(data[0])), [data])
}

const useStockForecast = (date: Date, product?: string) => {
  const preparedData = useMemo(
    () => {
      if (!product) {
        return undefined
      }

      const preparedParams: TStockForecastParams = {
        ...parseProductSelectValue(product),
        date: dotDateFormat(date)
      }
      return preparedParams
    },
    [product, date]
  )

  return useStockForecastQuery(preparedData)
}

const DashboardState = () => {
  const formReturn = useFormInit()
  useResetForm(formReturn)
  const formValues = formReturn.watch() as TDashboardForm

  return {
    formReturn,
    stockForecast: useStockForecast(formValues.date, formValues?.product),
    weeklyOverview: useWeeklyOverviewQuery(formValues.week)
  }
}

export const [DashboardProvider, useDashboardContext] = constate(DashboardState)
