import { FC } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import AccordionSummary from '../AccordionSummary/AccordionSummary'
import GrowingPlanTable from '../GrowingPlanTable/GrowingPlanTable'
import Accordion from '../../../../../shared/components/Accordion/Accordion'
import { useGrowingPlanQuery } from '../../../BaseGrowingPlanWorkShop/queries'
import { prepareTableTotalRow } from '../../utils/prepareTableTotalRow'
import styles from './AccordionDetails.module.scss'

type TProps = {
  isCurrentSeller: boolean
  growingPlanId: string
}
const AccordionDetails: FC<TProps> = ({ growingPlanId, isCurrentSeller }) => {
  const { data } = useGrowingPlanQuery(growingPlanId)
  const { t } = useTranslation()
  return (
    <>
      <p className={classNames(
        styles.detailsLabel,
        { [styles.marginTop]: !isCurrentSeller && data?.description }
      )}>
        {data?.description}
      </p>
      {
        data?.productPlans.map(({ total, unit, product, values, id, totalsByMonths }) => {
          const tableData = [...values, prepareTableTotalRow(totalsByMonths)]
          return (
            <Accordion
              key={id}
              summary={(icon) => (
                <AccordionSummary
                  title={product.name}
                  description={`${total} ${t(`units.${unit.toLowerCase()}`)}`}
                  icon={icon}/>
              )}
              detail={<GrowingPlanTable productsPlan={tableData}/>}
            />
          )
        })
      }
    </>
  )
}

export default AccordionDetails
