import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { NavLink, useSearchParams } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import styles from './SignUp.module.scss'
import { TSignUpForm, TSignUpMutation } from '../../types'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import { useSignUpMutation } from '../../queries'
import { AuthLayout } from '../../../../../layouts/primaryLayout'
import { useUploadAttachment, prepareAttachment } from '../../../../attechment'
import FirstName from '../../../../../shared/components/formFields/FirstName'
import CompanyName from '../../../../../shared/components/formFields/CompanyName'
import LastName from '../../../../../shared/components/formFields/LastName'
import CompanyUid from '../../../../../shared/components/formFields/CompanyUid'
import Address from '../../../../../shared/components/formFields/Address'
import ImageFileUpload from '../../../../../shared/components/formControls/FileUpload/ImageFileUpload/ImageFileUpload'
import Zip from '../../../../../shared/components/formFields/Zip'
import City from '../../../../../shared/components/formFields/City'
import Phone from '../../../../../shared/components/formFields/Phone'
import Password from '../../../../../shared/components/formFields/Password'
import Email from '../../../../../shared/components/formFields/Email'
import MatchingPassword from '../../../../../shared/components/formFields/MatchingPassword'
import CustomCheckbox from '../../../../../shared/components/formControls/CustomCheckbox/CustomCheckbox'
import { TAttachment } from '../../../../attechment/types'
import { useRedirect } from '../../../authBase'

function useFormInit() {
  return useForm<TSignUpForm>({
    defaultValues: {
      firstName: '',
      companyName: '',
      lastName: '',
      companyUid: '',
      address: '',
      zip: '',
      city: '',
      phone: '',
      password: '',
      matchingPassword: '',
      email: '',
      subscribeToNewsLetters: false,
      acceptTermsAndConditions: false
    }
  })
}

function useOnSubmit(
  signUpMutateAsync: UseMutateAsyncFunction<void, Error, TSignUpMutation>,
  uploadAttachmentMutateAsync: UseMutateAsyncFunction<string, Error, TAttachment>
) {
  const redirect = useRedirect()

  return useCallback(async (data: TSignUpForm) => {
    const { file, companyName, companyUid, ...rest } = data
    const baseCompany = {
      name: companyName,
      uid: companyUid
    }
    if (file) {
      const logoId = await uploadAttachmentMutateAsync(prepareAttachment(file))
      await signUpMutateAsync({ ...rest, redirect, company: { ...baseCompany, logoId } })
      return
    }
    await signUpMutateAsync({ ...rest, redirect, company: baseCompany })
  }, [uploadAttachmentMutateAsync, signUpMutateAsync, redirect])
}

function useErrorHandling(
  { setError }: UseFormReturn<TSignUpForm>,
  isSignUpError: boolean,
  signUpError: any | null
) {
  useEffect(() => {
    if (isSignUpError) {
      if (signUpError?.statusCode === 409) {
        setError('firstName', { message: signUpError?.message })
        return
      }
      setError('email', { message: signUpError?.message })
    }
  }, [signUpError?.message, signUpError, setError, isSignUpError])
}

function useCleanError(
  { clearErrors, watch }: UseFormReturn<TSignUpForm>,
  isSignUpError: boolean,
  signUpError: any | null
) {
  const email = watch('email')

  useEffect(() => {
    if (isSignUpError && signUpError?.statusCode === 409) {
      clearErrors('firstName')
    }
  }, [email])
}
const SignUp = () => {
  const {
    mutateAsync: uploadAttachmentMutateAsync,
    isLoading: uploadAttachmentIsLoading
  } = useUploadAttachment()
  const {
    mutateAsync: signUpMutateAsync,
    isError,
    error,
    isLoading: signUpIsLoading
  } = useSignUpMutation()
  const { t } = useTranslation()
  const useFormReturn = useFormInit()
  const onSubmit = useOnSubmit(signUpMutateAsync, uploadAttachmentMutateAsync)

  useErrorHandling(useFormReturn, isError, error)
  useCleanError(useFormReturn, isError, error)
  const { watch, handleSubmit, control } = useFormReturn

  const isAcceptedTerms = !watch('acceptTermsAndConditions')

  return (
    <AuthLayout size="medium" header={t('signUp.label')}>
      <FormProvider {...useFormReturn}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <FirstName/>
          <CompanyName/>
          <LastName/>
          <CompanyUid/>
          <Address/>
          <ImageFileUpload label={t('signUp.fileUploadLabel')} />
          <div className={styles.inputGroup}>
            <Zip/>
            <City/>
          </div>
          <h2 className={styles.title}>{t('common.password')}</h2>
          <Phone/>
          <Password isRequired isPasswordValidation/>
          <Email isEmailValidation/>
          <MatchingPassword label={t('signUp.matchingPassword')} isRequired isConfirmPasswordValidation/>
          <div className={styles.formFooter}>
            <div className={styles.checkBoxContainer}>
              <CustomCheckbox control={control} name="subscribeToNewsLetters"/>
              <span className={styles.label}>{t('signUp.subscribe')}</span>
            </div>
            <div className={styles.checkBoxContainer}>
              <CustomCheckbox control={control} name="acceptTermsAndConditions"/>
              <span className={styles.label}>
                {t('signUp.accept')}
                {' '}
                <a
                  className={styles.navigateTerms}
                  target="_blank"
                  href="https://www.freshtrade.ch/terms"
                  rel="noreferrer">
                  {`${t('signUp.termsAndConditions')}`}
                </a>
                {' '}
                *
              </span>
            </div>
            <div className={styles.buttonWrap}>
              <PrimaryButton
                disabled={isAcceptedTerms || uploadAttachmentIsLoading || signUpIsLoading}
                text={t('common.signUp')}
                type="submit"/>
            </div>
            <p className={styles.text}>
              <span>
                {t('signUp.haveAccount')}
                <NavLink
                  to={`/login${window.location.search}`}
                  className={styles.navigateSignUp}>
                  {t('common.login')}
                </NavLink>
              </span>
            </p>
          </div>
        </form>
      </FormProvider>
    </AuthLayout>
  )
}

export default SignUp
