/* eslint-disable no-underscore-dangle,consistent-return */
import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import { useCallback, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../auth/authBase'
import { apiClient } from '../..'
import { useRefreshTokenMutation } from '../../queries'

function updateAuthorizationHeader(config: AxiosRequestConfig, authToken: string) {
  config.headers = {
    ...config.headers,
    Authorization: `Bearer ${authToken}`
  }
}

function useRequestInterceptor() {
  const { authData } = useAuthContext()
  useEffect(() => {
    const interceptor = apiClient.interceptors.request.use(
      (config) => {
        if (config.headers && authData?.accessToken) {
          updateAuthorizationHeader(config, authData.accessToken)
        }
        return config
      },
      (error) => Promise.reject(error)
    )
    return () => apiClient.interceptors.request.eject(interceptor)
  }, [authData, authData?.accessToken])
}

function useTokenRefresh() {
  const { mutateAsync: refreshTokenMutate } = useRefreshTokenMutation()

  // Status if refresh token loading started
  const isUpdating = useRef(false)
  // Token after update cache
  const tokenAfterUpdate = useRef<string | null>(null)

  // There is situation when we perform several application requests simultaneously.
  // This code exists to perform ONLY one token refreshing request
  return async () => {
    // Perform single refresh token request
    if (!isUpdating.current) {
      isUpdating.current = true
      try {
        // Refresh request
        const refreshRes = await refreshTokenMutate()

        // Setting token to cache to use in awaiting usecase
        tokenAfterUpdate.current = refreshRes.accessToken

        // Return new token
        return refreshRes.accessToken
      } finally {
        // Clear flag in any case
        isUpdating.current = false
      }
    }

    // Await for token refreshing request
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        // Checking process flag
        if (!isUpdating.current) {
          clearInterval(interval)

          // return new token from cache
          resolve(tokenAfterUpdate.current)
        }
      }, 100)
    })
  }
}

export const useOtherErrorsHandling = () => {
  const navigate = useNavigate()
  return useCallback(async ({ response, message }: AxiosError<any>) => {
    if (!response) {
      return
    }

    if (response.status === 404) {
      navigate('/*')
    }
  }, [])
}

export const useUnauthorisedHandle = () => {
  const navigate = useNavigate()
  const refreshToken = useTokenRefresh()
  return useCallback(async ({ response, config }: AxiosError<any>) => {
    if (!response) {
      return
    }
    // @ts-ignore
    if (response && response.status === 401 && !response.request.responseURL.includes('auth/signin') && !config._retry) {
      // @ts-ignore
      config._retry = true

      try {
        const newToken = await refreshToken()

        // @ts-ignore
        updateAuthorizationHeader(config, newToken)
        // @ts-ignore
        return axios(config)
      } catch {
        navigate('/logout')
      }
    }

    if (response.status === 403) {
      return Promise.reject(response.data.message)
    }

    return Promise.reject(response.data)
  }, [])
}

function useErrorResponseInterceptor() {
  const otherErrorsHandling = useOtherErrorsHandling()
  const unauthorisedHandle = useUnauthorisedHandle()
  useEffect(() => {
    const interceptor = apiClient.interceptors.response.use(
      (res) => res,
      async (err: AxiosError) => {
        if (axios.isAxiosError(err)) {
          await otherErrorsHandling(err)
          return unauthorisedHandle(err)
        }
      }
    )
    return () => apiClient.interceptors.response.eject(interceptor)
  }, [otherErrorsHandling, unauthorisedHandle])
}

const Interceptor = () => {
  useRequestInterceptor()
  useErrorResponseInterceptor()
  return null
}

export default Interceptor
