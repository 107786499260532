import MuiAccordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { FC, ReactNode, useState } from 'react'
import { AccordionProps } from '@mui/material/Accordion/Accordion'
import classNames from 'classnames'
import ArrowRightIcon from '../icons/ArrowRightIcon'
import styles from './Accordion.module.scss'

type TProps = {
  summary: (icon: JSX.Element, isActive: boolean) => ReactNode
  detail: ReactNode
  isPaddingSmall?: boolean
  isOpenAccordion?: boolean
  noPadding?: boolean
  hasError?: boolean
} & Omit<AccordionProps, 'children'>

const Accordion: FC<TProps> = ({
  summary,
  detail,
  isPaddingSmall,
  noPadding,
  hasError,
  defaultExpanded,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(defaultExpanded || false)
  return (
    <MuiAccordion
      onChange={(_, expanded) => setIsOpen(expanded)}
      disableGutters
      elevation={0}
      square
      defaultExpanded={defaultExpanded}
      className={classNames(
        styles.accordion,
        {
          [styles.paddingSmall]: isPaddingSmall,
          [styles.noPadding]: noPadding,
          [styles.error]: hasError
        }
      )}
      {...rest}
    >
      <AccordionSummary
        className={styles.accordionSummary}
        expandIcon={false}>
        {summary(
          <div className={classNames(styles.iconWrapper, { [styles.iconTransform]: isOpen })}>
            <ArrowRightIcon/>
          </div>,
          isOpen
        )}
      </AccordionSummary>
      <AccordionDetails className={styles.accordionDetails}>
        {detail}
      </AccordionDetails>
    </MuiAccordion>
  )
}

export default Accordion
