import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { NavigationBoard } from '../../../BaseNavigatioBoard'
import { dotDateFormat } from '../../../../../shared/utils/date'

export function useAnalyticsNavigationOptions() {
  const { t } = useTranslation()
  return useMemo(() => [
    {
      to: `/dashboard/planning-forecast?date=${dotDateFormat(new Date())}`,
      text: t('navigationBoard.analytics.navigations.forecast')
    },
    {
      to: `/dashboard/weeklyReports?week=${dotDateFormat(new Date())}`,
      text: t('navigationBoard.analytics.navigations.weeklyReports')
    }
  ], [t])
}

const Analytics = () => {
  const { t } = useTranslation()
  const navigations = useAnalyticsNavigationOptions()
  return (
    <NavigationBoard
      navigations={navigations}
      heading={t('navigationBoard.analytics.header')}
      description={t('navigationBoard.analytics.description')}
      title={t('navigationBoard.analytics.title')}
    />
  )
}

export default Analytics
