import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styles from './OfferNegotiation.module.scss'
import CustomInput from '../../../../../shared/components/formControls/CustomInput/CustomInput'
import { addZeros, floatFormat } from '../../../../../shared/utils/formUtils'
import { useGetProduct } from '../../queries'
import { useValidationRules } from '../../../../../shared/hooks/useValidationRules'
import { useMobile } from '../../../../../shared/hooks/useDevice'

const OfferNegotiation = () => {
  const { t } = useTranslation()
  const { data } = useGetProduct()
  const { requiredRule, lessValueValidation, moreValueValidation } = useValidationRules()
  const { control } = useFormContext()
  const isMobile = useMobile()

  const { minAmountTu, negotiation, amountTu, minPricePerCu } = data!
  const amountTUValidation = (value: string) => {
    const parsedAmountTU = Number(value)
    const resLessValueValidation = lessValueValidation(
      parsedAmountTU,
      minAmountTu
    )
    const resMoreValueValidation = moreValueValidation(
      parsedAmountTU,
      amountTu
    )

    return (resLessValueValidation !== true && resLessValueValidation) || resMoreValueValidation
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <p className={styles.text}>{t('buy.offer.footer.tu')}</p>
        <div className={styles.inputWrapper}>
          <CustomInput
            name="amountTu"
            control={control}
            handleChange={floatFormat}
            rules={{
              required: requiredRule(),
              validate: amountTUValidation
            }}
          />
        </div>
      </div>
      {
        negotiation && (
          <div className={styles.content}>
            {!isMobile && <div className={styles.circle}/>}
            <p className={styles.text}>{t('buy.offer.footer.pricePerCU')}</p>
            <div className={styles.inputWrapper}>
              <CustomInput
                handleBlur={addZeros}
                handleChange={floatFormat}
                name="priceChf"
                rules={{
                  required: requiredRule(),
                  validate: (value) => lessValueValidation(Number(value), minPricePerCu!)
                }}
                control={control}/>
            </div>
          </div>
        )
      }

    </div>
  )
}

export default OfferNegotiation
