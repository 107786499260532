import { useTranslation } from 'react-i18next'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import BreadCrumb from '../../../../../shared/components/BreadCrumb/BreadCrumb'
import OfferBody from '../OfferBody/OfferBody'
import OfferFooter from '../OfferFooter/OfferFooter'
import styles from './Offer.module.scss'
import { useGetProduct } from '../../queries'
import Spinner from '../../../../../shared/components/Spinner/Spinner'

const Offer = () => {
  const { t } = useTranslation()
  const { isLoading, isError, error } = useGetProduct()

  if (isLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }

  if (isError && error?.message) {
    return (
      <div className={styles.errorWrap}>
        <PrimaryLayout maxHeight header={t('buy.offer.information')} size="large">
          <p className={styles.errorMessage}>{error?.message}</p>
        </PrimaryLayout>
      </div>
    )
  }

  return (
    <div>
      <PrimaryLayout noPadding header="" size="large">
        <BreadCrumb label={t('buy.offer.header.title')} navigateTo="/buy"/>
        <OfferBody/>
        <div className={styles.line}/>
        <OfferFooter/>
      </PrimaryLayout>
    </div>
  )
}

export default Offer
