import { useTranslation } from 'react-i18next'
import { Control, useWatch } from 'react-hook-form'
import { FC, memo, useMemo } from 'react'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { useProductsQuery } from '../../../queries'
import { TOrderItemForm, TProductData } from '../../../types'
import { useBaseQuantityFormContext } from '../../../state/BaseQuantityFormState'
import { findExistingProductLabels } from '../../../utils/generateFormData'
import { useSellerId } from '../../../hooks/useSellerId'
import { combineGrowingPlanProducts } from '../../../utils/prepareGrowingPlanProductData'

function useOptions(productData?: TProductData) {
  const { t } = useTranslation()
  return useMemo(() => {
    if (!productData) {
      return []
    }
    const { growingPlanProducts, noGrowingPlanProducts } = productData

    const growingPlanList = (growingPlanProducts ?? []).map(({ productName, productId }) => ({
      value: productId,
      label: productName
    }))
    const noGrowingPlanList = (noGrowingPlanProducts ?? []).map(({ productName, productId }) => ({
      value: productId,
      label: productName
    }))

    return [
      ...(growingPlanList.length ? [{
        groupName: t('quantity.selects.product.inGrowingPlan'),
        options: growingPlanList
      }] : []),
      ...(noGrowingPlanList.length ? [{
        groupName: t('quantity.selects.product.notInGrowingPlan'),
        options: noGrowingPlanList
      }] : [])
    ]
  },
  [productData])
}

function useFilteredOptions(control: Control<any>, quantityIndex: number) {
  const { growingPlanId, date } = useBaseQuantityFormContext()
  const preparedSellerId = useSellerId()

  const { data } = useProductsQuery(growingPlanId, preparedSellerId, date)

  const orderItems = useWatch({ control, name: 'orderItems' }) as TOrderItemForm[]

  const combinedGrowingPlanProducts = combineGrowingPlanProducts(data)

  const fullProductIds = orderItems?.reduce<string[]>((result, current) => {
    const foundedGrowingPlan = combinedGrowingPlanProducts.find(({
      productId }) => productId === current.productId)
    const labels = foundedGrowingPlan
      ? [...(foundedGrowingPlan?.gpLabels ?? []), ...(foundedGrowingPlan?.noGpLabels ?? [])]
      : []
    if (
      labels.length
      && current.productId
      && current.productId !== orderItems[quantityIndex].productId
      && labels.length === findExistingProductLabels(orderItems, current.productId).length
    ) {
      result.push(current.productId)
    }

    return result
  }, [])

  return useOptions(data)
    .map((groupOption) => ({
      ...groupOption,
      options: groupOption.options.filter(({ value }) => !fullProductIds.includes(value))
    }))
    .filter((groupOption) => !!groupOption.options.length)
}

type TProps = {
  disabled: boolean
  quantityIndex: number
  control: Control<any>
}

const ProductSelect: FC<TProps> = ({ quantityIndex, control, disabled }) => {
  const { t } = useTranslation()
  const options = useFilteredOptions(control, quantityIndex)

  return (
    <CustomSelectControl
      disabled={disabled}
      control={control}
      groupedOptions={options}
      isRequired
      label={`${t('quantity.selects.product.label')} *`}
      placeholder={t('quantity.selects.product.placeholder') as string}
      name={`orderItems[${quantityIndex}].productId`}
      handleError={(error) => (error as any)?.orderItems?.[quantityIndex]?.productId}
    />
  )
}

export default memo(ProductSelect)
