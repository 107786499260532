import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { memo, useMemo } from 'react'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import styles from './UnitSelect.module.scss'

function useOptions() {
  const { t } = useTranslation()
  return useMemo(() => ([
    {
      value: 'KG',
      label: t('units.kg')
    },
    {
      value: 'T',
      label: t('units.t')
    },
    {
      value: 'PCS',
      label: t('units.pcs')
    }
  ]), [])
}

const UnitSelect = () => {
  const { t } = useTranslation()
  const options = useOptions()
  const { control } = useFormContext()
  return (
    <div className={styles.wrapper}>
      <CustomSelectControl
        control={control}
        options={options}
        isRequired
        name="unit"
        label={`${t('growingPlan.workShop.unit')} *`}
        placeholder={t('growingPlan.workShop.selectUnit') as string}
        handleError={(error) => (error as any)?.unit}
      />
    </div>
  )
}

export default memo(UnitSelect)
