import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useIdleTimer } from 'react-idle-timer'
import Header from '../Header/Header'
import styles from './MainLayout.module.scss'
import Footer from '../Footer/Footer'
import { MainLayoutProvider } from '../../state/MainLayoutState'
import Sidebar from '../Sidebar/Sidebar'
import { LanguageSwitcherProvider } from '../../../../features/languageSwitcher'
import { useAuthContext } from '../../../../features/auth/authBase'

export type TProps = {
  isNonAuth?: boolean
}

function useAutoLogout() {
  const timeout = +process.env.REACT_APP_IDLE_TIME!
  const navigate = useNavigate()
  const { authData } = useAuthContext()
  const timer = useIdleTimer({
    timeout,
    debounce: 500,
    onIdle: () => {
      if (authData) {
        navigate('/logout')
      }
      timer.reset()
    }
  })
}

const MainLayout = () => {
  useAutoLogout()
  return (
    <>
      <Sidebar/>
      <div className={styles.wrapper}>
        <Header/>
        <div className={styles.content}>
          <div className={styles.outlet}>
            <Outlet/>
          </div>
        </div>
        <Footer/>
      </div>
    </>
  )
}

export default (props: TProps) => (
  <MainLayoutProvider {...props}>
    <LanguageSwitcherProvider>
      <MainLayout/>
    </LanguageSwitcherProvider>
  </MainLayoutProvider>
)
