import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { FC, useState } from 'react'
import SecondaryButton from '../../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import styles from './AddWeeklyReport.module.scss'
import { useQuantityReportsContext } from '../../../state/QuantityReportsState'
import { dotDateFormat } from '../../../../../../shared/utils/date'
import { Payment } from '../../../../../payment'
import { NO_GROWING_PLAN_OPTION } from '../../../../BaseQuantityReports'

type TProps = {
  weekStartDate: Date
}
const AddWeeklyReport: FC<TProps> = ({ weekStartDate }) => {
  const [isPaymentModal, setIsPaymentModal] = useState(false)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { selectedGrowingPlan, formMethods: { getValues } } = useQuantityReportsContext()

  const handleNavigate = () => {
    const { buyer, seller } = getValues()
    navigate({
      pathname: `weekly/${selectedGrowingPlan?.id || NO_GROWING_PLAN_OPTION}`,
      search: `&date=${dotDateFormat(weekStartDate)}&buyerId=${buyer}&sellerId=${seller}&copy=`
    })
  }

  return (
    <>
      <Payment
        label={t('quantity.modals.payment.label')}
        isOpen={isPaymentModal}
        onClose={() => setIsPaymentModal(false)}
        callBack={handleNavigate}/>
      <SecondaryButton
        text={t('quantity.calendar.addWeeklyReport')}
        onClick={handleNavigate}
        className={styles.buttonText}
      />
    </>
  )
}

export default AddWeeklyReport
