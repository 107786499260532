import * as React from 'react'
import { format, isWithinInterval, isSameDay } from 'date-fns'
import { styled } from '@mui/material/styles'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { PickersDay, PickersDayProps, LocalizationProvider, StaticDatePicker } from '@mui/x-date-pickers-pro'
import { Control, Controller, useWatch } from 'react-hook-form'
import { FC, useMemo, useState } from 'react'
import { DefaultTFuncReturn } from 'i18next'
import en from 'date-fns/locale/en-US'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
import it from 'date-fns/locale/it'
import { Menu, TextField } from '@mui/material'
import classNames from 'classnames'
import { endOfWeekCustom, startOfWeekCustom } from '../../../utils/date'
import { DOT_DATE_FORMAT } from '../../../constants/date'
import styles from './CustomWeekPicker.module.scss'
import { TLanguage } from '../../../../features/account/accountBase'
import { useLanguageSwitcherContext } from '../../../../features/languageSwitcher'
import DateIcon from '../../icons/DateIcon'

type CustomPickerDayProps = {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
};

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay'
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    }
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  }),
  ...(isLastDay && {
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  })
})) as unknown as React.ComponentType<CustomPickerDayProps>

export function useGetWeekLabel({ control, name }: TProps) {
  const week = useWatch({ control, name })
  const startOfWeek = format(startOfWeekCustom(week), 'dd.MM')
  const endOfWeek = format(endOfWeekCustom(week), DOT_DATE_FORMAT)
  return `${startOfWeek} - ${endOfWeek}`
}

type TProps = {
  control: Control<any>
  name: string
  label?: string | DefaultTFuncReturn
}
const locales: {[key in TLanguage]: Locale} = {
  en, fr, de, it
}

const CustomWeekPicker: FC<TProps> = ({ control, name, label }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const weekLabel = useGetWeekLabel({ control, name })
  const isOpen = Boolean(anchorEl)
  const { language } = useLanguageSwitcherContext()
  const week = useWatch({ control, name })
  const Day = (date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>) => {
    if (!week) {
      return <PickersDay {...pickersDayProps} />
    }

    const start = startOfWeekCustom(week)
    const end = endOfWeekCustom(week)
    const dayIsBetween = isWithinInterval(date, { start, end })
    const isFirstDay = isSameDay(date, start)
    const isLastDay = isSameDay(date, end)

    return (
      <CustomPickersDay
        disableMargin
        {...pickersDayProps}
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay} />
    )
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const locale = locales[language]

  if (locale && locale.options) {
    locale.options.weekStartsOn = 1
  }

  return (
    <div>
      {label && <p className={styles.label}>{label}</p>}
      <button className={classNames(styles.buttonWrap, { [styles.active]: isOpen })} type="button" onClick={handleClick}>
        <p className={styles.title}>{weekLabel}</p>
        <div className={styles.iconWrap}><DateIcon/></div>
      </button>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={handleClose}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
          <Controller
            control={control}
            name={name}
            render={({ field, fieldState, formState }) => (
              <StaticDatePicker
                showToolbar={false}
                showDaysOutsideCurrentMonth
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue)
                  handleClose()
                }}
                componentsProps={{
                  actionBar: { actions: [] }
                }}
                renderDay={Day}
                renderInput={(props) => <TextField {...props}/>}
              />
            )}
          />
        </LocalizationProvider>
      </Menu>
    </div>
  )
}

export default CustomWeekPicker
