import { FC } from 'react'
import { ReactComponent as Icon } from './svg/Percentage.svg'
import { IconWrapper } from './iconsBase/IconWrapper/IconWrapper'

const PercentageIcon: FC = () => (
  <IconWrapper>
    <Icon/>
  </IconWrapper>
)

export default PercentageIcon
