import { Drawer } from '@mui/material'
import { NavLink } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { useMainLayoutContext } from '../../state/MainLayoutState'
import styles from './Sidebar.module.scss'
import { useTablet } from '../../../../shared/hooks/useDevice'
import { dotDateFormat } from '../../../../shared/utils/date'

const baseKey = 'mainLayout.navigation'

function useSidebarClose() {
  const isNotTablet = !useTablet()
  const { setIsSidebar } = useMainLayoutContext()
  useEffect(() => {
    if (isNotTablet) {
      setIsSidebar(false)
    }
  }, [isNotTablet, setIsSidebar])
}
const Sidebar = () => {
  const { t } = useTranslation()
  const { isSidebar, setIsSidebar } = useMainLayoutContext()
  useSidebarClose()
  const handleClose = () => setIsSidebar(false)
  return (
    <Drawer
      disablePortal
      open={isSidebar}
      anchor="right"
      classes={{
        paper: styles.drawer
      }}
    >
      <Header/>
      <div className={styles.navigationContainer}>
        <NavLink onClick={handleClose} end className={styles.navigationMain} to="/dashboard">
          {t(`${baseKey}.dashboard`)}
        </NavLink>
        <NavLink onClick={handleClose} end className={styles.navigationMain} to="/planning">
          {t('navigationBoard.planning.header')}
        </NavLink>
        <NavLink onClick={handleClose} className={styles.navigation} to="/growing-plan">
          {t(`${baseKey}.growingPlan`)}
        </NavLink>
        <NavLink onClick={handleClose} end className={styles.navigation} to="/quantity-reports">
          {t(`${baseKey}.quantityReports`)}
        </NavLink>
        <NavLink onClick={handleClose} className={styles.navigation} to="/crop-stock">
          {t(`${baseKey}.crop/stock`)}
        </NavLink>
        <NavLink onClick={handleClose} end className={styles.navigationMain} to="/trading">
          {t('navigationBoard.trading.header')}
        </NavLink>
        <NavLink onClick={handleClose} className={styles.navigation} to="/sell">
          {t(`${baseKey}.sell`)}
        </NavLink>
        <NavLink onClick={handleClose} className={styles.navigation} to="/buy">
          {t(`${baseKey}.buy`)}
        </NavLink>
        <NavLink onClick={handleClose} end className={styles.navigationMain} to="/analytics">
          {t('navigationBoard.analytics.header')}
        </NavLink>
        <NavLink onClick={handleClose} className={styles.navigation} to={`/dashboard/planning-forecast?date=${dotDateFormat(new Date())}`}>
          {t('navigationBoard.analytics.navigations.forecast')}
        </NavLink>
        <NavLink onClick={handleClose} className={styles.navigation} to={`/dashboard/weeklyReports?week=${dotDateFormat(new Date())}`}>
          {t('navigationBoard.analytics.navigations.weeklyReports')}
        </NavLink>
        <NavLink
          end
          to="my-account"
          className={styles.navigationMain}>
          {t(`${baseKey}.user.myAccount`)}
        </NavLink>
        <NavLink onClick={handleClose} end className={styles.navigation} to="/my-orders">
          {t(`${baseKey}.user.myOrders`)}
        </NavLink>
        <NavLink onClick={handleClose} end className={styles.navigation} to="/my-sells">
          {t(`${baseKey}.user.mySells`)}
        </NavLink>
        <NavLink onClick={handleClose} end className={styles.navigation} to="/inner-circle">
          {t(`${baseKey}.user.innerCircle`)}
        </NavLink>
        <NavLink onClick={handleClose} end className={styles.navigation} to="/delivery-days">
          {t(`${baseKey}.deliveryDaysSettings`)}
        </NavLink>
        <a onClick={handleClose} className={styles.navigation} target="_blank" href="https://www.freshtrade.ch/invite" rel="noreferrer">
          {t(`${baseKey}.user.recommend`)}
        </a>
        <NavLink onClick={handleClose} end className={styles.navigation} to="/logout">
          {t(`${baseKey}.user.logout`)}
        </NavLink>
      </div>
      <Footer/>
    </Drawer>
  )
}

export default Sidebar
