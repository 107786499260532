import { useTranslation } from 'react-i18next'
import SellTable from '../SellTable/SellTable'
import { useMySellsQuery } from '../../queries'
import Spinner from '../../../../shared/components/Spinner/Spinner'
import styles from './MySells.module.scss'
import PrimaryLayout from '../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'

const MySells = () => {
  const { t } = useTranslation()
  const { isLoading, data } = useMySellsQuery()

  if (isLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }
  return (
    <PrimaryLayout maxHeight size="large" header={<div className={styles.label}>{t('sell.list.mySells')}</div>}>
      {
        data?.length
          ? <SellTable/>
          : <p className={styles.noDataLabel}>{t('sell.list.noDataSell.label')}</p>
      }
    </PrimaryLayout>
  )
}

export default MySells
