import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import styles from './ReadOnlyWeeklyReportButton.module.scss'
import SecondaryButton from '../../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { useQuantityReportsContext } from '../../../state/QuantityReportsState'
import { TOrdersCalendarDataItem } from '../../../types'

type TProps = {
  item: TOrdersCalendarDataItem
}

const ReadOnlyWeeklyReportButton: FC<TProps> = ({ item: { startDate, endDate } }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { formMethods: { getValues } } = useQuantityReportsContext()

  const handleNavigate = () => {
    const { buyer, seller, growingPlan } = getValues()
    navigate({
      pathname: `weekly/${growingPlan}`,
      search: `&readOnly=true&buyerId=${buyer}&sellerId=${seller}&dateFrom=${startDate}&dateTo=${endDate}`
    })
  }
  return (
    <SecondaryButton
      text={t('quantity.calendar.showWeeklyReport')}
      onClick={handleNavigate}
      className={styles.buttonText}
    />
  )
}

export default ReadOnlyWeeklyReportButton
