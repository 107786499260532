import { FC } from 'react'
import { DateHeaderProps } from 'react-big-calendar'
import { format } from 'date-fns'
import styles from './DateHeader.module.scss'

type TProps = DateHeaderProps
const DateHeader: FC<TProps> = ({ date }) => (
  <div className={styles.dateHeaderWrapper}>
    <p className={styles.dateLabel}>{ format(date, 'd')}</p>
  </div>
)

export default DateHeader
