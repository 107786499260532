import { useTranslation } from 'react-i18next'
import { ReactNode, useEffect, useMemo, useState } from 'react'
import CustomTabs from '../../../../../shared/components/tabs/Tabs/Tabs'
import Tab from '../../../../../shared/components/tabs/Tab/Tab'
import { useUsersContext } from '../../state/UsersState'
import { TFilter } from '../../types'
import styles from './Tabs.module.scss'
import { useRegistrationRequestsQuery } from '../../queries'

type TTabConfigItem = { name: string | ReactNode; key?: TFilter }

const NewRegistrations = ({ count }: {count: number}) => {
  const { t } = useTranslation()
  return (
    <div className={styles.tab}>
      <p className={styles.tabText}>{t('admin.users.tab.newRegistrations')}</p>
      {!!count && <span className={styles.circle}>{count}</span>}
    </div>
  )
}
function useTabs(newRegistrationCount: number): TTabConfigItem[] {
  const { t } = useTranslation()

  return [
    {
      name: t('admin.users.tab.allUsers'),
      key: 'ACTIVE'
    },
    {
      name: <NewRegistrations count={newRegistrationCount}/>,
      key: 'PENDING_APPROVAL'
    },
    {
      name: t('admin.users.tab.deactivated'),
      key: 'DEACTIVATED'
    },
    {
      name: t('admin.users.tab.rejected'),
      key: 'REJECTED'
    }
  ]
}

function useTabsHandle(newRegistrationCount: number) {
  const { filter, handleChangeFilter } = useUsersContext()

  const tabs = useTabs(newRegistrationCount)

  const initialFilterIndex = tabs.findIndex(({ key }) => key === filter) || 0

  const [filerIndex, setFilerIndex] = useState(initialFilterIndex)

  const filterKey = useMemo(() => tabs[filerIndex].key, [filerIndex])

  useEffect(() => handleChangeFilter(filterKey!), [filterKey])

  return {
    filerIndex,
    setFilerIndex
  }
}

const Tabs = () => {
  const { registeredUsers: { data } } = useUsersContext()
  const newRegistrationCount = data?.totalElements || 0
  const tabs = useTabs(newRegistrationCount)

  const tabNames = useMemo(() => tabs.map(({ name }) => name), [])
  const { filerIndex, setFilerIndex } = useTabsHandle(newRegistrationCount)

  return (
    <div className={styles.tabsWrapper}>
      <CustomTabs
        value={filerIndex}
        onChange={(_, newValue) => {
          setFilerIndex(newValue)
        }}
      >
        {tabNames.map((name, index) => (
        // eslint-disable-next-line react/no-array-index-key
          <Tab key={index} label={name} />
        ))}
      </CustomTabs>
      <div className={styles.line}/>
    </div>
  )
}

export default Tabs
