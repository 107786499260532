import constate from 'constate'
import { useState } from 'react'
import { useForm } from 'react-hook-form'

function useFormInit() {
  return useForm({
    defaultValues: {
      month: 'ALL'
    }
  })
}

type TGrowingPlanDeleteParams = {
  id: string
  title: string
}
const useGrowingPlanDetailsState = () => {
  const [growingPlanDeleteParams, setGrowingPlanDeleteParams] = useState<TGrowingPlanDeleteParams>()
  return {
    formReturn: useFormInit(),
    growingPlanDeleteParams,
    setGrowingPlanDeleteParams
  }
}

export const [
  GrowingPlanDetailsProvider,
  useGrowingPlanDetailsContext
] = constate(useGrowingPlanDetailsState)
