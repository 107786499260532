import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import styles from './Bid.module.scss'
import OfferNegotiation from '../OfferNegotiation/OfferNegotiation'
import { useGetProduct } from '../../queries'
import BidTotal from '../BidTotal/BidTotal'
import { useAuthContext } from '../../../../auth/authBase'

const Bid: FC = () => {
  const { productId } = useParams()
  const { data } = useGetProduct()
  const { authData } = useAuthContext()
  const { t } = useTranslation()

  const { negotiation } = data!

  return (
    <div className={styles.bidWrapper}>
      <p className={styles.textBold}>{t(`buy.offer.footer.${negotiation && authData ? 'makeBid' : 'buy'}`)}</p>
      {
        authData
          ? (
            <>
              <OfferNegotiation/>
              <BidTotal/>
            </>
          )
          : (
            <NavLink
              className={styles.navigation}
              to={`/login?redirect=/buy/${productId}`}>
              {t('login.buyProduct')}
            </NavLink>
          )
      }

    </div>
  )
}
export default Bid
