import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { apiClient } from '../../Interceptor'
import { TOffer, TOfferParams } from '../BaseOffer'

enum QueryKeys{
  SingleOffer = 'SingleOffer'
}
async function editOffer(params: { data: TOfferParams, publish: boolean }, offerId?: string) {
  const { data, publish } = params
  try {
    await apiClient.put(`app/offer/${offerId}?publish=${publish}`, data)
  } catch (e: any) {
    throw new Error(e)
  }
}

async function getOfferById(offerId: string): Promise<TOffer> {
  try {
    const response = await apiClient(`app/offer/${offerId}`)
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useGetOfferByIdQuery = () => {
  const { offerId } = useParams()
  const keys = [QueryKeys.SingleOffer, offerId]
  return useQuery<TOffer, Error>(
    keys,
    () => getOfferById(offerId!),
    {
      enabled: !!offerId,
      cacheTime: 0
    }
  )
}
export const useEditOfferMutation = () => {
  const queryClient = useQueryClient()
  const { offerId } = useParams()
  return useMutation<
    void,
    Error,
    { data: TOfferParams, publish: boolean }
  >((params) => editOffer(params, offerId), {
    onSuccess: () => queryClient.invalidateQueries(['OfferList'])
  })
}
