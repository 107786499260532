import { useMutation } from '@tanstack/react-query'
import { TCreateCropStockMutationParams } from './types'
import { apiClient } from '../../Interceptor'

async function createCropStock(data: TCreateCropStockMutationParams) {
  try {
    await apiClient.post('/app/crop-stock', data)
  } catch (error: any) {
    throw error
  }
}

export const useCreateCropStockMutation = () => useMutation<
  void,
  Error,
  TCreateCropStockMutationParams
>(
  (data) => createCropStock(data)
)
