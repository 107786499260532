import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { apiClient } from '../Interceptor'
import { TAttachment, TAttachmentUserResponse } from './types'

async function uploadAttachment({
  file,
  contentType,
  contentLength,
  fileName
}: TAttachment): Promise<string> {
  try {
    const { data: { preSignedUrl, key } } = await apiClient.put<TAttachmentUserResponse>(
      '/users/company-logo/init-upload', {
        fileName, contentType, contentLength
      }
    )

    await axios.put(preSignedUrl, file, {
      headers: {
        'Access-Control-Expose-Headers': '*',
        // 'Content-Length': contentLength,
        'Content-Type': contentType
      }
    })

    return key
  } catch (e: any) {
    throw new Error(e)
  }
}

export function useUploadAttachment() {
  return useMutation<string, Error, TAttachment>((file) => uploadAttachment(file))
}
