import { useForm, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useCallback, useEffect } from 'react'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import { TCreateCropStockMutationParams, TForm } from '../../types'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import { useCreateCropStockMutation } from '../../queries'
import ProductSelect from '../../../BaseCropStock/components/selects/ProductSelect/ProductSelect'
import LabelSelect from '../../../BaseCropStock/components/selects/LabelSelect/LabelSelect'
import CustomInput from '../../../../../shared/components/formControls/CustomInput/CustomInput'
import UnitSelect from '../../../BaseCropStock/components/selects/UnitSelect/UnitSelect'
import CustomTextArea from '../../../../../shared/components/formControls/CustomTextArea/CustomTextArea'
import CustomDatePicker from '../../../../../shared/components/formControls/CustomDatePicker/CustomDatePicker'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { floatFormat } from '../../../../../shared/utils/formUtils'
import styles from './CreateCropStock.module.scss'
import { useValidationRules } from '../../../../../shared/hooks/useValidationRules'
import { DOT_DATE_FORMAT } from '../../../../../shared/constants/date'

function useFormInit() {
  return useForm<TForm>({
    defaultValues: {
      type: '',
      title: '',
      date: new Date(),
      amount: '',
      description: '',
      productId: ''
    }
  })
}

function useHandleSubmit(
  mutateAsync: UseMutateAsyncFunction<void, Error, TCreateCropStockMutationParams>
) {
  const navigate = useNavigate()
  return useCallback((data: TForm) => {
    mutateAsync({
      ...data,
      date: format(new Date(data.date), DOT_DATE_FORMAT),
      amount: Number(data.amount),
      type: 'HARVEST_BUY' })
      .then(() => navigate('/crop-stock'))
  }, [])
}

function useSetProductIdError(
  isError: boolean,
  error: Error | null,
  { setError }: UseFormReturn<TForm>
) {
  useEffect(() => {
    if (isError && error?.message) {
      setError('productId', { message: error.message })
      setError('unit', { message: '' })
    }
  }, [error?.message, isError, setError])
}

const CreateCropStock = () => {
  const { mutateAsync, isError, error } = useCreateCropStockMutation()
  const navigate = useNavigate()
  const { requiredRule } = useValidationRules()
  const { t } = useTranslation()
  const useFormReturn = useFormInit()
  const onSubmit = useHandleSubmit(mutateAsync)
  useSetProductIdError(isError, error, useFormReturn)

  const { handleSubmit, control } = useFormReturn

  return (
    <div className={styles.wrapper}>
      <PrimaryLayout noPadding size="large" header={<div className={styles.label}>{t('cropStock.create.label')}</div>}>
        <form
          className={styles.form}
          onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.content}>
            <div className={styles.fieldGroup}>
              <div>
                <ProductSelect control={control}/>
              </div>
              <LabelSelect control={control}/>
              <CustomInput
                label={`${t('cropStock.common.title')} *`}
                name="title"
                control={control}
                rules={{
                  required: requiredRule()
                }}
              />
              <div className={styles.datePickerWrapper}>
                <CustomDatePicker
                  label={`${t('cropStock.common.date')} *`}
                  control={control}
                  isRequired
                  name="date"/>
              </div>
              <div className={styles.group}>
                <CustomInput
                  label={`${t('cropStock.common.amount')} *`}
                  name="amount"
                  handleChange={(value) => floatFormat(value)}
                  rules={{
                    required: requiredRule()
                  }}
                  control={control}/>
                <UnitSelect control={control}/>
              </div>
            </div>
            <div className={styles.textAreaWrapper}>
              <CustomTextArea
                label={t('cropStock.common.description')}
                name="description"
                control={control}/>
            </div>
          </div>
          <div className={styles.line}/>
          <div className={styles.buttonGroup}>
            <PrimaryButton type="submit" text={t('cropStock.create.addProduct')}/>
            <SecondaryButton onClick={() => navigate('/crop-stock')} text={t('common.cancel')}/>
          </div>
        </form>
      </PrimaryLayout>
    </div>
  )
}
export default CreateCropStock
