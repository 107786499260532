import { useTranslation } from 'react-i18next'
import styles from './Search.module.scss'
import CustomInput from '../../../../../shared/components/formControls/CustomInput/CustomInput'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SearchIcon from '../../../../../shared/components/icons/SearchIcon'
import CloseFillIcon from '../../../../../shared/components/icons/CloseCircleIcon'
import { useOffersContext } from '../../state/OffersState'

const Search = () => {
  const { t } = useTranslation()
  const {
    productListMutation: { mutate },
    useFormReturn: { watch, control, setValue }
  } = useOffersContext()
  const searchString = watch('searchString')

  return (
    <div className={styles.wrapper}>
      <CustomInput
        icon={<SearchIcon/>}
        control={control}
        placeholder={t('buy.offers.searchPlaceholder') as string}
        iconError={<SearchIcon/>}
        name="searchString"
        InputProps={{
          endAdornment: !!searchString && (
            <button
              type="button"
              className={styles.iconWrapper}
              onClick={() => {
                setValue('searchString', '')
                mutate('')
              }}
            >
              <CloseFillIcon width={20} height={21}/>
            </button>
          )
        }}

      />
      <PrimaryButton
        onClick={() => mutate(searchString)}
        text={t('buy.offers.search')}/>
    </div>
  )
}

export default Search
