import { FC } from 'react'
import classNames from 'classnames'
import { useGrowingPlanWorkShopFormContext } from '../../../state/GrowingPlanWorkShopFormState'
import styles from './TotalCell.module.scss'
import { addNumber } from '../../../utils/tableUtils'
import { TMonth } from '../../../types'
import { roundNumber } from '../../../../../../shared/utils/calculationUtils'

type TTableMonth = 'jan' | 'feb' | 'mar' | 'apr' | 'may' | 'jun' | 'jul' | 'aug' | 'sep' | 'oct' | 'nov' | 'dec'
type TProps = {
  month?: TTableMonth
  rowIndex?: number
  name: string
}

function useCalculateTotal(name: string, month?: TTableMonth, rowIndex?: number) {
  const { formReturn: { watch }, tableDefaultValues } = useGrowingPlanWorkShopFormContext()
  const lastRow = tableDefaultValues.length - 1
  const tableValuesForm = watch(name).values as TMonth[]
  const tableValues = tableValuesForm.map(({
    label,
    labelId,
    id,
    total,
    ...rest
  }) => {
    const months = Object.entries(rest)
    return months.reduce((acc, [month, { value }]) => ({
      ...acc,
      [month]: value
    }), {} as {[key in TTableMonth]: number})
  })

  function total() {
    if (month) {
      return roundNumber(tableValues
        .map((item) => item[month])
        .reduce(addNumber, 0))
    }

    if (rowIndex === undefined) {
      return 0
    }

    if (rowIndex === lastRow) {
      return roundNumber(Number(
        tableValues
          .reduce((acc: number, curr) => {
            const totalRow = Object.values(curr).reduce(addNumber, 0)
            return acc + totalRow
          }, 0)
      ))
    }
    return roundNumber(Number(Object
      .values(tableValues[rowIndex!] || {})
      .reduce(addNumber, 0)))
  }
  return total()
}

const TotalCell: FC<TProps> = ({ rowIndex, month, name }) => {
  const total = useCalculateTotal(name, month, rowIndex)

  return (
    <span className={classNames(
      styles.textBold,
      { [styles.paddingLeft]: !month }
    )}>
      {total}
    </span>
  )
}

export default TotalCell
