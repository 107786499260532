import { FC } from 'react'
import styles from './ErrorNotification.module.scss'
import WarningIcon from '../../../../shared/components/icons/WarningIcon'

type TProps = {
  message: string
}
const ErrorNotification: FC<TProps> = ({ message }) => (
  <div className={styles.wrapper}>
    <div className={styles.iconWrap}>
      <WarningIcon width={20} height={20}/>
    </div>
    <p className={styles.message}>{message}</p>
  </div>
)

export default ErrorNotification
