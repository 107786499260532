import { useTranslation } from 'react-i18next'
import { useFormContext, ValidateResult, RegisterOptions } from 'react-hook-form'
import { useCallback, useMemo } from 'react'
import styles from './AmountPricing.module.scss'
import CustomerUnitSelect from '../selects/CustomerUnitSelect/CustomerUnitSelect'
import TradeUnitSelect from '../selects/TradeUnitSelect/TradeUnitSelect'
import LogisticSelect from '../selects/LogisticSelect/LogisticSelect'
import CustomInput from '../../../../../shared/components/formControls/CustomInput/CustomInput'
import { addZeros, floatFormat } from '../../../../../shared/utils/formUtils'
import { useValidationRules } from '../../../../../shared/hooks/useValidationRules'
import { roundNumber } from '../../../../../shared/utils/calculationUtils'

function useCalculateTotalPrice() {
  const { watch } = useFormContext()

  const { pricePerCu, minPricePerCu, negotiation, amountTu, amountCuPerTu } = watch()
  const isNegotiationFalse = negotiation === 'NO'
  const price = isNegotiationFalse ? pricePerCu : minPricePerCu

  return useMemo(() => {
    if (!price || !amountCuPerTu || !amountTu) {
      return undefined
    }
    return roundNumber(Number(price) * Number(amountCuPerTu) * Number(amountTu))
  }, [amountTu, amountCuPerTu, price])
}

function useValidateAmountMinTu() {
  const { watch, setError } = useFormContext()
  const { t } = useTranslation()
  const { minAmountTu, amountTu } = watch()
  const minAmountValidate: RegisterOptions['validate'] = useCallback(
    (): ValidateResult => {
      if ((Number(minAmountTu) > Number(amountTu))) {
        return t('sell.workShop.amountError') as string
      }
      return true
    },
    [amountTu, minAmountTu, setError, t]
  )

  return minAmountValidate
}

function useValidateAmountTu() {
  const { watch, clearErrors } = useFormContext()
  const { minAmountTu, amountTu } = watch()
  const minAmountValidate: RegisterOptions['validate'] = useCallback(
    (): ValidateResult => {
      if ((Number(minAmountTu) < Number(amountTu))) {
        clearErrors('minAmountTu')
        return true
      }
      return true
    },
    [amountTu, minAmountTu]
  )

  return minAmountValidate
}

function useCalculateDiscountPrice() {
  const { watch } = useFormContext()
  const totalPrice = useCalculateTotalPrice()
  const { discount, minDiscountAmountTu } = watch()
  return useMemo(() => {
    if (!totalPrice || !discount || !minDiscountAmountTu) {
      return undefined
    }

    return roundNumber((totalPrice - (totalPrice * Number(discount)) / 100))
  }, [totalPrice, discount, minDiscountAmountTu])
}

const AmountPricing = () => {
  const { t } = useTranslation()
  const { requiredRule } = useValidationRules()
  const { control, watch } = useFormContext()
  const totalPrice = useCalculateTotalPrice()
  const discountTotalPrice = useCalculateDiscountPrice()
  const validateAmountMinTu = useValidateAmountMinTu()
  const validateAmountTu = useValidateAmountTu()

  const { discount, minDiscountAmountTu, negotiation, unit } = watch()
  const isNegotiationFalse = negotiation === 'NO'

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          {t('sell.workShop.amountPricing.label')}
        </h1>
      </div>
      <div className={styles.body}>
        <CustomerUnitSelect/>
        <TradeUnitSelect/>
        <LogisticSelect/>
        <CustomInput
          label={`${t('sell.workShop.inputs.amountCU.labelTest',
            { unit: unit?.toLowerCase() ?? '-' })} *`}
          name="amountKgPerCu"
          handleChange={(value) => floatFormat(value, 3)}
          control={control}
          rules={{
            required: requiredRule()
          }}
        />
        <CustomInput
          label={`${t('sell.workShop.inputs.amountTU.label')} *`}
          name="amountCuPerTu"
          handleChange={(value) => floatFormat(value, 3)}
          control={control}
          rules={{
            required: requiredRule()
          }}
        />
        <CustomInput
          label={`${t('sell.workShop.inputs.amountLU.label')} *`}
          name="amountTuPerLu"
          handleChange={(value) => floatFormat(value, 3)}
          control={control}
          rules={{
            required: requiredRule()
          }}
        />
        <CustomInput
          label={`${t('sell.workShop.inputs.amountInTU.label')} *`}
          name="amountTu"
          handleChange={floatFormat}
          control={control}
          rules={{
            required: requiredRule(),
            validate: validateAmountTu
          }}
        />
        <CustomInput
          label={`${t('sell.workShop.inputs.minAmountTU.label')} *`}
          name="minAmountTu"
          handleChange={floatFormat}
          control={control}
          rules={{
            required: requiredRule(),
            validate: validateAmountMinTu
          }}
        />
        {
          isNegotiationFalse
            ? (
              <CustomInput
                key={negotiation}
                label={`${t('sell.workShop.inputs.price.label')} *`}
                name="pricePerCu"
                handleBlur={addZeros}
                handleChange={floatFormat}
                control={control}
                rules={{
                  required: requiredRule()
                }}
              />
            )
            : (
              <CustomInput
                key={negotiation}
                label={<p className={styles.minPriceInputLabel}>{`${t('sell.workShop.inputs.minPrice.label')} *`}</p>}
                name="minPricePerCu"
                textFieldClassName={styles.minPriceInput}
                handleBlur={addZeros}
                handleChange={floatFormat}
                control={control}
                rules={{
                  required: requiredRule()
                }}
              />
            )
        }
      </div>
      <div className={styles.footer}>
        <div className={styles.discount}>
          <p className={styles.label}>{t('sell.workShop.amountPricing.discount')}</p>
          <div className={styles.discountInput}>
            <CustomInput
              control={control}
              name="discount"
              handleChange={floatFormat}
              rules={{
                required: minDiscountAmountTu ? requiredRule() : undefined
              }}
            />
          </div>
          <div className={styles.amountHighWrapper}>
            <p className={styles.label}>
              {t('sell.workShop.amountPricing.amountHigh')}
            </p>
            <div className={styles.amountHighInput}>
              <CustomInput
                control={control}
                name="minDiscountAmountTu"
                handleChange={floatFormat}
                rules={{
                  required: discount ? requiredRule() : undefined
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.priceWrapper}>
          <div className={styles.totalPrice}>
            {t('sell.workShop.amountPricing.totalPrice')}
            {' '}
            <span>{totalPrice ? addZeros(totalPrice) : '-'}</span>
          </div>
          <div className={styles.totalPriceDiscount}>
            {t('sell.workShop.amountPricing.totalPriceDiscount')}
            {' '}
            <span className={styles.discountAmount}>
              {discountTotalPrice !== undefined ? `CHF ${addZeros(discountTotalPrice)}` : '-'}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AmountPricing
