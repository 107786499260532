import { TProductLabelTranslation } from '../types'

export function capitalizeFirstLetter(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export function prepareProductLabelData(data: TProductLabelTranslation): TProductLabelTranslation {
  return {
    ...data,
    fr: data.fr ? data.fr : undefined,
    it: data.it ? data.it : undefined
  }
}
