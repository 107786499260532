import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../Interceptor'
import { TNotificationList } from './types'

enum QueryKeys{
  NotificationList = 'NotificationList',
}

async function getNotification(): Promise<TNotificationList[]> {
  try {
    const response = await apiClient('app/notification/list')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export function useNotificationListQuery() {
  return useQuery<TNotificationList[], Error>(
    [QueryKeys.NotificationList],
    () => getNotification(), {
      refetchInterval: 60000
    }
  )
}

async function notificationRead(notificationId: string) {
  try {
    await apiClient.put(`app/notification/${notificationId}/read`)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useNotificationReadMutation = () => {
  const queryClient = useQueryClient()
  return useMutation<void, Error, string>(
    (notificationId) => notificationRead(notificationId),
    {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.NotificationList])
    }
  )
}
