import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import styles from './ViewToggle.module.scss'
import { usePlanningForecastContext } from '../../state/PlanningForecastState'

const ViewToggle = () => {
  const { view, setView } = usePlanningForecastContext()
  const { t } = useTranslation()
  return (
    <div className={styles.viewWrapper}>
      <p className={styles.viewText}>{t('planningForecast.view')}</p>
      <div className={styles.buttonGroup}>
        <button
          onClick={() => setView('DAY')}
          className={classNames(styles.toggleButton, { [styles.active]: view === 'DAY' })}
          type="button">
          {t('planningForecast.day')}
        </button>
        <span className={styles.slash}>/</span>
        <button
          onClick={() => setView('WEEK')}
          className={classNames(styles.toggleButton, { [styles.active]: view === 'WEEK' })}
          type="button">
          {t('planningForecast.week')}
        </button>
      </div>
    </div>
  )
}

export default ViewToggle
