import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { TStatus } from '../../../QuantityReports/types'
import styles from './StatusTag.module.scss'

type TProps = {
    status: TStatus
    isDaily: boolean
    approverName: string
    isDateAvailable: boolean
}

const StatusTag = ({ status, isDaily, approverName, isDateAvailable = true }: Partial<TProps>) => {
  const { t } = useTranslation()
  const getTagText = () => {
    if (status === 'DRAFT') return t('quantity.status.draft')
    if (status === 'CONFIRMED') return isDaily ? t('quantity.status.daily.confirmed') : t('quantity.status.weekly.confirmed')
    if (status === 'NEGOTIATING') {
      return approverName
        ? t('quantity.status.waitingForReviewOfUser', { approver: approverName })
        : t('quantity.status.waitingForYourReview')
    }

    return null
  }

  if (status === 'NEW') return null

  return (
    <div className={classNames(
      styles.tag,
      {
        [styles.draft]: status === 'DRAFT' && isDateAvailable,
        [styles.confirmed]: status === 'CONFIRMED' && isDateAvailable,
        [styles.waitingForReview]: status === 'NEGOTIATING' && isDateAvailable,
        [styles.dateFromPaste]: !isDateAvailable
      }
    )}>
      {getTagText()}
    </div>
  )
}

export default StatusTag
