import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import { FC, memo, useMemo } from 'react'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { TCustomerUnit } from '../../../types'

const optionsValue: TCustomerUnit[] = [
  'G', 'KG', 'TONS', 'PCS', 'G_150',
  'G_250', 'G_500', 'KG_1', 'AB_150', 'AB_250', 'AB_500',
  'AB_1_KG', 'ABH', 'BD_S_10', 'BT_125', 'BT_150', 'BT_200',
  'BT_250', 'BT_500', 'BT_1_KG', 'BTS', 'FT_H', 'GESCHN_AB_H',
  'LOSE', 'LOSE_PER_KG', 'OFFEN', 'SCHALE_150_G', 'SCHALE_250_G',
  'SCHALE_500_G', 'SCHALE_1_KG', 'ST_S'
]

function useOptions() {
  const { t } = useTranslation()
  return useMemo(() => optionsValue.map((value) => ({
    value,
    label: t(`customerUnitOptions.${value}`)
  })), [])
}

type TProps = {
  disabled: boolean
  quantityIndex: number
  control: Control<any>
}
const CustomerUnitSelect: FC<TProps> = ({ quantityIndex, control, disabled }) => {
  const { t } = useTranslation()
  const options = useOptions()
  return (
    <CustomSelectControl
      disabled={disabled}
      control={control}
      options={options}
      isRequired
      name={`orderItems[${quantityIndex}].cu`}
      label={`${t('quantity.selects.customerUnit.label')} *`}
      placeholder={t('quantity.selects.customerUnit.placeholder') as string}
      handleError={(error) => (error as any)?.orderItems?.[quantityIndex]?.cu}
    />
  )
}

export default memo(CustomerUnitSelect)
