import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import { FC, useMemo } from 'react'
import { format } from 'date-fns'
import { de, enGB, fr, it } from 'date-fns/locale'
import CustomSelectControl
  from '../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { MonthFormat } from '../../../../../shared/constants/date'

import { TForm } from '../../types'
import { TLanguage } from '../../../../account/accountBase'
import { useLanguageSwitcherContext } from '../../../../languageSwitcher'

const languageMap: {[key in TLanguage]: Locale} = {
  en: enGB,
  de,
  it,
  fr
}

function useOptions() {
  const { language } = useLanguageSwitcherContext()
  const year = new Date().getFullYear()
  const locale = languageMap[language]

  return useMemo(() => {
    const months = []
    for (let i = 0; i < 12; i += 1) {
      const month = new Date(year, i, 1)
      const monthFormatted = format(month, MonthFormat, { locale })
      months.push({
        value: i.toString(),
        label: monthFormatted
      })
    }
    return months
  }, [locale, year])
}

type TProps = {
  control: Control<TForm>
}
const MonthSelect: FC<TProps> = ({ control }) => {
  const { t } = useTranslation()
  const options = useOptions()
  return (
    <CustomSelectControl
      control={control}
      options={options}
      name="month"
      label={`${t('quantity.selects.month.label')}`}
    />
  )
}

export default MonthSelect
