import constate from 'constate'
import React, { useCallback, useState } from 'react'

const GrowingPlanAccordionState = () => {
  const [activeAccordion, setActiveAccordion] = useState<number | undefined>(0)

  return {
    activeAccordion,
    handleAccordion: useCallback(
      (planIndex: number) => (event: React.SyntheticEvent, isExpanded: boolean) =>
        setActiveAccordion(isExpanded ? planIndex : undefined),
      []
    ),
    setActiveAccordion
  }
}

export const [
  GrowingPlanAccordionProvider,
  useGrowingPlanAccordionContext
] = constate(GrowingPlanAccordionState)
