import { FC, useRef } from 'react'
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Control, Controller } from 'react-hook-form'
import classNames from 'classnames'
import styles from './CustomTimePicker.module.scss'
import TimeIcon from '../../icons/TImeIcon'
import { useValidationRules } from '../../../hooks/useValidationRules'

type TProps = {
  control: Control<any>
  name: string
}
const CustomTimePicker: FC<TProps> = ({ control, name }) => {
  const ref = useRef<HTMLInputElement | null>(null)
  const { requiredRule } = useValidationRules()
  return (
    <div className={styles.wrapper}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Controller
          rules={{
            required: requiredRule()
          }}
          control={control}
          name={name}
          render={(({ field: { onChange, value }, fieldState: { error } }) => (
            <TimePicker
              slotProps={{
                textField: {
                  error: false,
                  helperText: ''
                }
              }}
              inputRef={ref}
              ampmInClock={false}
              className={classNames(
                styles.input,
                { [styles.inputError]: Object.values(error || {}).length }
              )}
              ampm={false}
              value={value}
              onChange={onChange}
            />
          ))}
        />
      </LocalizationProvider>
      <button
        type="button"
        onClick={() => ref?.current?.focus()}
        className={styles.iconWrapper}>
        <TimeIcon/>
      </button>
    </div>
  )
}

export default CustomTimePicker
