import { useTranslation } from 'react-i18next'
import { FC, useMemo } from 'react'
import { Control } from 'react-hook-form'
import CustomSelectControl
  from '../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'

const months = [
  'jan',
  'feb',
  'mar',
  'apr',
  'may',
  'jun',
  'jul',
  'aug',
  'sep',
  'oct',
  'nov',
  'dec']

function useOptions() {
  const { t } = useTranslation()
  const year = new Date().getFullYear()
  return useMemo(() => {
    const preparedMonth = months.map((month, index) => ({
      value: index + 1,
      label: t(`monthsFull.${month}`)
    }))
    return [
      {
        value: 'ALL',
        label: t('monthsFull.whole', { year })
      },
      ...preparedMonth
    ]
  }, [])
}

type TProps = {
  control: Control<any>
}

const MonthSelect: FC<TProps> = ({ control }) => {
  const { t } = useTranslation()
  const options = useOptions()
  return (
    <CustomSelectControl
      placeholder={t('growingPlan.monthsSelect.placeholder')}
      options={options}
      control={control}
      name="month"
    />
  )
}

export default MonthSelect
