import { useTranslation } from 'react-i18next'
import { useEffect, useMemo } from 'react'
import classNames from 'classnames'
import GrowingPlanSelect from '../GrowingPlanSelect/GrowingPlanSelect'
import MonthSelect from '../MonthSelect/MonthSelect'
import styles from './QuantityReportHeader.module.scss'
import { useQuantityReportsContext } from '../../state/QuantityReportsState'
import YearSelect from '../YearSelect/YearSelect'
import TradeSelect from '../TradeSelect/TradeSelect'
import { useBuyerListQuery, useSellerListQuery } from '../../queries'
import { NO_GROWING_PLAN_OPTION } from '../../../BaseQuantityReports'
import ViewToggle from '../ViewToggle/ViewToggle'

export function useGrowingPlanYear() {
  const { selectedGrowingPlan } = useQuantityReportsContext()
  return selectedGrowingPlan?.year
}

function useSetYear() {
  const { formMethods: { setValue } } = useQuantityReportsContext()
  const { selectedGrowingPlan } = useQuantityReportsContext()
  useEffect(() => {
    if (selectedGrowingPlan) {
      setValue('year', selectedGrowingPlan.year)
    }
  }, [selectedGrowingPlan])
}

function useSellerOptions() {
  const { data } = useSellerListQuery()
  return useMemo(() => {
    if (!data) {
      return []
    }
    return data.map(({ id, name }) => ({
      value: id,
      label: name
    }))
  }, [data])
}

function useBuyerOptions() {
  const { data } = useBuyerListQuery()
  return useMemo(() => {
    if (!data) {
      return []
    }
    return data.map(({ id, name }) => ({
      value: id,
      label: name
    }))
  }, [data])
}

const QuantityReportHeader = () => {
  useSetYear()
  const { formMethods: { control, watch, setValue } } = useQuantityReportsContext()
  const { t } = useTranslation()
  const growingPlanYear = useGrowingPlanYear()
  const sellerOptions = useSellerOptions()
  const buyerOptions = useBuyerOptions()
  const isNoGrowingPlan = watch('growingPlan') === NO_GROWING_PLAN_OPTION
  const buyer = watch('buyer')
  const seller = watch('seller')

  return (
    <div className={classNames(styles.wrapper, { [styles.noGrowingPlan]: isNoGrowingPlan })}>
      <GrowingPlanSelect control={control}/>
      {isNoGrowingPlan
        ? (
          <div className={styles.noGrowingPlanWrap}>
            <div className={styles.selectGroup}>
              {/* <TradeSelect */}
              {/*  control={control} */}
              {/*  options={sellerOptions} */}
              {/*  disabled={!!buyer || !sellerOptions.length} */}
              {/*  reset={seller ? () => setValue('seller', '') : undefined} */}
              {/*  name="seller"/> */}
              <TradeSelect
                control={control}
                options={buyerOptions}
                disabled={!!seller}
                reset={buyer ? () => setValue('buyer', '') : undefined}
                name="buyer"/>
              <div className={styles.selectDateGroup}>
                <YearSelect control={control}/>
                <MonthSelect control={control}/>
              </div>
            </div>
            <div className={styles.viewToggleWrap}>
              <ViewToggle/>
            </div>

          </div>
        )
        : (
          <div className={styles.group}>
            <div className={styles.yearWrapper}>
              <p className={styles.label}>{t('quantity.year')}</p>
              <p className={styles.description}>{growingPlanYear}</p>
            </div>
            <MonthSelect control={control}/>
          </div>
        )}
      {!isNoGrowingPlan && <ViewToggle/>}
    </div>
  )
}

export default QuantityReportHeader
