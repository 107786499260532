import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Column } from 'react-table'
import styles from './ProductLabelList.module.scss'
import BaseTable from '../../../../../shared/components/table/components/Table/Table'
import EditProductLabel from '../EditProductLabel/EditProductLabel'
import DeactivateProductLabel from '../DeactivateProductLabel/DeactivateProductLabel'
import { TProductLabelBase } from '../../types'
import { useProductLabelContext } from '../../state/ProductLabelState'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'

const ProductLabelList = () => {
  const { t } = useTranslation()
  const {
    handleStatus,
    type,
    data,
    isLoading,
    setPage
  } = useProductLabelContext()

  const columns = useMemo<Column[]>(() => [
    {
      Header: <div className={styles.tableHeader}>{t(`admin.${type}s.name`)}</div>,
      accessor: 'name',
      Cell: ({ value, row }) => {
        const isDeactivate = (row.original as TProductLabelBase).status === 'DEACTIVATED'
        return (
          <div className={styles.tableBody}>
            {value}
            {' '}
            {isDeactivate && <span className={styles.deactivated}>{`(${t('admin.users.tab.deactivated')})`}</span>}
          </div>
        )
      }
    },
    {
      Header: <div className={styles.tableHeader}>{t('common.actions')}</div>,
      accessor: 'id',
      Cell: ({ value, row }) => {
        const isDeactivate = (row.original as TProductLabelBase).status === 'DEACTIVATED'
        return (
          <div className={styles.actionsWrap}>
            <EditProductLabel id={value}/>
            {
              isDeactivate ? (
                <SecondaryButton className={styles.button} onClick={() => handleStatus({ id: value, status: 'ACTIVE' })}>
                  {t('admin.users.table.activate')}
                </SecondaryButton>

              ) : (
                <DeactivateProductLabel
                  handleDeactivate={() => handleStatus({ id: value, status: 'DEACTIVATED' })}
                  name={(row.original as TProductLabelBase).name}/>
              )
            }

          </div>
        )
      }
    }
  ], [data])

  const isPagination = type === 'product'

  return (
    <>
      <div className={styles.tableWrapper}>
        <BaseTable
          contentClass={styles.table}
          data={data?.content ?? []}
          showLoader={isLoading}
          columns={columns}
          paginationConfig={isPagination && data ? {
            isEnabled: data?.totalPages > 1,
            pageSize: 10,
            manual: true,
            totalRows: data.totalElements,
            pageCount: data.totalPages,
            onPageChange: (page) => setPage?.(page)
          } : undefined}

        />
      </div>
    </>
  )
}

export default ProductLabelList
