import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TGrowingPlanList, TGrowingPlanSummary } from './types'

enum QueryKeys{
  GrowingPlanList = 'GrowingPlanList',
  GrowingPlanSummary = 'GrowingPlanSummary'
}

async function growingPlanList(activeOnly?: boolean): Promise<TGrowingPlanList> {
  try {
    const response = await apiClient.get(
      '/app/growing-plans/list',
      { params: {
        activeOnly
      } }
    )
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

async function deleteGrowingPlan(growingPlanId: string): Promise<void> {
  try {
    await apiClient.delete(`/app/growing-plans/${growingPlanId}`)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useGrowingPlanDeleteMutation = () => {
  const queryClient = useQueryClient()
  return useMutation<void, Error, string>(
    (growingPlanId) =>
      deleteGrowingPlan(growingPlanId),
    {
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.GrowingPlanList])
    }
  )
}

export const useGrowingPlanListQuery = (activeOnly?: boolean) => {
  const key = QueryKeys.GrowingPlanList

  return useQuery<TGrowingPlanList, Error>(
    [key, activeOnly],
    () => growingPlanList(activeOnly),
    {
      cacheTime: 0
    }
  )
}

async function growingPlanSummary(monthIndex?: string): Promise<TGrowingPlanSummary[]> {
  try {
    const response = await apiClient('app/growing-plans/summary', {
      params: {
        months: monthIndex || null
      }
    })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useGrowingPlanSummary = (monthIndex?: string) => {
  const keys = [QueryKeys.GrowingPlanSummary, monthIndex]

  return useQuery<TGrowingPlanSummary[], Error>(keys,
    () => growingPlanSummary(monthIndex),
    {
      enabled: monthIndex !== ''
    })
}
