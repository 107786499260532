import constate from 'constate'
import { UseFieldArrayReturn, UseFormReturn } from 'react-hook-form'
import { useCallback, useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { currentWeekDays, generateFormData } from '../utils/generateFormData'
import { TForm, TOrderItemForm, TProductModel, TSubmitData } from '../types'
import { TWeeklyOrderDataItem } from '../../WeeklyReport/types'
import { NO_GROWING_PLAN_OPTION } from '../constants/quantityReports'

export const defaultOrderFormData = (date?: Date) => generateFormData(date)

function useAllDaysInWeek(date?: Date) {
  return useMemo(() => currentWeekDays(date), [date])
}

export type TBaseQuantityFormContextProps = {
  formReturn: UseFormReturn<any>,
  fieldArrayReturn: UseFieldArrayReturn<TForm, 'orderItems', 'id'>
  onlyDay?: string,
  date?: Date
  disabledProductModels?: TProductModel[]
  isSeller?: boolean
  isProductAddable?: boolean
  readOnly?: boolean
  buyerId?: string
  weeklySellerId?: string
  orders?: TWeeklyOrderDataItem[],
  initialFormData: {orderItems: TOrderItemForm[]}
  onSubmit: (data: TSubmitData) => void
  onConfirm: (orderId?: string) => void
}
const useBaseQuantityFormState = ({
  formReturn,
  isSeller,
  date,
  onlyDay,
  disabledProductModels,
  fieldArrayReturn,
  isProductAddable = true,
  readOnly,
  buyerId,
  weeklySellerId,
  orders,
  initialFormData,
  onSubmit,
  onConfirm
}: TBaseQuantityFormContextProps) => {
  const weekDays = useAllDaysInWeek(date)
  const { growingPlanId } = useParams()
  const [searchParams] = useSearchParams()
  const growingPlan = growingPlanId ?? searchParams.get('growingPlanId')
  const isNoGrowingPlan = growingPlan === NO_GROWING_PLAN_OPTION

  return {
    formReturn,
    fieldArrayReturn,
    append: useCallback(() => fieldArrayReturn.append(defaultOrderFormData(date)), []),
    remove: fieldArrayReturn.remove,
    growingPlanId: isNoGrowingPlan ? null : growingPlan,
    disabledProductModels,
    isSeller,
    isProductAddable,
    onlyDay,
    date,
    weekDays,
    readOnly,
    buyerId,
    weeklySellerId,
    orders,
    initialFormData,
    onSubmit,
    onConfirm
  }
}

export const [
  BaseQuantityFormProvider,
  useBaseQuantityFormContext
] = constate(useBaseQuantityFormState)
