import { useMutation, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../Interceptor'
import { TLanguage } from '../account/accountBase'

const changeLanguage = async (language: TLanguage) => {
  try {
    await apiClient.put('/users/change-language', { language })
  } catch (e: any) {
    throw new Error(e)
  }
}

export function useChangeLanguage() {
  const queryClient = useQueryClient()
  return useMutation<void, Error, TLanguage>(
    (language) => changeLanguage(language),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['Account'])
      }
    }
  )
}
