import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useAccountDetailsQuery } from '../../../accountBase'
import styles from './AccountDetails.module.scss'
import { AuthLayout } from '../../../../../layouts/primaryLayout'
import InfoCard from '../InfoCard/InfoCard'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'

const AccountDetails = () => {
  const { t } = useTranslation()
  const { data } = useAccountDetailsQuery()
  const navigate = useNavigate()

  if (!data) {
    return <></>
  }

  const {
    firstName,
    lastName,
    address,
    phone,
    email,
    company
  } = data

  return (
    <AuthLayout size="medium" header={t('account.details.label')}>
      <div className={styles.container}>
        <div className={styles.content}>
          <InfoCard title={t('common.firstName')} description={firstName}/>
          <InfoCard title={t('common.lastName')} description={lastName}/>
          <InfoCard title={t('common.address')} description={address.address}/>
          <InfoCard
            title={`${t('common.zip')}, ${t('common.city')}`}
            description={`${address.zip}, ${address.city}`}/>
          <InfoCard title={t('common.phone')} description={phone}/>
          <InfoCard title={t('common.email')} description={email}/>
        </div>
        <div className={styles.content}>
          <InfoCard title={t('common.companyName')} description={company.name}/>
          <InfoCard title={t('common.companyUid')} description={company.uid}/>
          <InfoCard title={t('common.iban')} description={company?.iban || '-'}/>
          <InfoCard title={t('common.invoice')} description={company?.invoicePaymentTerm?.toString() || '-'}/>
          <InfoCard
            title={t('account.details.companyLogo')}
            description={!company?.logoUrl ? '-' : ''}>
            {company?.logoUrl && (
              <img
                src={company?.logoUrl}
                className={styles.logo}
                alt={company.name}/>
            )}
          </InfoCard>

        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <PrimaryButton
          onClick={() => navigate('/edit-account')}
          text={t('account.details.editInformation')}
        />
      </div>
    </AuthLayout>
  )
}

export default AccountDetails
