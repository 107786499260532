import { useLocalStorage } from 'react-use'
import { useMemo } from 'react'
import { TOfferLocalData } from '../../Offer/types'
import { OFFER_LOCAL_STORAGE_KEY } from '../../../../shared/constants/localeStorage'
import { useGetProduct } from '../../Offer'
import { roundNumber } from '../../../../shared/utils/calculationUtils'

export const useCalculateTotal = () => {
  const { data } = useGetProduct()
  const [offerLocalData] = useLocalStorage<TOfferLocalData>(OFFER_LOCAL_STORAGE_KEY)
  const { amountTu, priceCHF } = offerLocalData!

  const {
    pricePerCu,
    negotiation,
    amountCuPerTu
  } = data!

  const price = Number(negotiation ? priceCHF : pricePerCu)

  return useMemo(() => roundNumber(amountTu * price * amountCuPerTu), [data, offerLocalData])
}
