import constate from 'constate'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { TPlanningForecastForm, TView } from '../types'
import { dateFromDotDateFormat, dotDateFormat } from '../../../../shared/utils/date'
import { prepareProductSelectValue, useGetProductLabelQuery } from '../../baseAnalytics'
import { TProductLabelItem } from '../../baseAnalytics/types'

function useFormInit() {
  const [searchParams] = useSearchParams()
  const date = searchParams.get('date')
  return useForm<TPlanningForecastForm>({
    defaultValues: {
      date: dateFromDotDateFormat(date!),
      products: [],
      foodWaste: 'ALL'
    }
  })
}

type TResetForm = {
  formReturn: UseFormReturn<TPlanningForecastForm>
  productLabel: UseQueryResult<TProductLabelItem[], Error>
}
function useResetForm({ formReturn: { setValue }, productLabel }: TResetForm) {
  useEffect(() => {
    if (!productLabel.data) {
      return
    }
    const preparedProducts = productLabel.data.map(prepareProductSelectValue)
    setValue('products', preparedProducts)
  }, [productLabel.data])
}

const PlanningForecast = () => {
  const [view, setView] = useState<TView>('DAY')
  const formReturn = useFormInit()
  const formData = formReturn.watch()
  const date = dotDateFormat(formData.date)

  const productLabel = useGetProductLabelQuery(date)
  useResetForm({ formReturn, productLabel })

  return {
    formReturn,
    productLabel,
    view,
    setView
  }
}

export const [
  PlanningForecastProvider,
  usePlanningForecastContext
] = constate(PlanningForecast)
