import MuiPagination from '@mui/material/Pagination'
import { FC } from 'react'
import { PaginationProps } from '@mui/material/Pagination/Pagination'
import styles from './Pagination.module.scss'

const Pagination: FC<PaginationProps> = (props) => (
  <MuiPagination
    className={styles.pagination}
    {...props}
  />
)

export default Pagination
