import { useQuery } from '@tanstack/react-query'
import { TAccountDetailsResponse } from './types'
import { apiClient } from '../../Interceptor'
import { useAuthContext } from '../../auth/authBase'

enum QueryKeys{
  Account = 'Account'
}

async function getAccountDetails(): Promise<TAccountDetailsResponse> {
  try {
    const response = await apiClient('/users/me')
    return response.data
  } catch (err: any) {
    throw new Error(err)
  }
}

export function useAccountDetailsQuery() {
  const { authData } = useAuthContext()
  return useQuery<TAccountDetailsResponse, Error>(
    [QueryKeys.Account],
    getAccountDetails,
    {
      enabled: !!authData?.accessToken
    }
  )
}
