import { useMutation, useQueryClient } from '@tanstack/react-query'
import { TPaymentInfo } from './types'
import { apiClient } from '../Interceptor'

async function paymentInfo(data: TPaymentInfo) {
  const { companyId, ...rest } = data
  try {
    await apiClient.put(`/app/companies/${companyId}/payment-info`, rest)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const usePaymentInfoMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, TPaymentInfo>(
    (data) => paymentInfo(data),
    {
      onSuccess: async () => queryClient.invalidateQueries(['Account'])
    }
  )
}
