import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TInnerCircle } from '../BaseInnerCircle'

enum QueryKeys{
  InnerCircleList = 'InnerCircleList',
  DeleteInnerCircle = 'DeleteInnerCircle'
}

async function innerCircleList(): Promise<TInnerCircle[]> {
  try {
    const response = await apiClient('app/inner-circle/list')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useInnerCircleListQuery = () => {
  const key = [QueryKeys.InnerCircleList]
  return useQuery<TInnerCircle[], Error>(key, innerCircleList)
}

async function deleteInnerCircle(innerCircleId: string): Promise<void> {
  try {
    await apiClient.delete(`app/inner-circle/${innerCircleId}`)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useDeleteInnerCircleMutation = () => {
  const key = [QueryKeys.DeleteInnerCircle]
  const queryClient = useQueryClient()

  return useMutation<void, Error, string>(
    (innerCircleId) => deleteInnerCircle(innerCircleId),
    {
      mutationKey: key,
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.InnerCircleList])
    }
  )
}
