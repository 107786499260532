import { useMutation } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { apiClient } from '../../Interceptor'
import { TForm } from '../BaseGrowingPlanWorkShop'

async function updateGrowingPlan(data: TForm, growingPlanId?: string): Promise<void> {
  try {
    await apiClient.put(`/app/growing-plans/${growingPlanId}`, data)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useUpdateGrowingPlanMutation = () => {
  const { growingPlanId } = useParams()
  return useMutation<void, Error, TForm>(
    (data) => updateGrowingPlan(data, growingPlanId)

  )
}
