import ProductLabelLayout from '../../../baseAdmin/components/ProductLabelLayout/ProductLabelLayout'
import {
  useLabelsQuery,
  useLabelStatusMutation,
  useUpdateLabelMutation,
  useCreateLabelMutation
} from '../../queries'
import { prepareProductLabelData } from '../../../baseAdmin/utils/baseAdminUtils'

const Labels = () => {
  const { data, isLoading } = useLabelsQuery()
  const { mutateAsync: addLabel } = useCreateLabelMutation()
  const { mutateAsync: editLabel } = useUpdateLabelMutation()
  const { mutateAsync: labelStatus } = useLabelStatusMutation()
  return (
    <ProductLabelLayout
      type="label"
      data={data}
      isLoading={isLoading}
      handleStatus={(data) => labelStatus(data)}
      handleEdit={async (data) => {
        const { id, ...rest } = data
        await editLabel({ ...prepareProductLabelData(rest), id })
      }}
      handleAdd={async (data) => addLabel(prepareProductLabelData(data))}/>
  )
}

export default Labels
