import Accordion from '../../../../../shared/components/Accordion/Accordion'
import AccordionDetails from '../AccordionDetails/AccordionDetails'
import AccordionSummary from '../AccordionSummary/AccordionSummary'
import {
  BaseQuantityFormProvider,
  TBaseQuantityFormContextProps,
  useBaseQuantityFormContext
} from '../../state/BaseQuantityFormState'
import AddProduct from '../AddProduct/AddProduct'
import styles from './QuantityAccordion.module.scss'

const QuantityAccordion = () => {
  const {
    fieldArrayReturn: { fields },
    formReturn: { formState: { errors } },
    isProductAddable,
    readOnly
  } = useBaseQuantityFormContext()

  return (
    <>
      <div className={styles.accordionWrapper}>
        {
          fields.map((field, index) => (
            <div key={field.id} className={styles.wrap}>
              {!!index && <div className={styles.line}/>}
              <Accordion
                defaultExpanded
                noPadding
                hasError={!!Object.keys((errors.orderItems as any)?.[index] || {}).length}
                summary={(
                  icon,
                  isActive
                ) => <AccordionSummary quantityIndex={index} isActive={isActive} icon={icon}/>}
                detail={<AccordionDetails quantityIndex={index}/>}
              />
            </div>
          ))
        }
      </div>
      {isProductAddable && !readOnly && <AddProduct/>}
    </>
  )
}

export default (props: TBaseQuantityFormContextProps) => (
  <BaseQuantityFormProvider {...props}>
    <QuantityAccordion/>
  </BaseQuantityFormProvider>
)
