import { RegisterOptions, ValidateResult } from 'react-hook-form'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isValid } from 'date-fns'
import { EMAIL_VALIDATION, PASSWORD_VALIDATION } from '../constants/regexes'

export const useValidationRules = () => {
  const { t } = useTranslation()
  const requiredRule: (message?: string) => RegisterOptions['required'] = useCallback(
    (message) => ({
      value: true,
      message: message || t('validationRules.required')
    }),
    []
  )

  const minLengthRule: (num: number) => RegisterOptions['minLength'] = useCallback((num) => ({
    value: num,
    message: `length be more than ${num}`
  }), [])

  const emailValidation: RegisterOptions['validate'] = useCallback(
    (email: string): ValidateResult =>
      EMAIL_VALIDATION.test(email)
      || t('validationRules.email') || false,
    []
  )

  const passwordValidation:
    (password: string, msg?: string) => ValidateResult = useCallback((password, msg) =>
      PASSWORD_VALIDATION.test(password)
        || msg || t('validationRules.password') || false, [])

  const confirmPasswordValidation: (
    confirmPassword: string,
    password: string) => ValidateResult = useCallback(
      (confirmPassword, password) =>
        confirmPassword === password || t('validationRules.confirmPassword') || false,
      []
    )

  const dateValidation: RegisterOptions['validate'] = useCallback(
    (date?: string): ValidateResult => {
      if (!date) {
        return true
      }
      return isValid(date) || t('validationRules.required') || false
    },
    []
  )

  const moreValueValidation: (
    value: number,
    compareValue: number) => ValidateResult = useCallback(
      (value, compareValue) =>
        !(value > compareValue) || t('validationRules.moreValue') || false,
      []
    )

  const lessValueValidation: (
    value: number,
    compareValue: number) => ValidateResult = useCallback(
      (value, compareValue) =>
        !(value < compareValue) || t('validationRules.lessValue') || false,
      []
    )

  return {
    requiredRule,
    emailValidation,
    dateValidation,
    moreValueValidation,
    lessValueValidation,
    passwordValidation,
    confirmPasswordValidation,
    minLengthRule
  }
}
