import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { FC } from 'react'
import format from 'date-fns/format'
import styles from './AccordionDetail.module.scss'
import WeekDayCard from '../WeekDayCard/WeekDayCard'
import { TForecast, TUnit } from '../../types'
import { dateFromDotDateFormat } from '../../../../../shared/utils/date'
import { TNameId, TProductLabel } from '../../../../growingPlan/BaseGrowingPlanWorkShop'

const SundayIndex = 6

type TProps = {
  forecasts: TForecast[]
  unit: TUnit
  productLabel: {
    product: TNameId,
    label: TProductLabel
  }
}

const AccordionDetail: FC<TProps> = ({ forecasts, unit, productLabel }) => {
  const { t } = useTranslation()
  const isSellShow = forecasts.some(({ ordersSellAmount }) => ordersSellAmount)
  const isBuyShow = forecasts.some(({ ordersBuyAmount }) => ordersBuyAmount)

  return (
    <div className={classNames(styles.wrapper,
      { [styles.noSellBuy]: !isSellShow && !isBuyShow },
      { [styles.sellBuy]: isBuyShow && isSellShow })}
    >
      <div className={styles.planningForecast}>
        <div className={styles.label}>{t('dashboard.stock')}</div>
        {isSellShow && <div className={styles.label}>{t('dashboard.weeklyReportsSell')}</div>}
        {isBuyShow && <div className={styles.label}>{t('dashboard.weeklyReportsBuy')}</div>}
        <div className={classNames(
          styles.label,
          styles.bold,
          styles.marginTop
        )}>
          {t('dashboard.forecast')}
        </div>
      </div>
      <div className={styles.weekWrap}>
        {
          forecasts.map(({
            date,
            stockAmount,
            forecast,
            ordersSellAmount,
            ordersBuyAmount
          }, index) => {
            const day = format(dateFromDotDateFormat(date), 'EEE')
            return (
              <WeekDayCard
                buy={isBuyShow ? ordersBuyAmount : undefined}
                key={date}
                productLabel={productLabel}
                day={day}
                stock={stockAmount}
                forecast={forecast}
                sell={isSellShow ? ordersSellAmount : undefined}
                noBorder={index === SundayIndex}
                unit={unit}
              />
            )
          })
        }
      </div>
    </div>
  )
}

export default AccordionDetail
