import styles from './InnerCircleOverview.module.scss'
import { useInnerCircleListQuery } from '../../queries'
import Accordion from '../../../../../shared/components/Accordion/Accordion'
import AccordionSummary from '../AccordionSummary/AccordionSummary'
import AccordionDetails from '../AccordionDetails/AccordionDetails'

const InnerCircleOverview = () => {
  const { data } = useInnerCircleListQuery()

  return (
    <div className={styles.accordionWrapper}>
      {
        data?.map(({ title, description, companies, id }) => (
          <Accordion
            noPadding
            key={id}
            summary={(icon) => (
              <AccordionSummary
                id={id}
                title={title}
                description={description}
                icon={icon}/>
            )}
            detail={<AccordionDetails companies={companies}/>}/>
        ))
      }

    </div>
  )
}

export default InnerCircleOverview
