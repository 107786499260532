import { useFieldArray, useForm, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { format, isAfter, isBefore, subWeeks } from 'date-fns'
import { useSearchParams } from 'react-router-dom'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import {
  defaultOrderFormData,
  QuantityAccordion,
  TForm,
  TOrderItemForm
} from '../../../BaseQuantityReports'
import styles from './WeeklyReport.module.scss'
import {
  useConfirmWeeklyOrderMutation,
  useCreateWeeklyOrderMutation, useDraftWeeklyOrderMutation,
  useOrdersWeeklyQuery,
  useUpdateWeeklyOrderMutation, useWeeklyInitQuery
} from '../../queries'
import { TUpdateWeeklyGrowingPlanData, TWeeklyOrderData, TWeeklyOrderDataItem } from '../../types'
import { currentWeekDays } from '../../../BaseQuantityReports/utils/generateFormData'
import { DOT_DATE_FORMAT } from '../../../../../shared/constants/date'
import { useIsEditMode } from '../../../BaseQuantityReports/hooks/editModeHooks'
import { useNavigateToCalendar } from '../../../BaseQuantityReports/hooks/useNavigateToCalendar'
import { useDisabledProductModels } from '../../../BaseQuantityReports/hooks/useDisabledProductModels'
import Spinner from '../../../../../shared/components/Spinner/Spinner'
import { useDate } from '../../../BaseQuantityReports/hooks/useDate'
import { addZeros } from '../../../../../shared/utils/formUtils'
import WeeklyDailyHeader from '../WeeklyHeader/WeeklyDailyHeader'
import {
  dateFromDotDateFormat,
  dotDateFormat,
  endOfWeekCustom,
  startOfWeekCustom
} from '../../../../../shared/utils/date'
import { useWeeklyOrderParams } from '../../../QuantityReports/hooks/useWeeklyOrderParams'
import { TSubmitData } from '../../../BaseQuantityReports/types'
import useExpiredDateCheck from '../../../BaseQuantityReports/hooks/usePastDateCheck'

export function useBuyerId() {
  const [searchParams] = useSearchParams()
  return searchParams.get('buyerId') || undefined
}

export function useSellerId() {
  const [searchParams] = useSearchParams()
  return searchParams.get('sellerId') || undefined
}

const prepareFormItem = (item: TWeeklyOrderDataItem, date?: Date) => ({
  orders: currentWeekDays(date).map((date) => {
    const foundOrder = item.dailyOrders.find(
      ({ date: orderDate }) => format(date, DOT_DATE_FORMAT) === orderDate
    )

    return {
      date: foundOrder?.date ?? format(date, DOT_DATE_FORMAT),
      quantityTu: foundOrder?.quantityTu ?? 0,
      pricePerCu: addZeros(foundOrder?.pricePerCu ?? 0),
      id: foundOrder?.id
    }
  }),
  unit: item.unit,
  labelId: item.label?.id,
  amountUnitPerCu: item.amountUnitPerCu,
  amountTuPerLu: item.amountTuPerLu,
  amountCuPerTu: item.amountCuPerTu,
  productId: item.product?.id,
  tu: item.tu,
  lu: item.lu,
  cu: item.cu
})

function prepareInitialFormItems(date?: Date, orderItems?: TWeeklyOrderDataItem[]) {
  if (!date) {
    return []
  }

  if (!orderItems?.length) {
    return [defaultOrderFormData(date)]
  }

  return orderItems.map((item) => prepareFormItem(item, date))
}

function useGetGrowingPlanInfo(
  growingPlanId?: string | null,
  date?: Date,
  ordersData?: TWeeklyOrderData
) {
  const buyerId = useBuyerId()

  const { data: growingPlanData } = useWeeklyInitQuery({
    growingPlanId,
    buyerId,
    firstWeekDate: dotDateFormat(date!)
  })
  return useMemo(() => {
    if (!growingPlanData && !ordersData) {
      return ''
    }
    const data = (ordersData || growingPlanData)!

    return `${data.growingPlanTitle} (${data.currentUserSeller ? data.buyer.name : data.seller.name})`
  }, [growingPlanData, ordersData])
}

function useGetSearchParams(searchParamValue: 'copy' | 'readOnly') {
  const [searchParams] = useSearchParams()
  return searchParams.get(searchParamValue)
}

export function useFormInit(date?: Date, orderItems?: TWeeklyOrderDataItem[]) {
  const initialFormData = useMemo(() => ({
    orderItems: prepareInitialFormItems(date, orderItems)
  }), [date, orderItems])
  const useFormReturn = useForm<TForm>({})
  const fieldArrayReturn = useFieldArray({ control: useFormReturn.control, name: 'orderItems' })

  useEffect(() => {
    prepareInitialFormItems(date, orderItems).forEach((item) => {
      fieldArrayReturn.append(item)
    })

    return () => {
      useFormReturn.reset()
    }
  }, [])

  return {
    useFormReturn,
    initialFormData,
    fieldArrayReturn
  }
}

function prepareData(
  data: TOrderItemForm,
  growingPlanId?: string | null,
  buyerId?: string,
  date?: Date,
  publish?: boolean
): TUpdateWeeklyGrowingPlanData {
  return {
    growingPlanId,
    buyerId,
    publish,
    firstWeekDate: format(currentWeekDays(date)[0], DOT_DATE_FORMAT),
    productId: data.productId as string,
    labelId: data.labelId as string,
    cu: data.cu as string,
    amountUnitPerCu: (data.amountUnitPerCu || null) as number,
    tu: data.tu as string,
    amountCuPerTu: Number(data.amountCuPerTu) as number,
    lu: data.lu as string,
    amountTuPerLu: Number(data.amountTuPerLu) as number,
    dailyOrders: data.orders.map(({ date, pricePerCu, quantityTu, id }) => ({
      date,
      pricePerCu,
      quantityTu,
      id
    }))
  }
}

function useOnSubmit(growingPlanId?: string | null, date?: Date, formReturn?: UseFormReturn<any>) {
  const { mutateAsync: createMutateAsync } = useCreateWeeklyOrderMutation()
  const { mutateAsync: updateMutateAsync } = useUpdateWeeklyOrderMutation()
  const { mutateAsync: updateDraftMutateAsync } = useDraftWeeklyOrderMutation()
  const buyerId = useBuyerId()
  return useCallback(({ data, quantityIndex, publish = true, orderId, saveDraft }: TSubmitData) => {
    if (!growingPlanId && !buyerId) {
      return
    }

    const onSuccess = (resData: TWeeklyOrderDataItem) => {
      formReturn?.setValue(`orderItems[${quantityIndex}]`, prepareFormItem(resData, date))
    }

    if (orderId) {
      if (saveDraft) {
        updateDraftMutateAsync({
          data: prepareData(data, growingPlanId, buyerId, date),
          orderId }).then(onSuccess)
      } else {
        updateMutateAsync({
          data: prepareData(data, growingPlanId, buyerId, date),
          orderId
        }).then(onSuccess)
      }

      return
    }

    createMutateAsync(prepareData(data, growingPlanId, buyerId, date, publish)).then(onSuccess)
  }, [growingPlanId, buyerId])
}

function useOnConfirm() {
  const { mutateAsync: createMutateAsync } = useConfirmWeeklyOrderMutation()

  return useCallback((weeklyOrderId?: string) => {
    if (weeklyOrderId) {
      createMutateAsync(weeklyOrderId)
    }
  }, [createMutateAsync])
}

type TProps = {
    ordersData?: TWeeklyOrderData,
    growingPlanId?: string | null,
}

const WeeklyReport: FC<TProps> = ({ ordersData, growingPlanId }) => {
  const date = useDate(ordersData?.firstWeekDate)
  const {
    useFormReturn,
    initialFormData,
    fieldArrayReturn
  } = useFormInit(date, ordersData?.orders)
  const { t } = useTranslation()
  const sellerId = useSellerId()
  const buyerId = useBuyerId()
  const growingPlanInfo = useGetGrowingPlanInfo(growingPlanId, date, ordersData)
  const navigateToCalendar = useNavigateToCalendar(growingPlanId, date, sellerId || '', buyerId || '')
  const isReadOnly = !!useGetSearchParams('readOnly')
  const onSubmit = useOnSubmit(growingPlanId, date, useFormReturn)
  const onConfirm = useOnConfirm()
  const isProductAddable = useExpiredDateCheck({ type: 'weekly', date: ordersData?.firstWeekDate })

  return (
    <PrimaryLayout
      noPadding
      size="large"
      maxHeight
      header={(
        <WeeklyDailyHeader
          title={t('quantity.weeklyReport')}
          description={growingPlanInfo}
          goBack={navigateToCalendar}
        />
      )}
    >
      <QuantityAccordion
        isProductAddable={isProductAddable}
        readOnly={isReadOnly}
        formReturn={useFormReturn}
        fieldArrayReturn={fieldArrayReturn}
        isSeller={ordersData?.currentUserSeller}
        date={date}
        disabledProductModels={useDisabledProductModels(ordersData)}
        buyerId={useBuyerId()}
        weeklySellerId={ordersData?.seller.id}
        onSubmit={onSubmit}
        onConfirm={onConfirm}
        orders={ordersData?.orders}
        initialFormData={initialFormData}
      />
      <div className={styles.line}/>
    </PrimaryLayout>
  )
}

export default () => {
  const params = useWeeklyOrderParams()

  const { data: weeklyOrdersData, refetch } = useOrdersWeeklyQuery(params)

  if ((useIsEditMode() && !weeklyOrdersData)) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }

  return (
    <WeeklyReport
      ordersData={weeklyOrdersData}
      growingPlanId={params?.growingPlanId}
    />
  )
}
