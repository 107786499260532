import { TProductLabel, TProductPlanForm } from '../types'

const defaultMonthValues = {
  value: 0,
  ordersExist: false
}
export const formDefaultValues = {
  jan: defaultMonthValues,
  feb: defaultMonthValues,
  mar: defaultMonthValues,
  apr: defaultMonthValues,
  may: defaultMonthValues,
  jun: defaultMonthValues,
  jul: defaultMonthValues,
  aug: defaultMonthValues,
  sep: defaultMonthValues,
  oct: defaultMonthValues,
  nov: defaultMonthValues,
  dec: defaultMonthValues
}

export const generateProductPlan = (formLabel: TProductLabel[]): TProductPlanForm => ({
  productId: '',
  unit: '',
  values: formLabel.map((label) => ({
    labelId: label.id,
    ...formDefaultValues
  }))
})
