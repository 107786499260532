import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { FC, useCallback, useMemo, useState } from 'react'
import Modal from '../../../../shared/components/Modal/Modal'
import styles from './DeclineOrder.module.scss'
import CustomRadioGroup from '../../../../shared/components/formControls/CustomRadioGroup/CustomRadioGroup'
import DangerousButton from '../../../../shared/components/buttons/DangerousButton/DangerousButton'
import SecondaryButton from '../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import CustomTextArea from '../../../../shared/components/formControls/CustomTextArea/CustomTextArea'
import { useDeclineMutation } from '../../queries'
import { TDeclineParams } from '../../types'

type TDeclineOrderForm = {
  reason?: TDeclineParams['reason'] & 'other'
  description: string
}
function useFormInit() {
  return useForm<TDeclineOrderForm>({
    defaultValues: {
      reason: undefined,
      description: ''
    }
  })
}

function useOptions() {
  const { t } = useTranslation()
  return useMemo(() => [
    {
      value: 'PRICE_IS_TOO_SMALL',
      label: t('sell.list.declineOrder.options.priceSmall')
    },
    {
      value: 'AMOUNT_IS_TOO_SMALL',
      label: t('sell.list.declineOrder.options.amountSmall')
    },
    {
      value: 'PRODUCT_IS_SOLD',
      label: t('sell.list.declineOrder.options.productSold')
    },
    {
      value: 'other',
      label: t('sell.list.declineOrder.options.other')
    }
  ], [])
}

function useOnSubmit(orderId: string) {
  const { mutateAsync } = useDeclineMutation()

  return useCallback(async (data: TDeclineOrderForm) => {
    const prepareParams = {
      orderId,
      ...(data.reason !== 'other' ? {
        reason: data.reason
      } : {
        other: data.description
      })
    }

    await mutateAsync(prepareParams)
  }, [])
}

type TProps = {
  id: string

}
const DeclineOrder: FC<TProps> = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const useFormReturn = useFormInit()
  const options = useOptions()
  const onSubmit = useOnSubmit(id)

  const { watch, control } = useFormReturn
  const reason = watch('reason')
  const description = watch('description')
  const isOther = reason === 'other'

  const isButtonDisabled = isOther ? !description : !reason
  const handleClose = () => setIsOpen(false)
  return (
    <>
      <Modal isOpen={isOpen} onClose={handleClose}>
        <h1 className={styles.label}>{t('sell.list.declineOrder.label')}</h1>
        <p className={styles.description}>{t('sell.list.declineOrder.description')}</p>
        <div className={styles.radioWrapper}>
          <CustomRadioGroup options={options} control={control} name="reason"/>
          {
            isOther && (
              <div className={styles.textarea}>
                <CustomTextArea control={control} name="description"/>
              </div>

            )
          }
        </div>

        <div className={styles.line}/>
        <div className={styles.buttonGroup}>
          <DangerousButton disabled={isButtonDisabled} onClick={useFormReturn.handleSubmit(onSubmit)} text={t('sell.list.decline')}/>
          <SecondaryButton onClick={handleClose} text={t('common.cancel')}/>
        </div>
      </Modal>
      <DangerousButton onClick={() => setIsOpen(true)} className={styles.button} text={t('sell.list.decline')}/>
    </>
  )
}

export default DeclineOrder
