import { ChangeEvent, FC } from 'react'
import { Control, useController } from 'react-hook-form'
import styles from './FileUploadBase.module.scss'

type TProps = {
  name: string
  label: string
  accept: string
  control: Control<any>
  clearError: () => void
  handleChange: (event: ChangeEvent<HTMLInputElement>, onChange: (...event: any[]) => void) => void
}
const FileUploadBase: FC<TProps> = ({
  accept,
  label,
  handleChange,
  name,
  control,
  clearError }) => {
  const {
    field: { ref, onChange },
    formState: { errors }
  } = useController({
    name,
    control
  })

  const fileErrorMessage = (errors[name] as any)?.message

  return (
    <div className={styles.container}>
      <p className={styles.title}>{label}</p>
      <label htmlFor="file-upload" className={styles.label}>
        Upload file
        <input
          name={name}
          ref={ref}
          onClick={clearError}
          id="file-upload"
          className={styles.input}
          accept={accept}
          type="file"
          onChange={(event) => handleChange(event, onChange)}
        />
      </label>
      {fileErrorMessage && (
        <p className={styles.errorMessage}>
          {fileErrorMessage}
        </p>
      )}
    </div>
  )
}

export default FileUploadBase
