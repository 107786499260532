import { endOfDay, formatISO, isAfter, isBefore, isTomorrow } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuantityReportsContext } from '../state/QuantityReportsState'
import { useNotificationContext } from '../../../notifications'

function useMinTime() {
  const { selectedGrowingPlan } = useQuantityReportsContext()

  if (!selectedGrowingPlan?.dailyOrderTime) {
    return undefined
  }

  const [isoDate] = formatISO(new Date()).split('T')

  return zonedTimeToUtc(`${isoDate} ${selectedGrowingPlan?.dailyOrderTime}`, 'Europe/Zurich')
}

export function useDayDisabledToEditCheck(date?: Date) {
  const minTime = useMinTime()
  const { t } = useTranslation()

  const { showErrorNotification } = useNotificationContext()

  return useCallback(
    (showNotification: boolean) => {
      if (!date) {
        return undefined
      }
      const tomorrowFailCondition = isTomorrow(date) && minTime && isAfter(new Date(), minTime)
      const lessEndOfToday = isBefore(date, endOfDay(new Date()))
      const result = lessEndOfToday || tomorrowFailCondition

      if (result && showNotification) {
        showErrorNotification(t('quantity.notification.error'))
      }

      return result
    },
    [minTime, date, showErrorNotification, t, date]
  )
}
