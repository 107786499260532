import bioSuisseImage from '../assets/labels/BioSuisse.png'
import demeterImage from '../assets/labels/Demeter.png'
import ipSuisseImage from '../assets/labels/IP-Suisse.png'
import olnImage from '../assets/labels/OLN.png'
import suisseGarantieImage from '../assets/labels/Suisse Garantie.png'
import swissGAPImage from '../assets/labels/Swiss GAP.png'

import { TProductLabel } from '../../growingPlan/BaseGrowingPlanWorkShop'

const labelsImageMap: {[key in string]: string} = {
  '8e4a63e0-7c5e-4d3f-ad58-bcf882132ccb': olnImage,
  '414f091d-741f-4bad-b1e3-c7a65e58fee5': swissGAPImage,
  '79f684ee-ea26-4221-82d2-2caf2b1704df': suisseGarantieImage,
  'e5227318-8159-4142-ae96-10b954089976': ipSuisseImage,
  '8f58d7fd-aff9-468c-92e8-f4b56e378739': demeterImage,
  '21a6a468-b8ad-479e-ada9-0c9708364095': bioSuisseImage
}

export const getLogo = (labelId: string) => labelsImageMap[labelId] ?? ''
