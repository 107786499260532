import { FC } from 'react'
import styles from './LanguageSwitcherLabel.module.scss'

type TProps = {
  icon: string
  label: string
}
const LanguageSwitcherLabel: FC<TProps> = ({ icon, label }) => (
  <div className={styles.container}>
    <img src={icon} alt="icon" />
    <p className={styles.label}>{label}</p>
  </div>
)

export default LanguageSwitcherLabel
