import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import Modal from '../../../../../shared/components/Modal/Modal'
import DangerousButton from '../../../../../shared/components/buttons/DangerousButton/DangerousButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import styles from './DeleteModal.module.scss'
import { useGrowingPlanDetailsContext } from '../../state/GrowingPlanListState'
import { useGrowingPlanDeleteMutation } from '../../queries'

const DeleteModal: FC = () => {
  const { t } = useTranslation()
  const { growingPlanDeleteParams, setGrowingPlanDeleteParams } = useGrowingPlanDetailsContext()
  const { mutateAsync, isLoading } = useGrowingPlanDeleteMutation()
  const handleClose = () => {
    setGrowingPlanDeleteParams(undefined)
  }
  const handleDelete = () => {
    mutateAsync(growingPlanDeleteParams!.id).then(handleClose)
  }

  if (!growingPlanDeleteParams) {
    return null
  }

  return (
    <Modal
      fullWidth
      isOpen={!!growingPlanDeleteParams.id}
      onClose={handleClose}>
      <div>
        <h1 className={styles.label}>
          {t('growingPlan.delete.label')}
        </h1>
        <div className={styles.description}>
          {t('growingPlan.delete.description')}
          <span className={styles.bold}>
            {` ${growingPlanDeleteParams.title}? `}
          </span>
        </div>
        <div className={styles.line}/>
        <div className={styles.buttonGroup}>
          <DangerousButton disabled={isLoading} onClick={handleDelete} text={t('common.delete')}/>
          <SecondaryButton disabled={isLoading} onClick={handleClose} text={t('common.cancel')}/>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal
