import { useTranslation } from 'react-i18next'
import styles from './Order.module.scss'
import OrderTable from '../OrderTable/OrderTable'
import ServiceFee from '../ServiceFee/ServiceFee'

const Order = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.label}>{t('buy.bidOffer.order.label')}</h1>
      <OrderTable/>
      <div className={styles.line}/>
      <ServiceFee/>
    </div>
  )
}
export default Order
