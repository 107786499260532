import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import Search from '../Search/Search'
import ProductList from '../ProductList/ProductList'
import styles from './Offers.module.scss'
import Spinner from '../../../../../shared/components/Spinner/Spinner'
import { OffersProvider, useOffersContext } from '../../state/OffersState'
import { TLocationState } from '../../types'

function useOffersProduct() {
  const { state } = useLocation() as {state: TLocationState}
  const { productListMutation: { mutate } } = useOffersContext()
  const product = state?.product?.name

  useEffect(() => {
    mutate(product || '')
  }, [])
}

const Offers = () => {
  const { t } = useTranslation()
  const {
    productListMutation: { isLoading }
  } = useOffersContext()
  useOffersProduct()

  if (isLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <PrimaryLayout size="large" maxHeight header={t('buy.offers.title')}>
          <div className={styles.content}>
            <Search/>
            <ProductList/>
          </div>
        </PrimaryLayout>
      </div>
    </div>

  )
}

export default () => (
  <OffersProvider>
    <Offers/>
  </OffersProvider>
)
