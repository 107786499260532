import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { apiClient } from '../../Interceptor'
import { TOffer } from './types'

enum QueryKeys {
  Product = 'Product'
}

async function getProduct(productId: string): Promise<TOffer> {
  try {
    const response = await apiClient(`app/market/offer/${productId}`)
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useGetProduct = () => {
  const { productId } = useParams()

  return useQuery<TOffer, Error>([QueryKeys.Product, productId],
    () => getProduct(productId!),
    {
      enabled: !!productId
    })
}
