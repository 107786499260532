import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import styles from './ListOffer.module.scss'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import NoData from '../../../../../shared/components/NoData/NoData'
import ListOfferHeader from '../ListOfferHeader/ListOfferHeader'
import { useListOffers } from '../../queries'
import Spinner from '../../../../../shared/components/Spinner/Spinner'
import OfferTable from '../OfferTable/OfferTable'
import { Payment } from '../../../../payment'
import { useAccountDetailsQuery } from '../../../../account/accountBase'

const ListOffer = () => {
  const navigate = useNavigate()
  const [isPaymentModal, setIsPaymentModal] = useState(false)
  const { t } = useTranslation()
  const { data: listOffers, isLoading } = useListOffers()
  const { data } = useAccountDetailsQuery()
  const handleNavigate = () => navigate('/sell/create')

  const handleCreate = () => {
    if (!data) {
      return
    }
    if (!data.company.invoicePaymentTerm || !data.company.iban) {
      setIsPaymentModal(true)
      return
    }
    handleNavigate()
  }

  if (isLoading || !listOffers) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }

  return (
    <>
      <Payment
        label={t('sell.payment.label')}
        isOpen={isPaymentModal}
        onClose={() => setIsPaymentModal(false)}
        callBack={handleNavigate}/>
      <PrimaryLayout
        size="large"
        maxHeight
        header={(
          <ListOfferHeader
            handleCreate={handleCreate}
            isCreateButton={!!listOffers.length}
          />
        )}>

        {
          listOffers.length
            ? <OfferTable/>
            : (
              <NoData
                handleClick={handleCreate}
                title={t('sell.list.noData.label')}
                buttonText={t('sell.list.addOffer')}
              />
            )
        }
      </PrimaryLayout>
    </>
  )
}

export default ListOffer
