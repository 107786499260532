import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../../../../../shared/components/Modal/Modal'
import InfoCard from '../InfoCard/InfoCard'
import styles from './UserDetailsModal.module.scss'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import DangerousButton from '../../../../../shared/components/buttons/DangerousButton/DangerousButton'
import {
  useRegistrationRequestsStatusMutation,
  useRegistrationRequestUserDetails,
  useUserDetailsQuery
} from '../../queries'

type TProps = {
  isOpen: boolean,
  onClose: () => void
  id: string
  isModalAction?: boolean
  isRegistrationRequest?: boolean
}
const UserDetailsModal: FC<TProps> = ({
  isOpen,
  onClose,
  id,
  isModalAction,
  isRegistrationRequest
}) => {
  const userId = isOpen ? id : undefined
  const userQuery = isRegistrationRequest ? useRegistrationRequestUserDetails : useUserDetailsQuery

  const { data } = userQuery(userId)
  const { t } = useTranslation()
  const { mutateAsync, isLoading } = useRegistrationRequestsStatusMutation()

  if (!data) {
    return <></>
  }

  const handleReject = () => {
    mutateAsync({ userId: id, status: 'REJECTED' }).then(onClose)
  }
  const handleApprove = () => {
    mutateAsync({ userId: id, status: 'APPROVED' }).then(onClose)
  }

  const {
    firstName,
    lastName,
    address,
    phone,
    email,
    company,
    registrationDate
  } = data as any
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.wrapper}>
        <h1 className={styles.title}>{t('admin.users.userInformation')}</h1>
        <div className={styles.container}>
          <div className={styles.content}>
            <InfoCard title={t('common.firstName')} description={firstName}/>
            <InfoCard title={t('common.lastName')} description={lastName}/>
            <InfoCard title={t('common.email')} description={email}/>
            <InfoCard title={t('common.companyName')} description={company.name}/>
            <InfoCard title={t('common.phone')} description={phone}/>
            <InfoCard
              title={t('account.details.companyLogo')}
              description={!company?.logoUrl ? '-' : ''}>
              {company?.logoUrl && (
                <img
                  src={company?.logoUrl}
                  className={styles.logo}
                  alt={company.name}/>
              )}
            </InfoCard>
          </div>

          <div className={styles.content}>
            <InfoCard title={t('common.companyUid')} description={company.uid}/>
            <InfoCard title={t('common.address')} description={address.address}/>
            <InfoCard title={t('common.zip')} description={address.zip}/>

            <InfoCard title={t('common.city')} description={address.city}/>

            {
              !isRegistrationRequest && (
                <>
                  <InfoCard title={t('common.iban')} description={company?.iban || '-'}/>
                  <InfoCard title={t('common.invoice')} description={company?.invoicePaymentTerm?.toString() || '-'}/>
                  <InfoCard title={t('admin.users.table.registeredOn')} description={registrationDate}/>
                </>
              )
            }

          </div>
        </div>
        <div className={styles.line}/>
        <div className={styles.buttonWrapper}>
          {
            isModalAction ? (
              <>
                <PrimaryButton disabled={isLoading} className={styles.button} onClick={handleApprove}>{t('admin.users.table.approve')}</PrimaryButton>
                <DangerousButton disabled={isLoading} className={styles.button} onClick={handleReject} text={t('common.reject')}/>
              </>
            ) : <SecondaryButton className={styles.button} onClick={onClose}>{t('common.close')}</SecondaryButton>
          }

        </div>
      </div>
    </Modal>
  )
}
export default UserDetailsModal
