import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import GrowingPlanWorkShopHeader from '../GrowingPlanWorkShopHeader/GrowingPlanWorkShopHeader'
import GrowingPlanWorkShopBody from '../GrowingPlanWorkShopBody/GrowingPlanWorkShopBody'
import GrowingPlanWorkShopFooter from '../GrowingPlanWorkShopFooter/GrowingPlanWorkShopFooter'
import {
  GrowingPlanWorkShopFormProvider,
  useGrowingPlanWorkShopFormContext
} from '../../state/GrowingPlanWorkShopFormState'
import styles from './GrowingPlanWorkShop.module.scss'
import { TForm } from '../../types'
import { GrowingPlanAccordionProvider } from '../../state/GrowingPlanAccordionState'

type TProps = {
  label: string
  formReturn: UseFormReturn<any>
  handleSubmit: (data: TForm) => void
  isGrowingPlanOrderExist?: boolean
}
const GrowingPlanWorkShop = ({ label }: {label: string}) => {
  const { t } = useTranslation()
  const { fieldArrayReturn: { fields } } = useGrowingPlanWorkShopFormContext()

  return (
    <PrimaryLayout
      size="large"
      noPadding
      header={<div className={styles.headerLabel}>{label}</div>}>
      <GrowingPlanWorkShopHeader/>
      <div className={styles.body}>
        <div className={styles.lineWrap}>
          <div className={styles.line} />
        </div>
        <p className={styles.label}>
          {t('growingPlan.workShop.plan')}
        </p>
        {
          fields.map((field, index) => (
            <GrowingPlanWorkShopBody
              planIndex={index}
              key={field.id}/>
          ))
        }
      </div>
      <GrowingPlanWorkShopFooter/>
    </PrimaryLayout>
  )
}

export default ({ formReturn, handleSubmit, label, isGrowingPlanOrderExist }: TProps) => (
  <GrowingPlanAccordionProvider>
    <GrowingPlanWorkShopFormProvider
      formReturn={formReturn}
      handleSubmit={handleSubmit}
      isGrowingPlanOrderExist={isGrowingPlanOrderExist}>
      <GrowingPlanWorkShop label={label}/>
    </GrowingPlanWorkShopFormProvider>
  </GrowingPlanAccordionProvider>
)
