import { useNavigate } from 'react-router-dom'
import { FC } from 'react'
import classNames from 'classnames'
import styles from './BreadCrumb.module.scss'
import ArrowLineLeftIcon from '../icons/ArrowLineLeftIcon'

type TProps = {
  navigateTo: string
  label: string
  noPadding?: boolean
}
const BreadCrumb: FC<TProps> = ({ navigateTo, label, noPadding }) => {
  const navigate = useNavigate()
  return (
    <button
      type="button"
      onClick={() => navigate(navigateTo)}
      className={classNames(styles.wrapper, { [styles.noPadding]: noPadding })}>
      <div className={styles.iconWrap}>
        <ArrowLineLeftIcon/>
      </div>
      <p className={styles.headerText}>{label}</p>
    </button>
  )
}

export default BreadCrumb
