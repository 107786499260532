import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { SellWorkShop, TForm } from '../../../BaseOffer'
import { useCreateOfferMutation } from '../../querries'
import { TLocationState } from '../../types'
import { useScrollTop } from '../../../../../shared/hooks/useScrollTop'

function useFormInit() {
  const { state } = useLocation() as {state: TLocationState}

  return useForm<TForm>({
    defaultValues: {
      productId: state?.product?.id || '',
      labelId: state?.label?.id,
      origin: '',
      negotiation: 'NO',
      deliveryOption: 'PICK_UP',
      anonymous: 'NO',
      packageDate: undefined,
      bestBefore: undefined,
      pickUpDateTime: new Date(),
      innerCircle: [],
      publishFor: 'OPEN_MARKET'
    }
  })
}

const CreateOffer = () => {
  useScrollTop()
  const useFormReturn = useFormInit()
  const { mutateAsync } = useCreateOfferMutation()
  const { t } = useTranslation()
  return (
    <SellWorkShop
      label={t('sell.workShop.addOffer')}
      onSubmit={mutateAsync}
      useFormReturn={useFormReturn}
    />
  )
}

export default CreateOffer
