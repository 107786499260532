import React, { FC, useCallback, useState } from 'react'
import EditIcon from '../../../../../shared/components/icons/EditIcon'
import styles from './EditProduct.module.scss'
import EditProductLabelModal from '../EditProductLabelModal/EditProductLabelModal'

type TProps = {
  id: string
}

const EditProductLabel: FC<TProps> = ({ id }) => {
  const [isModal, setIsModal] = useState(false)

  return (
    <>
      <button
        onClick={() => setIsModal(true)}
        type="button"
        className={styles.iconWrap}>
        <EditIcon width={22} height={22}/>
      </button>
      <EditProductLabelModal
        id={id}
        isModal={isModal}
        handleClose={useCallback(() => setIsModal(false), [])}/>
    </>
  )
}

export default EditProductLabel
