import { FC, SyntheticEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from './AccordionSummary.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { useGrowingPlanDetailsContext } from '../../state/GrowingPlanListState'

type TProps = {
  icon: JSX.Element
  title: string
  id?: string
  description: string
  date?: string
  isEditButton?: boolean
  isDeleteButton?: boolean
}
const AccordionSummary: FC<TProps> = ({
  title,
  description,
  date,
  isDeleteButton,
  isEditButton,
  icon,
  id
}) => {
  const { t } = useTranslation()
  const { setGrowingPlanDeleteParams } = useGrowingPlanDetailsContext()
  const navigate = useNavigate()
  const handleDelete = (e: SyntheticEvent) => {
    e.stopPropagation()
    setGrowingPlanDeleteParams({
      id: id || '',
      title
    })
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <div className={styles.titleWrapper}>
          {icon}
          <div className={styles.title}>{title}</div>
        </div>
        <div className={styles.circle}/>
        <div className={styles.description}>
          {description}
        </div>
        {
          date && (
            <>
              <div className={styles.circle}/>
              <div className={styles.date}>
                {`${t('growingPlan.list.validUntil')} ${date}`}
              </div>
            </>
          )
        }
      </div>
      <div className={styles.buttonGroup}>
        {isEditButton && (
          <PrimaryButton
            onClick={() => navigate(`/growing-plan/update/${id}`)}
            text={t('growingPlan.list.editPlan')}/>
        )}
        {
          isDeleteButton && (
            <SecondaryButton
              onClick={(e) => handleDelete(e)}
              text={t('common.delete')}/>
          )
        }
      </div>
    </div>
  )
}

export default AccordionSummary
