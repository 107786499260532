import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { FC } from 'react'

import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import styles from './InnerCircleHeader.module.scss'

type TProps = {
  isCreateButton: boolean
}
const InnerCircleListHeader: FC<TProps> = ({ isCreateButton }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>
        {t('innerCircle.list.label')}
      </div>
      {isCreateButton && (
        <div className={styles.buttonWrapper}>
          <PrimaryButton
            onClick={() => navigate('/inner-circle/create')}
            text={t('innerCircle.list.addInnerCircle')}/>
        </div>
      )}
    </div>
  )
}

export default InnerCircleListHeader
