import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import { FC, memo, useMemo } from 'react'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { TLogisticUnit } from '../../../types'

function useOptions() {
  const { t } = useTranslation()
  const optionValue: TLogisticUnit[] = ['PALETTE', 'GROSSKISTEN', 'BALOXEN']
  return useMemo(() => optionValue.map((value) => ({
    value,
    label: t(`logisticOptions.${value}`)
  })), [])
}

type TProps = {
  disabled: boolean
  quantityIndex: number
  control: Control<any>
}
const LogisticSelect: FC<TProps> = ({ control, quantityIndex, disabled }) => {
  const { t } = useTranslation()
  const options = useOptions()
  return (
    <CustomSelectControl
      disabled={disabled}
      control={control}
      options={options}
      isRequired
      label={`${t('quantity.selects.logistic.label')} *`}
      placeholder={t('quantity.selects.logistic.placeholder') as string}
      name={`orderItems[${quantityIndex}].lu`}
      handleError={(error) => (error as any)?.orderItems?.[quantityIndex]?.lu}
    />
  )
}

export default memo(LogisticSelect)
