import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { useBaseQuantityFormContext } from '../../state/BaseQuantityFormState'

import styles from './AccordionHandlers.module.scss'
import { useControlsDisableState } from '../../hooks/useControlsDisableState'
import { useIsEditableForUser, useIsEditMode, useIsOrderDraft } from '../../hooks/editModeHooks'
import { TSubmitData } from '../../types'

type TProps = {
    quantityIndex: number
}

const AccordionHandlers = ({ quantityIndex }: TProps) => {
  const { t } = useTranslation()
  const isEditMode = useIsEditMode()

  const {
    isSeller,
    formReturn,
    readOnly,
    onlyDay,
    initialFormData,
    orders,
    onSubmit,
    onConfirm
  } = useBaseQuantityFormContext()

  const { trigger, getValues } = formReturn

  const {
    isSubmitDisabled,
    isConfirmDisabled,
    isSaveForLaterDisabled
  } = useControlsDisableState(
    initialFormData, formReturn, quantityIndex, orders?.[quantityIndex], onlyDay
  )

  const order = orders?.[quantityIndex]
  const isDraft = useIsOrderDraft(order)

  const SubmitButton = useMemo(() =>
    ((order && isEditMode && !isDraft) ? SecondaryButton : PrimaryButton),
  [isDraft, isEditMode, order])

  const dailyOrderId = order?.dailyOrders?.find((daily) => daily.date === onlyDay)?.id

  const isEditableForUser = useIsEditableForUser(order, onlyDay)

  const handleSubmitProduct = async (submitData: Omit<TSubmitData, 'data'>) => {
    const isValid = await trigger(`orderItems[${quantityIndex}]`)
    if (isValid) {
      const formData = getValues(`orderItems[${quantityIndex}]`)
      onSubmit({ ...submitData, quantityIndex, data: formData })
    }
  }

  if (!isEditableForUser) return null

  return (
    <div className={classNames(
      styles.buttonGroup, { [styles.buttonGroupEdit]: isEditMode }
    )}>
      {(order && isEditMode && !isDraft && !readOnly) && (
        <PrimaryButton
          disabled={isConfirmDisabled}
          className={styles.button}
          onClick={() => onConfirm(onlyDay ? dailyOrderId : order?.weeklyOrderId)}
          text={t(onlyDay ? 'quantity.daily.confirm' : 'quantity.weekly.confirm')}/>
      )}
      {!readOnly && (
        <>
          <SubmitButton
            disabled={isSubmitDisabled}
            className={styles.button}
            onClick={() => handleSubmitProduct({
              orderId: onlyDay ? dailyOrderId : order?.weeklyOrderId
            })}
            text={t(onlyDay ? 'quantity.daily.submit' : 'quantity.weekly.submit')}/>

          {(!order?.status || order?.status === 'DRAFT') && !onlyDay && isSeller && (
            <SecondaryButton
              disabled={isSaveForLaterDisabled}
              className={styles.button}
              onClick={() => handleSubmitProduct({
                publish: false,
                orderId: order?.weeklyOrderId,
                saveDraft: true
              })}
              text={t('quantity.weekly.saveForLater')}/>
          )}
        </>
      )}
    </div>
  )
}

export default AccordionHandlers
