import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from '../../../../../shared/components/Modal/Modal'
import styles from './DeactivateModal.module.scss'
import DangerousButton from '../../../../../shared/components/buttons/DangerousButton/DangerousButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'

type TProps = {
  isOpen: boolean
  onClose: () => void
  handleDeactivate: () => void
  name?: string
}
const DeactivateModal: FC<TProps> = ({ isOpen, onClose, handleDeactivate, name }) => {
  const { t } = useTranslation()
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <h1 className={styles.title}>{`${t('admin.users.table.deactivate')} ${name}`}</h1>
          <p className={styles.description}>
            Are you sure you want to deactivate
            {' '}
            <span className={styles.bold}>{name}</span>
            {' '}
            ?
          </p>
        </div>
        <div className={styles.line}/>
        <div className={styles.buttonGroup}>
          <DangerousButton onClick={handleDeactivate}>{t('admin.users.table.deactivate')}</DangerousButton>
          <SecondaryButton onClick={onClose}>{t('common.cancel')}</SecondaryButton>
        </div>
      </div>
    </Modal>
  )
}

export default DeactivateModal
