import React, { FC } from 'react'
import ReactDOM from 'react-dom/client'
import { QueryClientProvider } from '@tanstack/react-query'
import { queryClient } from './shared/utils/queryClient'
import { AuthProvider } from './features/auth/authBase'
import { NotificationProvider, Notification } from './features/notifications'
import Routes from './Routes'
import './shared/locales/i18n'
import 'normalize.css'
import './shared/styles/_reset.scss'
import { NotFoundProvider } from './features/notFound/state/NotFoundState'

const App: FC = () => (
  <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <NotificationProvider>
        <NotFoundProvider>
          <Notification/>
          <Routes />
        </NotFoundProvider>
      </NotificationProvider>
    </AuthProvider>
  </QueryClientProvider>
)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>

)
