import { TDaysOfWeek, TGpItem } from '../types'

export function prepareTableValues(tableData: TGpItem[]) {
  const values = tableData
    .reduce((acc, { values }) => {
      values.forEach(({ dayOfWeek, amount }) => {
        const dayAmount = acc[dayOfWeek]
        acc[dayOfWeek] = (dayAmount || 0) + amount
      })
      return acc
    }, {} as any)

  return Object.entries(values).map(([key, value]) => ({
    dayOfWeek: key as TDaysOfWeek,
    amount: value
  }))
}
