import { FormProvider, useForm } from 'react-hook-form'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { UseMutateFunction } from '@tanstack/react-query'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import styles from './ResetPassword.module.scss'
import { AuthLayout } from '../../../../../layouts/primaryLayout'
import { useSearchParamsCode } from '../../hooks/useSearchParamsCode'
import { useNotificationContext } from '../../../../notifications'
import { TResetPasswordForm } from '../../types'
import { useResetPasswordMutation } from '../../queries'
import Password from '../../../../../shared/components/formFields/Password'
import MatchingPassword from '../../../../../shared/components/formFields/MatchingPassword'

function useFormInit() {
  return useForm<TResetPasswordForm>({
    defaultValues: {
      password: '',
      matchingPassword: ''
    }
  })
}

function useOnSubmit(mutate: UseMutateFunction<void, Error, TResetPasswordForm>) {
  return useCallback((data: TResetPasswordForm) => {
    mutate(data)
  }, [mutate])
}

function useCheckSearchParams() {
  const navigate = useNavigate()
  const codeFormSearchParams = useSearchParamsCode()

  useEffect(
    () => {
      if (!codeFormSearchParams) {
        navigate('/forgot-password')
      }
    },
    [codeFormSearchParams, navigate]
  )
}

function useNotifySuccess(isSuccess: boolean) {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { showSuccessNotification } = useNotificationContext()
  useEffect(() => {
    if (!isSuccess) {
      return
    }
    showSuccessNotification(t('resetPassword.passwordChanged'))
    navigate('/login')
  }, [isSuccess, navigate, showSuccessNotification, t])
}

const ResetPassword = () => {
  useCheckSearchParams()
  const { mutate, isSuccess, isLoading } = useResetPasswordMutation()
  const { t } = useTranslation()
  const useFormReturn = useFormInit()
  const onSubmit = useOnSubmit(mutate)
  useNotifySuccess(isSuccess)

  const { handleSubmit } = useFormReturn
  return (
    <AuthLayout header={t('resetPassword.newPassword')} isLabelMarginSmall>
      <p className={styles.helperText}>
        {t('resetPassword.helperText.main')}
        {' '}
        <span className={styles.noSpaceText}>{t('resetPassword.helperText.body')}</span>
        {' '}
        {t('resetPassword.helperText.footer')}
        {' '}
        <span className={styles.noSpaceText}>
          {t('resetPassword.helperText.symbols')}
        </span>
      </p>
      <FormProvider {...useFormReturn}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Password label={t('resetPassword.newPassword')}/>
          <MatchingPassword label={(t('resetPassword.confirmNewPassword'))}/>
          <PrimaryButton disabled={isLoading} type="submit" text={t('resetPassword.saveChanges')}/>
        </form>
      </FormProvider>
    </AuthLayout>
  )
}

export default ResetPassword
