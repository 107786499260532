import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import CustomInput from '../formControls/CustomInput/CustomInput'
import { integerFormat } from '../../utils/formUtils'
import { useValidationRules } from '../../hooks/useValidationRules'

type TProps = {
  isRequired?: boolean
}
const Invoice: FC<TProps> = ({ isRequired }) => {
  const { t } = useTranslation()
  const { control } = useFormContext()
  const { requiredRule } = useValidationRules()
  return (
    <CustomInput
      label={`${t('common.invoice')} ${isRequired ? '*' : ''}`}
      name="invoicePaymentTerm"
      InputProps={{
        inputProps: {
          maxLength: 2
        }
      }}
      rules={{
        required: isRequired ? requiredRule() : undefined
      }}
      handleChange={integerFormat}
      control={control}
    />
  )
}

export default Invoice
