import { TCropStockFilteredResponse } from '../types'

export const prepareFormData = (formData: TCropStockFilteredResponse) => {
  const [day, month, year] = formData.date.split('.')

  return {
    amount: formData.amount,
    unit: formData.unit,
    description: formData.description,
    type: formData.type,
    title: formData.title,
    date: new Date(Number(year), Number(month) - 1, Number(day))
  }
}
