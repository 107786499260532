import { Chip, OutlinedInput } from '@mui/material'
import { Control, Controller, FieldError, FieldErrors, FieldErrorsImpl, Merge, useFieldArray } from 'react-hook-form'
import { FC } from 'react'
import { DefaultTFuncReturn } from 'i18next'
import CustomSelectBase, { TGroupedOption, TOption } from '../CustomSelectBase/CustomSelectBase'
import CloseCircleIcon from '../../../icons/CloseCircleIcon'
import styles from './CustomSelectChip.module.scss'
import { useValidationRules } from '../../../../hooks/useValidationRules'

function usePrepareOptions(options?: TOption[], groupedOptions?: TGroupedOption[]) {
  return options ?? groupedOptions?.map(({ options }) => options).flat() ?? []
}

type TProps = {
  name: string
  control: Control<any>
  options?: TOption[]
  groupedOptions?: TGroupedOption[]
  label?: string | null
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
  placeholder?: DefaultTFuncReturn
  handleError?: (error: FieldErrors) =>
    FieldError
    | Merge<FieldError, FieldErrorsImpl<any>>
    | undefined,
}
const CustomSelectChip: FC<TProps> = ({
  name,
  options,
  groupedOptions,
  label,
  labelPlacement = 'top',
  control,
  placeholder,
  handleError
}) => {
  const { remove, append, fields } = useFieldArray({
    control,
    name
  })
  const { requiredRule } = useValidationRules()
  const preparedOptions = usePrepareOptions(options, groupedOptions)

  return (
    <Controller
      name={name}
      rules={{
        required: requiredRule()
      }}
      control={control}
      render={({ field: { ref, ...fieldRest }, formState }) => {
        const error = handleError?.(formState.errors)
        return (
          <div>
            <CustomSelectBase
              selectClassName={styles.select}
              {...fieldRest}
              isError={!!error}
              label={label}
              labelPlacement={labelPlacement}
              options={options}
              groupedOptions={groupedOptions}
              currentValue={fields as unknown as TOption['value'][]}
              handleChange={(value) => append(value)}
              input={<OutlinedInput />}
              multiple
              renderValue={(value) => {
                const selectedValues = value as string[]
                if (!selectedValues.length) {
                  return <p className={styles.placeholder}>{placeholder}</p>
                }
                return (
                  <div className={styles.chipWrapper}>
                    {selectedValues?.map((selectedValue: string, index: number) => {
                      const label = preparedOptions?.find(
                        ({ value }) => value === selectedValue
                      )?.label
                      return (
                        <Chip
                          className={styles.chip}
                          deleteIcon={<CloseCircleIcon width={16} height={16}/>}
                          onDelete={() => remove(index)}
                          key={selectedValue}
                          label={label}
                          onMouseDown={(event) => {
                            event.stopPropagation()
                          }}
                        />
                      )
                    })}
                  </div>
                )
              }}
            />
            {error && <p className={styles.errorMessage}>{(error as any).message}</p>}
          </div>
        )
      }}
    />
  )
}

export default CustomSelectChip
