import { useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import {
  GrowingPlanWorkShop,
  TForm
} from '../../../BaseGrowingPlanWorkShop'
import { useGrowingPlanQuery, useLabelListQuery } from '../../../BaseGrowingPlanWorkShop/queries'
import { setForm } from '../../utils/setForm'
import { useUpdateGrowingPlanMutation } from '../../queries'
import { timeFormat } from '../../../../../shared/constants/dateFormat'
import { prepareGrowingPlan } from '../../utils/prepareGrowingPlan'
import { generateProductPlan } from '../../../BaseGrowingPlanWorkShop/utils/generateProductPlan'
import { TProductPlanForm } from '../../../BaseGrowingPlanWorkShop/types'

function useGetGrowingPlan() {
  const { growingPlanId } = useParams()
  return useGrowingPlanQuery(growingPlanId)
}

function useFormInit(defaultProductPlan: TProductPlanForm) {
  const { data } = useGetGrowingPlan()

  return useForm<any>({
    defaultValues: useMemo(() => {
      const result = setForm(defaultProductPlan, data)
      return {
        customerId: result?.customerId || '',
        description: result?.description || '',
        year: result?.year || '',
        dailyOrderTime: result?.dailyOrderTime || '',
        title: result?.title || '',
        productPlans: result?.productsPlan.length ? result?.productsPlan : [defaultProductPlan]
      }
    }, [data, defaultProductPlan])
  })
}

function useResetForm(useFormReturn: UseFormReturn<any>, defaultProductPlan: TProductPlanForm) {
  const { data } = useGetGrowingPlan()
  useEffect(() => {
    if (!data) {
      return
    }
    const result = setForm(defaultProductPlan, data)!
    useFormReturn.reset({
      customerId: result.customerId,
      description: result?.description,
      year: result.year,
      dailyOrderTime: result.dailyOrderTime,
      title: result.title,
      productPlans: result.productsPlan
    })
  }, [data, defaultProductPlan, useFormReturn])
}

function useHandleSubmit() {
  const { mutateAsync } = useUpdateGrowingPlanMutation()
  const navigate = useNavigate()
  return useCallback((data: TForm) => {
    mutateAsync({
      ...prepareGrowingPlan(data),
      dailyOrderTime: format(data.dailyOrderTime as Date, timeFormat) })
      .then(() => navigate('/growing-plan'))
  }, [mutateAsync, navigate])
}

type TProps = {
  defaultProductPlan: TProductPlanForm
}
const UpdateGrowingPlan: FC<TProps> = ({ defaultProductPlan }) => {
  const { t } = useTranslation()
  const formReturn = useFormInit(defaultProductPlan)
  useResetForm(formReturn, defaultProductPlan)
  const handleSubmit = useHandleSubmit()
  const { data } = useGetGrowingPlan()
  return (
    <GrowingPlanWorkShop
      isGrowingPlanOrderExist={data?.ordersExist}
      formReturn={formReturn}
      handleSubmit={handleSubmit}
      label={t('growingPlan.workShop.labelUpdate')}
    />
  )
}

export default () => {
  const { data: labelsData, isLoading } = useLabelListQuery()
  if (!labelsData || isLoading) {
    return <></>
  }
  return <UpdateGrowingPlan defaultProductPlan={generateProductPlan(labelsData)}/>
}
