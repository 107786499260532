import { FC } from 'react'
import { Control } from 'react-hook-form'
import styles from './AccordionSummary.module.scss'
import { useGrowingPlanWorkShopFormContext } from '../../state/GrowingPlanWorkShopFormState'
import { TProductPlan } from '../../types'
import { addNumber } from '../../utils/tableUtils'
import InActiveAccordionSummary from '../InActiveAccordionSummary/InActiveAccordionSummary'
import ActiveAccordionSummary from '../ActiveAccordionSummary/ActiveAccordionSummary'
import { roundNumber } from '../../../../../shared/utils/calculationUtils'

function useCalculateTotal(planIndex: number) {
  const { formReturn: { watch } } = useGrowingPlanWorkShopFormContext()

  const tableValues = watch(`productPlans[${planIndex}]`) as TProductPlan

  return roundNumber(
    Number(tableValues?.values
      .map(({
        label, labelId,
        id,
        total,
        ...rest }) => rest)
      .reduce((acc: number, curr) => {
        const totalRow = Object
          .values(curr)
          .reduce((acc, curr) => addNumber(acc, curr.value), 0)
        return acc + totalRow
      }, 0))
  )
}

type TProps = {
  icon: JSX.Element
  control: Control<any>
  planIndex: number
  isActive: boolean
}
const AccordionSummary: FC<TProps> = ({
  icon,
  control,
  planIndex,
  isActive
}) => {
  const total = useCalculateTotal(planIndex)
  const { removePlan, formReturn: { watch } } = useGrowingPlanWorkShopFormContext()
  const { isPlanOrderExist } = watch().productPlans[planIndex]
  const isDeleteButton = watch().productPlans.length > 1
    && !isPlanOrderExist

  return (
    <div className={styles.wrapper}>
      {
        isActive
          ? (
            <ActiveAccordionSummary
              isPlanOrderExist={isPlanOrderExist}
              isDeleteButton={isDeleteButton}
              removePlan={() => removePlan(planIndex)}
              icon={icon}
              total={total}
              planIndex={planIndex}
              control={control}
            />
          )
          : (
            <InActiveAccordionSummary
              isDeleteAbleProduct={isDeleteButton}
              planIndex={planIndex}
              icon={icon}
              removePlan={() => removePlan(planIndex)}
              total={total}
            />
          )
      }
    </div>
  )
}

export default AccordionSummary
