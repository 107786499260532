import constate from 'constate'
import { useState } from 'react'
import { TProps } from '../components/MainLayout/MainLayout'

const MainLayoutState = ({ isNonAuth }: TProps) => {
  const [isSidebar, setIsSidebar] = useState(false)

  return {
    isSidebar,
    setIsSidebar,
    isNonAuth
  }
}

export const [MainLayoutProvider, useMainLayoutContext] = constate(MainLayoutState)
