import styles from './CropStockOverview.module.scss'
import { useCropStockListQuery } from '../../queries'
import Accordion from '../../../../../shared/components/Accordion/Accordion'
import AccordionSummary from '../AccordionSummary/AccordionSummary'
import CropStockTable from '../CropStockTable/CropStockTable'

const CropStockOverview = () => {
  const { data } = useCropStockListQuery()

  return (
    <div className={styles.accordionWrapper}>
      {
        data?.map((props) => (
          <Accordion
            key={props.id}
            isPaddingSmall
            summary={(icon) => <AccordionSummary icon={icon} {...props}/>}
            detail={<CropStockTable productStockId={props.id}/>}
          />
        ))
      }
    </div>
  )
}

export default CropStockOverview
