import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import { FC, useMemo } from 'react'
import CustomSelectControl
  from '../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { TForm } from '../../types'
import { useQuantityReportsContext } from '../../state/QuantityReportsState'
import { NO_GROWING_PLAN_OPTION } from '../../../BaseQuantityReports'

function useOptions() {
  const { growingPlanListQuery: { data: growingPlanListData } } = useQuantityReportsContext()
  const { t } = useTranslation()
  return useMemo(() => {
    const preparedGrowingPlanList = growingPlanListData
      ? growingPlanListData.activePlans.map(({
        id, title,
        seller: { name: companyName },
        currentUserSeller
      }) => ({
        value: id,
        label: `${title} ${currentUserSeller ? '' : `(${companyName})`}`
      }))
      : []
    return growingPlanListData ? [
      {
        value: NO_GROWING_PLAN_OPTION,
        label: t('quantity.selects.growingPlan.noGrowingPlan')
      }, ...preparedGrowingPlanList] : []
  }, [growingPlanListData])
}

type TProps = {
  control: Control<TForm>
}
const GrowingPlanSelect: FC<TProps> = ({ control }) => {
  const { t } = useTranslation()
  const options = useOptions()

  return (
    <CustomSelectControl
      control={control}
      options={options}
      name="growingPlan"
      label={`${t('quantity.selects.growingPlan.label')}`}
    />
  )
}

export default GrowingPlanSelect
