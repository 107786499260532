import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import { FC, memo, useMemo } from 'react'
import CustomSelectControl from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { useCompaniesListQuery } from '../../../queries'
import { TForm } from '../../../types'

function useOptions() {
  const { data } = useCompaniesListQuery()

  return useMemo(
    () => (data?.length
      ? data.map(({ id, name }) => ({
        value: id,
        label: name
      }))
      : []),
    [data]
  )
}

type TProps = {
  control: Control<TForm>
  disabled: boolean
}

const CustomerSelect: FC<TProps> = ({ control, disabled }) => {
  const { t } = useTranslation()
  const options = useOptions()

  return (
    <CustomSelectControl
      control={control}
      options={options}
      disabled={disabled}
      isRequired
      name="customerId"
      label={`${t('growingPlan.workShop.customer')} *`}
      placeholder={t('growingPlan.workShop.selectCompanyPlaceholder') as string}
      handleError={(error) => error?.customerId}
    />
  )
}

export default memo(CustomerSelect)
