import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { useMemo } from 'react'
import styles from './BidTotal.module.scss'
import { useGetProduct } from '../../queries'
import { HUNDRED_PERCENTAGE } from '../../../../../shared/constants/percentage'
import { roundNumber } from '../../../../../shared/utils/calculationUtils'
import PercentageIcon from '../../../../../shared/components/icons/PercentageIcon'
import { addZeros } from '../../../../../shared/utils/formUtils'

function useCalculateTotal() {
  const { watch } = useFormContext()
  const { data } = useGetProduct()

  const {
    amountCuPerTu,
    pricePerCu,
    negotiation,
    discount,
    minDiscountAmountTu
  } = data!

  const amountTu = watch('amountTu')
  const priceChf = watch('priceChf')
  return useMemo(() => {
    if (!amountTu || (negotiation && !priceChf)) {
      return undefined
    }

    const price = Number(negotiation ? priceChf : pricePerCu)
    const beforeDiscountPrice = Number(amountTu) * price! * amountCuPerTu
    let total = beforeDiscountPrice
    if ((Number(amountTu) >= Number(minDiscountAmountTu)) && discount) {
      total *= (HUNDRED_PERCENTAGE - discount) / HUNDRED_PERCENTAGE
    }
    return {
      total: roundNumber(total),
      saving: roundNumber(beforeDiscountPrice - total)
    }
  }, [data, amountTu, priceChf])
}

const BidTotal = () => {
  const { t } = useTranslation()
  const calculatedTotal = useCalculateTotal()

  return (
    <div className={styles.wrapper}>
      <p className={styles.text}>
        {t('buy.offer.footer.totalPrice')}
        {calculatedTotal?.total
          ? <span className={styles.totalCHF}>{`${t('common.chf')} ${addZeros(calculatedTotal?.total)}`}</span>
          : '-'}
      </p>
      {
        calculatedTotal?.saving
          ? (
            <div className={styles.discount}>
              <div className={styles.icon}>
                <PercentageIcon/>
              </div>
              {t('buy.offer.footer.discounted', { discounted: addZeros(calculatedTotal.saving) })}
            </div>
          )
          : null
      }

    </div>
  )
}

export default BidTotal
