import { NavLink } from 'react-router-dom'
import { FC, memo } from 'react'
import styles from './Navigation.module.scss'
import ArrowLineRightIcon from '../../../../../shared/components/icons/ArrowLineRightIcon'

type TProps = {
  text: string
  to: string
}
const Navigation: FC<TProps> = ({ to, text }) => (
  <NavLink className={styles.navigation} to={to}>
    {text}
    <div className={styles.iconWrapper}>
      <ArrowLineRightIcon/>
    </div>
  </NavLink>
)

export default memo(Navigation)
