import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useBaseQuantityFormContext } from '../state/BaseQuantityFormState'
import { useAccountDetailsQuery } from '../../../account/accountBase'

export const useSellerId = () => {
  const [searchParams] = useSearchParams()
  const { buyerId, weeklySellerId } = useBaseQuantityFormContext()
  const { data } = useAccountDetailsQuery()

  return useMemo(() => {
    if (weeklySellerId) {
      return weeklySellerId
    }
    return buyerId ? data?.company.id : (searchParams.get('sellerId') || undefined)
  }, [weeklySellerId, data, buyerId])
}
