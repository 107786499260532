import React, { FC } from 'react'
import { MenuItem } from '@mui/material'
import { NavLink, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import styles from './NavigationDropDownMenu.module.scss'
import Menu from '../../../../shared/components/menu/Menu'
import ArrowDownIcon from '../../../../shared/components/icons/ArrowDownIcon'

 type TProps = {
  mainTo: string
   mainLabel: string
  options: {
    to: string
    text: string
  }[]
}
const NavigationDropDownMenu: FC<TProps> = ({ mainTo, mainLabel, options }) => {
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isOpen = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <NavLink className={styles.navigation} to={mainTo}>{mainLabel}</NavLink>
        <button
          type="button"
          className={classNames(styles.icon, { [styles.active]: isOpen })}
          onClick={handleClick}>
          <ArrowDownIcon width={24} height={24}/>
        </button>
      </div>
      <Menu
        size="small"
        anchorEl={anchorEl}
        isOpen={isOpen}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {
          options.map(({ text, to }) => (
            <MenuItem
              className={styles.menuItem}
              key={text}
              onClick={() => {
                navigate(to)
                handleClose()
              }}>
              {text}
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  )
}

export default NavigationDropDownMenu
