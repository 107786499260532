import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import CustomInput from '../formControls/CustomInput/CustomInput'
import { useValidationRules } from '../../hooks/useValidationRules'

type TProps = {
  label?: string
}
const CompanyUid: FC<TProps> = ({ label }) => {
  const { requiredRule } = useValidationRules()
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <CustomInput
      label={label || `${t('common.companyUid')} *`}
      name="companyUid"
      placeholder="CHE-123.456.789"
      control={control}
      rules={{
        required: requiredRule()
      }}
    />
  )
}

export default CompanyUid
