import { isAfter, subDays, subWeeks } from 'date-fns'
import { dateFromDotDateFormat, endOfWeekCustom } from '../../../../shared/utils/date'

const useExpiredDateCheck = ({ type, date }: { type: 'weekly' | 'daily', date?: string }) => {
  if (!date) return false

  if (type === 'weekly') {
    return isAfter(dateFromDotDateFormat(date), endOfWeekCustom(subWeeks(new Date(), 1)))
  }

  return isAfter(dateFromDotDateFormat(date), subDays(new Date(), 1))
}

export default useExpiredDateCheck
