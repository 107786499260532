import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import styles from '../SellingOptions/SellingOptions.module.scss'
import PublishRadio from '../radios/PublishRadio/PublishRadio'
import InnerCircleChipSelect from '../selects/InnerCircleChipSelect/InnerCircleChipSelect'

const Publish = () => {
  const { watch } = useFormContext()
  const { t } = useTranslation()

  const isInnerCircle = watch('publishFor') === 'INNER_CIRCLE'
  return (
    <div className={styles.publishRadioWrapper}>
      <PublishRadio/>
      {isInnerCircle
      && (
        <div className={styles.chipWrap}>
          <InnerCircleChipSelect/>
          <a
            target="_blank"
            href="https://www.freshtrade.ch/invite"
            className={styles.navigation}
            rel="noreferrer"
          >
            {t('sell.workShop.inviteCompanies')}
          </a>
        </div>
      )}
    </div>
  )
}

export default Publish
