import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import NotificationIcon from '../NotificationIcon/NotificationIcon'
import styles from './Notification.module.scss'

const Notification = () => (
  <ToastContainer
    className={styles.notification}
    newestOnTop
    icon={false}
    closeButton={<NotificationIcon />}
  />
)

export default Notification
