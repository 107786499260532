import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './DayViewCard.module.scss'
import { ProductInfo } from '../../../baseAnalytics'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import { TForecastDayResponse } from '../../types'

type TProps = TForecastDayResponse
const DayViewCard: FC<TProps> = ({
  label,
  product,
  ordersSellAmount,
  ordersBuyAmount,
  stockAmount,
  unit,
  forecast
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isPositiveForecast = forecast >= 0
  const unitLowerCased = unit.toLowerCase()

  const handleClick = () => {
    const state = {
      product, label
    }
    if (isPositiveForecast) {
      navigate('/sell/create', { state })
      return
    }
    navigate('/buy', { state })
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.labelWrap}>
        <h1 className={styles.label}>{product.name}</h1>
        <p className={styles.description}>{`(${label.name})`}</p>
      </div>
      <div className={styles.productInfoWrap}>
        <ProductInfo label={t('dashboard.stock')} value={`${stockAmount} ${t(`units.${unitLowerCased}`)}`}/>
        {ordersBuyAmount ? (
          <ProductInfo
            label={t('dashboard.weeklyReportsBuy')}
            value={`+ ${ordersBuyAmount} ${t(`units.${unitLowerCased}`)}`}/>
        ) : null}
        {
          ordersSellAmount ? (
            <ProductInfo
              label={t('dashboard.weeklyReportsSell')}
              value={`- ${ordersSellAmount} ${t(`units.${unitLowerCased}`)}`}/>
          ) : null
        }
        <div className={styles.line}/>
        <ProductInfo
          label={<p className={styles.label}>{t('dashboard.forecast')}</p>}
          value={(
            <p className={styles[isPositiveForecast ? 'positive' : 'negative']}>
              {isPositiveForecast ? '+' : null}
              {`${forecast} ${t(`units.${unitLowerCased}`)}`}
            </p>
          )}
        />
      </div>
      <PrimaryButton onClick={handleClick}>
        {t(`planningForecast.${isPositiveForecast ? 'sell' : 'buy'}Now`)}
      </PrimaryButton>
    </div>
  )
}

export default memo(DayViewCard)
