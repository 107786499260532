import constate from 'constate'
import { useCallback, useState } from 'react'

type TModalType = 'UPDATE' | 'DELETE' | 'EDIT'

type TModalStateProps = {
  productStockId: string
  transactionId?: string
  type: TModalType
}

const useCropStockModalState = () => {
  const [modalState, setModalState] = useState<TModalStateProps>()

  return {
    modalState,
    setModalState,
    handleClose: useCallback(() => setModalState(undefined), []),
    deleteModal: useCallback(
      (
        productStockId: string,
        transactionId: string
      ) => setModalState({ productStockId, transactionId, type: 'DELETE' }),
      []
    ),
    updateModal: useCallback(
      (productStockId: string) => setModalState({ productStockId, type: 'UPDATE' }),
      []
    ),
    editModal: useCallback(
      (
        productStockId: string,
        transactionId: string
      ) => setModalState({ productStockId, transactionId, type: 'EDIT' }),
      []
    )
  }
}

export const [
  CropStockModalStateProvider,
  useCropStockModalContext
] = constate(useCropStockModalState)
