import { memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CustomSelectControl
  from '../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { usePlanningForecastContext } from '../../state/PlanningForecastState'

function useOptions() {
  const { t } = useTranslation()
  return useMemo(() => [
    {
      label: t('foodwaste.ALL'),
      value: 'ALL'
    },
    {
      label: t('foodwaste.SUB_QUANTITY'),
      value: 'SUB_QUANTITY'
    },
    {
      label: t('foodwaste.OVER_QUANTITY'),
      value: 'OVER_QUANTITY'
    }
  ], [])
}

const FoodWasteSelect = () => {
  const { formReturn: { control }, view, productLabel } = usePlanningForecastContext()
  const { t } = useTranslation()
  const options = useOptions()
  const isDisabled = view === 'WEEK' || !productLabel.data?.length
  return (
    <CustomSelectControl
      disabled={isDisabled}
      label={t('foodwaste.label')}
      control={control}
      name="foodWaste"
      options={options}/>
  )
}

export default memo(FoodWasteSelect)
