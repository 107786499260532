import { useTranslation } from 'react-i18next'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { useFormContext } from 'react-hook-form'
import styles from './BidOfferFooter.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import { useNotificationContext } from '../../../../notifications'
import { useGetProduct } from '../../../Offer'
import { useBidMutation, useBuyNowMutation } from '../../queries'
import { TOfferLocalData } from '../../../Offer/types'
import { OFFER_LOCAL_STORAGE_KEY } from '../../../../../shared/constants/localeStorage'
import { TForm } from '../../types'

function useOnSubmit() {
  const { getValues } = useFormContext()
  const { data } = useGetProduct()
  const { t } = useTranslation()
  const { showSuccessNotification } = useNotificationContext()
  const { mutateAsync: buyNowMutateAsync } = useBuyNowMutation()
  const { mutateAsync: bidMutateAsync } = useBidMutation()
  const navigate = useNavigate()
  const [
    offerLocalData, ,
    cleanLocaleStorage] = useLocalStorage<TOfferLocalData>(OFFER_LOCAL_STORAGE_KEY)
  const { negotiation } = data!

  const afterMutationAction = () => {
    navigate('/buy')
    showSuccessNotification(t('buy.bidOffer.successNotification'))
    cleanLocaleStorage()
  }
  return useCallback(() => {
    const baseParams = {
      ...getValues() as TForm,
      amountTu: offerLocalData?.amountTu!
    }
    if (negotiation) {
      return bidMutateAsync(
        { ...baseParams, pricePerCu: offerLocalData?.priceCHF! }
      )
        .then(afterMutationAction)
    }
    return buyNowMutateAsync(baseParams).then(afterMutationAction)
  }, [data])
}

const BidOfferFooter = () => {
  const { t } = useTranslation()
  const { data } = useGetProduct()
  const { negotiation } = data!
  const submit = useOnSubmit()
  return (
    <div className={styles.wrapper}>
      <div className={styles.line}/>
      <div className={styles.buttonWrap}>
        <PrimaryButton onClick={submit} text={t(`buy.bidOffer.footer.${negotiation ? 'placeMyBid' : 'buyNow'}`)}/>
      </div>
    </div>
  )
}

export default BidOfferFooter
