import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import CustomInput from '../formControls/CustomInput/CustomInput'
import { useValidationRules } from '../../hooks/useValidationRules'

const FirstName = () => {
  const { requiredRule } = useValidationRules()
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <CustomInput
      label={`${t('common.firstName')} *`}
      name="firstName"
      control={control}
      rules={{
        required: requiredRule()
      }}
    />
  )
}

export default FirstName
