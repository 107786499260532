import { useTranslation } from 'react-i18next'
import styles from './Start.module.scss'
import Navigation from '../Navigation/Navigation'

const Start = () => {
  const { t } = useTranslation()
  return (
    <div>
      <div>
        <h1 className={styles.title}>{t('start.together')}</h1>
        <div className={styles.descriptionWrapper}>
          <p className={styles.description}>{t('start.descriptionHeader')}</p>
          <div className={styles.description}>
            {t('start.descriptionBody')}
            {' '}
            <a
              target="_blank"
              rel="noreferrer"
              className={styles.navigation}
              href="https://www.freshtrade.ch/faq">
              https://www.freshtrade.ch/faq.
            </a>
          </div>
        </div>
      </div>
      <div className={styles.navigationWrapper}>
        <p className={styles.navigationTitle}>
          {t('start.startNow')}
        </p>
        <Navigation to="/growing-plan" text={t('start.growingPlan')}/>
        <Navigation to="/quantity-reports" text={t('start.quantityReports')}/>
        <Navigation to="/crop-stock" text={t('start.cropStock')}/>
      </div>
    </div>
  )
}

export default Start
