import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { useMemo } from 'react'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { useLabelsQuery } from '../../../../../admin/labels/queries'
import { useLabelListQuery } from '../../../../../growingPlan/BaseGrowingPlanWorkShop/queries'

function useOptions() {
  const { data } = useLabelListQuery()
  return useMemo(() => {
    if (!data) {
      return []
    }

    return data.map(({ id, name }) => ({
      value: id,
      label: name
    }))
  }, [data])
}
const LabelSelect = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <CustomSelectControl
      control={control}
      options={useOptions()}
      isRequired
      name="labelId"
      label={`${t('sell.workShop.selects.label.label')} *`}
      placeholder={t('sell.workShop.selects.label.placeholder') as string}
      handleError={(error) => (error as any).productId}
    />
  )
}

export default LabelSelect
