import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../Interceptor'
import { TDeliveryDays, TSettingsSeller, TUpdateSettingsSeller } from './types'

enum QueryKeys {
  DeliveryDays = 'DeliveryDays',
  DeliveryDaysUpdate = 'DeliveryDaysUpdate',
  SettingsSellers = 'SettingsSellers',
  SettingsSellersUpdate = 'SettingsSellersUpdate'
}

async function deliveryDays(): Promise<TDeliveryDays> {
  try {
    const response = await apiClient('app/settings/delivery-days')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useDeliveryDaysQuery = () => {
  const keys = [QueryKeys.DeliveryDays]

  return useQuery<TDeliveryDays, Error>(keys, deliveryDays)
}

async function deliveryDaysUpdate(deliveryDays: TDeliveryDays): Promise<void> {
  try {
    const response = await apiClient.put('app/settings/delivery-days', { ...deliveryDays })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useDeliveryDaysUpdateMutation = () => {
  const queryClient = useQueryClient()
  return useMutation<void, Error, TDeliveryDays>(
    (deliveryDays) => deliveryDaysUpdate(deliveryDays),
    {
      mutationKey: [QueryKeys.DeliveryDaysUpdate],
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.DeliveryDays])
    }
  )
}

async function getSettingsSellers(): Promise<TSettingsSeller[]> {
  try {
    const response = await apiClient('app/settings/sellers')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useSettingsSellersQuery = () => {
  const keys = [QueryKeys.SettingsSellers]

  return useQuery<TSettingsSeller[], Error>(keys, getSettingsSellers)
}

async function settingsSellersUpdate(data: TUpdateSettingsSeller[]) {
  try {
    await apiClient.put('app/settings/sellers', data)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useSettingsSellersMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, TUpdateSettingsSeller[]>(
    (data) => settingsSellersUpdate(data), {
      mutationKey: [QueryKeys.SettingsSellersUpdate],
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.SettingsSellers])
    }
  )
}
