import { useMutation } from '@tanstack/react-query'
import { TForgotPasswordForm, TResetPasswordForm } from './types'
import { apiClient } from '../../Interceptor'
import { useSearchParamsCode } from './hooks/useSearchParamsCode'
import { TLanguage } from '../../account/accountBase'
import { useLanguageSwitcherContext } from '../../languageSwitcher'

enum QueryKeys{
  ForgotPassword = 'ForgotPassword',
  ResetPassword = 'ChangePassword'
}

async function forgotPassword(params: TForgotPasswordForm, language: TLanguage): Promise<void> {
  try {
    await apiClient.post('/auth/forgot-password', { email: params.email }, {
      headers: {
        'Accept-Language': language
      }
    })
  } catch (err: any) {
    throw new Error(err)
  }
}

async function resetPassword(
  params: TResetPasswordForm,
  codeFormSearchParams: string,
  language: TLanguage
): Promise<void> {
  try {
    const response = await apiClient.post(
      `/auth/reset-password/?code=${codeFormSearchParams}`,
      params,
      {
        headers: {
          'Accept-Language': language
        }
      }
    )
    return response.data
  } catch (err: any) {
    throw new Error(err)
  }
}

export const useForgotPasswordMutation = () => {
  const { language } = useLanguageSwitcherContext()

  return useMutation<void, Error, TForgotPasswordForm>(
    (params) => forgotPassword(params, language),
    {
      mutationKey: [QueryKeys.ForgotPassword]
    }
  )
}

export const useResetPasswordMutation = () => {
  const codeFormSearchParams = useSearchParamsCode()
  const { language } = useLanguageSwitcherContext()

  return useMutation<void, Error, TResetPasswordForm>(
    (params) => resetPassword(params, codeFormSearchParams, language),
    {
      mutationKey: [QueryKeys.ResetPassword]
    }
  )
}
