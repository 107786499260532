import { useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { InnerCircleWorkShop, TForm } from '../../BaseInnerCircle'
import { useEditInnerCircleMutation, useGetSingleInnerCircleQuery } from '../queries'
import { prepareEditInnerCircle } from '../utils/prepareEditInnerCircle'

function useFormInit() {
  const { data } = useGetSingleInnerCircleQuery()

  return useForm<TForm>({
    defaultValues: useMemo(() => ({
      title: data?.title || '',
      description: data?.description || '',
      companyIds: data?.companies.map(({ id }) => id) || []

    }), [data])
  })
}
function useFormReset({ reset }: UseFormReturn<TForm>) {
  const { data } = useGetSingleInnerCircleQuery()
  useEffect(() => {
    if (!data) {
      return
    }
    const { title, description, companies } = data

    const companyIds = companies.map(({ id }) => id)
    reset({
      title,
      description,
      companyIds
    })
  }, [data])
}

function useOnSubmit() {
  const { mutateAsync } = useEditInnerCircleMutation()
  const navigate = useNavigate()
  const { data: innerCircleData } = useGetSingleInnerCircleQuery()

  return useCallback((data: TForm) => {
    const initialCompanyIds = innerCircleData?.companies.map(({ id }) => id) || []

    mutateAsync(prepareEditInnerCircle({
      initialCompanyIds,
      ...data
    }))
      .then(() => navigate('/inner-circle'))
  }, [innerCircleData])
}

const EditInnerCircle = () => {
  const { t } = useTranslation()
  const useFormReturn = useFormInit()
  useFormReset(useFormReturn)
  const onSubmit = useOnSubmit()

  return (
    <InnerCircleWorkShop
      label={t('innerCircle.edit.label')}
      useFormReturn={useFormReturn}
      onSubmit={onSubmit}/>
  )
}

export default EditInnerCircle
