import { Control } from 'react-hook-form'
import { FC } from 'react'
import FormLabel from '../../../../shared/components/FormLabel/FormLabel'
import styles from './LabelCheckbox.module.scss'
import CustomCheckbox from '../../../../shared/components/formControls/CustomCheckbox/CustomCheckbox'

type TProps = {
  label: string
  name: string
  control: Control<any>
}

const LabelCheckbox: FC<TProps> = ({ label, name, control }) => (
  <FormLabel
    className={styles.formLabel}
    label={label}
    labelPlacement="end"
  >
    <CustomCheckbox
      name={name}
      control={control}
      checkBoxStyle={styles.checkBox}
    />
  </FormLabel>
)

export default LabelCheckbox
