import { FC, memo } from 'react'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './WeekDayCard.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import { TNameId, TProductLabel } from '../../../../growingPlan/BaseGrowingPlanWorkShop'

type TProps = {
  day: string
  stock: number
  sell?: number
  buy?: number
  forecast: number
  noBorder: boolean
  unit: string
  productLabel: {
    product: TNameId,
    label: TProductLabel
  }
}
const WeekDayCard: FC<TProps> = ({
  day,
  stock,
  sell,
  forecast,
  noBorder,
  buy,
  unit,
  productLabel
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isPositiveForecast = forecast >= 0

  const handleClick = () => {
    if (isPositiveForecast) {
      navigate('/sell/create', { state: productLabel })
      return
    }
    navigate('/buy', { state: productLabel })
  }

  return (
    <div className={classNames(styles.wrapper, { [styles.noBorder]: noBorder })}>
      <p className={classNames(styles.label, styles.bold)}>{day}</p>
      <p className={styles.text}>{`${stock} ${unit}`}</p>
      {sell !== undefined ? <p className={styles.text}>{`${sell} ${unit}`}</p> : null}
      {buy !== undefined ? <p className={styles.text}>{`+${buy} ${unit}`}</p> : null}
      <p className={classNames(
        styles.forecastText,
        styles[isPositiveForecast ? 'positive' : 'negative']
      )}>
        {` ${isPositiveForecast ? '+' : ''} ${forecast} ${unit}`}
      </p>
      <div className={styles.buttonWrap}>
        <PrimaryButton onClick={handleClick}>
          {t(`planningForecast.${isPositiveForecast ? 'sell' : 'buy'}Now`)}
        </PrimaryButton>
      </div>
    </div>
  )
}
export default memo(WeekDayCard)
