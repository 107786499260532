import React, { FC, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import Modal from '../../../../../shared/components/Modal/Modal'
import styles from '../EditProductLabel/EditProduct.module.scss'
import ProductLabelFormContent from '../ProductLabelFormContent/ProductFormContent'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { useFormInit } from '../../hooks/formHooks'
import { useProductLabelContext } from '../../state/ProductLabelState'
import { useGetProductQuery } from '../../../products'
import { capitalizeFirstLetter } from '../../utils/baseAdminUtils'
import { TProductLabelTranslation } from '../../types'
import { useGetLabelQuery } from '../../../labels/queries'

function useOnSubmit(id: string, handleClose: () => void, { setError }: UseFormReturn<any>) {
  const { handleEdit } = useProductLabelContext()
  return useCallback((data: TProductLabelTranslation) => {
    handleEdit({ ...data, id })
      .then(handleClose)
      .catch((error: any) => {
        error?.errors.map(({ field, message }: any) =>
          setError(field, { message }))
      })
  }, [])
}

function useResetForm({ reset }: UseFormReturn<any>, data?: TProductLabelTranslation) {
  useEffect(() => {
    if (data) {
      reset(data)
    }
  }, [data])
}

type TProps = {
  id: string
  isModal: boolean
  handleClose: () => void
}
const EditProductLabelModal: FC<TProps> = ({ id, isModal, handleClose }) => {
  const { t } = useTranslation()
  const { type } = useProductLabelContext()
  const { data: labelData } = useGetLabelQuery(isModal && type === 'label' ? id : undefined)
  const { data: productData } = useGetProductQuery(isModal && type === 'product' ? id : undefined)

  const editData = type === 'product' ? productData : labelData

  const formReturn = useFormInit(editData?.localizations)
  useResetForm(formReturn, editData?.localizations)
  const handleSubmit = useOnSubmit(id, handleClose, { ...formReturn })

  if (!isModal || !editData) {
    return <></>
  }

  const title = t(`admin.${type}.edit${capitalizeFirstLetter(type)}`)

  return (
    <Modal isOpen={isModal} onClose={handleClose}>
      <form onSubmit={formReturn.handleSubmit(handleSubmit)}>
        <h1 className={styles.formTitle}>{title}</h1>
        <ProductLabelFormContent control={formReturn.control} />
        <div className={styles.line}/>
        <div className={styles.buttonGroup}>
          <PrimaryButton className={styles.button} type="submit">{t('common.save')}</PrimaryButton>
          <SecondaryButton className={styles.button} onClick={handleClose}>{t('common.cancel')}</SecondaryButton>
        </div>
      </form>
    </Modal>
  )
}

export default EditProductLabelModal
