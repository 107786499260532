import { useNavigate } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TAccountEditMutationParams } from './types'

enum QueryKeys{
  AccountEdit = 'AccountEdit'
}

async function accountEdit(data: TAccountEditMutationParams) {
  const { userId, ...rest } = data
  try {
    await apiClient.put(`/users/${userId}`, { ...rest })
  } catch (e: any) {
    throw new Error(e)
  }
}

export function useEditAccountMutation() {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  return useMutation<void, Error, TAccountEditMutationParams>(
    (data) => accountEdit(data),
    {
      mutationKey: [QueryKeys.AccountEdit],
      onSuccess: async () => {
        await queryClient.invalidateQueries(['Account'])
        navigate('/my-account')
      }
    }
  )
}
