import { Control } from 'react-hook-form'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { TOption } from '../../../../../shared/components/formControls/CustomSelect/CustomSelectBase/CustomSelectBase'
import CustomSelectControl
  from '../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import styles from './TradeSelect.module.scss'
import CloseFillIcon from '../../../../../shared/components/icons/CloseCircleIcon'

type TProps = {
  control: Control<any>
  name: string
  options: TOption[]
  disabled?: boolean
  reset?: () => void
}
const TradeSelect: FC<TProps> = ({
  control,
  name,
  options,
  disabled,
  reset
}) => {
  const { t } = useTranslation()
  return (
    <div className={styles.selectWrap}>
      <div className={styles.select}>
        <CustomSelectControl
          disabled={disabled}
          control={control}
          options={options}
          name={name}
          placeholder={t('growingPlan.workShop.selectCompanyPlaceholder')}
          label={`${t(`quantity.selects.${name}.label`)}`}
        />
      </div>

      {reset && (
        <button type="button" onClick={reset} className={styles.iconWrap}>
          <CloseFillIcon width={20} height={20}/>
        </button>
      )}
    </div>
  )
}

export default TradeSelect
