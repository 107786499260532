import { FormProvider, UseFormReturn } from 'react-hook-form'
import { FC } from 'react'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import styles from './SellWorkShop.module.scss'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import ProductInformation from '../ProductInformation/ProductInformation'
import AmountPricing from '../AmountPricing/AmountPricing'
import SellWorkShopFooter from '../SellWorkShopFooter/SellWorkShopFooter'
import SellingOptions from '../SellingOptions/SellingOptions'
import { TForm, TOfferParams } from '../../types'

type TProps = {
  useFormReturn: UseFormReturn<TForm>
  label: string
  onSubmit: UseMutateAsyncFunction<void, Error, {data: TOfferParams, publish: boolean}>
}
const SellWorkShop: FC<TProps> = ({ onSubmit, useFormReturn, label }) => (
  <PrimaryLayout
    maxHeight
    noPadding
    size="large"
    header={<div className={styles.label}>{label}</div>}>
    <FormProvider {...useFormReturn}>
      <ProductInformation/>
      <SellingOptions/>
      <AmountPricing/>
      <SellWorkShopFooter onSubmit={onSubmit}/>
    </FormProvider>
  </PrimaryLayout>
)

export default SellWorkShop
