import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import { useInnerCircleListQuery } from '../../queries'
import NoData from '../../../../../shared/components/NoData/NoData'
import InnerCircleListHeader from '../InnerCircleHeader/InnerCircleHeader'
import InnerCircleOverview from '../InnerCircleOverview/InnerCircleOverview'
import Spinner from '../../../../../shared/components/Spinner/Spinner'
import styles from './InnerCircleList.module.scss'

const InnerCircleList = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data } = useInnerCircleListQuery()
  if (!data) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }
  return (
    <PrimaryLayout
      maxHeight
      size="large"
      header={<InnerCircleListHeader isCreateButton={!!data.length}/>}
    >
      {
        data.length
          ? <InnerCircleOverview/>
          : (
            <NoData
              handleClick={() => navigate('/inner-circle/create')}
              title={t('innerCircle.list.noList')}
              buttonText={t('innerCircle.list.addInnerCircle')}
            />
          )
      }
    </PrimaryLayout>
  )
}

export default InnerCircleList
