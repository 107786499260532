import constate from 'constate'
import { useForm } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { useProductListMutation } from '../queries'
import { TForm, TLocationState } from '../types'

function useFormInit() {
  const { state } = useLocation() as {state: TLocationState}
  return useForm<TForm>({
    defaultValues: {
      searchString: state?.product?.name || ''
    }
  })
}
const OffersState = () => ({
  productListMutation: useProductListMutation(),
  useFormReturn: useFormInit()
})

export const [OffersProvider, useOffersContext] = constate(OffersState)
