import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { FC, useCallback, useMemo } from 'react'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import styles from './SellWorkShopFooter.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { useNotificationContext } from '../../../../notifications'
import { useProductListQuery } from '../../../../growingPlan/BaseGrowingPlanWorkShop/queries'
import { TForm, TOfferParams } from '../../types'
import { prepareInnerCircles, prepareOffer } from '../../utils/prepareOffer'
import { useInnerCircleListFinderQuery, useUploadImageSellMutation } from '../../queries'
import { prepareAttachment } from '../../../../attechment'

type TProps = {
  onSubmit: UseMutateAsyncFunction<void, Error, {data: TOfferParams, publish: boolean}>
}

function usePrepareData() {
  const { mutateAsync } = useUploadImageSellMutation()
  const { data: innerCircleListData } = useInnerCircleListFinderQuery()

  return useCallback(async (data: TForm) => {
    if (!innerCircleListData) {
      return undefined
    }
    const partialPrepareData = prepareOffer(data) as TOfferParams
    const innerCircle = prepareInnerCircles(innerCircleListData, data.innerCircle)
    const isOpenMarket = data.publishFor === 'OPEN_MARKET'
    const preparedData = {
      ...partialPrepareData,
      innerCircle: isOpenMarket ? {
        innerCircleIds: [],
        companyIds: []
      } : innerCircle
    }
    if (data.file) {
      await mutateAsync(prepareAttachment(data.file)).then((key) => {
        preparedData.imageKey = key
      })
    }

    return preparedData
  }, [innerCircleListData, mutateAsync])
}
function useProductName() {
  const { watch } = useFormContext()
  const { productId } = watch()
  const { data } = useProductListQuery()
  return useMemo(() => {
    if (!data) {
      return undefined
    }
    return data?.find(({ id }) => id === productId)?.name
  }, [data, productId])
}

function useOnSubmitPublish(onSubmit: TProps['onSubmit']) {
  const navigate = useNavigate()
  const prepareData = usePrepareData()
  const { showSuccessNotification } = useNotificationContext()
  const { data: innerCircleListData } = useInnerCircleListFinderQuery()
  const { t } = useTranslation()
  const productName = useProductName()

  return useCallback(
    async (data: TForm) => {
      const preparedData = await prepareData(data)
      if (!preparedData) {
        return
      }

      await onSubmit({ data: preparedData!, publish: true }).then(() => {
        navigate('/sell')
        showSuccessNotification(t('sell.workShop.publishMessage', { productName }))
      })
    },
    [productName, innerCircleListData]
  )
}
function useOnSubmitSave(onSubmit: TProps['onSubmit']) {
  const navigate = useNavigate()
  const prepareData = usePrepareData()
  const { showSuccessNotification } = useNotificationContext()
  const { t } = useTranslation()
  const productName = useProductName()

  return useCallback(async (data: TForm) => {
    const preparedData = await prepareData(data)
    if (!preparedData) {
      return
    }
    await onSubmit({ data: preparedData, publish: false }).then(() => {
      navigate('/sell')
      showSuccessNotification(t('sell.workShop.saveMessage', { productName }))
    })
  },
  [productName])
}

const SellWorkShopFooter: FC<TProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const { handleSubmit } = useFormContext<TForm>()
  const navigate = useNavigate()
  const submitPublish = useOnSubmitPublish(onSubmit)
  const submitSave = useOnSubmitSave(onSubmit)

  return (
    <div className={styles.wrapper}>
      <div className={styles.line}/>
      <div className={styles.buttonGroup}>
        <div className={styles.buttonGroupMain}>
          <PrimaryButton
            type="submit"
            onClick={handleSubmit(submitPublish)}
            text={t('sell.workShop.publish')}/>
          <SecondaryButton
            onClick={handleSubmit(submitSave)}
            text={t('sell.workShop.saveForLater')}/>
        </div>
        <div className={styles.cancelButtonWrap}>
          <SecondaryButton onClick={() => navigate('/sell')} text={t('common.cancel')}/>
        </div>
      </div>
    </div>
  )
}

export default SellWorkShopFooter
