import { FC, useEffect } from 'react'
import ActiveAccordionSummary from '../ActiveAccordionSummary/ActiveAccordionSummary'
import styles from './AccordionSummary.module.scss'
import InActiveAccordionSummary from '../InActiveAccordionSummary/InActiveAccordionSummary'
import { useBaseQuantityFormContext } from '../../state/BaseQuantityFormState'
import { useValidationRules } from '../../../../../shared/hooks/useValidationRules'
import { TAccordionSummaryField } from '../../types'

const accordionSummaryFields: (keyof TAccordionSummaryField)[] = [
  'productId',
  'cu',
  'labelId',
  'tu',
  'lu',
  // 'amountUnitPerCu',
  'amountCuPerTu',
  'amountTuPerLu'
]
function useRegisterField(
  quantityIndex: number,
  isActive: boolean
) {
  const { formReturn: { register } } = useBaseQuantityFormContext()
  const { requiredRule } = useValidationRules()
  const baseName = `orderItems[${quantityIndex}]`

  useEffect(() => {
    if (isActive) {
      return
    }
    accordionSummaryFields.forEach((fieldName) => (
      register(`${baseName}.${fieldName}`, { required: requiredRule() })
    ))
  }, [baseName, isActive, quantityIndex, register, requiredRule])
}

type TProps = {
  icon: JSX.Element,
  isActive: boolean
  quantityIndex: number
}
const AccordionSummary: FC<TProps> = ({ icon, isActive, quantityIndex }) => {
  const {
    formReturn: { control },
    fieldArrayReturn: { fields }
  } = useBaseQuantityFormContext()

  useRegisterField(quantityIndex, isActive)

  const isQuantities = fields.length > 1

  return (
    <div className={styles.wrapper}>
      {
        isActive
          ? (
            <ActiveAccordionSummary
              icon={icon}
              quantityIndex={quantityIndex}
              control={control}
            />
          )
          : (
            <InActiveAccordionSummary
              icon={icon}
              isQuantities={isQuantities}
              quantityIndex={quantityIndex}
            />
          )
      }
    </div>
  )
}

export default AccordionSummary
