import { useTranslation } from 'react-i18next'
import { FC, SyntheticEvent, useCallback } from 'react'
import Modal from '../../../../../shared/components/Modal/Modal'
import DangerousButton from '../../../../../shared/components/buttons/DangerousButton/DangerousButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import styles from './DeleteModal.module.scss'
import { useDeleteInnerCircleMutation } from '../../queries'
import { TInnerCircleDeleteParams } from '../../types'

function useDeleteInnerCircle(resetInnerCircleDeleteParams: () => void, innerCircleId?: string) {
  const { mutateAsync } = useDeleteInnerCircleMutation()

  return useCallback(
    (event: SyntheticEvent) => {
      event.stopPropagation()
      if (!innerCircleId) {
        return undefined
      }
      return mutateAsync(innerCircleId).then(resetInnerCircleDeleteParams)
    },
    [innerCircleId]
  )
}

type TProps = {
  resetInnerCircleDeleteParams: () => void
  innerCircleDeleteParams?: TInnerCircleDeleteParams
}
const DeleteModal: FC<TProps> = ({ resetInnerCircleDeleteParams, innerCircleDeleteParams }) => {
  const { t } = useTranslation()

  const handleDelete = useDeleteInnerCircle(
    resetInnerCircleDeleteParams,
    innerCircleDeleteParams?.id
  )

  const handleClose = (event: SyntheticEvent) => {
    event.stopPropagation()
    resetInnerCircleDeleteParams()
  }

  if (!innerCircleDeleteParams) {
    return null
  }

  const { title, id } = innerCircleDeleteParams
  return (
    <Modal
      isOpen={!!id}
      onClose={handleClose}>
      <div className={styles.wrapper}>
        <h1 className={styles.label}>
          {`${t('innerCircle.list.delete.label')} ${title}`}
        </h1>
        <div className={styles.description}>
          {t('innerCircle.list.delete.description')}
          <span className={styles.bold}>
            {` ${title}? `}
          </span>
        </div>
        <div className={styles.line}/>
        <div className={styles.buttonGroup}>
          <DangerousButton onClick={handleDelete} text={t('common.delete')}/>
          <SecondaryButton onClick={handleClose} text={t('common.cancel')}/>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal
