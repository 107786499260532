import { useTranslation } from 'react-i18next'
import styles from './GrowingPlanOverview.module.scss'
import { useGrowingPlanListQuery } from '../../queries'
import GrowingPlanAccordion from '../GrowingPlanAccordion/GrowingPlanAccordion'
import GrowingPlansAmount from '../GrowingPlansAmount/GrowingPlansAmount'

const GrowingPlanOverview = () => {
  const { t } = useTranslation()
  const { data } = useGrowingPlanListQuery()

  if (!data) {
    return null
  }
  return (
    <div>
      <GrowingPlansAmount/>
      <h3 className={styles.labelValid}>{t('growingPlan.validOverview')}</h3>
      <div className={styles.accordionWrapper}>
        {
          data.activePlans?.map(
            (props) => (
              <GrowingPlanAccordion
                key={props.id}
                isPaddingSmall={!(!props.currentUserSeller && props.weeklyOrderExists)}
                {...props}
              />
            )
          )
        }
      </div>
      {data.inactivePlans?.length
        ? <h3 className={styles.labelNotValid}>{t('growingPlan.notValidOverview')}</h3>
        : null}
      <div className={styles.accordionWrapper}>
        {
          data.inactivePlans?.map(
            (props) => (
              <GrowingPlanAccordion
                key={props.id}
                isArchived
                isPaddingSmall={false}
                {...props} />
            )
          )
        }
      </div>
    </div>
  )
}

export default GrowingPlanOverview
