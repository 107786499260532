import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useMemo } from 'react'
import { useLocalStorage } from 'react-use'
import styles from './ServiceFee.module.scss'
import { useCalculateTotal } from '../../hooks/useCalculateTotal'
import { HUNDRED_PERCENTAGE } from '../../../../../shared/constants/percentage'
import { roundNumber } from '../../../../../shared/utils/calculationUtils'
import { useGetProduct } from '../../../Offer'
import { TOfferLocalData } from '../../../Offer/types'
import { OFFER_LOCAL_STORAGE_KEY } from '../../../../../shared/constants/localeStorage'
import { addZeros } from '../../../../../shared/utils/formUtils'

const SERVICE_FEE = 0.5
const VAT = 7.7

function useCalculations() {
  const { data } = useGetProduct()
  const priceProduct = useCalculateTotal()
  const [offerLocalData] = useLocalStorage<TOfferLocalData>(OFFER_LOCAL_STORAGE_KEY)

  const { minDiscountAmountTu, discount } = data!
  const { amountTu } = offerLocalData!

  return useMemo(() => {
    const isDiscount = !!((Number(amountTu) >= Number(minDiscountAmountTu)) && discount)
    const discounted = roundNumber(
      priceProduct * ((HUNDRED_PERCENTAGE - (discount || 0)) / HUNDRED_PERCENTAGE)
    )
    const discountAppliedPrice = isDiscount ? discounted : priceProduct

    const discountPrice = isDiscount ? addZeros(roundNumber(discounted - priceProduct)) : '-'
    const serviceFee = roundNumber(discountAppliedPrice * (SERVICE_FEE / HUNDRED_PERCENTAGE))
    const netPrice = roundNumber(discountAppliedPrice + serviceFee)
    const vat = roundNumber(netPrice * (VAT / HUNDRED_PERCENTAGE))
    const totalPrice = roundNumber(netPrice + vat)

    return {
      discountPrice: discountPrice === '-' ? discountPrice : addZeros(discountPrice),
      serviceFee: addZeros(serviceFee),
      netPrice: addZeros(netPrice),
      vat: addZeros(vat),
      totalPrice: addZeros(totalPrice)
    }
  }, [])
}
const ServiceFee = () => {
  const { t } = useTranslation()
  const { serviceFee, vat, totalPrice, netPrice, discountPrice } = useCalculations()
  const { data } = useGetProduct()
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.serviceFee}>
          <p className={styles.serviceFeeText}>
            {t('buy.bidOffer.serviceFee.discount')}
          </p>
          <div className={styles.serviceFeeText}>
            {t('buy.bidOffer.serviceFee.serviceFee')}
            {' '}
            (
            <a
              className={styles.navigation}
              href="https://www.freshtrade.ch/servicefee"
              target="blank">
              {t('buy.bidOffer.serviceFee.details')}
            </a>
            )
          </div>
          <p className={styles.serviceFeeText}>
            {t('buy.bidOffer.serviceFee.netAmount')}
          </p>
          <p className={styles.serviceFeeText}>
            {t('buy.bidOffer.serviceFee.vat')}
          </p>
        </div>
        <div className={styles.middleContent}>
          <div className={classNames(styles.middleContentText, styles.green)}>
            {`${data?.discount || '-'}%`}
          </div>
          <div className={styles.middleContentText}>
            {`${SERVICE_FEE}%`}
          </div>
        </div>
        <div className={styles.totalPrice}>
          <p className={styles.serviceFeeText}>{`${t('common.chf')} ${discountPrice}`}</p>
          <p className={styles.serviceFeeText}>{`${t('common.chf')} ${serviceFee}`}</p>
          <p className={styles.serviceFeeText}>{`${t('common.chf')} ${netPrice}`}</p>
          <p className={styles.serviceFeeText}>{`${t('common.chf')} ${vat}`}</p>
        </div>
      </div>
      <div className={styles.line}/>
      <div className={styles.totalContent}>
        <p className={styles.title}>{t('buy.bidOffer.serviceFee.total')}</p>
        <p className={classNames(styles.serviceFeeText, styles.bold)}>{`${t('common.chf')} ${addZeros(totalPrice)}`}</p>
      </div>
      <div className={styles.description}>
        {t('buy.bidOffer.serviceFee.policy')}
        {' '}
        <a className={styles.navigation} target="_blank" href="https://www.freshtrade.ch/terms" rel="noreferrer">
          {t('buy.bidOffer.serviceFee.conditions')}
        </a>
        {' '}
        {t('buy.bidOffer.serviceFee.and')}
        {' '}
        <a className={styles.navigation} target="_blank" href="https://www.freshtrade.ch/privacy" rel="noreferrer">
          {t('buy.bidOffer.serviceFee.privacyPolicy')}
        </a>
      </div>
    </div>
  )
}

export default ServiceFee
