import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { useLabelListQuery } from '../../../../../growingPlan/BaseGrowingPlanWorkShop/queries'

function useOptions() {
  const { data } = useLabelListQuery()
  return useMemo(() => {
    if (!data) {
      return []
    }
    return data.map(({ id, name }) => ({
      value: id,
      label: name
    }))
  }, [data])
}

type TProps = {
  control: Control<any>
}
const LabelSelect: FC<TProps> = ({ control }) => {
  const { t } = useTranslation()
  const options = useOptions()
  return (
    <CustomSelectControl
      control={control}
      options={options}
      isRequired
      name="labelId"
      label={`${t('cropStock.common.label')} *`}
      placeholder={t('cropStock.create.selectLabel') as string}
      handleError={(error) => (error as any).label}
    />
  )
}

export default memo(LabelSelect)
