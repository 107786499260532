import format from 'date-fns/format'
import { eachDayOfInterval, endOfWeek, parse, startOfWeek } from 'date-fns'
import { DOT_DATE_FORMAT, ISO_DOT_DATE_TIME_FORMAT } from '../constants/date'

export function dotDateFormat(date: Date) {
  return format(date, DOT_DATE_FORMAT)
}

export function dateFromDotDateFormat(date: string) {
  return parse(date, DOT_DATE_FORMAT, new Date())
}

export function dateFromDotDateTimeFormat(date: string) {
  return parse(date, ISO_DOT_DATE_TIME_FORMAT, new Date())
}

export function weekDays(date: Date) {
  const weekStart = startOfWeekCustom(date)
  const weekEnd = endOfWeekCustom(date)
  return eachDayOfInterval({ start: weekStart, end: weekEnd })
}

export function startOfWeekCustom(date: Date) {
  return startOfWeek(date, { weekStartsOn: 1 })
}

export function endOfWeekCustom(date: Date) {
  return endOfWeek(date, { weekStartsOn: 1 })
}
