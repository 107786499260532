import { useCallback } from 'react'
import { TProductLabel } from '../../../growingPlan/BaseGrowingPlanWorkShop'
import { TOption } from '../../../../shared/components/formControls/CustomSelect/CustomSelectBase/CustomSelectBase'
import { useLabelListQuery } from '../../../growingPlan/BaseGrowingPlanWorkShop/queries'

export function usePrepareLabelSelectOption() {
  const { data } = useLabelListQuery()

  return useCallback((
    labels: TProductLabel[],
    existingProductLabels: string[]
  ) => labels.map((label) => {
    if (!existingProductLabels.includes(label.id)) {
      return {
        value: label?.id,
        label: label.name
      }
    }
    return undefined
  }).filter(Boolean) as TOption[],
  [data])
}
