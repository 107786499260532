import { TOffer, TForm } from '../../BaseOffer'
import { dateFromDotDateFormat, dateFromDotDateTimeFormat } from '../../../../shared/utils/date'
import { addZeros } from '../../../../shared/utils/formUtils'

export const prepareOfferForm = (data: TOffer): TForm => {
  const {
    id,
    product,
    pickUpDateTime,
    innerCircle,
    bestBefore,
    packageDate,
    anonymous,
    negotiation,
    pricePerCu,
    minPricePerCu,
    label,
    ...rest
  } = data
  const innerCircles = innerCircle.innerCircles.map(({ id }) => id)
  const companies = innerCircle.companies.map(({ id }) => id)

  return {
    productId: product.id,
    labelId: label.id,
    packageDate: packageDate ? dateFromDotDateFormat(packageDate) : undefined,
    bestBefore: bestBefore ? dateFromDotDateFormat(bestBefore) : undefined,
    pickUpDateTime: dateFromDotDateTimeFormat(pickUpDateTime),
    innerCircle: [...innerCircles, ...companies],
    anonymous: anonymous ? 'YES' : 'NO',
    negotiation: negotiation ? 'YES' : 'NO',
    minPricePerCu: addZeros(minPricePerCu || 0),
    pricePerCu: addZeros(pricePerCu || 0),
    ...rest
  }
}
