import { useTranslation } from 'react-i18next'
import styles from './GrowingPlansAmount.module.scss'
import { useGrowingPlanDetailsContext } from '../../state/GrowingPlanListState'
import AmountCard from '../AmountCard/AmountCard'
import MonthSelect from '../MonthSelect/MonthSelect'
import { useGrowingPlanSummary } from '../../queries'
import Spinner from '../../../../../shared/components/Spinner/Spinner'

const GrowingPlansAmount = () => {
  const { formReturn: { control, watch } } = useGrowingPlanDetailsContext()
  const monthFormValue = watch('month')
  const month = monthFormValue === 'ALL' ? undefined : monthFormValue
  const { data } = useGrowingPlanSummary(month)
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div className={styles.selectGroup}>
        <p className={styles.label}>{t('growingPlan.amount')}</p>
        <div className={styles.selectWrap}>
          <MonthSelect control={control}/>
        </div>
      </div>
      <div className={styles.content}>
        {
          data
            ? data.map(({
              product,
              gpTotalAmount,
              sellAmount,
              buyAmount,
              label,
              unit
            }) => (
              <AmountCard
                key={`${product.id}_${label.id}_${gpTotalAmount}`}
                unit={unit}
                label={label}
                product={product}
                growingPlan={gpTotalAmount}
                buy={buyAmount}
                sell={sellAmount}/>
            ))
            : (
              <div className={styles.spinnerWrapper}>
                <Spinner/>
              </div>
            )
        }
      </div>
    </div>
  )
}

export default GrowingPlansAmount
