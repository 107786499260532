import { useQuery } from '@tanstack/react-query'
import { TForecastDayParams, TForecastDayResponse, TPlanningForecast } from './types'
import { apiClient } from '../../Interceptor'

enum QueryKeys{
  ForecastPerDay = 'ForecastPerDay',
  PlanningForecast = 'PlanningForecast'
}

async function forecastDay({
  date,
  products,
  foodWaste
}: TForecastDayParams): Promise<TForecastDayResponse[]> {
  try {
    const response = await apiClient(`/app/analytics/forecast/day?date=${date}&foodWaste=${foodWaste}${products}`)
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useForecastDayQuery = (params: TForecastDayParams) => {
  const keys = [
    QueryKeys.ForecastPerDay,
    params.products?.length,
    params.date,
    params.foodWaste
  ]
  return useQuery<TForecastDayResponse[], Error>(keys,
    () => forecastDay(params),
    {
      enabled: params.products !== undefined
    })
}

async function planningForecast(
  date: string,
  products: string
): Promise<TPlanningForecast[]> {
  try {
    const response = await apiClient(`/app/analytics/forecast/week?date=${date}${products}`)
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const usePanningForecastQuery = (date: string, products?: string) => {
  const keys = [QueryKeys.PlanningForecast, date, products?.length]
  return useQuery<TPlanningForecast[], Error>(keys, () => planningForecast(date, products!), {
    enabled: products !== undefined
  })
}
