import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './EmailConfirmation.module.scss'

const EmailConfirmation = () => {
  const { t } = useTranslation()
  return (
    <div className={styles.wrapper}>
      <div className={styles.card}>
        <h3 className={styles.title}>{t('emailConfirm.title')}</h3>
        <p className={styles.description}>{t('emailConfirm.decision')}</p>
        <p className={styles.description}>{t('emailConfirm.inform')}</p>
      </div>
    </div>
  )
}
export default memo(EmailConfirmation)
