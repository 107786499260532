import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import UserDetails from '../../UserDetails/UserDetails'
import PrimaryButton from '../../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import styles from './DeactivatedActions.module.scss'
import { useUserStatusMutation } from '../../../queries'

type TProps = {
  id: string
}
const DeactivatedActions: FC<TProps> = ({ id }) => {
  const { t } = useTranslation()
  const { mutate, isLoading } = useUserStatusMutation()
  const handleActivate = () => {
    mutate({ userId: id, status: 'ACTIVE' })
  }
  return (
    <>
      <UserDetails id={id}/>
      <PrimaryButton disabled={isLoading} className={styles.button} onClick={handleActivate}>{t('admin.users.table.activate')}</PrimaryButton>
    </>
  )
}

export default DeactivatedActions
