import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './InnerCircleWorkShop.module.scss'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import { TForm } from '../../types'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import CustomInput from '../../../../../shared/components/formControls/CustomInput/CustomInput'
import CustomTextArea from '../../../../../shared/components/formControls/CustomTextArea/CustomTextArea'
import { useValidationRules } from '../../../../../shared/hooks/useValidationRules'
import CompaniesChipSelect
  from '../CompaniesChipSelect/CompaniesChipSelect'

type TProps = {
  label: string
  useFormReturn: UseFormReturn<TForm>
  onSubmit: (data: TForm) => void
}
const InnerCircleWorkShop: FC<TProps> = ({ useFormReturn, onSubmit, label }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { requiredRule } = useValidationRules()
  const { handleSubmit, control } = useFormReturn
  return (
    <div>
      <PrimaryLayout
        noPadding
        size="large"
        header={<div className={styles.label}>{label}</div>}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.fieldGroup}>
            <div className={styles.inputTitleWrapper}>
              <CustomInput
                control={control}
                name="title"
                rules={{
                  required: requiredRule()
                }}
                label={`${t('innerCircle.create.title')} *`}
              />
            </div>
            <div className={styles.textareaWrapper}>
              <CustomTextArea
                control={control}
                name="description"
                label={t('innerCircle.create.description')} />
            </div>
            <CompaniesChipSelect control={control}/>
          </div>
          <div className={styles.line}/>
          <div className={styles.buttonGroup}>
            <PrimaryButton type="submit" text={t('common.save')}/>
            <SecondaryButton onClick={() => navigate('/inner-circle')} text={t('common.cancel')}/>
          </div>
        </form>
      </PrimaryLayout>
    </div>
  )
}

export default InnerCircleWorkShop
