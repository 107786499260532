import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-locize-backend'
// import Backend from 'i18next-http-backend'
// @ts-ignore
import LastUsed from 'locize-lastused'
// @ts-ignore
import { locizePlugin } from 'locize'
import translationEN from './en/translation.json'

// The API key should only be used in development,
// not in production.
// You should not expose your apps API key to production!!!
const locizeOptions = {
  projectId: process.env.REACT_APP_LOCIZE_ID!,
  apiKey: process.env.REACT_APP_LOCIZE_KEY,
  referenceLng: 'en'
}

const isReactAppInProduction = !!process.env.REACT_APP_PRODUCTION
const isProduction = process.env.NODE_ENV === 'production'

i18n
  // i18next-locize-backend
  // loads translations from your project, saves new keys to it (saveMissing: true)
  // https://github.com/locize/i18next-locize-backend
  .use(Backend)
  // locize-lastused
  // sets a timestamp of last access on every translation segment on locize
  // -> safely remove the ones not being touched for weeks/months
  // https://github.com/locize/locize-lastused
  .use(LastUsed)
  // locize-editor
  // InContext Editor of locize
  .use(locizePlugin)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: !isReactAppInProduction,
    fallbackLng: 'en',
    saveMissing: isProduction, // you should not use saveMissing in production
    // // keySeparator: false,
    // resources: {
    //   en: {
    //     translation: translationEN
    //   }
    // },
    lng: 'de',
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    backend: locizeOptions,
    locizeLastUsed: locizeOptions
  })

export default i18n
