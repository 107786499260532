import { FC, SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { TInnerCircleDeleteParams } from '../../types'
import DeleteModal from '../DeleteModal/DeleteModal'

const DeleteInnerCircle: FC<TInnerCircleDeleteParams> = ({ title, id }) => {
  const { t } = useTranslation()
  const [innerCircleDeleteParams, setInnerCircleDeleteParams] = useState<TInnerCircleDeleteParams>()
  const handleDelete = (event: SyntheticEvent) => {
    event.stopPropagation()
    setInnerCircleDeleteParams({ title, id })
  }
  return (
    <>
      <DeleteModal
        resetInnerCircleDeleteParams={() => setInnerCircleDeleteParams(undefined)}
        innerCircleDeleteParams={innerCircleDeleteParams}
      />
      <SecondaryButton
        onClick={handleDelete}
        text={t('common.delete')}/>
    </>
  )
}

export default DeleteInnerCircle
