import { useTranslation } from 'react-i18next'
import styles from './Footer.module.scss'
import InnerLayout from '../Inner/InnerLayout'
import { LanguageSwitcher } from '../../../../features/languageSwitcher'

const Footer = () => {
  const { t } = useTranslation()
  return (
    <InnerLayout borderTopLine contentClass={styles.innerContent}>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          <p className={styles.label}>
            {t('mainLayout.footer.label')}
          </p>
          <div className={styles.navigationContainer}>
            <a
              className={styles.navigate}
              target="_blank"
              href="https://www.freshtrade.ch/terms"
              rel="noreferrer">
              {t('mainLayout.footer.terms')}
            </a>
            <a
              className={styles.navigate}
              target="_blank"
              href="https://www.freshtrade.ch/privacy"
              rel="noreferrer">
              {t('mainLayout.footer.privacy')}
            </a>
            <a
              className={styles.navigate}
              target="_blank"
              href="https://www.freshtrade.ch/about"
              rel="noreferrer">
              {t('mainLayout.footer.aboutUs')}
            </a>
          </div>
        </div>
        <LanguageSwitcher/>
      </div>
    </InnerLayout>

  )
}

export default Footer
