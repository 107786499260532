import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { TLoginForm } from './types'
import { TAuthData, useAuthContext, useRedirect } from '../authBase'
import { apiClient } from '../../Interceptor'
import { useLanguageSwitcherContext } from '../../languageSwitcher'
import { TLanguage } from '../../account/accountBase'

enum QueryKeys{
  Login = 'Login'
}

async function login(params: TLoginForm, language: TLanguage): Promise<TAuthData> {
  // eslint-disable-next-line no-useless-catch
  try {
    const response = await apiClient.post('/auth/signin', params, {
      headers: {
        'Accept-Language': language
      }
    })
    return response.data
  } catch (error: any) {
    throw error
  }
}

export function useLoginMutation() {
  const { setAuthData } = useAuthContext()
  const navigate = useNavigate()
  const redirect = useRedirect()
  const { language } = useLanguageSwitcherContext()
  return useMutation<TAuthData, Error, TLoginForm>(
    (params) => login(params, language),
    {
      mutationKey: [QueryKeys.Login],
      onSuccess: (data) => {
        const isAdmin = data.user?.role === 'ADMIN'
        setAuthData(data)
        navigate(isAdmin ? '/admin/users' : redirect || '/quantity-reports')
      }
    }
  )
}
