import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import styles from './SellingOptions.module.scss'
import NegotiationRadio from '../radios/NegotiationRadio/NegotiationRadio'
import DeliveryRadio from '../radios/DeliveryRadio/DeliveryRadio'
import AnonymousOfferRadio from '../radios/AnonymousOfferRadio/AnonymousOfferRadio'

import CustomDateTimePicker
  from '../../../../../shared/components/formControls/CustomDateTimePicker/CustomDateTimePicker'
import Publish from '../Publish/Publish'

const SellingOptions = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>
        {t('sell.workShop.sellingOptions.label')}
      </h1>
      <div className={styles.content}>
        <Publish/>
        <DeliveryRadio/>
        <div className={styles.pickerGroup}>
          <CustomDateTimePicker
            label={`${t('sell.workShop.productInformation.datePickers.pickUpDate')} *`}
            control={control}
            name="pickUpDateTime"/>
        </div>
        <NegotiationRadio/>
        <AnonymousOfferRadio/>
      </div>
    </div>
  )
}

export default SellingOptions
