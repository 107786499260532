import { NavLink, useNavigate } from 'react-router-dom'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './AdminNavigationMenu.module.scss'
import DropDownMenu from '../DropDownMenu/DropDownMenu'
import { TMenuOptions } from '../../types'
import { navigationBaseKey } from '../../constants/translate'

export function useAdminMenuOptions() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const userMenuOptions: TMenuOptions[] = [

    {
      label: t(`${navigationBaseKey}.user.logout`),
      handleSelect: () => navigate('/logout')
    }
  ]

  return userMenuOptions
}
const AdminNavigationMenu = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className={styles.container}>
        <NavLink end className={styles.navigation} to="/admin/users">
          {t('admin.navigation.users')}
        </NavLink>
        <NavLink end className={styles.navigation} to="/admin/products">
          {t('admin.navigation.products')}
        </NavLink>
        <NavLink end className={styles.navigation} to="/admin/labels">
          {t('admin.navigation.labels')}
        </NavLink>
      </div>
      <div className={styles.container}>
        <DropDownMenu options={useAdminMenuOptions()}/>
      </div>
    </>
  )
}

export default AdminNavigationMenu
