import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { DefaultTFuncReturn } from 'i18next'
import CustomInput from '../formControls/CustomInput/CustomInput'
import { useValidationRules } from '../../hooks/useValidationRules'

type TProps = {
  isRequired?: boolean
  isPasswordValidation?: boolean
  label?: DefaultTFuncReturn
}
const Password: FC<TProps> = ({
  isRequired = true,
  isPasswordValidation = true,
  label
}) => {
  const { requiredRule, passwordValidation } = useValidationRules()
  const { t } = useTranslation()
  const { control, formState } = useFormContext()
  const isConfirmPasswordError = !!formState.errors?.matchingPassword?.message
  return (
    <CustomInput
      label={`${label || t('common.password')} ${isRequired ? '*' : ''}`}
      name="password"
      type="password"
      control={control}
      error={isConfirmPasswordError}
      rules={{
        required: isRequired ? requiredRule() : undefined,
        validate: isPasswordValidation ? (value) => passwordValidation(value) : undefined
      }}
    />
  )
}

export default Password
