import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import styles from './WeeklyReports.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import { useDashboardContext } from '../../state/DashboardState'
import CustomWeekPicker from '../../../../../shared/components/formControls/CustomWeekPicker/CustomWeekPicker'
import WeeklyReportCard from '../WeeklyReportCard/WeeklyReportCard'
import Spinner from '../../../../../shared/components/Spinner/Spinner'
import { dotDateFormat } from '../../../../../shared/utils/date'

const WeeklyReports = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    formReturn: { control, getValues },
    weeklyOverview: { data }
  } = useDashboardContext()

  if (!data) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }

  const handleNavigate = () => {
    const { week } = getValues()
    navigate(`/dashboard/weeklyReports?week=${dotDateFormat(week)}&dashboard=true`)
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t('dashboard.weeklyReports')}</h1>
      <CustomWeekPicker name="week" control={control} label={t('dashboard.week')}/>
      <div className={classNames(styles.reports,
        { [styles.noReports]: !data.length })}>
        {
          data.length
            ? data.map(({ product, label, amount, unit }) => (
              <WeeklyReportCard
                key={`${product.id}_${label}`}
                unit={unit}
                label={label.name}
                product={product.name}
                quantity={amount}/>
            ))
            : <p className={styles.noWeeklyReportText}>{t('dashboard.noWeeklyReport')}</p>
        }

      </div>
      <div className={styles.buttonWrap}>
        <PrimaryButton onClick={handleNavigate} text={t('dashboard.weeklyReportsOverview')}/>
      </div>
    </div>
  )
}
export default memo(WeeklyReports)
