import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../../authBase'
import { useLogoutQuery } from '../../queries'
import { queryClient } from '../../../../../shared/utils/queryClient'

function useLogout() {
  const { removeAuthData } = useAuthContext()
  const { refetch } = useLogoutQuery()
  const navigate = useNavigate()

  useEffect(() => {
    refetch().finally(() => {
      removeAuthData()
      queryClient.clear()
      navigate('/login')
    })
  }, [navigate, refetch, removeAuthData])
}

const Logout = () => {
  useLogout()
  return null
}
export default Logout
