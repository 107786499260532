import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { memo } from 'react'
import { useGetProduct } from '../../queries'
import PhoneIcon from '../../../../../shared/components/icons/PhoneIcon'
import MailIcon from '../../../../../shared/components/icons/MailIcon'
import styles from './Address.module.scss'
import { useAuthContext } from '../../../../auth/authBase'

const Address = () => {
  const { t } = useTranslation()
  const { data } = useGetProduct()
  const { anonymous, producer } = data!
  const { authData } = useAuthContext()
  return (
    <>
      {anonymous
        ? (
          <div>
            <p className={styles.label}>{t('buy.offer.body.producer')}</p>
            <p className={styles.value}>{producer?.address?.city}</p>
          </div>
        )
        : (
          <div>
            <p className={styles.label}>{t('buy.offer.body.producer')}</p>
            {
              authData ? (
                <>
                  <div className={styles.marginBottom}>
                    <p className={styles.value}>{producer?.companyName}</p>
                    <p className={styles.value}>{producer?.contact?.fullName}</p>
                    <p className={styles.value}>{producer?.address?.address}</p>
                    <p className={styles.value}>{producer?.address?.city}</p>
                    <p className={styles.value}>{producer?.address?.zip}</p>
                  </div>
                  <a href={`tel:${producer?.contact?.phone}`} className={classNames(styles.producerInfoWrap, styles.marginBottom)}>
                    <PhoneIcon/>
                    <p className={styles.producerInfoText}>{producer?.contact?.phone}</p>
                  </a>
                  <a href={`mailto:${producer?.contact?.email}`} className={styles.producerInfoWrap}>
                    <MailIcon/>
                    <p className={styles.producerInfoText}>{producer?.contact?.email}</p>
                  </a>
                </>
              ) : (
                <div className={styles.marginBottom}>
                  <p className={styles.value}>{t('buy.offer.visibleForLoggedUser')}</p>
                </div>
              )
            }
          </div>
        )}
    </>
  )
}

export default memo(Address)
