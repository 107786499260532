import styles from './DayView.module.scss'
import DayViewCard from '../DayViewCard/DayViewCard'
import { useForecastDayQuery } from '../../queries'
import { prepareProductLabel } from '../../../weeklyReportsAnalytics'
import { usePlanningForecastContext } from '../../state/PlanningForecastState'
import { dotDateFormat } from '../../../../../shared/utils/date'
import Spinner from '../../../../../shared/components/Spinner/Spinner'

const DayView = () => {
  const { formReturn: { watch }, productLabel } = usePlanningForecastContext()
  const { date, foodWaste, products } = watch()

  const { data, isFetching, isLoading } = useForecastDayQuery({
    date: dotDateFormat(date),
    foodWaste,
    products: prepareProductLabel(products, productLabel)
  })

  if (isFetching && isLoading) {
    return (
      <div className={styles.spinnerWrap}>
        <Spinner/>
      </div>
    )
  }

  return (
    <div className={styles.wrapper}>
      {
        data?.map((props) => (
          <DayViewCard
            key={`${props.product.id}_${props.label.id}_${props.stockAmount}`}
            {...props}/>
        ))
      }
    </div>
  )
}

export default DayView
