import { useTranslation } from 'react-i18next'
import { FC } from 'react'
import styles from './ListOfferHeader.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'

type TProps = {
  isCreateButton: boolean
  handleCreate: () => void
}
const ListOfferHeader: FC<TProps> = ({ isCreateButton, handleCreate }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <div>
        {t('sell.list.myOffers')}
      </div>
      {isCreateButton && (
        <div className={styles.buttonWrapper}>

          <PrimaryButton
            onClick={handleCreate}
            text={t('sell.list.addOffer')}/>
        </div>
      )}
    </div>
  )
}

export default ListOfferHeader
