import { FC } from 'react'
import styles from './SuccessNotification.module.scss'
import SuccessIcon from '../../../../shared/components/icons/SuccessIcon'

type TProps = {
  message: string
}
const SuccessNotification: FC<TProps> = ({ message }) => (
  <div className={styles.wrapper}>
    <SuccessIcon/>
    <p className={styles.message}>{message}</p>
  </div>
)

export default SuccessNotification
