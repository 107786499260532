import { FC } from 'react'
import { TableRow, TableCell } from '@mui/material'
import { Row } from 'react-table'
import { useTableContext } from '../../state/TableState'
import styles from './TableBodyRow.module.scss'

type TProps = {
  row: Row
}

const TableBodyRow: FC<TProps> = ({ row }) => {
  const {
    tableInstance: { prepareRow },
    onRowClick
  } = useTableContext()
  prepareRow(row)

  const { key: rowKey, ...restRowProps } = row.getRowProps()

  return (
    <TableRow
      {...restRowProps}
      key={rowKey}
      onClick={() => onRowClick?.(row)}
    >
      {row.cells.map((cell) => {
        const { key, ...restProps } = cell.getCellProps()
        return (
        // eslint-disable-next-line react/jsx-key
          <TableCell
            {...restProps}
            key={key}
            className={styles.tableCell}
          >
            {cell.render('Cell')}
          </TableCell>
        )
      })}
    </TableRow>
  )
}

export default TableBodyRow
