import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import {
  TCropStock, TCropStockFilteredResponse, TCropStockMutationParams,
  TCropStockSingle,
  TEditCropStockMutationParams,
  TUpdateCropStockMutationParams
} from './types'

enum QueryKeys{
  CropStockList = 'CropStockList',
  CropStockSingle = 'CropStockSingle',
  CropStockFiltered = 'CropStockSingle'
}

async function deleteCropStock(
  { productStockId, transactionId }: TCropStockMutationParams
): Promise<void> {
  try {
    const response = await apiClient.delete(`/app/crop-stock/${productStockId}/transaction/${transactionId}`)
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

async function editCropStock(params: TEditCropStockMutationParams): Promise<void> {
  const { transactionId, productStockId, ...rest } = params
  try {
    await apiClient.put(`/app/crop-stock/${productStockId}/transaction/${transactionId}`, rest)
  } catch (e: any) {
    throw new Error(e)
  }
}

async function updateCropStock(params: TUpdateCropStockMutationParams): Promise<void> {
  const { productStockId, ...rest } = params
  try {
    await apiClient.post(`/app/crop-stock/${productStockId}/transaction`, rest)
  } catch (e: any) {
    throw new Error(e)
  }
}

async function cropStockList(): Promise<TCropStock[]> {
  try {
    const response = await apiClient('/app/crop-stock')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

async function cropStockFiltered({ productStockId, transactionId }: TCropStockMutationParams):
  Promise<TCropStockFilteredResponse> {
  try {
    const response = await apiClient(`/app/crop-stock/${productStockId}/transaction/${transactionId}`)
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

async function cropStockSingle(
  productStockId: string,
  isTransition: boolean
): Promise<TCropStockSingle> {
  try {
    const response = await apiClient(`/app/crop-stock/${productStockId}?withTransactions=${isTransition}`)
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useCropStockFiltered = (params: Partial<TCropStockMutationParams>) => {
  const key = [QueryKeys.CropStockFiltered, params?.productStockId, params?.transactionId]

  return useQuery(
    [key],
    () => cropStockFiltered(<TCropStockMutationParams>params),
    {
      enabled: !!params?.transactionId && !!params?.productStockId
    }
  )
}

export const useUpdateCropStockMutation = () => {
  const queryClient = useQueryClient()
  return useMutation<void, Error, TUpdateCropStockMutationParams>(
    (params) => updateCropStock(params),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          [QueryKeys.CropStockList]
        )
        await queryClient.invalidateQueries(
          [QueryKeys.CropStockSingle]
        )
      }
    }
  )
}

export const useDeleteCropStockMutation = () => {
  const queryClient = useQueryClient()
  return useMutation<void, Error, TCropStockMutationParams>(
    (params) => deleteCropStock(params),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          [QueryKeys.CropStockList]
        )
        await queryClient.invalidateQueries(
          [QueryKeys.CropStockSingle]
        )
      }
    }
  )
}

export const useEditCropStockMutation = () => {
  const queryClient = useQueryClient()

  return useMutation<void, Error, TEditCropStockMutationParams>(
    (params) => editCropStock(params),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          [QueryKeys.CropStockList]
        )
        await queryClient.invalidateQueries(
          [QueryKeys.CropStockSingle]
        )
      }
    }
  )
}

export const useCropStockListQuery = () => {
  const key = QueryKeys.CropStockList
  return useQuery<TCropStock[], Error>([key], cropStockList)
}

export const useCropStockSingleQuery = (isTransition: boolean, productStockId?: string) => {
  const key = QueryKeys.CropStockSingle
  return useQuery<TCropStockSingle, Error>(
    [key, productStockId, isTransition],
    () => cropStockSingle(productStockId!, isTransition),
    {
      enabled: !!productStockId
    }
  )
}
