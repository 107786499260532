export function dayPropGetter(date: Date) {
  const dayIndex = new Date(date).getDay()
  const weekendsIndex = [6, 0]
  if (weekendsIndex.includes(dayIndex)) {
    return {
      style: {
        backgroundColor: '#F5F5F5'
      }
    }
  }
  return {}
}
