import classNames from 'classnames'
import { FC, memo, useMemo } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styles from './InActiveAccordionSummary.module.scss'
import PlanRemove from '../PlanRemove/PlanRemove'
import { useGrowingPlanWorkShopFormContext } from '../../state/GrowingPlanWorkShopFormState'
import { useProductListQuery } from '../../queries'

function useProductName({ watch }: UseFormReturn, planIndex: number) {
  const { data } = useProductListQuery()
  const productId = watch(`productPlans[${planIndex}].productId`)
  return useMemo(() => {
    if (!data || !productId) {
      return undefined
    }
    return data?.find(({ id }) => id === productId)?.name
  }, [productId, data])
}

function useUnitName({ watch }: UseFormReturn, planIndex: number) {
  return watch(`productPlans[${planIndex}].unit`)?.toLowerCase()
}

type TProps = {
  isDeleteAbleProduct: boolean
  icon: JSX.Element
  removePlan: () => void
  total: number
  planIndex: number
}
const InActiveAccordionSummary: FC<TProps> = ({
  icon,
  total,
  isDeleteAbleProduct,
  removePlan,
  planIndex
}) => {
  const { t } = useTranslation()
  const { formReturn } = useGrowingPlanWorkShopFormContext()
  const productName = useProductName(formReturn, planIndex)
  const unitName = useUnitName(formReturn, planIndex)
  const { formState: { errors } } = formReturn

  return (
    <div className={styles.content}>
      <div className={styles.titleWrapper}>
        <div className={classNames(styles.iconArrow)}>
          {icon}
        </div>
        <div className={classNames(
          styles.productLabel,
          {
            [styles.inActive]: !productName,
            [styles.error]: !!(errors as any)?.productPlans?.[planIndex]?.productId
          }
        )}>
          {productName || t('growingPlan.workShop.productNotSelected') }
        </div>
        <div className={classNames(
          styles.amountLabelWrapper,
          {
            [styles.inActive]: !unitName,
            [styles.error]: !!(errors as any)?.productPlans?.[planIndex]?.unit
          }
        )}>
          <div className={styles.circle}/>
          <div className={styles.amountLabelWrapper}>{total}</div>
          {unitName || t('growingPlan.workShop.unitNotSelected')}
        </div>
      </div>
      {
        isDeleteAbleProduct
        && (
          <PlanRemove
            removePlan={removePlan}
            noMargin
          />
        )
      }
    </div>
  )
}

export default memo(InActiveAccordionSummary)
