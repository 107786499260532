import constate from 'constate'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useEffect } from 'react'
import { UseQueryResult } from '@tanstack/react-query'
import { TSellProductLabel, TWeeklyReportsAnalyticsForm } from '../types'
import { dateFromDotDateFormat, dotDateFormat, endOfWeekCustom, startOfWeekCustom } from '../../../../shared/utils/date'
import { useAnalyticsWeekly, useCompanyListQuery, useSellProductLabelQuery } from '../queries'
import { TNameId } from '../../../growingPlan/BaseGrowingPlanWorkShop'
import { parseProductSelectValue, prepareProductSelectValue } from '../../baseAnalytics'
import { TLocationState } from '../../../sell/CreateOffer/types'

export function prepareProductLabel(products: string[],
  sellProducts: UseQueryResult<TSellProductLabel[], Error>) {
  if (!products.length) {
    return undefined
  }
  if (sellProducts.data?.length === products.length) {
    return ''
  }
  const preparedProducts = products.reduce((acc, curr) => {
    const { productId, labelId } = parseProductSelectValue(curr)
    return `${acc}&products=${productId}.${labelId}`
  }, '')
  return `&${preparedProducts}`
}

function prepareCompanyIds(
  companyIds: string[],
  companyList: UseQueryResult<TNameId[], Error>
) {
  if ((companyList.data?.length === companyIds.length) || !companyIds.length) {
    return ''
  }
  return `&${companyIds.join(',')}`
}

function useFormInit() {
  const [searchParams] = useSearchParams()
  const week = searchParams.get('week')
  const { state } = useLocation() as {state: TLocationState }
  const products = (state?.product && state?.label)
    ? [prepareProductSelectValue({
      product: state.product,
      label: state.label
    })]
    : []

  return useForm<TWeeklyReportsAnalyticsForm>({
    defaultValues: {
      week: week ? dateFromDotDateFormat(week) : new Date(),
      company: [],
      products
    }

  })
}

type TResetForm = {
  formReturn: UseFormReturn<TWeeklyReportsAnalyticsForm>
  companyList: UseQueryResult<TNameId[], Error>
  sellProductLabel: UseQueryResult<TSellProductLabel[], Error>
}

function useResetForm({
  formReturn: { setValue },
  sellProductLabel,
  companyList }: TResetForm) {
  useEffect(() => {
    if (!sellProductLabel.data || !companyList.data) {
      return
    }
    const companyValues = companyList.data.map(({ id }) => id)
    const productLabelValues = sellProductLabel.data.map(prepareProductSelectValue)
    setValue('products', productLabelValues)
    setValue('company', companyValues)
  }, [sellProductLabel.data?.length, companyList.data?.length])
}

function useWeekDates({ watch }: UseFormReturn<TWeeklyReportsAnalyticsForm>) {
  return {
    firstDate: dotDateFormat(startOfWeekCustom(watch('week'))),
    lastDate: dotDateFormat(endOfWeekCustom(watch('week')))
  }
}

const WeeklyReportsAnalyticsState = () => {
  const formReturn = useFormInit()
  const weekDates = useWeekDates(formReturn)
  const companyList = useCompanyListQuery(weekDates)

  const companyForm = formReturn.watch('company')
  const productForm = formReturn.watch('products')

  const companyIds = prepareCompanyIds(companyForm, companyList)
  const sellProductLabel = useSellProductLabelQuery({
    ...weekDates,
    companyIds
  })

  const preparedProducts = prepareProductLabel(productForm, sellProductLabel)

  useResetForm({
    formReturn,
    sellProductLabel,
    companyList
  })

  return {
    formReturn,
    sellProductLabel,
    companyList,
    analyticsWeekly: useAnalyticsWeekly({
      firstDate: weekDates.firstDate,
      companyIds: companyForm.length ? companyIds : undefined,
      products: preparedProducts
    })
  }
}

export const [
  WeeklyReportsAnalyticsProvider,
  useWeeklyReportsAnalyticsContext
] = constate(WeeklyReportsAnalyticsState)
