import { FC } from 'react'
import styles from './Header.module.scss'

type TProps = {
  title: string
  descriptionBold: string,
  description: string
}
const Header: FC<TProps> = ({ title, description, descriptionBold }) => (
  <div className={styles.wrapper}>
    <h2 className={styles.title}>{title}</h2>
    <div className={styles.info}>
      <p className={styles.textBold}>{descriptionBold}</p>
      <p className={styles.text}>{description}</p>
    </div>
  </div>
)

export default Header
