import { useMemo } from 'react'
import { Column } from 'react-table'
import { useTranslation } from 'react-i18next'
import format from 'date-fns/format'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import styles from './OfferTable.module.scss'
import { Table as CustomTable } from '../../../../../shared/components/table/index'
import { useListOffers } from '../../queries'
import { dateFromDotDateTimeFormat } from '../../../../../shared/utils/date'
import { DOT_DATE_TIME_FORMAT } from '../../../../../shared/constants/date'
import DeleteOffer from '../DeleteOffer/DeleteOffer'
import { TOfferList } from '../../types'
import EditIcon from '../../../../../shared/components/icons/EditIcon'
import { addZeros } from '../../../../../shared/utils/formUtils'

const statusMap: {[key in TOfferList['status']]: string} = {
  IN_NEGOTIATION: 'negotiation',
  PUBLISHED: 'published',
  UNPUBLISHED: 'unPublished',
  SOLD: 'sold',
  PARTIALLY_SOLD: 'partiallySold'
}
const OfferTable = () => {
  const { data: listOffers } = useListOffers()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const columns = useMemo<Column[]>(() =>
    [
      {
        Header: <div className={classNames(styles.text, styles.bold)}>{t('sell.list.offerTable.product')}</div>,
        accessor: 'product.name',
        Cell: ({ value }) => <span className={styles.text}>{value}</span>
      },
      {
        Header: <div className={classNames(styles.text, styles.bold)}>{t('sell.list.offerTable.label')}</div>,
        accessor: 'label.name',
        Cell: ({ value }) => (
          <span
            className={styles.text}>
            {value}
          </span>
        )
      },
      {
        Header: <div className={classNames(styles.text, styles.bold)}>{t('sell.list.offerTable.publishedFor')}</div>,
        accessor: 'publishFor',
        Cell: ({ value }) => <span className={styles.text}>{t(`sell.workShop.radios.publish.options.${value}`)}</span>
      },
      {
        Header: <div className={classNames(styles.text, styles.bold)}>{t('sell.list.offerTable.negotiation')}</div>,
        accessor: 'negotiation',
        Cell: ({ value }) => <span className={styles.text}>{t(`sell.workShop.radios.negotiation.options.${value ? 'yes' : 'no'}`)}</span>
      },
      {
        Header: <div className={classNames(styles.text, styles.bold)}>{t('sell.list.offerTable.availableAmount')}</div>,
        accessor: 'availableAmount',
        Cell: ({ value }) => <span className={styles.text}>{`${value} ${t('sell.tu')}`}</span>
      },
      {
        Header: <div className={classNames(styles.text, styles.bold)}>{t('sell.list.offerTable.pricePerCU')}</div>,
        accessor: 'pricePerCu',
        Cell: ({ value }) => <span className={styles.text}>{`${t('common.chf')} ${addZeros(value)} `}</span>
      },
      {
        Header: <div className={classNames(styles.text, styles.bold)}>{t('sell.list.offerTable.publishedDate')}</div>,
        accessor: 'publishedDate',
        Cell: ({ value, row }) => {
          const rowOriginal = row.original as TOfferList
          const isStatusUnpublished = rowOriginal.status === 'UNPUBLISHED'
          return (
            <span className={styles.text}>
              {
                isStatusUnpublished
                  ? '-'
                  : format(dateFromDotDateTimeFormat(value), DOT_DATE_TIME_FORMAT)
              }
            </span>
          )
        } },
      {
        Header: <div className={classNames(styles.text, styles.bold)}>{t('sell.list.offerTable.status')}</div>,
        accessor: 'status',
        Cell: ({ value }) => (
          <span
            className={classNames(styles.text, styles.status, styles[statusMap[value as TOfferList['status']]])}>
            {t(`sell.status.${value}`)}
          </span>
        )
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ value, row }) => {
          const rowOriginal = row.original as TOfferList

          if (!rowOriginal.ordersExist) {
            return (
              <div className={styles.buttonGroup}>
                <button
                  type="button"
                  onClick={() => navigate(`/sell/edit/${value}`)}
                  className={styles.iconWrap}>
                  <EditIcon width={20} height={20}/>
                </button>
                <DeleteOffer
                  id={value}
                  title={`${rowOriginal.product.name} (${rowOriginal.label})`}/>
              </div>
            )
          }
          return <></>
        }
      }

    ], [listOffers, listOffers?.length])
  return (
    <div className={styles.tableWrapper}>
      <CustomTable
        data={listOffers || []}
        contentClass={styles.table}
        columns={columns}
      />
    </div>
  )
}

export default OfferTable
