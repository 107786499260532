import { FC, ReactNode } from 'react'
import styles from './AccordionSummary.module.scss'

type TProps = {
  icon: ReactNode
  productName: string
  label: string
}
const AccordionSummary: FC<TProps> = ({ productName, label, icon }) => (
  <div className={styles.wrapper}>
    <div>
      {icon}
    </div>
    <div className={styles.textWrap}>
      <p>{productName}</p>
      <p className={styles.label}>{`(${label})`}</p>
    </div>
  </div>
)

export default AccordionSummary
