import { useTranslation } from 'react-i18next'
import { FC, memo, useMemo } from 'react'
import { Control } from 'react-hook-form'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'

function useOptions() {
  const { t } = useTranslation()
  return useMemo(() => ([
    {
      value: 'HARVEST_BUY',
      label: t('cropStock.inOut.HARVEST_BUY')
    },
    {
      value: 'OFFLINE_SELL',
      label: t('cropStock.inOut.OFFLINE_SELL')
    }
  ]), [])
}

type TProps = {
  control: Control<any>
}
const InOutSelect: FC<TProps> = ({ control }) => {
  const { t } = useTranslation()
  const options = useOptions()
  return (
    <CustomSelectControl
      control={control}
      options={options}
      isRequired
      name="type"
      label={`${t('cropStock.common.inOut')} *`}
      handleError={(error) => (error as any).type}
    />
  )
}

export default memo(InOutSelect)
