import { useQuery } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import {
  TAnalyticsWeekly,
  TAnalyticsWeeklyParams,
  TCustomerListParams,
  TSellProductLabel,
  TSellProductLabelParams
} from './types'
import { TNameId } from '../../growingPlan/BaseGrowingPlanWorkShop'

enum QueryKeys{
  SellProductLabel = 'SellProductLabel',
  CustomerList = 'StockForecast',
  AnalyticsWeekly = 'AnalyticsWeekly'
}

async function sellProductLabel(
  { companyIds, firstDate, lastDate }: TSellProductLabelParams
): Promise<TSellProductLabel[]> {
  try {
    const response = await apiClient(
      `app/analytics/weekly/planned-orders/products?firstDate=${firstDate}&lastDate=${lastDate}${companyIds}`
    )
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useSellProductLabelQuery = (params: TSellProductLabelParams) => {
  const keys = [QueryKeys.SellProductLabel, params?.firstDate, params?.companyIds]

  return useQuery<TSellProductLabel[], Error>(keys, () => sellProductLabel(params))
}

async function companyList(params: TCustomerListParams): Promise<TNameId[]> {
  try {
    const response = await apiClient('app/analytics/weekly/planned-orders/companies', { params })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useCompanyListQuery = (params: TCustomerListParams) => {
  const keys = [QueryKeys.CustomerList, params?.firstDate]

  return useQuery<TNameId[], Error>(keys, () => companyList(params))
}

async function analyticsWeekly({
  products,
  companyIds,
  firstDate }: TAnalyticsWeeklyParams): Promise<TAnalyticsWeekly[]> {
  try {
    const response = await apiClient(
      `app/analytics/weekly/planned-orders?firstDate=${firstDate}${products}${companyIds}`
    )
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useAnalyticsWeekly = (params: TAnalyticsWeeklyParams) => {
  const keys = [
    QueryKeys.AnalyticsWeekly,
    params?.firstDate,
    params?.companyIds?.length,
    params?.products?.length
  ]
  return useQuery([keys], () => analyticsWeekly(params), {
    enabled: params?.companyIds !== undefined && params.products !== undefined
  })
}
