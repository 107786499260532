import { useTranslation } from 'react-i18next'
import add from 'date-fns/add'
import { Control } from 'react-hook-form'
import { FC, memo } from 'react'
import styles from './YearSelect.module.scss'
import CustomSelectControl from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { TForm } from '../../../types'

function addYear(years?: number) {
  return add(new Date(), { years }).getFullYear().toString()
}

const options = [
  {
    value: addYear(),
    label: addYear()
  },
  {
    value: addYear(1),
    label: addYear(1)
  },
  {
    value: addYear(2),
    label: addYear(2)
  },
  {
    value: addYear(3),
    label: addYear(3)
  }
]

type TProps = {
  control: Control<TForm>
  disabled: boolean
}
const YearSelect: FC<TProps> = ({ control, disabled }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.wrapper}>
      <CustomSelectControl
        disabled={disabled}
        label={t('growingPlan.workShop.year')}
        options={options}
        control={control}
        name="year"
      />
    </div>
  )
}

export default memo(YearSelect)
