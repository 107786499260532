import { useTranslation } from 'react-i18next'
import { FC, useMemo } from 'react'
import CustomSelectControl
  from '../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { useWeeklyReportsAnalyticsContext } from '../../state/WeeklyReportsAnalyticsState'
import styles from './CompanySelect.module.scss'

function useCompanyOptions() {
  const { companyList: { data } } = useWeeklyReportsAnalyticsContext()

  return useMemo(() => {
    if (!data) {
      return []
    }
    return data.map(({ id: value, name: label }) => ({
      label,
      value
    }))
  }, [data])
}

const CompanySelect: FC = () => {
  const { t } = useTranslation()
  const options = useCompanyOptions()
  const {
    formReturn: { control },
    sellProductLabel: { data }
  } = useWeeklyReportsAnalyticsContext()

  return (
    <CustomSelectControl
      key={data?.length}
      disabled={!data?.length}
      control={control}
      multiple
      selectAll
      options={options}
      selectAllLabel={t('dashboard.weeklyAnalytics.allSellBuy') as string}
      name="company"
      label={t('dashboard.weeklyAnalytics.sellBuy')}
      placeholder={
        data?.length
          ? t('dashboard.weeklyAnalytics.selectSellBuy')
          : <p className={styles.noCustomer}>{t('dashboard.weeklyAnalytics.NoSellBuy')}</p>
      }
    />
  )
}

export default CompanySelect
