import { TAccountDetailsResponse } from '../../accountBase'

export function setForm(data: TAccountDetailsResponse) {
  const {
    firstName,
    lastName,
    company,
    address,
    phone,
    email,
    subscribeToNewsLetters
  } = data

  return {
    firstName,
    companyName: company?.name,
    lastName,
    companyUid: company?.uid,
    phone,
    subscribeToNewsLetters,
    companyLogoId: company?.logoId,
    companyLogoUrl: company?.logoUrl,
    email,
    address: address?.address,
    zip: address?.zip,
    city: address?.city,
    iban: company?.iban,
    invoicePaymentTerm: company?.invoicePaymentTerm,
    password: '',
    matchingPassword: ''
  }
}
