import React, { ChangeEvent, Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useFormContext, UseFormSetError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import styles from './ImageFileUpload.module.scss'
import DeleteBinIcon from '../../../../../shared/components/icons/DeleteBinIcon'
import FileUploadBase from '../FileUploadBase/FileUploadBase'

function useImage(imageWatch?: string) {
  const [image, setImage] = useState<string>()

  useEffect(() => {
    if (!imageWatch) {
      return
    }
    setImage(imageWatch)
  }, [imageWatch])

  return {
    image,
    setImage
  }
}

function useImageHandleChange(
  name: string,
  setImage: Dispatch<SetStateAction<string | undefined>>,
  setError: UseFormSetError<any>

) {
  const { t } = useTranslation()
  return useCallback((
    { target }: ChangeEvent<HTMLInputElement>,
    onChange: (...event: any[]) => void
  ) => {
    if (target && target.files) {
      const file = target.files[0]
      const fileSize = file.size
      const fileType = file.type.split('/')[1]
      const fileSizeToMB = fileSize / 1024 / 1024
      if (fileSizeToMB > 5) {
        setError(name, { message: t('attachment.imageUpload.errorSize') || '' })
        return
      }
      if (!imageFormats.includes(fileType)) {
        setError(name, { message: t('attachment.imageUpload.errorType') || '' })
        return
      }
      onChange(file)
      setImage(URL.createObjectURL(file))
      target.value = ''
    }
  }, [name, setError, setImage, t])
}

const imageFormats = ['gif', 'jpg', 'webp', 'svg+xml', 'jpeg', 'png']

type TProps = {
  label: string
  contentClass?: string
  removeImageForm?: () => void
  imageWatch?: string
}

const ImageFileUpload: FC<TProps> = ({ label, removeImageForm, imageWatch, contentClass }) => {
  const { control, setValue, clearErrors, setError } = useFormContext()
  const { image, setImage } = useImage(imageWatch)
  const handleImageUploadChange = useImageHandleChange('file', setImage, setError)
  const removeImage = () => {
    setImage(undefined)
    setValue('file', undefined)
    removeImageForm?.()
    clearErrors('file')
  }

  if (image) {
    return (
      <div className={styles.wrapper}>
        <h1 className={styles.label}>{label}</h1>
        <div className={classNames(styles.content, contentClass)}>
          <img
            className={styles.logo}
            src={image}
            alt="logo"/>
          <button
            onClick={removeImage}
            type="button"
            className={styles.iconWrapper}
          >
            <DeleteBinIcon width={20} height={20}/>
          </button>
        </div>
      </div>
    )
  }

  return (
    <FileUploadBase
      label={label}
      accept=".gif, .jpg, .png, .webp, .svg"
      name="file"
      control={control}
      handleChange={handleImageUploadChange}
      clearError={() => clearErrors('file')}
    />
  )
}

export default ImageFileUpload
