import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import format from 'date-fns/format'
import styles from './OfferInfo.module.scss'
import ProductInfo from '../ProductInfo/ProductInfo'
import { useGetProduct } from '../../queries'
import { addZeros } from '../../../../../shared/utils/formUtils'
import { useAuthContext } from '../../../../auth/authBase'
import { dateFromDotDateTimeFormat } from '../../../../../shared/utils/date'
import { DOT_DATE_TIME_FORMAT } from '../../../../../shared/constants/date'

const OfferInfo = () => {
  const { t } = useTranslation()
  const { data } = useGetProduct()
  const { authData } = useAuthContext()
  const {
    label, origin, tu,
    cu, packageDate, bestBefore,
    pickUpDateTime, minAmountTu, amountTu, pricePerCu,
    negotiation
  } = data!

  const priceDescription = authData
    ? `${t('common.chf')} ${addZeros(pricePerCu || 0)}/${t(`customerUnitOptions.${cu}`)}`
    : t('buy.offer.visibleForLoggedUser')

  return (
    <div>
      <p className={styles.label}>{t('buy.offer.body.productInformation')}</p>
      <div className={styles.productInfoWrap}>
        <ProductInfo
          label={t('buy.offer.body.label')}
          description={label.name}
        />
        <ProductInfo
          label={t('buy.offer.body.originRegion')}
          description={origin}
        />
      </div>
      <div className={styles.productInfoWrap}>
        <ProductInfo
          label={t('buy.offer.body.availableAmount')}
          description={`${amountTu.toString()} ${t('common.tu')}`}
        />
        <ProductInfo
          label={t('buy.offer.body.minAmountTU')}
          description={`${minAmountTu.toString()} ${t('common.tu')}`}
        />
      </div>
      <div className={styles.productInfoWrap}>
        <ProductInfo
          label={t('buy.offer.body.tradeUnit')}
          description={t(`tradeInOptions.${tu}`)}
        />
        {
          negotiation && authData
            ? (
              <ProductInfo
                label={t('buy.offer.body.customerUnit')}
                description={t(`customerUnitOptions.${cu}`)}
              />
            ) : (
              <ProductInfo
                label={`${t('common.price')}:`}
                description={priceDescription}
              />
            )
        }
      </div>
      <div>
        <ProductInfo
          label={t('buy.offer.body.packageDate')}
          description={packageDate || '-'}
        />
        <ProductInfo
          label={t('buy.offer.body.bestBeforeDate')}
          description={bestBefore || '-'}
        />
        <ProductInfo
          label={t('buy.offer.body.pickUpDate')}
          description={format(dateFromDotDateTimeFormat(pickUpDateTime), DOT_DATE_TIME_FORMAT)}
        />
      </div>
    </div>
  )
}

export default memo(OfferInfo)
