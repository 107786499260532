import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import { FC, useMemo } from 'react'
import add from 'date-fns/add'
import CustomSelectControl
  from '../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'

function useOptions() {
  const date = new Date()
  return useMemo(() => [
    {
      value: date.getFullYear(),
      label: date.getFullYear()
    },
    {
      value: add(new Date(), { years: 1 }).getFullYear(),
      label: add(new Date(), { years: 1 }).getFullYear()
    }
  ], [])
}

type TProps = {
  control: Control<any>
}
const YearSelect: FC<TProps> = ({ control }) => {
  const { t } = useTranslation()
  const options = useOptions()
  return (
    <CustomSelectControl
      control={control}
      options={options}
      name="year"
      label={`${t('quantity.year')}`}
    />
  )
}

export default YearSelect
