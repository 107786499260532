import { useTranslation } from 'react-i18next'
import { useOrderListQuery } from '../../queries'
import styles from './MyOrders.module.scss'
import PrimaryLayout from '../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import OrderListTable from '../OrderListTable/OrderListTable'

const MyOrders = () => {
  const { t } = useTranslation()
  const { isLoading, data } = useOrderListQuery()
  if (isLoading || !data) {
    return (
      <div className={styles.spinnerWrapper} />
    )
  }

  return (
    <div>
      <PrimaryLayout
        size="large"
        maxHeight
        header={<div className={styles.header}>{t('buy.myOrders.label')}</div>}
      >
        <div className={styles.content}>
          {data.length
            ? <OrderListTable/>
            : <p className={styles.text}>{t('buy.myOrders.noOrder')}</p>}
        </div>

      </PrimaryLayout>
    </div>
  )
}

export default MyOrders
