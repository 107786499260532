import { useParams } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TBidParams, TBuyNowParams } from './types'

async function buyNow(params: TBuyNowParams, offerId?: string) {
  try {
    await apiClient.post(`/app/market/offer/${offerId}/buy`, params)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useBuyNowMutation = () => {
  const { productId } = useParams()

  return useMutation<void, Error, TBuyNowParams>((params) => buyNow(params, productId))
}

async function makeBid(params: TBidParams, offerId?: string) {
  try {
    await apiClient.post(`/app/market/offer/${offerId}/bid`, params)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useBidMutation = () => {
  const { productId } = useParams()

  return useMutation<void, Error, TBidParams>(
    (params) => makeBid(params, productId)
  )
}
