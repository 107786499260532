import { useForm } from 'react-hook-form'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useCreateInnerCircleMutation } from '../../querries'
import { InnerCircleWorkShop, TForm } from '../../../BaseInnerCircle'

function useFormInit() {
  return useForm<TForm>({
    defaultValues: {
      title: '',
      description: '',
      companyIds: []
    }
  })
}

function useOnSubmit() {
  const { mutateAsync } = useCreateInnerCircleMutation()
  const navigate = useNavigate()
  return useCallback((data: TForm) => {
    mutateAsync(data).then(() => navigate('/inner-circle'))
  }, [])
}

const CreateInnerCircle = () => {
  const { t } = useTranslation()
  const useFormReturn = useFormInit()
  const onSubmit = useOnSubmit()

  return (
    <InnerCircleWorkShop
      label={t('innerCircle.create.label')}
      onSubmit={onSubmit}
      useFormReturn={useFormReturn}
    />
  )
}

export default CreateInnerCircle
