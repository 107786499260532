import { ReactNode } from 'react'
import { TView } from '../../types'
import { usePlanningForecastContext } from '../../state/PlanningForecastState'
import DayView from '../DayView/DayView'
import WeekView from '../WeekView/WeekView'

const ViewMap: {[key in TView]: ReactNode} = {
  DAY: <DayView/>,
  WEEK: <WeekView/>
}

const Footer = () => {
  const { view } = usePlanningForecastContext()
  return (
    <>
      {ViewMap[view]}
    </>
  )
}

export default Footer
