import { FC, useMemo } from 'react'
import { Column, Row } from 'react-table'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { Table as CustomTable } from '../../../../../shared/components/table'
import styles from './CropStockTable.module.scss'
import EditIcon from '../../../../../shared/components/icons/EditIcon'
import DeleteBinIcon from '../../../../../shared/components/icons/DeleteBinIcon'
import { useCropStockSingleQuery } from '../../queries'
import { prepareTableData } from '../../utils/prepareTableData'
import { useCropStockModalContext } from '../../state/CropStockModalState'
import { TTransaction } from '../../types'
import { TCropStockType } from '../../../BaseCropStock'

function rowLast({ index }: Row, lastRow: number) {
  return index === lastRow
}

type TProps = {
  productStockId: string
}
const CropStockTable: FC<TProps> = ({ productStockId }) => {
  const { data } = useCropStockSingleQuery(true, productStockId)
  const { editModal, deleteModal } = useCropStockModalContext()
  const { t } = useTranslation()
  const cropStockData = useMemo(
    () => (data ? prepareTableData(data, t('cropStock.table.total')) : []),
    [data]
  )
  const lastRow = cropStockData.length - 1

  const columns = useMemo<Column[]>(
    () => [
      {
        Header: () => <div className={styles.tableHeader}>{t('cropStock.table.title')}</div>,
        accessor: 'title',
        Cell: ({ value, row }) => (
          <span className={classNames(
            styles.tableValue,
            { [styles.textBold]: rowLast(row, lastRow) }
          )}>
            {value}
          </span>
        )
      },
      {
        Header: () => <div className={styles.tableHeader}>{t('cropStock.table.date')}</div>,
        accessor: 'date',
        Cell: ({ value }) => <span className={styles.tableValue}>{value}</span>
      },
      {
        Header: () => <div className={styles.tableHeader}>{t('cropStock.table.type')}</div>,
        accessor: 'type',
        Cell: ({ value, row }) => (
          <span className={styles.tableValue}>
            {!rowLast(row, lastRow) && t(`cropStock.inOut.${value}`)}
          </span>
        )
      },
      {
        Header: () => <div className={styles.tableHeader}>{t('cropStock.table.amount')}</div>,
        accessor: 'amount',
        Cell: ({ value, row }) => {
          const cropStockType: Partial<TCropStockType>[] = ['HARVEST_BUY', 'WEBSITE_BUY', 'TRADING_BUY']
          const sign = cropStockType.includes((row.original as TTransaction).type) ? '+' : '-'
          return (
            <span className={classNames(
              styles.tableValue,
              { [styles.textBold]: rowLast(row, lastRow) }
            )}>
              {rowLast(row, lastRow) ? value : `${sign} ${value}`}
            </span>
          )
        }
      },
      {
        Header: () => <div className={styles.tableHeader}>{t('cropStock.table.unit')}</div>,
        accessor: 'unit',
        Cell: ({ value }) => <span className={styles.tableValue}>{value ? t(`units.${value.toLowerCase()}`) : ''}</span>
      },
      {
        Header: () => <div className={styles.tableHeader}>{t('cropStock.table.description')}</div>,
        accessor: 'description',
        Cell: ({ value, row }) => <span className={styles.tableValue}>{!rowLast(row, lastRow) && (value || '-')}</span>
      },
      {
        Header: '',
        accessor: 'id',
        Cell: ({ value: transactionId, row }) => {
          const actionShowType: Partial<TTransaction['type'][]> = ['HARVEST_BUY', 'OFFLINE_SELL']
          const transactionType = (row.original as TTransaction).type
          return (!rowLast(row, lastRow) && actionShowType.includes(transactionType)) ? (
            <div className={styles.iconsContainer}>
              <button
                type="button"
                onClick={() => editModal(productStockId, transactionId)}
                className={styles.iconWrapper}
              >
                <EditIcon width={20} height={20}/>
              </button>
              <button
                type="button"
                onClick={() => deleteModal(productStockId, transactionId)}
                className={styles.iconWrapper}
              >
                <DeleteBinIcon width={20} height={20}/>
              </button>
            </div>
          ) : null
        }

      }
    ],
    [cropStockData]
  )
  return (
    <div className={styles.tableWrapper}>
      <CustomTable columns={columns} data={cropStockData}/>
    </div>

  )
}
export default CropStockTable
