import { FC, memo } from 'react'
import styles from './ProductInfo.module.scss'

type TProps = {
  label: string
  description: string
}
const ProductInfo: FC<TProps> = ({ label, description }) => (
  <div className={styles.wrapper}>
    <p className={styles.label}>{label}</p>
    <p className={styles.description}>{description}</p>
  </div>
)

export default memo(ProductInfo)
