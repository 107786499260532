import { useMutation } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { TSignUpMutation } from './types'
import { apiClient } from '../../Interceptor'
import { TLanguage } from '../../account/accountBase'
import { useLanguageSwitcherContext } from '../../languageSwitcher'

enum QueryKeys{
  SignUp = 'SignUp'
}

async function signUp(data: TSignUpMutation, language: TLanguage) {
  try {
    await apiClient.post('/auth/signup', data, {
      headers: {
        'Accept-Language': language
      }
    })
  } catch (e: any) {
    console.log('error', e)
    throw e
  }
}

export function useSignUpMutation() {
  const navigate = useNavigate()
  const { language } = useLanguageSwitcherContext()

  return useMutation<void, Error, TSignUpMutation>(
    (data) => signUp(data, language),
    {
      mutationKey: [QueryKeys.SignUp],
      onSuccess: () => {
        navigate(`/login${window.location.search}`)
      }
    }
  )
}
