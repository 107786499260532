import constate from 'constate'
import {
  addDays,
  eachDayOfInterval,
  endOfMonth,
  format,
  getDay,
  set,
  isEqual,
  startOfMonth,
  subDays
} from 'date-fns'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { useGrowingPlanListQuery } from '../../../growingPlan/ListGrowingPlan'
import { useOrdersCalendarQuery } from '../queries'
import { DOT_DATE_FORMAT } from '../../../../shared/constants/date'
import { TGrowingPlanList } from '../../../growingPlan/ListGrowingPlan/types'
import { startOfWeekCustom } from '../../../../shared/utils/date'
import { TForm, TView } from '../types'
import { NO_GROWING_PLAN_OPTION } from '../../BaseQuantityReports'
import { useGrowingPlanYear } from '../components/QuantityReportHeader/QuantityReportHeader'

function monthDates(date: Date) {
  return eachDayOfInterval({
    start: startOfMonth(date),
    end: endOfMonth(date)
  })
}

function dateWeekDayNumber(date: Date) {
  return getDay(date) || 7
}

function weeksStartDates(dates: Date[]) {
  return dates.reduce<Date[]>((result, date) => [
    ...result,
    ...(
      result.some((resultDate) => isEqual(resultDate, startOfWeekCustom(date)))
        ? []
        : [startOfWeekCustom(date)]
    )
  ], [])
}

export function useCalendarDates({ watch }: UseFormReturn<TForm>) {
  const monthIndex = watch('month')
  const year = watch('year')
  return useMemo(() => {
    const date = set(new Date(), {
      month: Number(monthIndex),
      year: year ? Number(year) : undefined
    })

    const currentMonthDates = monthDates(date)
    const [monthFirstDay] = currentMonthDates
    const [monthLastDay] = [...currentMonthDates].reverse()

    const daysBeforeAmount = dateWeekDayNumber(monthFirstDay) - 1
    const daysAfterAmount = 14 - dateWeekDayNumber(monthLastDay)

    const datesBefore = Array(daysBeforeAmount)
      .fill(undefined)
      .map((_, index) => subDays(monthFirstDay, index + 1))
      .reverse()

    const datesAfter = Array(daysAfterAmount)
      .fill(undefined)
      .map((_, index) => addDays(monthLastDay, index + 1))

    const dates = [...datesBefore, ...currentMonthDates, ...datesAfter]
    return {
      dates,
      firstDate: dates[0],
      lastDate: dates[dates.length - 1],
      weeksStartDates: weeksStartDates(dates)
    }
  }, [monthIndex, year])
}

function useSelectedGrowingPlan(
  { watch }: UseFormReturn<TForm>,
  growingPlanListData?: TGrowingPlanList
) {
  const { growingPlan } = watch()

  return {
    selectedGrowingPlan: useMemo(
      () => growingPlanListData?.activePlans.find(({ id }) => id === growingPlan),
      [growingPlanListData, growingPlan]
    )
  }
}

function useFormInit(growingPlanListData?: TGrowingPlanList) {
  const { growingPlanId, month, year, seller, buyer } = useCurrentSearchParams()

  const useFormReturn = useForm<TForm>({
    defaultValues: {
      growingPlan: growingPlanId ?? '',
      year: year || new Date().getFullYear().toString(),
      seller: seller ?? '',
      buyer: buyer ?? '',
      month: month
        ? (Number(month) - 1).toString()
        : new Date().getMonth().toString()
    }
  })

  useEffect(() => {
    const firstGrowingPlan = growingPlanListData?.activePlans[0]

    if (firstGrowingPlan && !growingPlanId) {
      const { id, year } = firstGrowingPlan
      useFormReturn.setValue('growingPlan', id)
      useFormReturn.setValue('year', year)
    }
  }, [growingPlanListData])

  return useFormReturn
}

function useResetGrowingPlan({ watch, setValue }: UseFormReturn<TForm>) {
  const growingPlan = watch('growingPlan')
  useEffect(() => {
    if (!growingPlan || growingPlan === NO_GROWING_PLAN_OPTION) {
      return
    }
    setValue('seller', '')
    setValue('buyer', '')
    setValue('year', new Date().getFullYear().toString())
  }, [growingPlan])
}

function useCurrentSearchParams() {
  const [searchParams] = useSearchParams()
  const monthString = searchParams.get('month')

  return {
    growingPlanId: searchParams.get('growingPlanId'),
    month: monthString,
    year: searchParams.get('year'),
    buyer: searchParams.get('buyerId'),
    seller: searchParams.get('sellerId')
  }
}

function useSearchParamsHandle({ watch }: UseFormReturn<TForm>) {
  const [, setSearchParams] = useSearchParams()

  const { growingPlan, month, year, seller, buyer } = watch()

  useEffect(() => {
    setSearchParams({
      growingPlanId: growingPlan,
      year: year || '',
      sellerId: seller || '',
      buyerId: buyer || '',
      month: month
        ? (Number(month) + 1).toString()
        : ''
    })
  }, [growingPlan, month, year, seller, buyer])
}

function useView({ watch }: UseFormReturn<TForm>) {
  const growingPlanYear = useGrowingPlanYear()
  const [view, setView] = useState<TView>('WEEK')
  const monthIndex = Number(watch('month'))
  const year = Number(growingPlanYear || watch('year'))

  useEffect(() => {
    const date = new Date()
    const currentMonth = date.getMonth()
    const currentYear = date.getFullYear()
    setView(currentMonth === monthIndex && currentYear === year ? 'WEEK' : 'MONTH')
  }, [monthIndex, year])

  return {
    handleView: useCallback((view: TView) => setView(view), []),
    view
  }
}

const useContext = () => {
  const growingPlanListQuery = useGrowingPlanListQuery(true)
  const formMethods = useFormInit(growingPlanListQuery.data)
  useSearchParamsHandle(formMethods)
  useResetGrowingPlan(formMethods)
  const isNoGrowingPlan = formMethods.watch('growingPlan') === NO_GROWING_PLAN_OPTION
  const buyerId = formMethods.watch('buyer')
  const sellerId = formMethods.watch('seller')
  const {
    selectedGrowingPlan
  } = useSelectedGrowingPlan(formMethods, growingPlanListQuery.data)
  const { dates, lastDate, firstDate, weeksStartDates } = useCalendarDates(formMethods)
  const view = useView(formMethods)

  return {
    ...view,
    ordersCalendarQuery: useOrdersCalendarQuery(
      {
        dateFrom: format(firstDate, DOT_DATE_FORMAT),
        dateTo: format(lastDate, DOT_DATE_FORMAT),
        growingPlanId: isNoGrowingPlan ? NO_GROWING_PLAN_OPTION : selectedGrowingPlan?.id,
        buyerId,
        sellerId
      }
    ),
    growingPlanListQuery,
    selectedGrowingPlan,
    calendarDates: dates,
    formMethods,
    weeksStartDates
  }
}

export const [
  QuantityReportsProvider,
  useQuantityReportsContext
] = constate(useContext)
