import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useLocalStorage } from 'react-use'
import { useEffect, useLayoutEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import Delivery from '../Delivery/Delivery'
import BreadCrumb from '../../../../../shared/components/BreadCrumb/BreadCrumb'
import BidOfferFooter from '../BidOfferFooter/BidOfferFooter'
import Payment from '../Payment/Payment'
import styles from './BidOffer.module.scss'
import Order from '../Order/Order'
import { useGetProduct } from '../../../Offer'
import Spinner from '../../../../../shared/components/Spinner/Spinner'
import { TForm } from '../../types'
import { TOfferLocalData } from '../../../Offer/types'
import { OFFER_LOCAL_STORAGE_KEY } from '../../../../../shared/constants/localeStorage'

function useFormInit() {
  return useForm<TForm>({
    defaultValues: {
      deliveryOption: 'PICK_UP',
      paymentType: 'INVOICE'
    }
  })
}

function useScrollTop() {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])
}

function useNavigateBack() {
  const { productId } = useParams()
  const navigate = useNavigate()
  const [offerLocalData] = useLocalStorage<TOfferLocalData>(OFFER_LOCAL_STORAGE_KEY)
  useEffect(() => {
    if (offerLocalData) {
      return
    }
    navigate(`/buy/${productId}`)
  }, [])
}
const BidOffer = () => {
  const { productId } = useParams()
  const { t } = useTranslation()
  const useFormReturn = useFormInit()
  const { isLoading, data } = useGetProduct()
  useNavigateBack()
  useScrollTop()

  if (isLoading || !data) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }

  return (
    <PrimaryLayout
      noPadding
      size="large"
      maxHeight
      header={(
        <div className={styles.header}>
          <BreadCrumb
            navigateTo={`/buy/${productId}`}
            label={t('buy.bidOffer.navigateLabel')}/>
        </div>
      )}>
      <FormProvider {...useFormReturn}>
        <Delivery/>
        <Payment/>
        <Order/>
        <BidOfferFooter/>
      </FormProvider>
    </PrimaryLayout>

  )
}

export default BidOffer
