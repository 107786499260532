import { useForm } from 'react-hook-form'
import { format, set } from 'date-fns'
import { FC, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  GrowingPlanWorkShop,
  TForm
} from '../../../BaseGrowingPlanWorkShop'
import { useCreateGrowingPlanMutation } from '../../queries'
import { timeFormat } from '../../../../../shared/constants/dateFormat'
import { generateProductPlan } from '../../../BaseGrowingPlanWorkShop/utils/generateProductPlan'
import { useLabelListQuery } from '../../../BaseGrowingPlanWorkShop/queries'
import { TProductPlanForm } from '../../../BaseGrowingPlanWorkShop/types'

function useFormInit(defaultProductPlan: TProductPlanForm) {
  return useForm<any>({
    defaultValues: {
      year: new Date().getFullYear().toString(),
      dailyOrderTime: set(new Date(), { hours: 23, minutes: 59 }),
      description: '',
      customerId: '',
      title: '',
      productPlans: [defaultProductPlan]
    }
  })
}

function useHandleSubmit() {
  const { mutateAsync } = useCreateGrowingPlanMutation()
  const navigate = useNavigate()
  return useCallback((data: TForm) => {
    mutateAsync({
      ...data,
      dailyOrderTime: format(data.dailyOrderTime as Date, timeFormat) })
      .then(() => navigate('/growing-plan'))
  }, [mutateAsync, navigate])
}

type TProps = {
  defaultProductPlan: TProductPlanForm
}
const CreateGrowingPlan: FC<TProps> = ({ defaultProductPlan }) => {
  const { t } = useTranslation()
  const formReturn = useFormInit(defaultProductPlan)
  const handleSubmit = useHandleSubmit()
  return (
    <GrowingPlanWorkShop
      handleSubmit={handleSubmit}
      formReturn={formReturn}
      label={t('growingPlan.workShop.labelCreate')} />
  )
}

export default () => {
  const { data: labelsData, isLoading } = useLabelListQuery()
  if (!labelsData || isLoading) {
    return <></>
  }

  return <CreateGrowingPlan defaultProductPlan={generateProductPlan(labelsData)}/>
}
