import { FC, memo } from 'react'
import Accordion from '../../../../../shared/components/Accordion/Accordion'
import AccordionSummary from '../AccordionSummary/AccordionSummary'
import styles from './GrowingPlanAccordion.module.scss'
import AccordionDetails from '../AccordionDetails/AccordionDetails'
import { TGrowingPlanDetails } from '../../types'

type TProps = {
  isArchived?: boolean
  isPaddingSmall?: boolean
} & TGrowingPlanDetails
const GrowingPlanAccordion: FC<TProps> = ({
  id,
  title,
  currentUserSeller,
  validUntil,
  seller,
  customer,
  isPaddingSmall,
  isArchived,
  weeklyOrderExists
}) => (
  <Accordion
    isPaddingSmall={isPaddingSmall}
    summary={(icon, isActive) => (
      <AccordionSummary
        icon={icon}
        key={isActive.toString()}
        id={id}
        isDeleteButton={!isArchived && currentUserSeller && !weeklyOrderExists}
        isEditButton={!isArchived && currentUserSeller}
        title={title}
        description={currentUserSeller ? customer.name : seller.name}
        date={validUntil}/>
    )}
    detail={(
      <div className={styles.detailsWrapper}>
        <AccordionDetails isCurrentSeller={currentUserSeller} growingPlanId={id}/>
      </div>
    )}/>
)

export default memo(GrowingPlanAccordion)
