import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './GrowingPlanWorkShopFooter.module.scss'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import { useGrowingPlanWorkShopFormContext } from '../../state/GrowingPlanWorkShopFormState'

const GrowingPlanWorkShopFooter = () => {
  const { t } = useTranslation()
  const { appendPlan, formReturn, handleSubmit } = useGrowingPlanWorkShopFormContext()
  const navigate = useNavigate()

  return (
    <div>
      <div className={styles.buttonWrap}>
        <SecondaryButton onClick={appendPlan} text={t('growingPlan.workShop.addProduct')}/>
      </div>
      <div className={styles.line} />
      <div className={styles.buttonGroup}>
        <PrimaryButton
          onClick={formReturn.handleSubmit(handleSubmit)}
          text={t('growingPlan.workShop.saveGrowingPlan')}/>
        <SecondaryButton onClick={() => navigate('/growing-plan')} text={t('common.cancel')}/>
      </div>
    </div>
  )
}

export default GrowingPlanWorkShopFooter
