import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo } from 'react'
import { useForm, UseFormReturn } from 'react-hook-form'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import { format } from 'date-fns'
import styles from './CropStockUpdateModal.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { ChangeCropStockFormField } from '../../../BaseCropStock'
import { useCropStockModalContext } from '../../state/CropStockModalState'
import { useCropStockSingleQuery, useUpdateCropStockMutation } from '../../queries'
import { TCropStockSingle, TForm, TUpdateCropStockMutationParams } from '../../types'
import { roundNumber } from '../../../../../shared/utils/calculationUtils'
import Spinner from '../../../../../shared/components/Spinner/Spinner'
import { DOT_DATE_FORMAT } from '../../../../../shared/constants/date'
import Modal from '../../../../../shared/components/Modal/Modal'

function useFormInit(formData?: TCropStockSingle) {
  return useForm<TForm>({
    defaultValues: useMemo(
      () => {
        if (!formData) {
          return undefined
        }
        return {
          date: new Date(),
          unit: formData?.unit
        }
      },
      [formData?.unit]
    )
  })
}

function useFormReset(
  useFormReturn: UseFormReturn<TForm>,
  isModalContent: boolean,
  formData?: TCropStockSingle

) {
  useEffect(() =>
    useFormReturn.reset({ unit: formData?.unit, date: new Date() }),
  [isModalContent, formData?.unit])
}

function useOnSubmit(
  mutateAsync: UseMutateAsyncFunction<void, Error, TUpdateCropStockMutationParams>
) {
  const { handleClose, modalState } = useCropStockModalContext()
  return useCallback((data: TForm) => {
    if (!modalState?.productStockId) {
      return
    }
    mutateAsync({
      ...data,
      date: format(new Date(data.date!), DOT_DATE_FORMAT),
      productStockId: modalState.productStockId
    }).then(handleClose)
  }, [modalState?.productStockId])
}

function useCountTotal({ watch }: UseFormReturn<TForm>, total?: number) {
  const amount = watch('amount')
  const type = watch('type')
  const parsedAmount = Number(amount || 0)
  return useMemo(
    () => {
      if (total === undefined) {
        return 0
      }
      if (!type) {
        return total
      }
      return roundNumber(type === 'HARVEST_BUY' ? total + parsedAmount : total - parsedAmount)
    },
    [total, type, parsedAmount]
  )
}

const CropStockUpdateModal = () => {
  const { mutateAsync, isLoading: isUpdateCropStockLoading } = useUpdateCropStockMutation()
  const { t } = useTranslation()
  const { handleClose, modalState } = useCropStockModalContext()
  const { data, isLoading } = useCropStockSingleQuery(false, modalState?.productStockId)
  const useFormReturn = useFormInit(data)
  const isModalContent = !isLoading && !!data
  useFormReset(useFormReturn, isModalContent, data)
  const total = useCountTotal(useFormReturn, data?.total)
  const onsubmit = useOnSubmit(mutateAsync)

  const { handleSubmit } = useFormReturn

  if (!data || isLoading) {
    return <></>
  }
  return (
    <Modal
      fullWidth
      large
      modalClass={styles.modal}
      isOpen={modalState?.type === 'UPDATE'}
      onClose={handleClose}
    >
      {
        isModalContent ? (
          <>
            <h1 className={styles.title}>
              {`${t('cropStock.updateCropStock')} - ${data.product.name} 
          (${data.label.name})`}
            </h1>
            <div className={classNames(styles.infoWrapper, styles.marginBottom)}>
              <p className={styles.label}>{`${t('cropStock.cropStock')} (${data?.lastCropStockDate}) `}</p>
              <p className={styles.value}>{`${data.total} ${data?.unit ? t(`units.${data.unit.toLowerCase()}`) : ''}`}</p>
            </div>
            <form onSubmit={handleSubmit(onsubmit)}>
              <ChangeCropStockFormField control={useFormReturn.control}/>
              <div className={classNames(
                styles.infoWrapper,
                styles.marginTop,
                styles.marginBottom
              )}>
                <p className={styles.label}>{t('cropStock.cropStock')}</p>
                <p className={styles.value}>
                  {`${total} ${data?.unit ? t(`units.${data.unit.toLowerCase()}`) : ''}`}
                </p>
              </div>
              <div className={styles.line}/>
              <div className={styles.buttonGroup}>
                <PrimaryButton disabled={isUpdateCropStockLoading} type="submit" text={t('cropStock.updateCropStock')}/>
                <SecondaryButton onClick={handleClose} text={t('common.cancel')}/>
              </div>
            </form>
          </>
        ) : (
          <div className={styles.spinnerWrapper}>
            <Spinner/>
          </div>
        )
      }
    </Modal>
  )
}

export default CropStockUpdateModal
