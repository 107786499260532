import { TProductIdLabel } from '../../dashboard'
import { TProductLabelItem } from '../types'

export function prepareProductSelectValue(
  { product: { id: productId }, label: { id: labelId } }: TProductLabelItem
) {
  return JSON.stringify({
    productId,
    labelId
  })
}

export function parseProductSelectValue(value: string): TProductIdLabel {
  return JSON.parse(value)
}
