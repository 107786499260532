import { useMutation } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TOfferList } from './types'

enum QueryKeys {
  ProductList = 'ProductList'
}

async function productList(searchString: string): Promise<TOfferList[]> {
  try {
    const response = await apiClient('app/market/list', {
      params: {
        searchString
      }
    })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useProductListMutation = () => {
  const key = [QueryKeys.ProductList]
  return useMutation<TOfferList[], Error, string>((searchString) => productList(searchString), {
    mutationKey: key
  })
}
