import React, { FC, useLayoutEffect } from 'react'
import { useNotFoundContext } from '../../notFound/state/NotFoundState'
import { TRole } from './types'
import { useAuthContext } from './state/useAuthState'

type TProps = {
  children: JSX.Element,
  role?: TRole
}

const RoleGuard: FC<TProps> = ({
  children,
  role

}) => {
  const { setShowNotFound } = useNotFoundContext()
  const { authData } = useAuthContext()
  const currentUserRole = authData?.user?.role

  useLayoutEffect(() => {
    if (!role) {
      return
    }

    if (role !== currentUserRole) {
      setShowNotFound(true)
    }
  }, [role])

  return children
}

export default RoleGuard
