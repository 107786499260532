import { useQuery } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TCompaniesListResponse, TGrowingPlan, TProductLabel, TProductListResponse } from './types'

enum QueryKeys{
  ProductsList = 'ProductsList',
  LabelList = 'LabelList',
  CompaniesList = 'CompaniesList',
  GrowingPlan = 'GrowingPlan'
}

async function getProductList(): Promise<TProductListResponse[]> {
  try {
    const response = await apiClient('/app/products/list')
    return response.data
  } catch (err: any) {
    throw new Error(err)
  }
}

async function getCompaniesList(): Promise<TCompaniesListResponse[]> {
  try {
    const response = await apiClient('/app/companies/list')
    return response.data
  } catch (err: any) {
    throw new Error(err)
  }
}

async function growingPlan(growingPlanId: string): Promise<TGrowingPlan> {
  try {
    const response = await apiClient(`/app/growing-plans/${growingPlanId}`)
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

async function labelList(): Promise<TProductLabel[]> {
  try {
    const response = await apiClient('/app/labels')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useCompaniesListQuery = () => useQuery<TCompaniesListResponse[], Error>(
  [QueryKeys.CompaniesList],
  getCompaniesList
)

export const useProductListQuery = () => useQuery<TProductListResponse[], Error>(
  [QueryKeys.ProductsList],
  getProductList
)

export function useLabelListQuery() {
  return useQuery<TProductLabel[], Error>([QueryKeys.LabelList], labelList)
}

export const useGrowingPlanQuery = (growingPlanId?: string | null) => {
  const key = QueryKeys.GrowingPlan
  return useQuery<TGrowingPlan, Error>(
    [key, growingPlanId],
    () => growingPlan(growingPlanId!),
    {
      enabled: !!growingPlanId
    }
  )
}
