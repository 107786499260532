import { useTranslation } from 'react-i18next'
import styles from './AddProduct.module.scss'
import { useBaseQuantityFormContext } from '../../state/BaseQuantityFormState'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'

const AddProduct = () => {
  const { t } = useTranslation()
  const { append, onlyDay } = useBaseQuantityFormContext()
  return (
    <div className={styles.wrapper}>
      <div className={styles.line}/>
      <div className={styles.buttonGroup}>
        <SecondaryButton onClick={append} text={t(onlyDay ? 'quantity.daily.addProduct' : 'quantity.weekly.addProduct')}/>
      </div>
    </div>
  )
}

export default AddProduct
