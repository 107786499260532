import { useTranslation } from 'react-i18next'
import React, { useCallback, useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import { capitalizeFirstLetter } from '../../utils/baseAdminUtils'
import Modal from '../../../../../shared/components/Modal/Modal'
import styles from './AddProductLabel.module.scss'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { useFormInit } from '../../hooks/formHooks'
import { TProductLabelTranslation } from '../../types'
import { useProductLabelContext } from '../../state/ProductLabelState'
import ProductLabelFormContent from '../ProductLabelFormContent/ProductFormContent'

function useOnSubmit(callback: () => void, { setError }: UseFormReturn<TProductLabelTranslation>) {
  const { handleAdd } = useProductLabelContext()
  return useCallback((data: TProductLabelTranslation) => {
    handleAdd(data).then(callback).catch((error) => {
      error?.errors.map(({ field, message }: any) =>
        setError(field, { message }))
    })
  }, [])
}

const AddProductLabel = () => {
  const { type } = useProductLabelContext()
  const [isModal, setIsModal] = useState(false)
  const { t } = useTranslation()
  const formReturn = useFormInit(undefined, true)
  const handleSubmit = useOnSubmit(() => setIsModal(false), { ...formReturn })

  const title = t(`admin.${type}.add${capitalizeFirstLetter(type)}`)

  return (
    <>
      <PrimaryButton
        className={styles.button}
        onClick={() => setIsModal(true)}>
        {title}
      </PrimaryButton>
      <Modal isOpen={isModal} onClose={() => setIsModal(false)}>
        <form onSubmit={formReturn.handleSubmit(handleSubmit)}>
          <h1 className={styles.formTitle} >{title}</h1>
          <ProductLabelFormContent control={formReturn.control} />
          <div className={styles.line}/>
          <div className={styles.buttonGroup}>
            <PrimaryButton className={styles.button} type="submit">{title}</PrimaryButton>
            <SecondaryButton className={styles.button} onClick={() => setIsModal(false)}>{t('common.cancel')}</SecondaryButton>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default AddProductLabel
