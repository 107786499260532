import constate from 'constate'
import { useCallback, useEffect, useState } from 'react'
import { TFilter } from '../types'
import { useRegistrationRequestsQuery, useUsersQuery } from '../queries'

const UsersState = () => {
  const [filter, setFilter] = useState<TFilter>('ACTIVE')
  const [page, setPage] = useState(1)

  useEffect(() => setPage(1), [filter])

  const usersQuery = useUsersQuery(page - 1, filter)
  const registeredAndRejectedQuery = useRegistrationRequestsQuery(page - 1, filter)

  const isUsersQuery = ['ACTIVE', 'DEACTIVATED'].includes(filter)

  return {
    handleChangeFilter: useCallback((filter: TFilter) => setFilter(filter), []),
    handleChangePage: useCallback((newPage: number) => setPage(newPage), []),
    registeredUsers: useRegistrationRequestsQuery(0, 'PENDING_APPROVAL'),
    users: isUsersQuery ? usersQuery : registeredAndRejectedQuery,
    filter

  }
}

export const [UsersProvider, useUsersContext] = constate(UsersState)
