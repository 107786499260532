import React, { ReactNode, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import GermanFlagIcon from '../../../../shared/components/icons/svg/GermanyFlag.svg'
import EnglandFlagIcon from '../../../../shared/components/icons/svg/EnglangFlag.svg'
import FrenchFlagIcon from '../../../../shared/components/icons/svg/FrenchFlag.svg'
import ItalianFlagIcon from '../../../../shared/components/icons/svg/ItalianFlag.svg'
import LanguageSwitcherLabel from '../LanguageSwitcherLabel/LanguageSwitcherLabel'
import styles from './LanguageSwitcher.module.scss'
import CustomSelectBase from '../../../../shared/components/formControls/CustomSelect/CustomSelectBase/CustomSelectBase'
import { useChangeLanguage } from '../../queries'
import { useLanguageSwitcherContext } from '../../state/LanguageSwitcherState'
import { TLanguage } from '../../../account/accountBase'
import { useAuthContext } from '../../../auth/authBase'

type TLanguageSelectOption = {
  value: TLanguage,
  label: ReactNode
}
function useLanguageSelectOptions(): TLanguageSelectOption[] {
  return [
    {
      value: 'de',
      label: <LanguageSwitcherLabel icon={GermanFlagIcon} label="Deutsch"/>
    },
    {
      value: 'en',
      label: <LanguageSwitcherLabel icon={EnglandFlagIcon} label="English"/>
    },
    {
      value: 'fr',
      label: <LanguageSwitcherLabel icon={FrenchFlagIcon} label="Français"/>
    },
    {
      value: 'it',
      label: <LanguageSwitcherLabel icon={ItalianFlagIcon} label="Italiano"/>
    }
  ]
}

function useTranslate() {
  const { authData } = useAuthContext()
  const { mutateAsync } = useChangeLanguage()
  const { setLanguage, language } = useLanguageSwitcherContext()
  const { i18n } = useTranslation()

  const handleChange = useCallback(async (value: string | string[]) => {
    const language = value as TLanguage
    if (authData?.accessToken) {
      await mutateAsync(language)
    }
    await i18n.changeLanguage(language)
    setLanguage(language)
  }, [i18n, authData?.accessToken, language])

  return {
    handleChange
  }
}

const LanguageSwitcher = () => {
  const options = useLanguageSelectOptions()
  const { language } = useLanguageSwitcherContext()
  const { handleChange } = useTranslate()

  return (
    <div className={styles.selectWrapper}>
      <CustomSelectBase
        options={options}
        currentValue={language}
        handleChange={handleChange}
        selectClassName={styles.languageSelect}
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          disablePortal: false
        }}
      />
    </div>
  )
}

export default LanguageSwitcher
