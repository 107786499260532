import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import { FC } from 'react'
import PrimaryButton from '../../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import UserDetails from '../../UserDetails/UserDetails'
import DangerousButton from '../../../../../../shared/components/buttons/DangerousButton/DangerousButton'
import styles from './NewRegistrationsActions.module.scss'
import { useRegistrationRequestsStatusMutation } from '../../../queries'

type TProps = {
  id: string
}

const NewRegistrationsActions: FC<TProps> = ({ id }) => {
  const { t } = useTranslation()
  const { mutate, isLoading } = useRegistrationRequestsStatusMutation()

  const handleReject = () => {
    mutate({ userId: id, status: 'REJECTED' })
  }
  const handleApprove = () => {
    mutate({ userId: id, status: 'APPROVED' })
  }
  return (
    <>
      <UserDetails isRegistrationRequest isModalAction id={id}/>
      <PrimaryButton disabled={isLoading} className={classNames(styles.button, styles.primaryButton)} onClick={handleApprove}>{t('admin.users.table.approve')}</PrimaryButton>
      <DangerousButton disabled={isLoading} className={classNames(styles.button, styles.dangerousButton)} onClick={handleReject} text={t('common.reject')}/>
    </>
  )
}
export default NewRegistrationsActions
