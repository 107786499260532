import { FC, memo } from 'react'
import styles from '../AmountCard/AmountCard.module.scss'

type TProps = {
  label: string
  value: number
  unit: string
}
const GrowingPlanDeal: FC<TProps> = ({ label, unit, value }) => (
  <div className={styles.info}>
    <div>{label}</div>
    <div className={styles.bold}>{value}</div>
    <div className={styles.unit}>{unit}</div>
  </div>
)

export default memo(GrowingPlanDeal)
