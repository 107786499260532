import { FC } from 'react'
import classNames from 'classnames'
import DeleteBinIcon from '../../../../../shared/components/icons/DeleteBinIcon'
import styles from './PlanRemove.module.scss'

type TProps = {
  noMargin?: boolean
  removePlan: () => void
}
const PlanRemove: FC<TProps> = ({ noMargin, removePlan }) => (
  <div className={classNames(styles.buttonWrap, { [styles.noMargin]: noMargin })}>
    <button
      type="button"
      onClick={(event) => {
        event.stopPropagation()
        removePlan()
      }}
      className={styles.iconWrapper}
    >
      <DeleteBinIcon width={22} height={22}/>
    </button>
  </div>
)

export default PlanRemove
