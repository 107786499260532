import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import { FC, memo, useMemo } from 'react'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { TForm } from '../../../types'

function useOptions() {
  const { t } = useTranslation()
  return useMemo(() => ([
    {
      value: 'KG',
      label: t('units.kg')
    },
    {
      value: 'T',
      label: t('units.t')
    },
    {
      value: 'PCS',
      label: t('units.pcs')
    }
  ]), [])
}

type TProps = {
  planIndex: number
  control: Control<TForm>
  disabled: boolean
}

const UnitSelect: FC<TProps> = ({ planIndex, control, disabled }) => {
  const { t } = useTranslation()
  const options = useOptions()
  return (
    <CustomSelectControl
      control={control}
      options={options}
      disabled={disabled}
      isRequired
      name={`productPlans[${planIndex}].unit`}
      label={`${t('growingPlan.workShop.unit')} *`}
      placeholder={t('growingPlan.workShop.selectUnit') as string}
      handleError={(error) => (error as any)?.productPlans?.[planIndex]?.unit}
    />
  )
}

export default memo(UnitSelect)
