import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import CustomInput from '../formControls/CustomInput/CustomInput'
import { useValidationRules } from '../../hooks/useValidationRules'

type TProps = {
  isEmailValidation?: boolean
  isDisabled?: boolean
}
const Email: FC<TProps> = ({ isEmailValidation, isDisabled }) => {
  const { requiredRule, emailValidation } = useValidationRules()
  const { control } = useFormContext()
  const { t } = useTranslation()

  return (
    <CustomInput
      label={`${t('common.email')} *`}
      name="email"
      control={control}
      disabled={isDisabled}
      rules={{
        required: requiredRule(),
        validate: isEmailValidation ? emailValidation : undefined
      }}
    />
  )
}

export default Email
