import { FC } from 'react'
import { IconWrapper } from './iconsBase/IconWrapper/IconWrapper'
import { ReactComponent as Icon } from './svg/Time.svg'
import { TIconProps } from './iconsBase/types'

const TimeIcon: FC<TIconProps> = (props) => (
  <IconWrapper {...props}>
    <Icon/>
  </IconWrapper>
)

export default TimeIcon
