import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import styles from './ProductInformation.module.scss'
import CustomInput from '../../../../../shared/components/formControls/CustomInput/CustomInput'
import { useValidationRules } from '../../../../../shared/hooks/useValidationRules'
import ProductSelect from '../selects/ProductSelect/ProductSelect'
import LabelSelect from '../selects/LabelSelect/LabelSelect'
import CustomDatePicker from '../../../../../shared/components/formControls/CustomDatePicker/CustomDatePicker'
import ImageFileUpload from '../../../../../shared/components/formControls/FileUpload/ImageFileUpload/ImageFileUpload'
import CustomTextArea from '../../../../../shared/components/formControls/CustomTextArea/CustomTextArea'
import UnitSelect from '../selects/UnitSelect/UnitSelect'

const baseNamespace = 'sell.workShop.productInformation'
const ProductInformation = () => {
  const { t } = useTranslation()
  const { control, watch, setValue } = useFormContext()
  const { requiredRule } = useValidationRules()
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>
        {t(`${baseNamespace}.label`)}
      </h1>
      <div className={styles.inputGroup}>
        <ProductSelect/>
        <LabelSelect/>
        <UnitSelect/>
        <CustomInput
          control={control}
          name="origin"
          label={`${t('sell.workShop.inputs.originRegion.label')} *`}
          rules={{
            required: requiredRule()
          }}
        />
      </div>
      <ImageFileUpload
        imageWatch={watch('imageUrl')}
        removeImageForm={() => {
          setValue('imageUrl', undefined)
          setValue('imageKey', undefined)
        }}
        label={t('sell.workShop.productInformation.imageLabel')}
        contentClass={styles.imageFileContent}
      />
      <div className={styles.datePickerGroup}>
        <CustomDatePicker
          label={t(`${baseNamespace}.datePickers.packageData`)}
          control={control}
          name="packageDate"/>
        <CustomDatePicker
          label={t(`${baseNamespace}.datePickers.bestBeforeDate`)}
          control={control}
          name="bestBefore"/>
      </div>
      <div className={styles.textareaWrapper}>
        <CustomTextArea
          label={t(`${baseNamespace}.additionalInformation`)}
          control={control}
          name="additionalInfo"
        />
      </div>
    </div>
  )
}

export default ProductInformation
