import { FC, memo } from 'react'
import { useTranslation } from 'react-i18next'
import { TCropStock } from '../../types'
import styles from './AccordionSummary.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import { useCropStockModalContext } from '../../state/CropStockModalState'

type TProps = {
  icon: JSX.Element
} & TCropStock
const AccordionSummary: FC<TProps> = ({
  icon,
  product,
  lastCropStockDate,
  unit,
  label,
  total,
  id: productStockId
}) => {
  const { t } = useTranslation()
  const { updateModal } = useCropStockModalContext()
  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <div className={styles.titleWrapper}>
          {icon}
          <div className={styles.title}>{product.name}</div>
        </div>
        <div className={styles.circle}/>
        <p className={styles.text}>{label.name}</p>
        <div className={styles.circle}/>
        <p className={styles.text}>{`${t('cropStock.lastCropStock')} ${lastCropStockDate || '-'}`}</p>
        <div className={styles.circle}/>
        <p className={styles.text}>
          {total}
          {unit ? t(`units.${unit.toLowerCase()}`) : ''}
        </p>
      </div>
      <div className={styles.buttonWrap}>
        <PrimaryButton
          onClick={(event) => {
            event.stopPropagation()
            updateModal(productStockId)
          }}
          text={t('common.update')} />
      </div>
    </div>
  )
}
export default memo(AccordionSummary)
