import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import styles from './Payment.module.scss'
import CustomRadioGroup from '../../../../../shared/components/formControls/CustomRadioGroup/CustomRadioGroup'
import { useGetProduct } from '../../../Offer'

function useOptions() {
  const { t } = useTranslation()
  return useMemo(() => [
    {
      value: 'INVOICE',
      label: t('buy.bidOffer.payment.paymentType.options.INVOICE')
    },
    {
      value: 'ONLINE_PAYMENT',
      label: t('buy.bidOffer.payment.paymentType.options.ONLINE_PAYMENT'),
      disabled: true
    }
  ], [])
}

const Payment = () => {
  const { t } = useTranslation()
  const { control } = useFormContext()
  const options = useOptions()
  const { data } = useGetProduct()

  const {
    producer,
    anonymous
  } = data!
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.label}>{t('buy.bidOffer.payment.label')}</h1>
      <div className={styles.container}>
        <div className={styles.content}>
          <p className={styles.title}>{t('buy.bidOffer.payment.paymentType.label')}</p>
          <CustomRadioGroup
            control={control}
            name="paymentType"
            options={options}
          />
        </div>
        <div className={styles.content}>
          <p className={styles.title}>{t('buy.bidOffer.payment.billingAddress.label')}</p>
          <div className={styles.address}>
            {anonymous
              ? <p className={styles.noAddressText}>{t('buy.bidOffer.noAddress')}</p>
              : (
                <>
                  <p className={styles.description}>{producer?.companyName}</p>
                  <p className={styles.description}>{producer?.contact.fullName}</p>
                  <p className={styles.description}>{producer?.address?.address}</p>
                  <p className={styles.description}>{`${producer?.address?.zip}, ${producer?.address?.city}`}</p>
                </>
              )}

          </div>
        </div>
      </div>
    </div>
  )
}

export default Payment
