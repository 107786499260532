import { useMemo } from 'react'
import { TDaysOfWeek, useDeliveryDaysQuery } from '../../../settings'

export function useDeliveryDaysSettings() {
  const { data } = useDeliveryDaysQuery()
  return useMemo(
    () => (data
      ? Object.keys(data).filter((key) => data[key as TDaysOfWeek])
      : []),
    [data]
  )
}
