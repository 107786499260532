import { Control } from 'react-hook-form'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import CustomInput from '../../../../../shared/components/formControls/CustomInput/CustomInput'
import { useValidationRules } from '../../../../../shared/hooks/useValidationRules'
import CustomDatePicker from '../../../../../shared/components/formControls/CustomDatePicker/CustomDatePicker'
import UnitSelect from '../selects/UnitSelect/UnitSelect'
import CustomTextArea from '../../../../../shared/components/formControls/CustomTextArea/CustomTextArea'
import { floatFormat } from '../../../../../shared/utils/formUtils'
import InOutSelect from '../selects/InOutSelect/InOutSelect'
import styles from './ChangeCropStockFormField.module.scss'
import { useMobile } from '../../../../../shared/hooks/useDevice'

type TProps = {
  control: Control<any>
}
const ChangeCropStockFormField: FC<TProps> = ({ control }) => {
  const { requiredRule } = useValidationRules()
  const { t } = useTranslation()
  const isMobile = useMobile()
  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.headerContent}>
          <CustomInput
            label={`${t('cropStock.common.title')} *`}
            control={control}
            name="title"
            rules={{ required: requiredRule() }}
          />
          <div className={styles.datePickerWrapper}>
            <CustomDatePicker isRequired label={`${t('cropStock.common.date')} *`} control={control} name="date"/>
          </div>
        </div>
        <div className={styles.bodyContent}>
          <div className={styles.group}>
            <InOutSelect control={control}/>
            {!isMobile && (
              <CustomInput
                label={`${t('cropStock.common.amount')} *`}
                control={control}
                name="amount"
                rules={{ required: requiredRule() }}
                handleChange={(value) => floatFormat(value)}
              />
            )}
          </div>
          <div className={styles.amountGroup}>
            {isMobile && (
              <CustomInput
                label={`${t('cropStock.common.amount')} *`}
                control={control}
                name="amount"
                rules={{ required: requiredRule() }}
                handleChange={(value) => floatFormat(value)}
              />
            )}
            <UnitSelect disabled control={control}/>
          </div>
        </div>
      </div>
      <div className={styles.textarea}>
        <CustomTextArea label={t('cropStock.common.description')} control={control} name="description"/>
      </div>

    </div>
  )
}

export default ChangeCropStockFormField
