import { memo, useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { useProductListQuery } from '../../../../../growingPlan/BaseGrowingPlanWorkShop/queries'

function useOptions() {
  const { data } = useProductListQuery()

  return useMemo(
    () => (data?.length
      ? data.map(({ id, name }) => ({
        value: id,
        label: name
      }))
      : []),
    [data]
  )
}
const ProductSelect = () => {
  const { t } = useTranslation()
  const options = useOptions()
  const { control } = useFormContext()
  return (
    <CustomSelectControl
      control={control}
      options={options}
      isRequired
      name="productId"
      label={`${t('sell.workShop.selects.product.label')} *`}
      placeholder={t('sell.workShop.selects.product.placeholder') as string}
      handleError={(error) => (error as any).productId}
    />
  )
}
export default memo(ProductSelect)
