import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import React, { useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { UseMutateFunction } from '@tanstack/react-query'
import { TForgotPasswordForm } from '../../types'
import styles from './ForgotPassword.module.scss'
import PrimaryButton from '../../../../../shared/components/buttons/PrimaryButton/PrimaryButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import { AuthLayout } from '../../../../../layouts/primaryLayout'
import { useForgotPasswordMutation } from '../../queries'
import { useNotificationContext } from '../../../../notifications'
import Email from '../../../../../shared/components/formFields/Email'

function useFormInit() {
  return useForm<TForgotPasswordForm>({
    defaultValues: {
      email: ''
    }
  })
}

function useOnSubmit(mutate: UseMutateFunction<void, Error, TForgotPasswordForm>) {
  return useCallback((data: TForgotPasswordForm) => {
    mutate(data)
  }, [mutate])
}

function useErrorHandling(
  { setError }: UseFormReturn<TForgotPasswordForm>,
  isForgotPasswordError: boolean,
  forgotPasswordError: Error | null
) {
  useEffect(() => {
    if (isForgotPasswordError) {
      setError('email', { message: forgotPasswordError?.message })
    }
  }, [forgotPasswordError?.message, isForgotPasswordError, setError])
}

function useNotifySuccess(isSuccess: boolean) {
  const { t } = useTranslation()
  const { showSuccessNotification } = useNotificationContext()
  useEffect(() => {
    if (!isSuccess) {
      return
    }
    showSuccessNotification(t('forgotPassword.resetLinkSend'))
  }, [isSuccess, showSuccessNotification, t])
}

const ForgotPassword = () => {
  const { mutate, isError, error, isSuccess, isLoading } = useForgotPasswordMutation()
  const { t } = useTranslation()
  const useFormReturn = useFormInit()
  const onSubmit = useOnSubmit(mutate)
  const navigate = useNavigate()
  useErrorHandling(useFormReturn, isError, error)
  useNotifySuccess(isSuccess)

  const { handleSubmit } = useFormReturn
  return (
    <AuthLayout header={t('forgotPassword.forgotPassword')}>
      <FormProvider {...useFormReturn}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <Email isEmailValidation/>
          <div className={styles.buttonGroup}>
            <PrimaryButton disabled={isLoading} text={t('forgotPassword.resetLink')} type="submit"/>
            <SecondaryButton onClick={() => navigate('/login')} text={t('common.cancel')}/>
          </div>
        </form>
      </FormProvider>
    </AuthLayout>
  )
}

export default ForgotPassword
