import { useParams, useSearchParams } from 'react-router-dom'
import {
  dateFromDotDateFormat,
  dotDateFormat,
  endOfWeekCustom,
  startOfWeekCustom
} from '../../../../shared/utils/date'
import { NO_GROWING_PLAN_OPTION } from '../../BaseQuantityReports'

export const useWeeklyOrderParams = () => {
  const { growingPlanId } = useParams()
  const [searchParams] = useSearchParams()
  const date = searchParams.get('date')
  const growingPlan = growingPlanId ?? searchParams.get('growingPlanId')
  const isNoGrowingPlan = growingPlan === NO_GROWING_PLAN_OPTION

  return {
    buyerId: searchParams.get('buyerId'),
    sellerId: searchParams.get('sellerId'),
    dateFrom: date ? dotDateFormat(startOfWeekCustom(dateFromDotDateFormat(date))) : searchParams.get('dateFrom'),
    dateTo: date ? dotDateFormat(endOfWeekCustom(dateFromDotDateFormat(date))) : searchParams.get('dateTo'),
    growingPlanId: isNoGrowingPlan ? null : growingPlan
  }
}
