import { useTranslation } from 'react-i18next'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import styles from './Dashboard.module.scss'
import { useAccountDetailsQuery } from '../../../../account/accountBase'
import Spinner from '../../../../../shared/components/Spinner/Spinner'
import { DashboardProvider } from '../../state/DashboardState'
import PlanningForecast from '../PlanningForecast/PlanningForecast'
import WeeklyReports from '../WeeklyReports/WeeklyReports'
import Start from '../Start/Start'

const Dashboard = () => {
  const { t } = useTranslation()
  const { data, isLoading } = useAccountDetailsQuery()

  if (isLoading) {
    return (
      <div className={styles.spinnerWrapper}>
        <Spinner/>
      </div>
    )
  }
  return (
    <div>
      <PrimaryLayout
        size="large"
        header={(
          <div className={styles.label}>
            {`${t('start.welcome')} ${data?.firstName}`}
          </div>
        )}
      >
        <div className={styles.wrapper}>

          <div className={styles.content}>
            <PlanningForecast/>
          </div>

          <div className={styles.content}>
            <WeeklyReports/>
          </div>

          <div className={styles.content}>
            <Start/>
          </div>
        </div>
      </PrimaryLayout>
    </div>
  )
}

export default () => (
  <DashboardProvider>
    <Dashboard/>
  </DashboardProvider>
)
