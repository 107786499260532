import React, { FC } from 'react'
import classNames from 'classnames'
import { MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './DropDownMenu.module.scss'
import Menu from '../../../../shared/components/menu/Menu'
import UserIcon from '../../../../shared/components/icons/UserIcon'
import ArrowDownIcon from '../../../../shared/components/icons/ArrowDownIcon'
import { TMenuOptions } from '../../types'
import { navigationBaseKey } from '../../constants/translate'
import { useAccountDetailsQuery } from '../../../../features/account/accountBase'

type TProps = {
 options: {
   label: string,
   handleSelect: () => void
 }[]
}
const DropDownMenu: FC<TProps> = ({ options }) => {
  const { data } = useAccountDetailsQuery()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const isOpen = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        onClick={handleClick}
        className={classNames(styles.content, { [styles.bordered]: isOpen })}
      >
        <div className={styles.logoWrapper}>
          {data?.company?.logoUrl
            ? (
              <img
                src={data.company.logoUrl}
                alt="logo"
                className={styles.logo}/>
            )
            : (
              <div className={styles.userIconWrapper}>
                <UserIcon/>
              </div>
            )}
        </div>
        <div className={styles.icon}>
          <ArrowDownIcon width={20} height={20}/>
        </div>
      </button>
      <Menu
        size="small"
        anchorEl={anchorEl}
        isOpen={isOpen}
        onClose={handleClose}
      >
        {
          options.map(({ label, handleSelect }) => (
            <MenuItem
              key={label}
              onClick={() => {
                handleSelect()
                handleClose()
              }}>
              {label}
            </MenuItem>
          ))
        }
      </Menu>
    </div>
  )
}

export default DropDownMenu
