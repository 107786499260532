import { memo } from 'react'
import styles from './NotificationIcon.module.scss'
import CloseFillIcon from '../../../../shared/components/icons/CloseFill'

const NotificationIcon = () => (
  <div id="crossIcon" className={styles.iconWrapper}>
    <CloseFillIcon width={20} height={20}/>
  </div>
)
export default memo(NotificationIcon)
