import { FC, memo } from 'react'
import styles from './WeeklyReportCard.module.scss'

type TProps = {
  product: string
  label: string
  quantity: number
  unit: string
}
const WeeklyReportCard: FC<TProps> = ({ product, label, quantity, unit }) => (
  <div className={styles.card}>
    <p className={styles.product}>{product}</p>
    <p className={styles.label}>{label}</p>
    <div className={styles.quantityWrap}>
      <p>{quantity}</p>
      <span className={styles.unit}>{unit}</span>
    </div>
  </div>
)

export default memo(WeeklyReportCard)
