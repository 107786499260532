import {
  TDaysOfWeek,
  TDeliveryDays,
  TSettingsForm,
  TSettingsSeller,
  TUpdateSettingsSeller
} from '../types'

export function prepareSettingsSellersForm(settingsSellers: TSettingsSeller[]) {
  return settingsSellers.reduce((acc, {
    id, allowSellerInitDailyOrderProcess }) =>
    ({ ...acc, [id]: allowSellerInitDailyOrderProcess }), {})
}

const daysOfWeekMap: TDaysOfWeek[] = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']

export const prepareDaysOfWeekMutation = (formData: TSettingsForm) => daysOfWeekMap.reduce((acc,
  daysOfWeek) => ({
  ...acc,
  [daysOfWeek]: formData[daysOfWeek] }),
  {} as TDeliveryDays)

export const prepareSettingsSellersMutation = (formData: TSettingsForm) =>
  Object.keys(formData).map((key) => {
    if (daysOfWeekMap.includes(key as TDaysOfWeek)) {
      return undefined
    }
    return {
      id: key,
      allowSellerInitDailyOrderProcess: formData[key]
    }
  }).filter((value) => value) as unknown as TUpdateSettingsSeller[]
