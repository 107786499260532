import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import BaseAccordion from '../../../../../shared/components/Accordion/Accordion'
import Table from '../Table/Table'
import AccordionSummary from '../AccordionSummary/AccordionSummary'
import { TAnalyticsWeekly } from '../../types'

type TProps = {
  analyticsWeeklyData: TAnalyticsWeekly
  defaultExpanded: boolean
}
const Accordion: FC<TProps> = ({ analyticsWeeklyData, defaultExpanded }) => {
  const { t } = useTranslation()
  return (
    <BaseAccordion
      defaultExpanded={defaultExpanded}
      summary={(icon) => (
        <AccordionSummary
          icon={icon}
          label={analyticsWeeklyData.label.name}
          productName={analyticsWeeklyData.product.name}/>
      )}
      detail={(
        <Table
          tableData={analyticsWeeklyData.gpItems}
          totalAmountPerWeek={analyticsWeeklyData.totalAmountPerWeek}
        />
      )}/>
  )
}

export default Accordion
