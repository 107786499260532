import { useMutation, useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { TImageUploadResponse, TInnerCirceListFinder, TSellImage } from './types'
import { apiClient } from '../../Interceptor'

enum QueryKeys{
  InnerCircleFinder = 'InnerCircleFinder'
}

async function innerCircListFinder(): Promise<TInnerCirceListFinder[]> {
  try {
    const response = await apiClient('/app/inner-circle/list/finder')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useInnerCircleListFinderQuery = () => {
  const key = [QueryKeys.InnerCircleFinder]

  return useQuery<TInnerCirceListFinder[], Error>(key, innerCircListFinder)
}

async function uploadImageSell({
  file,
  contentType,
  contentLength,
  fileName
}: TSellImage): Promise<string> {
  try {
    const { data: { preSignedUrl, key } } = await apiClient.put<TImageUploadResponse>(
      '/app/offer/product/init-upload', { fileName, contentType, contentLength }
    )
    await axios.put(preSignedUrl, file, {
      headers: {
        'Content-Length': contentLength,
        'Content-Type': contentType
      }
    })

    return key
  } catch (e: any) {
    throw new Error(e)
  }
}

export function useUploadImageSellMutation() {
  return useMutation<string, Error, TSellImage>((file) => uploadImageSell(file))
}
