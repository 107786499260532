import { useTranslation } from 'react-i18next'
import { FC, useCallback } from 'react'
import DangerousButton from '../../../../../shared/components/buttons/DangerousButton/DangerousButton'
import SecondaryButton from '../../../../../shared/components/buttons/SecondaryButton/SecondaryButton'
import styles from './DeleteModal.module.scss'
import { TOfferDeleteParams } from '../../types'
import { useDeleteOfferMutation } from '../../queries'
import Modal from '../../../../../shared/components/Modal/Modal'

function useDeleteOffer(handleClose: () => void, offerId?: string) {
  const { mutateAsync } = useDeleteOfferMutation()

  return useCallback(
    () => {
      if (!offerId) {
        return undefined
      }
      return mutateAsync(offerId).then(handleClose)
    },
    [offerId]
  )
}

type TProps = {
  handleClose: () => void
  offerDeleteParams?: TOfferDeleteParams
}
const DeleteModal: FC<TProps> = ({ handleClose, offerDeleteParams }) => {
  const { t } = useTranslation()

  const handleDelete = useDeleteOffer(
    handleClose,
    offerDeleteParams?.id
  )

  if (!offerDeleteParams) {
    return null
  }

  const { title, id } = offerDeleteParams
  return (
    <Modal
      fullWidth
      isOpen={!!id}
      onClose={handleClose}>
      <div className={styles.wrapper}>
        <h1 className={styles.label}>
          {`${t('sell.list.delete.label')}`}
        </h1>
        <div className={styles.description}>
          {t('sell.list.delete.description')}
          <span className={styles.bold}>
            {` ${title}`}
          </span>
          { ` ${t('sell.list.delete.offer')} ?`}
        </div>
        <div className={styles.line}/>
        <div className={styles.buttonGroup}>
          <DangerousButton onClick={handleDelete} text={t('common.delete')}/>
          <SecondaryButton onClick={handleClose} text={t('common.cancel')}/>
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal
