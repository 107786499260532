import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TOfferList } from './types'

enum QueryKeys{
  OfferList = 'OfferList',
  DeleteOffer = 'DeleteOffer'
}

async function listOffers(): Promise<TOfferList[]> {
  try {
    const response = await apiClient('app/offer/list')
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export function useListOffers() {
  const key = [QueryKeys.OfferList]
  return useQuery<TOfferList[], Error>(key, listOffers)
}

async function deleteOffer(offerId: string): Promise<void> {
  try {
    await apiClient.delete(`app/offer/${offerId}`)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useDeleteOfferMutation = () => {
  const key = [QueryKeys.DeleteOffer]
  const queryClient = useQueryClient()

  return useMutation<void, Error, string>(
    (offerId) => deleteOffer(offerId),
    {
      mutationKey: key,
      onSuccess: () => queryClient.invalidateQueries([QueryKeys.OfferList])
    }
  )
}
