import { useTranslation } from 'react-i18next'
import { useMemo } from 'react'
import { NavigationBoard } from '../../../BaseNavigatioBoard'

export function useTradingNavigationOptions() {
  const { t } = useTranslation()
  return useMemo(() => [
    {
      to: '/buy',
      text: t('mainLayout.navigation.buy')
    },
    {
      to: '/sell',
      text: t('mainLayout.navigation.sell')
    }
  ], [t])
}

const Trading = () => {
  const { t } = useTranslation()
  const navigationOptions = useTradingNavigationOptions()
  return (
    <NavigationBoard
      navigations={navigationOptions}
      heading={t('navigationBoard.trading.header')}
      description={t('navigationBoard.trading.description')}
      title={t('navigationBoard.trading.title')}
    />
  )
}

export default Trading
