import constate from 'constate'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TLanguage, useAccountDetailsQuery } from '../../account/accountBase'

function useSetLanguage(setLanguage: Dispatch<SetStateAction<TLanguage>>) {
  const { data } = useAccountDetailsQuery()
  const { i18n } = useTranslation()
  useEffect(() => {
    if (!data || !data?.language) {
      return
    }
    i18n.changeLanguage(data.language).then(() => {
      setLanguage(data.language)
    })
  }, [data])
}

const LanguageSwitcherState = () => {
  const [language, setLanguage] = useState<TLanguage>('de')
  useSetLanguage(setLanguage)

  return {
    language,
    setLanguage
  }
}

export const [
  LanguageSwitcherProvider,
  useLanguageSwitcherContext
] = constate(LanguageSwitcherState)
