import { Control } from 'react-hook-form'
import { FC, memo, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { useProductListQuery } from '../../../../../growingPlan/BaseGrowingPlanWorkShop/queries'

function useOptions() {
  const { data } = useProductListQuery()

  return useMemo(
    () => (data?.length
      ? data.map(({ id, name }) => ({
        value: id,
        label: name
      }))
      : []),
    [data]
  )
}

type TProps = {
  control: Control<any>
}
const ProductSelect: FC<TProps> = ({ control }) => {
  const { t } = useTranslation()
  const options = useOptions()

  return (
    <CustomSelectControl
      control={control}
      options={options}
      isRequired
      name="productId"
      label={`${t('cropStock.common.product')} *`}
      placeholder={t('cropStock.create.selectProduct') as string}
      handleError={(error) => (error as any).productId}
    />
  )
}

export default memo(ProductSelect)
