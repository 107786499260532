import { useTranslation } from 'react-i18next'
import PrimaryLayout from '../../../../../layouts/primaryLayout/components/primaryLayout/PrimaryLayout'
import Tabs from '../Tabs/Tabs'
import Table from '../Table/Table'
import { UsersProvider, useUsersContext } from '../../state/UsersState'
import styles from './Users.module.scss'

const Users = () => {
  const { t } = useTranslation()
  const { registeredUsers: { data } } = useUsersContext()
  return (
    <div>
      <PrimaryLayout size="large" header={t('admin.users.title')}>
        <div className={styles.wrapper}>
          {data && <Tabs/>}
          <Table/>
        </div>
      </PrimaryLayout>
    </div>
  )
}

export default () => (
  <UsersProvider>
    <Users/>
  </UsersProvider>
)
