import { useTranslation } from 'react-i18next'
import { Control } from 'react-hook-form'
import { FC, memo, useMemo } from 'react'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import styles from './Unit.module.scss'

function useOptions() {
  const { t } = useTranslation()
  return useMemo(() => ([
    {
      value: 'KG',
      label: t('units.kg')
    },
    {
      value: 'T',
      label: t('units.t')
    },
    {
      value: 'PCS',
      label: t('units.pcs')
    }
  ]), [])
}

type TProps = {
  control: Control<any>
  disabled?: boolean
}

const UnitSelect: FC<TProps> = ({ control, disabled }) => {
  const { t } = useTranslation()
  const options = useOptions()
  return (
    <div className={styles.wrapper}>
      <CustomSelectControl
        control={control}
        options={options}
        disabled={disabled}
        isRequired
        name="unit"
        label={`${t('cropStock.common.unit')} *`}
        placeholder={t('cropStock.create.selectUnit') as string}
        handleError={(error) => (error as any)?.unit}
      />
    </div>
  )
}

export default memo(UnitSelect)
