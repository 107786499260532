import { useMutation } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TForm } from '../BaseGrowingPlanWorkShop'

async function createGrowingPlan(data: TForm): Promise<void> {
  try {
    await apiClient.post('/app/growing-plans', data)
  } catch (err: any) {
    throw new Error(err)
  }
}

export const useCreateGrowingPlanMutation = () => useMutation<void, Error, TForm>(
  (data) => createGrowingPlan(data)
)
