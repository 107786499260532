import React, { FC } from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styles from './ProductFormContent.module.scss'
import CustomInput from '../../../../../shared/components/formControls/CustomInput/CustomInput'
import { useValidationRules } from '../../../../../shared/hooks/useValidationRules'
import { useProductLabelContext } from '../../state/ProductLabelState'

type TProps = {
  control: Control<any>
}
const ProductLabelFormContent: FC<TProps> = ({ control }) => {
  const { requiredRule } = useValidationRules()
  const { type } = useProductLabelContext()
  const { t } = useTranslation()
  return (

    <div className={styles.formFields}>
      <CustomInput
        label={`${t(`admin.${type}.nameDeutsch`)} *`}
        name="de"
        rules={{
          required: requiredRule()
        }}
        control={control}
      />
      <CustomInput
        label={`${t(`admin.${type}.nameEnglish`)} *`}
        name="en"
        rules={{
          required: requiredRule()
        }}
        control={control}
      />
      <CustomInput
        label={t(`admin.${type}.nameFrance`)}
        name="fr"
        control={control}
      />
      <CustomInput
        label={t(`admin.${type}.nameItalian`)}
        name="it"
        control={control}
      />
    </div>
  )
}

export default ProductLabelFormContent
