import { useMutation } from '@tanstack/react-query'
import { apiClient } from './utils/apiClient'
import { TAuthData, useAuthContext } from '../auth/authBase'

enum QueryKeys{
  RefreshToken = 'RefreshToken'
}

async function refreshToken(refreshToken?: string): Promise<TAuthData> {
  try {
    const response = await apiClient.post('/auth/token/refresh', { refreshToken })
    return response.data
  } catch (e) {
    throw new Error()
  }
}

export const useRefreshTokenMutation = () => {
  const { authData, setAuthData } = useAuthContext()

  return useMutation<TAuthData, Error>(() => refreshToken(authData?.refreshToken), {
    mutationKey: [QueryKeys.RefreshToken],
    onSuccess: (data) => {
      setAuthData(data)
    }
  })
}
