import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FC, memo, useMemo } from 'react'
import CustomSelectControl
  from '../../../../../../shared/components/formControls/CustomSelect/CustomSelectControl/CustomSelectControl'
import { TTradeInUnit } from '../../../types'

const optionsValue: TTradeInUnit[] = [
  'IFCO_6408', 'IFCO_6410', 'IFCO_6413',
  'IFCO_6416', 'IFCO_6418', 'IFCO_6420',
  'IFCO_6424', 'MIGROS_AN', 'MIGROS_BN',
  'MIGROS_CN', 'MIGROS_UN', 'G1', 'G2', 'G3'
]

function useOptions() {
  const { t } = useTranslation()
  return useMemo(() => optionsValue.map((value) => ({
    value,
    label: t(`tradeInOptions.${value}`)
  })), [])
}

type TProps = {
  disabled: boolean
  quantityIndex: number
  control: Control<any>
}
const TradeInSelect: FC<TProps> = ({ quantityIndex, control, disabled }) => {
  const { t } = useTranslation()
  const options = useOptions()
  return (
    <CustomSelectControl
      disabled={disabled}
      control={control}
      options={options}
      isRequired
      label={`${t('quantity.selects.tradeIn.label')} *`}
      placeholder={t('quantity.selects.tradeIn.placeholder') as string}
      name={`orderItems[${quantityIndex}].tu`}
      handleError={(error) => (error as any)?.orderItems?.[quantityIndex]?.tu}
    />
  )
}

export default memo(TradeInSelect)
