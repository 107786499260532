import { FC, memo, ReactNode } from 'react'
import styles from './InfoCard.module.scss'

type TProps = {
  title: string
  description?: string
  children?: ReactNode
}

const InfoCard: FC<TProps> = ({ title, description, children }) => (
  <div className={styles.wrapper}>
    <p className={styles.title}>{title}</p>
    {children || <h2 className={styles.description}>{description}</h2>}
  </div>
)

export default memo(InfoCard)
