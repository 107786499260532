import React, { FC, memo, useEffect, useMemo } from 'react'
import classNames from 'classnames'
import { UseFormRegister } from 'react-hook-form'
import styles from './GrowingPlanWorkShopBody.module.scss'
import GrowingPlanWorkShopTable from '../GrowingPlanWorkShopTable/GrowingPlanWorkShopTable'
import { useGrowingPlanWorkShopFormContext } from '../../state/GrowingPlanWorkShopFormState'
import Accordion from '../../../../../shared/components/Accordion/Accordion'
import AccordionSummary from '../AccordionSummary/AccordionSummary'
import { useGrowingPlanAccordionContext } from '../../state/GrowingPlanAccordionState'
import { useValidationRules } from '../../../../../shared/hooks/useValidationRules'

function useRegisterField(
  register: UseFormRegister<any>,
  planIndex: number,
  isActive: boolean
) {
  const { requiredRule } = useValidationRules()
  useEffect(() => {
    register(`productPlans[${planIndex}].productId`, { required: requiredRule() })
    register(`productPlans[${planIndex}].unit`, { required: requiredRule() })
  }, [isActive, planIndex, register, requiredRule])
}

type TProps = {
  planIndex: number
}
const GrowingPlanWorkShopBody: FC<TProps> = ({ planIndex }) => {
  const { formReturn: { control, register } } = useGrowingPlanWorkShopFormContext()
  const { activeAccordion, handleAccordion } = useGrowingPlanAccordionContext()
  const isActive = useMemo(
    () => planIndex === activeAccordion,
    [activeAccordion, planIndex]
  )
  useRegisterField(register, planIndex, isActive)

  return (
    <div className={classNames(styles.wrapper, { [styles.marginTop]: !!planIndex })}>
      <Accordion
        key={activeAccordion}
        expanded={isActive}
        isPaddingSmall
        onChange={handleAccordion(planIndex)}
        summary={(icon) => (
          <AccordionSummary
            isActive={isActive}
            planIndex={planIndex}
            control={control}
            icon={icon}/>
        )}
        detail={(
          isActive
            ? <GrowingPlanWorkShopTable planIndex={planIndex}/>
            : null
        )}/>
    </div>
  )
}

export default memo(GrowingPlanWorkShopBody)
