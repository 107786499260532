import { useQuery } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TStockForecastParams, TStockForecastResponse, TWeeklyOverview } from './types'
import { dotDateFormat, endOfWeekCustom, startOfWeekCustom } from '../../../shared/utils/date'

enum QueryKeys{
  WeeklyOverview = 'WeeklyOverview',
  StockForecast = 'StockForecast',

}

async function getWeeklyOverview(date: Date): Promise<TWeeklyOverview[]> {
  const firstDate = dotDateFormat(startOfWeekCustom(date))
  const lastDate = dotDateFormat(endOfWeekCustom(date))
  try {
    const response = await apiClient('app/dashboard/weekly/overview',
      { params: { firstDate, lastDate } })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useWeeklyOverviewQuery = (date: Date) => {
  const keys = [QueryKeys.WeeklyOverview, date]
  return useQuery<TWeeklyOverview[], Error>(keys, () => getWeeklyOverview(date), { cacheTime: 0 })
}

async function getStockForecast(params: TStockForecastParams): Promise<TStockForecastResponse> {
  try {
    const response = await apiClient('app/dashboard/stock-forecast',
      { params })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useStockForecastQuery = (params?: TStockForecastParams) => {
  const keys = [QueryKeys.StockForecast, params?.date, params?.productId, params?.label]

  return useQuery<TStockForecastResponse, Error>(
    keys,
    () => getStockForecast(params!),
    {
      enabled: !!params
    }
  )
}
