import { useMutation } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TForm } from '../BaseInnerCircle'

export enum QueryKeys{
  CreateInnerCircle = 'CreateInnerCircle',
}

async function createInnerCircle(data: TForm) {
  try {
    await apiClient.post('app/inner-circle', data)
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useCreateInnerCircleMutation = () => useMutation<void, Error, TForm>(
  (data) => createInnerCircle(data),
  {
    mutationKey: [QueryKeys.CreateInnerCircle]
  }
)
