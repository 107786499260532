import { useQuery } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'

enum QueryKeys {
  Logout = 'Logout'
}

async function logout() {
  try {
    await apiClient.post('/auth/logout')
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useLogoutQuery = () => useQuery<void, Error>(
  [QueryKeys.Logout],
  logout,
  {
    enabled: false
  }
)
