import { useTranslation } from 'react-i18next'
import { FC, memo } from 'react'
import { useSearchParams } from 'react-router-dom'
import BreadCrumb from '../../../../../shared/components/BreadCrumb/BreadCrumb'
import styles from './Header.module.scss'

type TProps = {
  title: string
}
const Header: FC<TProps> = ({ title }) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const isDashboard = !!searchParams.get('dashboard')
  return (
    <div className={styles.wrapper}>
      {isDashboard && <BreadCrumb noPadding navigateTo="/dashboard" label={t('backToDashboard')}/>}
      <h1 className={styles.title}>{title}</h1>
    </div>
  )
}

export default memo(Header)
