import { useQuery } from '@tanstack/react-query'
import { apiClient } from '../../Interceptor'
import { TProductLabelItem } from './types'

enum QueryKeys{
  ProductsLabel = 'ProductsLabel',
}

async function getProductLabel(date: string): Promise<TProductLabelItem[]> {
  try {
    const response = await apiClient('app/dashboard/product-label/list', { params: { date } })
    return response.data
  } catch (e: any) {
    throw new Error(e)
  }
}

export const useGetProductLabelQuery = (date: string) => {
  const keys = [QueryKeys.ProductsLabel, date]
  return useQuery<TProductLabelItem[], Error>(keys, () => getProductLabel(date))
}
